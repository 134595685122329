import moment from 'moment';
import Loader from 'react-loader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateFilter } from 'utils/DateFilter';
import { Pagination, getTIMEZONE } from 'utils';
import { goToCustomerDetailsPage } from 'utils/navigations';
import { getCustomerList, getSearchCustomerList } from 'Redux/actions/customers';
import { viewIntoSpecificTimeZone } from '../../utils/common';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: '',
      term: '',
      count: 0,
      start_date: new Date(),
      end_date: new Date(),
      page: 10,
      is_loaded: true,
      is_submitted: true,
      error_message: '',
      partnerId: 'HDFC',
      is_search: false,
      selected: 0,
    };
    this.getCustomersList = this.getCustomersList.bind(this);
    this.getCustomersListFromSearch = this.getCustomersListFromSearch.bind(this);
  }

  componentDidMount = () => {};

  getCustomersList(page=1) {
    let { start_date, end_date, partnerId, is_search, search_term, term } = this.state
    let data = {}
    let {tz} = this.props.common;
    this.setState({is_loaded: false, is_submitted: true})
    if (is_search) {
      data[search_term] = term
    }
    else {
      data["start_date"]= start_date && getTIMEZONE(moment.utc(start_date).startOf('day'))
      data["end_date"]= end_date && getTIMEZONE(moment.utc(end_date).endOf('day'))
    }
    this.props.getCustomerList({
      page,
      data,
      partnerId
    })
    .then((docs) => {
      // docs.user.map(user => user["account_number"]='1')
      this.setState({
        customerList:docs.user,
        count : docs.count,
        is_loaded: true,
      })
    })
    .catch(({error}) => {
      this.setState({
        error_message: error,
        customerList: [],
        count: 0,
        is_loaded: true,
      })
    })
    this.setState({is_search: false,})
  }

  getCustomersListFromSearch(){
    let { search_term } = this.state;
    this.setState({ is_loaded: false, is_submitted: true });
    this.props
    .getSearchCustomerList({
      search_term,
      userId: this.props.auth.id,
    })
    .then((docs) => {
      // docs.user.map(user => user["account_number"]='1')
      this.setState({
        customerList: docs,
        count: docs.length,
        is_loaded: true,
      });
    })
    .catch(({ error }) => {
      this.setState({
        error_message: error,
        customerList: [],
        count: 0,
        is_loaded: true,
      });
    });
  this.setState({ is_search: false });
  }

  onPaginationChange = ({ page }) => {
    this.setState({ page, selected: page });
    this.getCustomersList(page)
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDateChange = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  onSearch = () => {
    this.setState({ is_search: true }, () => {
      this.getCustomersListFromSearch();
    });
  };

  reset = () => {
    this.setState(
      {
        is_search: false,
        search_term: '',
        term: '',
      },
      () => this.getCustomersList()
    );
  };

  render() {
    let { is_loaded, customerList, count } = this.state;
    return (
      <div>
        <SearchFilter
          state={this.state}
          onChange={this.onChange}
          onSearch={this.onSearch}
          reset={this.reset}
        />
        <br />
        <DateFilter
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          partnerId={this.state.partnerId}
          onSubmit={() => this.getCustomersList(1)}
          onChange={this.onChange}
          onChangeDate={this.onDateChange}
          {...this.props}
        />
        <br />
        <Loader loaded={is_loaded}>
          {customerList &&
          Array.isArray(customerList) &&
          customerList.length > 0 ? (
            <div className='card'>
              <div className='card-body'>
                <div className='float float-right'>Count: {count}</div>
                <table className='table table-striped'>
                  <thead>
                    <TableHeaders />
                  </thead>
                  <tbody>
                    {customerList.map((data, index) => (
                      <TableBody
                        data={data}
                        key={index}
                        index={index}
                        // goToCustomerDetailsPage={goToCustomerDetailsPage}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              {/* {
                <Pagination
                  style={{ align: 'center' }}
                  count={this.state.count}
                  onClick={({ page }) => this.onPaginationChange({ page })}
                  pageBound={5}
                  selected={this.state.selected}
                />
              } */}
            </div>
          ) : (
            <h3 style={{ textAlign: 'center' }}> No data Found </h3>
          )}
        </Loader>
      </div>
    );
  }
}

const TableHeaders = ({ customerList }) => {
  return (
    <tr>
      <th scope='col'>Email</th>
      <th scope='col'>Name</th>
      <th scope='col'>Account Number</th>
      <th scope='col'>Phone Number</th>
      <th scope='col'>Partner</th>
      <th scope='col'>Created At</th>
    </tr>
  );
};

const TableBody = ({ data }) => {
  const accountNumber =
    Object.keys(data).includes('dw') && data.dw.length > 0
      ? data.dw[0].accountNo
      : '';
  return (
    <tr>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => goToCustomerDetailsPage({ userId: data._id })}
      >
        {data.username}
      </td>
      <td>{data.fullname}</td>
      <td>{accountNumber}</td>
      <td>
        {data.phone || (data.dw && data.dw.length > 0 && data.dw[0].phoneHome)}
      </td>
      <td>{data.riaId ? data.riaId : 'STOCKAL'}</td>
      <td>{data.createdAt && viewIntoSpecificTimeZone(data.createdAt)}</td>
    </tr>
  );
};

const search_keys = {
  fullname: 'Name',
  username: 'Email',
  phone: 'Phone Number',
  'dw.userID': 'UserID',
};

const SearchFilter = ({ state, onChange, onSearch, reset }) => {
  let { search_term, term } = state;
  let searchInputClassName = search_term === 'phone' ? 'number-control' : '';
  return (
    <React.Fragment>
      <div className='form-row'>
        <div className='form-group col-md-3'>
          <input
            className={`form-control`}
            name='search_term'
            value={search_term}
            onChange={onChange}
          />
        </div>
        <div className='form-group col-md-2'>
          <button className='btn btn-primary btn-stockal' onClick={onSearch}>
            Search
          </button>
        </div>
        {/* <div className="form-group col-md-2">
        <select 
          className="form-control"
          value={search_term}          
          onChange={onChange}
          name="search_term"  
        > 
        <option value="" >Search By </option>
        { 
          Object.keys(search_keys).map((data) => (<option key={data} value={data}>{search_keys[data]}</option>)
        )}
        </select>
        </div> */}
        {/* {search_term && search_term !== '' && (
          <React.Fragment>
            <div className='form-group col-md-2'>
              <input
                className={`${searchInputClassName} form-control`}
                type={search_term === 'phone' ? 'number' : 'text'}
                name='term'
                value={term}
                onChange={onChange}
              />
            </div>
            <div className='form-group col-md-2'>
              <button
                className='btn btn-primary btn-stockal'
                onClick={onSearch}
              >
                Search
              </button>
            </div>-
          </React.Fragment>
        )} */}
        {/* {term !== '' || search_term !== '' ? (
          <div className='form-group col-md-1'>
            <button className='btn btn-secondary' onClick={reset}>
              Reset
            </button>
          </div>
        ) : null} */}
      </div>
      <div className='form-row'></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ common, auth }) => {
  return { common, auth };
};

export default connect(mapStateToProps, { getCustomerList, getSearchCustomerList })(List);
