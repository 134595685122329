

export const goToComments = ({userId, applicantName, applicantEmail, category, categoryID}) => {
  let url = `/comments/${category}/${userId}/${applicantEmail}/${applicantName}/${categoryID}`
  if(userId !== categoryID){
    url = `${url}/${categoryID}`
  }
  window.open( url, '_blank');
}


export const goToCustomerDetailsPage = ({userId}) => {
  window.open(`/customer_details/${userId}`)
}

export const goToLRSDetails = (lrsid) => {
  // this.props.history.push(`/lrs/lrs_details/${lrsid}`)
  window.open(`/lrs/lrs_details/${lrsid}`, '_blank');
}