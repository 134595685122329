import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_ENV, SECRET_KEY} from '../../Redux/apiConstant';
import axios from 'axios';
import { getCustomerDetails } from 'Redux/actions/customers';
import BasicDetails from './BasicDetails';
import EditBasicDetails from './EditBasicDetails';
import LrsDetails from './LrsDetails';
import PaymentDetails from './PaymentDetails';
import { DWDetails } from './DWDetails';
import DwDetailsNew from './DwDetailsNew';
import { resetWarningCache } from 'prop-types';
import { CAN_EDIT_CUSTOMER } from 'utils/roles'
import Loader from 'react-loader';



class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      showBasicDetail: false,
      showPersonalDetail: false,
      showAddress: false,
      showEmployment: false,
      showInvestingProfile: false,
      firstName: '',
      lastName: '',
      emailAddress1: '',
      phoneHome: '',
      dob: '',
      gender: '',
      is_loaded: true,
      plans: [],
    };
    this.reset = this.reset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount = () => {
    this.getCustomerDetails();
    this.setState({ plans: this.props.common.plans })
  };

  static getDerivedStateFromProps(props, state) {
    if (state.plans.length === 0) {
      return { plans: props.common.plans }
    }
    return null
  }



  handleChange(e) {
    e.target
      ? this.setState({ [e.target.name]: e.target.value })
      : this.setState({ dob: e });
  }

  async submit() {
    console.log(this.state.dob);
    const userID = this.state.details.user.dw[0].userID;

    const config = {
      headers: {
        'stockal-secret-key': SECRET_KEY,
        userID: userID,
      },
    };
    try {
      const res = await axios.post(
        `https://devapi.stockal.com/v2/users/update`,
        {
          firstName: this.state.firstName,
          username: this.state.emailAddress1,
          lastName: this.state.lastName,
          emailAddress1: this.state.emailAddress1,
          phoneHome: this.state.phoneHome,
          dob: this.state.dob,
          gender: this.state.gender,
        },
        config
      );

      if (res.data.code === 200) {
        // console.log('HI');
        // this.setState({
        //   details: {},
        // showBasicDetail: false,
        // showPersonalDetail: false,
        // showAddress: false,
        // showEmployment: false,
        // showInvestingProfile: false,
        // firstName: '',
        // lastName: '',
        // emailAddress1: '',
        // phoneHome: '',
        // dob: '',
        // gender: '',
        // });
      }
    } catch (err) {
      console.error(err);
    }
  }

  reset(value) {
    this.setState({
      showBasicDetail: false,
      showPersonalDetail: false,
      showAddress: false,
      showEmployment: false,
      showInvestingProfile: false,
      firstName: '',
      lastName: '',
      emailAddress1: '',
      phoneHome: '',
      dob: '',
      gender: '',
    });
    value === 'basic_detail'
      ? this.setState({
          showBasicDetail: true,
          showPersonalDetail: false,
          showAddress: false,
          showEmployment: false,
          showInvestingProfile: false,
        })
      : value === 'address'
      ? this.setState({
          showBasicDetail: false,
          showPersonalDetail: false,
          showAddress: true,
          showEmployment: false,
          showInvestingProfile: false,
        })
      : value === 'employment'
      ? this.setState({
          showBasicDetail: false,
          showPersonalDetail: false,
          showAddress: false,
          showEmployment: true,
          showInvestingProfile: false,
        })
      : this.setState({
          showBasicDetail: false,
          showPersonalDetail: false,
          showAddress: false,
          showEmployment: false,
          showInvestingProfile: true,
        });
  }

  getCustomerDetails = () => {
    this.props
      .getCustomerDetails({
        userId: this.props.match.params.userId,
        partnerId: 'HDFC',
      })
      .then((details) => {
        this.setState({ details, is_loaded:false });
      });
  };

  render() {
    let { details, plans } = this.state;
    // console.log('p------->', plans);
    let loader = <div className='loaderProfile'></div>;
    return( <React.Fragment>
      {this.state.is_loaded ? <div>{loader}</div> : 
      details.user ? (
      // details.user ? (
        <div>
         
          <BasicDetails
            user={details && details.user}
            refCode={details && details.refCode}
            auth={this.props.auth}
            userId={this.props.match.params.userId}
          />
          <br />
          {details.user.dw && details.user.dw[0] ? 
            CAN_EDIT_CUSTOMER.includes(this.props.auth.roleTitle)?
              (<DwDetailsNew  dw = {details.user.dw[0]} {...this.props} getDetails={this.getCustomerDetails}/>)
              :
              (<DWDetails dw={details && details.user && details.user.dw} />)
          : <div><h4>Broker data not available</h4></div>}
          <br />
          <LrsDetails lrs={details && details.lrs} /> <br />
          <PaymentDetails payment={details && details.payment} plans={this.state.plans} /> <br />
        </div>
      // ) : (
      //   <div>No Data Found</div>
      // )
    ) : (
      <div>No Data Found</div>
    )
    }
    </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, { getCustomerDetails })(Details);
