import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { dateToString, viewIntoSpecificTimeZone } from 'utils/common';
import { default as DataGrid } from 'utils/DataGrid/index';
import { DateRangePicker } from 'rsuite';
import { debounce } from 'lodash';
import { getPartnerOptions } from 'utils/common';
import { getInstaPaymentData } from 'Redux/actions/instapayment';

class Instapayment extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(start.getDate() - 2);
        this.onDateChange = this.onDateChange.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.debouncedSave= undefined;
        this.state = {
            minStart: new Date('2019-08-15'),
            start,
            end,
            partnerId: '',
            payments: [],
            plans: {},
            filterBy: 'Registration',
            page: 1,
            pageSize: 0,
            apiCall: false,
            filter:{partnerId:'ALL'},
        }; 
        this.partnerOptions = getPartnerOptions({...props.auth, ...props.common});
        this.columns = [
            { key: "createdAt", 
                name: "Initiated Date", 
                formatter: this.DateFormatter, 
                filterRenderer: p => 
                <DateRangePicker block placeholder="2021-01-01"
                    showOneCalendar
                    onChange= {(range) => {
                        this.setState({start: range[0], end: range[1]});
                        const { filter, pageSize } = this.state;
                        this.props.getInstaPaymentData(this.props.auth.id, viewIntoSpecificTimeZone(range[0]), viewIntoSpecificTimeZone(range[1]), filter, 1, pageSize)
                    }}
                />
            },
            { 
                key: "id", 
                name: "Transaction ID", 
                formatter: ({colum, row})=>row['_id'],
                filterRenderer: p => <input type='search' 
                    className="form-control" 
                    name="hashId"
                    onChange={ this.handleInputChange }
                    /> },
            { key: "instapayQuoteId", name: "Instapay Quote",
                formatter : ({column, row})=>row.quote ? row.quote['quoteId'] || row.quote['quoteProcessId']: ''},
            { 
                key: "fullname", 
                name: "Name of Customer", 
            },
            {   key: "phone", 
                name: "Phone Number", 
            },
            { 
                key: "email", 
                name: "Email", 
            },
            { key: "username", name: "Username",
                filterRenderer: p => <input type='search' 
                className="form-control" 
                name="dwUsername"
                onChange={ this.handleInputChange }
                />},
            { key: "fixedFCAmt", name: "Amount(USD)"},

            { key: "instapayUserId", name: "Instapay User Id",
                formatter : ({column, row})=>row.userId['fairexpayId'] ? row.userId['fairexpayId']: '',
                filterRenderer: p => <input type='search' 
                className="form-control" 
                name="fUserId"
                onChange={ this.handleInputChange }
                />},
            { key: "transStatus", name: "Transaction Status",
                formatter : ({column, row})=>{
                    if (row.transaction) {
                        return row.transaction['transStatus'];
                    } else if (row.quote) {
                        return row.quote['transStatus'];
                    } else {
                        return null;
                    }
                },
            },
            { key: "riaId", name: "Partner", filterRenderer: p =>                 
                    Array.isArray(this.partnerOptions) && this.partnerOptions.length > 0?
                    (<select 
                    name='partnerId'
                    className="form-control custom-select" 
                    onChange={this.handleInputChange}
                    > 
                    {
                      this.partnerOptions.map((partner, id) => (
                        <option key={id} value={partner.code}>{partner.name}</option>
                      )) 
                    }
                  </select> ):''
            },
        ];
    }
    handleInputChange(e) {
        console.log(e.target.name, e.target.value)
        this.setState({filter:{...this.state.filter, [e.target.name] : e.target.value}});
        if (!this.debouncedSave) {
            this.debouncedSave = debounce(() => { 
            let { start, end, filter, pageSize} = this.state
            this.props.getInstaPaymentData(this.props.auth.id, viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), filter, 1 , pageSize); 
            }, 1000);
        }        
		this.debouncedSave();
    }
    getFilterData(){
        
    }
    DateFormatter ({column, row}){
        const key = column.key;
        if (row[key]) {
            return dateToString(row[key]);
        } else 
        {
            return '';
        }
    };
    componentDidMount () {
        const { start, end, filter, page, pageSize } = this.state;
        this.props.getInstaPaymentData(this.props.auth.id, viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), filter, page, pageSize);    
    }

    static getDerivedStateFromProps(props, state) {
        if (state.payments.length === 0 ) {
            return { payments: props.payments };
        }
        if (props.page !== state.page) {
            return { page: props.page };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.payments.length !== this.props.payments.length) {
            this.setState({ payments: this.props.payments, apiCall: false });
        }
    }
    
    onDateChange(name,date) {
    const max = new Date();
        if (name === 'start_date') { 
            const start = date;
            const end = new Date(date);
            end.setDate(end.getDate() + 60);
            
            this.setState({ start, end }); 
            if (max > end) {
                this.setState({ end});
            } else {
                this.setState({ end: max});
            }
        }
        if (name === 'end_date') { 
            this.setState({ 'end':date }); 
        }
    }

    scrollEvent() {
        if (!this.state.apiCall) {
            console.log('api call');
            let { page, pageSize, start, end, filter} = this.state;
            page = page + 1;
            this.props.getInstaPaymentData(this.props.auth.id, viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), filter, page, pageSize);
            this.setState({ apiCall: true});
        }
        
    }

    renderStatusRibbon () {
        if (this.props.message !== '') {
            return (
                <Loader>
                     <div className={`alert text-center ${this.props.message !== '' ? this.props.payments.length > 0 ? 'alert-danger': 'alert-primary' : 'hide' }`} role="alert">
                         {this.props.message}
                    </div>
                </Loader>
            );
        } else {
            return '';
        }
        
    }
    setFilter(filters) {
        console.log(filters);
    }
    renderSearchFilter() {      
        return (
            <Fragment>                
                <DataGrid columns={this.columns} rows={this.state.payments} message={this.props.message} 
                    scrollEvent={this.scrollEvent}
                    setFilter={this.setFilter}
                    enableFilters = {true}
                >
                    <span className="form-control"><strong>Count: {this.state.payments.length}</strong></span>
                </DataGrid>
            </Fragment>
        );
    }

    render() {
        return (<Fragment>
            <div className='card-header px-0 py-0'>
                <div className='card-body px-0 py-0 bg-white'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <ul>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>Initiated:</span>Initiated for Remittance Process to Broker</li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>HOLD:</span> There is an issue initiating Remittance</li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>COMPLETED:</span></li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>REFUNDED:</span></li>
                            </ul>
                        </div>
                        <div className='col-md-4'>
                            <ul>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>FUND_RECEIVED:</span> Funds Received</li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>WAITING_FOR_FUND:</span> Waiting for Payment</li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>PROCESSED:</span></li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>REJECTED:</span></li>
                            </ul>
                        </div>
                        <div className='col-md-4'>
                            <ul>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>FUND_RECEIVED:</span> Funds Received</li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>BENI ADDED:</span></li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>PROVIDER SELECT:</span></li>
                                <li className='font-weight-light font-italic'><span className='font-weight-bold'>SUBMIT_REQUEST:</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
             {this.renderStatusRibbon()} 
             {this.renderSearchFilter()}
        </Fragment>);
    }
}

const mapStateToProps = (props) => {
    return {
        common: props.common,
        auth: props.auth,
        payments: props.instapayment.payments,
        page: props.instapayment.page,
        message: props.instapayment.paymentsReportMessage,
    };
}

export default connect(mapStateToProps, { getInstaPaymentData })(Instapayment);


