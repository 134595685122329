import {
  GET_CUSTOMER_REPORT,
  GET_USER_REPORT,
  GET_USER_REPORT_SUCCESS,
  GET_USER_REPORT_FAIL,
  GET_USER_REPORT_RESET
  } from '../type'

const INTIAL_STATE ={
  users: [],
  usersReportMessage: '',
  customer_report: [],
  skip: 0,
  limit: 0,
  filter: 'Registration',
}

const CustomerReportReducer = (state=INTIAL_STATE, action) => {
  switch(action.type){
    case GET_CUSTOMER_REPORT:
      return {
          ...state,
          customer_report:action.payload
      }
    
    case GET_USER_REPORT:
      let users = state.users
      if (action.payload.skip === 0) {
        users = [];
      } 
      return { ...state, users, skip: action.payload.skip, limit: action.payload.limit, filter: action.payload.filter,  usersReportMessage: 'Fetching Users Report' };

    case GET_USER_REPORT_SUCCESS:
      const successUsers = [...state.users, ...action.payload];
      
      return { ...state, users: successUsers, usersReportMessage: '' };

    case GET_USER_REPORT_FAIL:
      return { ...state, users: [], usersReportMessage: 'Failed Fetching Reports. Use Export Section to Download' };

    case GET_USER_REPORT_RESET:
      return { ...state,  usersReportMessage: '' };

    default:
      return state
  }
}

export default CustomerReportReducer;
