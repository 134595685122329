

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import {store} from "./store";
import {App} from './App'
// import 'rsuite/dist/styles/rsuite-default.css';
import './css/custom.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-vertical-timeline-component/style.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import confirm alert

// import '@fontawesome/fontawesome-free/css/all.min.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
// import "react-datepicker/dist/react-datepicker.css";


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
document.getElementById("container"));











