/**
 * Create New User by escaping all the usual process
 */


import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { validateUsername, createDWAccount, createUserAccount, getCountryList } from 'Redux/actions/customers'
import OverlayLoader from 'utils/OverlayLoader';
import {DW_KEYS} from 'Redux/apiConstant'

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  username: "",
  email: "",
  password: "",
  riaId: "STOCUBE",
  message: "",
  success_message: "",
  spin: "",
  country:"",
  loaded: false,
  is_created: false,
  country_list:[]
}

class CreateNew extends Component {
  
  constructor(props){
    super(props);
    this.state = initialState
  }

  componentDidMount(){
    this.props.getCountryList()
    .then((docs)=>{
      this.setState({country_list:docs})
    })
  }

  onChange = (e) => {
    this.setState({[e.target.name]:e.target.value})
  }

   // password validation and username

  isValid = () => {
    this.setState({message:''})
    let { password, phone, spin } = this.state
    if (!/\d+/.test(password) || !/[a-zA-Z]+/.test(password) || password.length < 8 ) {
      this.setState({message: "Your password should contain at least one numeral and one alphabets & be at least 8 characters long."})
      return false; 
    }
    if (phone.length !== 10) {
      this.setState({message: "Invalid Phone Number."})
      return false; 
    }
    if (spin.length !== 4) {
      this.setState({message: "SPIN should be 4 digits."})
      return false;
    }
    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loaded: true});
    let {wlpID, referralCode} = DW_KEYS.key
    let { username, password, firstName, lastName, email, riaId, phone, spin,country } = this.state
    if (this.isValid()) {
      const dwData = {
        wlpID,
        referralCode,
        username, 
        password,
        firstName,
        lastName,      
        emailAddress1: email
      }
  
      const userData = {
        username,
        password, 
        fullname: firstName + " " + lastName,
        spin: spin,  /* s pin should be number and 4 digit only*/
        phone, 
        country,
        dwUsername: username,
        riaId
      } 
      validateUsername({username})
      .then((isExisted) => {
        if(!isExisted){
          this.props.createDWAccount(dwData)
          .then((data) => {
            this.props.createUserAccount(userData)
            .then((user) => {
              this.setState({loaded: false})
              this.setState({message: "Updated!", is_created: true}, () => {
                setTimeout(() => {
                  this.clearState()
                }, 2000);
              })

            })
          })
          .catch((err) => {
            this.setState({loaded: false})
            console.log("catch err ", err);
            alert(err)
          })  
        }
        else {
          this.setState({loaded: false})
          this.setState({message: "Username Already existed!"})
        }
      })
    }
    else {
      this.setState({loaded: false})
    }
  }

  clearState = () => {
    this.setState(initialState)
  }

  
 
  render(){
    let {
      firstName,
      lastName,
      email,
      username,
      phone,
      riaId,
      password,
      message,
      spin,
      country
    } = this.state
    return (
      <React.Fragment>
        <OverlayLoader 
          loaded={this.state.loaded}
        />
        <div className="card container" style={{width:'600px'}}>
          <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group row">
                <label className="col-sm-3 col-form-label">First Name</label>
                <div className="col-sm-9">
                  <input 
                    className="form-control"
                    type="text" 
                    name="firstName"
                    value={firstName}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Last Name</label>
                <div className="col-sm-9">
                  <input 
                    type="text" 
                    className="form-control"
                    name="lastName"
                    value={lastName}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Username</label>
                <div className="col-sm-9">
                  <input 
                    className="form-control"
                    type="text" 
                    name="username"
                    value={username}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Phone</label>
                <div className="col-sm-9">
                  <input 
                    className="form-control" 
                    maxLength={10}
                    type="text" 
                    name="phone"
                    value={phone}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Email</label>
                <div className="col-sm-9">
                  <input 
                    className="form-control"
                    type="email" 
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Password</label>
                <div className="col-sm-9">
                  <input 
                    className="form-control"
                    type="password" 
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">SPIN</label>
                <div className="col-sm-9">
                  <input 
                    maxLength={4}
                    className="form-control"
                    type="text" 
                    name="spin"
                    value={spin}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Select Country</label>
                <div className="col-sm-9">
                  <select className="form-control" name="country" value={country} onChange={this.onChange} required> 
                    <option value={""}>Select Country</option>
                    {this.state.country_list.map(country => {
                      return (
                      <option key={country.code3} value={country.code3}>{country.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Partner Code</label>
                <div className="col-sm-9">
                  <select className="form-control" name="riaId" value={riaId} onChange={this.onChange} required> 
                    <option value={""}>Select Partner</option>
                    <option value={"STOCUBE"}>CUBE</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <span style={this.state.is_created ? {color: 'green'} : {color: 'red'}}>{message} </span>  
                </div>
                <div className="col-sm-2">
                  <button type="submit" className="btn btn-primary btn-stockal float">Create</button>
                </div>
              </div>
            </form>
          </div>     
        </div>
        </React.Fragment>
      )
    }
  }

  export default connect(null, {createDWAccount, createUserAccount,getCountryList})(CreateNew)