import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { getTIMEZONE } from 'utils';
import { getCustomerList } from 'Redux/actions/customers';
import { connect } from 'react-redux';
import { goToCustomerDetailsPage } from 'utils/navigations';
import {
  API_ENDPOINT,
  DW_API_ENDPOINT,
  API_ENDPOINT_V2,
  SECRET_KEY
} from 'Redux/apiConstant';
import Loader from 'react-loader';
import { CAN_PORTFOLIO_REGENERATEBUTTON } from '../../utils/roles';

// import { getCustomerList } from 'Redux/actions/customers'

const v2Host = API_ENDPOINT_V2;

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      result: [],
      loader: false,
      query: '',
      input: '',
      completedData: [],
      search_term: 'dw.userID',
      search_error_message: '',
      holdingSection: true,
      clickUserID: '',
      is_loaded: true,
      isButtonDisabled: false,
      pending_error_message: '',
      complete_error_message: '',
      cardText: '',
      cardTextToggle: false,
      progressBar: false,
      completePortfolioLoader: true,
      regenerateButton: true,
      searchButton: true,
      searchList: [],
      showClearButton: false
    };
    // this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.getCustomersList = this.getCustomersList.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.getCompletedOrdersAPI = this.getCompletedOrdersAPI.bind(this);
    // this.getPendingOrdersAPI = this.getPendingOrdersAPI.bind(this);
    this.holdingSection = this.holdingSection.bind(this);
    this.getSearchCustomerList = this.getSearchCustomerList.bind(this);
    this.regenerateSingleTicker = this.regenerateSingleTicker.bind(this);
    this.setClickUserID = this.setClickUserID.bind(this);
    this.cardToggle = this.cardToggle.bind(this);
    this.regenerateAllTicker = this.regenerateAllTicker.bind(this);
    this.timestamp = this.timestamp.bind(this);
    this.decimalAdjust = this.decimalAdjust.bind(this);
    this.getAllOrder = this.getAllOrder.bind(this);
    this.collapse = this.collapse.bind(this);
    this.targetCollapse = this.targetCollapse.bind(this);
    this.dataTargetCollapse = this.dataTargetCollapse.bind(this);
    this.cleanSingleTicker = this.cleanSingleTicker.bind(this);
  }

  componentDidMount = () => {
    if (CAN_PORTFOLIO_REGENERATEBUTTON.includes(this.props.auth.roleTitle)) {
      this.setState({ regenerateButton: true , showClearButton: true});
    } else {
      this.setState({ regenerateButton: false , showClearButton: false});
    }
  };

  getSearchCustomerList() {
    this.setState({ is_loaded: false });
    const config = {
      headers: {
        'stockal-secret-key': SECRET_KEY,
        adminId: this.props.auth.id
      },
    };
    axios
      .get(`${v2Host}/users-dashboard/search?q=${encodeURIComponent(this.state.ID)}`, config)
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            searchList: response.data.data,
            is_loaded: true,
          });
        } else {
          this.setState({
            is_loaded: true,
            search_error_message: 'No Data Found',
            searchList: [],
          });
        }
      })
      .catch((err) => {
        console.log('something error', err);
        this.setState({
          search_error_message: 'No Data Found',
          is_loaded: true,
          searchList: [],
        });
      });
  }

  holdingSection(e) {
    this.setState({ holdingSection: e });
  }

  setClickUserID(e) {
    this.setState(
      { clickUserID: e.dw[0].userID, accountID: e.dw[0].accountId },
      () => this.getAllOrder()
    );
  }

  // new portfolio order and pending API

  getAllOrder() {
    this.setState({ completePortfolioLoader: true, holdingSection: true });
    const headers = {
      'content-type': 'application/json',
      'stockal-secret-key': SECRET_KEY,
      accountid: this.state.accountID,
      userid: this.state.clickUserID,
    };
    axios
      .get(`${v2Host}/users-dashboard/portfolio/detail`, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState(
            {
              completedData: response.data.data.holdings,
              completePortfolioLoader: false,
              pendingData: response.data.data.pendingData,
            },
            this.getPortFolioTotal()
          );
        } else {
          return this.setState(
            {
              complete_error_message: 'No Data Found',
              pending_error_message: 'No Data Found',
              completePortfolioLoader: false,
            },
            this.getPortFolioTotal()
          );
        }
      })
      .catch((err) => {
        console.log('i bought u a all portfolio error', err);
        this.setState(
          {
            complete_error_message: 'No Data Found',
            completePortfolioLoader: false,
            pending_error_message: 'No Data Found',
          },
          this.getPortFolioTotal()
        );
      });
  }

  getPortFolioTotal() {
    const headers = {
      accountid: this.state.accountID,
      userid: this.state.clickUserID,
      'stockal-secret-key': SECRET_KEY,
    };
    axios
      .get(`${v2Host}/users/accountSummary/dashboard`, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            cashBalance: response.data.data.accountSummary.cashBalance,
            stackInvestment:
              response.data.data.portfolioSummary.stackPortfolio
                .investmentAmount,
            stackCurrentValue:
              response.data.data.portfolioSummary.stackPortfolio.currentValue,
            stockInvestment:
              response.data.data.portfolioSummary.stockPortfolio
                .investmentAmount,
            stockCurrentValue:
              response.data.data.portfolioSummary.stockPortfolio.currentValue,
            etfInvestment:
              response.data.data.portfolioSummary.etfPortfolio.investmentAmount,
            etfCurrentValue:
              response.data.data.portfolioSummary.etfPortfolio.currentValue,
            totalCurrentValue: response.data.data.portfolioSummary.totalCurrentValue,
            totalInvestment: response.data.data.portfolioSummary.totalInvestmentAmount,
          });
        } else {
          return this.setState({ portfolioTotal: 'No Data Found' });
        }
      })
      .catch((err) => {
        console.log('i bought u a error', err);
        this.setState({
          portfolioTotal: 'No Data Found',
          stackInvestment: '--',
          stackCurrentValue: '--',
          stockInvestment: '--',
          stockCurrentValue: '--',
          cashBalance: '--',
          etfCurrentValue: '--',
          etfInvestment: '--',
          totalInvestment: '--',
          totalCurrentValue: '--'
        });
      });
  }

  regenerateSingleTicker(e) {
    this.setState({ isButtonDisabled: true, completePortfolioLoader: true });
    const headers = {
      'stockal-secret-key': SECRET_KEY,
    };
    const body = {
      userIDs: e.userID,
      category: e.category,
      symbol: e.ticker,
    };
    axios
      .post(`${v2Host}/users-dashboard/portfolio/regenerate`, body, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          setTimeout(
            () =>
              this.setState(
                {
                  progressBar: true,
                  cardText: 'Portfolio Retriggered',
                  cardTextToggle: true,
                },
                this.cardToggle()
              ),
            5000
          );
          setTimeout(
            () =>
              this.setState(
                {
                  cardText: 'Successfull',
                  cardTextToggle: true,
                  progressBar: false,
                  isButtonDisabled: false,
                },
                this.getAllOrder(),
                this.cardToggle()
              ),
            10000
          );
        } else {
          this.setState(
            {
              completePortfolioLoader: false,
              isButtonDisabled: false,
              cardText: 'Unsuccessful',
            },
            this.cardToggle()
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            cardText: 'Unsuccessful',
            completePortfolioLoader: false,
            isButtonDisabled: false,
          },
          this.cardToggle()
        );
      });
  }

  regenerateAllTicker(e) {
    this.setState({ isButtonDisabled: true, completePortfolioLoader: true });
    const headers = {
      'stockal-secret-key': SECRET_KEY,
    };
    const body = {
      userIDs: this.state.clickUserID,
      category: 'all',
      symbol: 'all',
    };
    axios
      .post(`${v2Host}/users-dashboard/portfolio/regenerate`, body, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          setTimeout(
            () =>
              this.setState(
                {
                  progressBar: true,
                  cardText: 'Portfolio Retriggered',
                  cardTextToggle: true,
                },
                this.cardToggle()
              ),
            5000
          );

          setTimeout(
            () =>
              this.setState(
                {
                  cardText: 'Successfull',
                  cardTextToggle: true,
                  progressBar: false,
                  isButtonDisabled: false,
                },
                this.getAllOrder(),
                this.cardToggle()
              ),
            10000
          );
        } else {
          this.setState(
            {
              completePortfolioLoader: false,
              isButtonDisabled: false,
              cardText: 'Unsuccessful',
            },
            this.cardToggle()
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            cardText: 'Unsuccessful',
            completePortfolioLoader: false,
            isButtonDisabled: false,
          },
          this.cardToggle()
        );
      });
  }

  cardToggle() {
    setTimeout(() => this.setState({ cardTextToggle: false }), 1000);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      ID: e.target.value,
      searchButton: false,
    });
  };

  onSearch = () => {
    this.setState({ is_search: true }, () => {
      this.getCustomersList();
    });
  };

  timestamp(value) {
    if (value === 'undefined') {
      return '--';
    } else {
      return new Date(value).toISOString();
    }
  }

  decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
  }

  collapse(e) {
    this.setState({collapseStocks:e.stocks})
  }

  targetCollapse(e) {
    if (e.includes('&')){
       let y = e.replace('&','');
       return `accordion_${y}`
    } else {
        return `accordion_${e}`
    }
  }

  dataTargetCollapse(e){
    if (e.includes('&')){
        let y = e.replace('&','');
        return `#accordion_${y}`
     } else {
         return `#accordion_${e}`
     }
  }


  // clear button Api Call
  cleanSingleTicker(e) {
    this.setState({ isButtonDisabled: true, completePortfolioLoader: true });
    const headers = {
      'stockal-secret-key': SECRET_KEY,
      adminId: this.props.auth.id
    };
    const body = {
      userIDs: e.userID,
      category: e.category,
      symbol: e.ticker,
    };
    axios
      .post(`${v2Host}/dashboard/portfolio/clean-regenerate`, body, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          setTimeout(
            () =>
              this.setState(
                {
                  progressBar: true,
                  cardText: 'Stock Data Clear',
                  cardTextToggle: true,
                },
                this.cardToggle()
              ),
            5000
          );
          setTimeout(
            () =>
              this.setState(
                {
                  cardText: 'Successfull',
                  cardTextToggle: true,
                  progressBar: false,
                  isButtonDisabled: false,
                },
                this.getAllOrder(),
                this.cardToggle()
              ),
            10000
          );
        } else {
          this.setState(
            {
              completePortfolioLoader: false,
              isButtonDisabled: false,
              cardText: 'Unsuccessful',
            },
            this.cardToggle()
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            cardText: 'Unsuccessful',
            completePortfolioLoader: false,
            isButtonDisabled: false,
          },
          this.cardToggle()
        );
      });
  }

//   curl --location --request POST 'http://localhost:3001/v2/dashboard/portfolio/clean-regenerate' \
// --header 'stockal-secret-key: CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF' \
// --header 'adminid: 5d7bb370d6802408898de40f' \
// --header 'Content-Type: application/json' \
// --data-raw '{
//     "userIDs": "c196ab86-ed08-4155-a713-b07b37199dfd",
//     "category": "stock",
//     "symbol": "MSFT"
// }'


  render() {
    let { is_loaded } = this.state;
    let loader = <div className='loaderProfile'></div>;
    return (
      <div>
        <SearchFilter
          state={this.state}
          onChange={this.onChange}
          onSearch={this.onSearch}
          getSearchCustomerList={this.getSearchCustomerList}
          portfolioData={this.getPortFolioTotal}
          searchButton={this.state.searchButton}
        />

        <Loader loaded={is_loaded}>
          {this.state.cardTextToggle ? (
            <div className='card SuccessfulCard'>
              <div className='card-body '>
                <p className='SuccessText'>{this.state.cardText}</p>
              </div>
            </div>
          ) : (
            ''
          )}

          <table className='table table-striped'>
            <thead>
              <TableHeaders />
            </thead>
            <tbody className='accordion' id='accordionExample'>
              {this.state.searchList &&
              Array.isArray(this.state.searchList) &&
              this.state.searchList.length > 0 ? (
                this.state.searchList.map((data, index) => (
                  <TableBody
                    data={data}
                    // key={index}
                    index={index}
                    getAllOrder={this.getAllOrder}
                    // getPendingOrdersAPI = {this.getPendingOrdersAPI}
                    newList={this.newList}
                    loader={loader}
                    completedData={this.state.completedData}
                    // loader={is_loaded}
                    holdingSection={this.state.holdingSection}
                    holding={this.holdingSection}
                    pendingData={this.state.pendingData}
                    currentStackValue={this.state.stackCurrentValue}
                    currentStockValue={this.state.stockCurrentValue}
                    investmentStackValue={this.state.stackInvestment}
                    investmentStockValue={this.state.stockInvestment}
                    cashBalance={this.state.cashBalance}
                    regenerateSingleTicker={this.regenerateSingleTicker}
                    isButtonDisabled={this.state.isButtonDisabled}
                    pending_error_message={this.state.pending_error_message}
                    complete_error_message={this.state.complete_error_message}
                    setClickUserID={this.setClickUserID}
                    progressBar={this.state.progressBar}
                    completePortfolioLoader={this.state.completePortfolioLoader}
                    regenerateAllTicker={this.regenerateAllTicker}
                    regenerateButton={this.state.regenerateButton}
                    timestamp={this.timestamp}
                    decimalAdjust={this.decimalAdjust}
                    collapse={this.collapse}
                    collapseStocks={this.state.collapseStocks}
                    targetCollapse={this.targetCollapse}
                    dataTargetCollapse={this.dataTargetCollapse}
                    investmentEtfValue={this.state.etfInvestment}
                    totalInvestment={this.state.totalInvestment}
                    totalCurrentValue={this.state.totalCurrentValue}
                    cleanSingleTicker={this.cleanSingleTicker}
                    showClearButton={this.state.showClearButton}
                  />
                ))
              ) : (
                <h3>{this.state.search_error_message}</h3>
              )}
            </tbody>
          </table>
        </Loader>
      </div>
    );
  }
}

const TableHeaders = ({}) => {
  return (
    <tr className='portfolioHead'>
      <th scope='col-3'>UserID</th>
      <th scope='col-2'>Name</th>
      <th scope='col-2'>Account Number</th>
      <th scope='col-2'>UserName</th>
      <th scope='col-2'>Partner</th>
    </tr>
  );
};

const TableBody = ({
  data,
  index,
  getAllOrder,
  completedData,
  holdingSection,
  holding,
  pendingData,
  investmentStackValue,
  investmentStockValue,
  investmentEtfValue,
  cashBalance,
  regenerateSingleTicker,
  isButtonDisabled,
  setClickUserID,
  progressBar,
  loader,
  completePortfolioLoader,
  regenerateAllTicker,
  regenerateButton,
  timestamp,
  decimalAdjust,
  collapse,
  collapseStocks,
  dataTargetCollapse,
  targetCollapse,
  totalInvestment,
  totalCurrentValue,
  cleanSingleTicker,
  showClearButton
}) => {
  const accountNumber =
    Object.keys(data).includes('dw') && data.dw.length > 0
      ? data.dw[0].accountNo
      : '';
  return (
    <React.Fragment>
      <tr
        data-toggle='collapse'
        data-target={
          data.dw && data.dw[0] && data.dw[0].userID ? `#collapse${index}` : ''
        }
        onClick={data.dw && data.dw[0] ? (e) => setClickUserID(data) : ''}
        aria-expanded='false'
        aria-controls={`collapse${index}`}
      >
        {data.dw && data.dw[0] && data.dw[0].userID ? (
          <td style={{ cursor: 'pointer' }}>
            <span className='portfolioDot'></span>
            {data.dw[0].userID}
          </td>
        ) : (
          <td style={{ cursor: 'pointer' }}>--</td>
        )}
        <td>{data.fullname}</td>
        <td>{accountNumber ? accountNumber : '--'}</td>
        {data.dw && data.dw[0] && data.dw[0].username ? (
          <td>{data.dw[0].username}</td>
        ) : (
          <td>--</td>
        )}
        <td>{data.riaId ? data.riaId : 'STOCKAL'}</td>
      </tr>

      {/* accordian */}
      <tr>
        <td colSpan='6'>
          <div
            id={`collapse${index}`}
            style={{ textAlign: 'center' }}
            className='collapse accordionCard'
            data-parent='#accordionExample'
          >
            <div className='row'>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Total Investment</p>
                {totalInvestment && totalInvestment > 0 ? (
                  <p className='font-weight-bold'>
                    ${' '}
                    {totalInvestment &&
                      decimalAdjust('floor', totalInvestment, -2)}
                  </p>
                ) : totalInvestment === 0 ? (
                  '$0'
                ) : (
                  '--'
                )}
              </div>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Stock Investment</p>
                {investmentStockValue && investmentStockValue > 0 ? (
                  <p className='font-weight-bold'>
                    ${' '}
                    {investmentStockValue &&
                      decimalAdjust('floor', investmentStockValue, -2)}
                  </p>
                ) : investmentStockValue === 0 ? (
                  '$0'
                ) : (
                  '--'
                )}
              </div>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Etf Investment</p>
                {investmentEtfValue && investmentEtfValue > 0 ? (
                  <p className='font-weight-bold'>
                    ${' '}
                    {investmentEtfValue &&
                      decimalAdjust('floor', investmentEtfValue, -2)}
                  </p>
                ) : investmentEtfValue === 0 ? (
                  '$0'
                ) : (
                  '--'
                )}
              </div>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Stack Investment</p>
                {investmentStackValue && investmentStackValue > 0 ? (
                  <p className='font-weight-bold'>
                    ${' '}
                    {investmentStackValue &&
                      decimalAdjust('floor', investmentStackValue, -2)}
                  </p>
                ) : investmentStackValue === 0 ? (
                  '$0'
                ) : (
                  '--'
                )}
              </div>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Total Current Value</p>
                {totalCurrentValue && totalCurrentValue > 0 ? (
                  <p className='font-weight-bold'>
                    ${' '}
                    {totalCurrentValue &&
                      decimalAdjust('floor', totalCurrentValue, -2)}{' '}
                  </p>
                ) : totalCurrentValue === 0 ? (
                  '$0'
                ) : (
                  '--'
                )}
              </div>
              <div className='col-2 text-left portfolioInvestmentHead'>
                <p>Cash Balance</p>
                {cashBalance && cashBalance > 0 ? (
                  <p className='font-weight-bold'>
                    $ {cashBalance && decimalAdjust('floor', cashBalance, -2)}
                  </p>
                ) : cashBalance === 0 ? (
                  '$ 0'
                ) : (
                  '--'
                )}
              </div>
            </div>
            <>
            {regenerateButton ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <button
                    className={
                      isButtonDisabled ? 'disabledRegenerate' : 'Regenerate'
                    }
                    onClick={(e) => regenerateAllTicker(data)}
                    disabled={isButtonDisabled}
                  >
                    Regenerate All
                  </button>
                </div>
              ) : (
                ''
              )}
            </>
            {/* pill button */}
            <div className='row'>
              <div
                className='btn-group btn-group-toggle'
                style={{ float: 'left', marginBottom: '20px' }}
                data-toggle='buttons'
              >
                <label
                  className='btn btn-secondary active'
                  onClick={(e) => holding(true)}
                >
                  <input
                    type='radio'
                    name='options'
                    id='option1'
                    autoComplete='off'
                    checked
                  />{' '}
                  Holding
                </label>
                <label
                  className='btn btn-secondary'
                  onClick={(e) => holding(false)}
                >
                  <input
                    type='radio'
                    name='options'
                    id='option2'
                    autoComplete='off'
                  />{' '}
                  Order
                </label>
                {progressBar ? (
                  <div className='bar'>
                    <div className='insideBar'></div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* <div className="row">
                <h6 className="text-bold">TimeStamp:{timestamp}</h6>
              </div> */}
            {completePortfolioLoader ? (
              <div>{loader}</div>
            ) : (
              <table className='table'>
                {holdingSection ? (
                  <React.Fragment>
                    <thead>
                      <tr
                        className='portfolioHead'
                        style={{ backgroundColor: 'white' }}
                      >
                        <th scope='col'>Ticker</th>
                        <th scope='col'>Category</th>
                        <th scope='col'>Investment</th>
                        <th scope='col'>Units</th>
                        <th scope='col'>Time Stamp</th>
                        <th scope='col'></th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody className='completeTableBody' className="accordion" id="accordionComplete">
                    {completedData && completedData.length > 0 ? (
                      completedData.map((item, index) => (
                        <React.Fragment>
                          <tr style={{ backgroundColor: 'white', color: 'black' }}>
                            <td  data-toggle="collapse" onClick={() => collapse(item)}
                            data-target={dataTargetCollapse(item.ticker)}
                            aria-expanded="false"
                            aria-controls={`accordion${item.ticker}`}
                            >{item.ticker} {item.category === "stack" ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
                              </svg> : "" }
                            </td>
                            <td>{item.category}</td>
                            <td>
                              $
                              {item.totalInvestment &&
                                decimalAdjust(
                                  'floor',
                                  item.totalInvestment,
                                  -2
                                )}
                            </td>
                            {item.category === 'stock' ? (
                              <td>
                                {item.totalUnit}
                              </td>
                            ) : (
                              <td>--</td>
                            )}
                            <td>{timestamp(item.timestamp)}</td>
                            {regenerateButton ? (
                              <td>
                                <button
                                  className={
                                    isButtonDisabled
                                      ? 'disabledRegenerate'
                                      : 'Regenerate'
                                  }
                                  onClick={(e) => regenerateSingleTicker(item)}
                                  disabled={isButtonDisabled}
                                >
                                  Regenerate
                                </button>
                              </td>
                            ) : (
                              ''
                            )}
                            {/* adding clear button */}
                            {showClearButton ?
                            <td>
                            <button
                                  onClick={() => cleanSingleTicker(item)}
                                  disabled={isButtonDisabled}
                                >
                                  Clear
                                </button>
                            </td> : ""}
                          </tr>
                          <div id={targetCollapse(item.ticker)} className="collapse" data-parent="#accordionComplete">
                          {collapseStocks && collapseStocks.length > 0 ? (
                          collapseStocks.map((stocks, index) => (
                            <tr>
                              <td colSpan="1">
                                {stocks.ticker}
                              </td>
                              <td colSpan="1">
                                stock
                              </td>
                              <td colSpan="1">
                                {stocks.totalInvestment}
                              </td>
                              <td colSpan="1">
                                {stocks.qty}
                              </td>
                              <td colSpan="1">
                                --
                              </td>
                              <td colSpan="1">
                                --
                              </td>
                              </tr>
                          ))) : "" }
                          </div>
                      </React.Fragment>
                      ))
                    ) : (
                      <h4>"No Data Found"</h4>
                    )}
                    </tbody>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <thead>
                      <tr
                        className='portfolioHead'
                        style={{ backgroundColor: 'white' }}
                      >
                        <th scope='col'>Ticker</th>
                        <th scope='col'>Category</th>
                        <th scope='col'>Order Amount</th>
                        <th scope='col'>Quantity</th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody>
                    {pendingData && pendingData.length > 0 ? (
                      pendingData.map((item, index) => (
                          <tr
                            style={{ backgroundColor: 'white', color: 'black' }}
                          >
                            {item.category === 'stock' ? (
                              <td>{item.code} </td>
                            ) : (
                              <td>{item.ticker} </td>
                            )}
                            <td>{item.category}</td>
                            <td>${item.investmentAmount}</td>
                            {item.category === 'stock' ? (
                              <td>{item.quantity}</td>
                            ) : (
                              <td>--</td>
                            )}
                            {/* <td><button className="Regenerate" onClick={(e) => regenerateSingleTicker(item)}>Regenerate</button></td> */}
                          </tr>
                      ))
                    ) : (
                      <h4>No Data Found</h4>
                    )}
                     </tbody>
                  </React.Fragment>
                )}
              </table>
            )}
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

const search_keys = {
  'dw.userID': 'UserID',
};

const SearchFilter = ({
  state,
  onChange,
  onSearch,
  reset,
  getSearchCustomerList,
  portfolioData,
  searchButton,
}) => {
  let { search_term, term } = state;
  let searchInputClassName = search_term === 'phone' ? 'number-control' : '';
  return (
    <React.Fragment>
      <div className='form-row'>
        <React.Fragment>
          <div className='form-group col-md-4'>
            <input
              className={`${searchInputClassName} form-control`}
              // style={{width:"400px"}}
              type={search_term === 'phone' ? 'number' : 'text'}
              name='term'
              value={term}
              onChange={onChange}
            />
          </div>
          <div className='form-group col-md-2'>
            <button
              className='btn btn-primary btn-stockal'
              style={{ width: '150px' }}
              onClick={getSearchCustomerList}
              disabled={searchButton}
            >
              Search
            </button>
          </div>
        </React.Fragment>
      </div>
      <div className='form-row'></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ common }) => {
  return { common };
};

export default connect(mapStateToProps, { getCustomerList })(Portfolio);
