import React from "react"

function Trade(){
    return(
        <div>
            <div id="accordion">
                <div class="card">
                    <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Trade Info
                        </button>
                    </h5>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div >
                        <table className="table">
                            <tr>
                                <th scope="row">Name</th>
                                <td>Nishan Gupta</td>  
                                <th>ID</th>
                                <td>#123</td>  
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td>Ishan6380@gmail.com</td>
                                <th>Status</th>
                                <td>Online</td>
                            </tr>
                            <tr>
                                <th>Plan Type</th>
                                <td>Premium</td>
                                <th>Created Date</th>
                                <td>19/03/2019</td>
                            </tr>
                            <tr>
                                <th>Contact Number</th>
                                <td>8755140457</td>
                                <th>Location</th>
                                <td>Banglore</td>
                            </tr>
                            
                        </table>
                    </div>
                    </div>
                </div>
            </div>    
        </div>
    );
}

export default Trade