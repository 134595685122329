import { ADD_NEW_COUPON } from '../type';

const CouponReducer = (prevState = null, action) => {
  switch (action.type) {
    case ADD_NEW_COUPON: {
      return [...prevState, action.payload];
    }
  }
  return [];
};

export default CouponReducer;
