
import {
    compose,
    createStore,
    applyMiddleware
} from 'redux'
import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk';

import {loadState,saveState} from './localStorage'
import rootReducer from './Redux/index.js'


const middleware = [
    thunkMiddleware,
//    logger
]
const persistedState = loadState();
const enhancers = []

export const store = createStore(
    rootReducer,
    persistedState,
    compose(
        applyMiddleware(...middleware,),
        ...enhancers
    )
)



store.subscribe((e) => {
    saveState({
        auth: store.getState().auth,
        common:store.getState().common
    })
})
