


import axios from 'axios'
import {API_ENDPOINT} from 'Redux/apiConstant'
import {getConfig} from '../getConfig'


export const registerUser = async (data) => {
    let response = await axios.post(`${API_ENDPOINT}/api/streams/dashboard/user/register`, data, getConfig())
    return response.data
}

export const getOnboardUsers = ({email}) => {
  return (dispatch) => new Promise(async(resolve, reject) => {
      axios.get(`${API_ENDPOINT}/api/streams/dashboard/user/list/${email}`, getConfig())
      .then((response) => {
          if(response.data.status === 200){
              return resolve(response.data)
          }
      })
      .catch((error) => {
          error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
          alert("Network Error") : alert("Network Error in api")
      })
  })
}

// export const register = (data) => {   
//   const config = getConfig()
//   return (dispatch) => new Promise(async(resolve, reject) => {
//       axios.post(`${API_ENDPOINT}/api/streams/dashboard/user/register`, data, config)
//       .then((response) => {
//           if(response.data.status === 200){
//               return resolve(response.data)
//           }
//       })
//       .catch((error) => {
//           error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
//           alert("Network Error") : alert("Network Error in api")
//       })
//   })
// }