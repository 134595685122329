import axios from 'axios';
import _ from 'lodash';
import {getConfig} from '../getConfig';
import { GET_STACKS, GET_STACKS_SUCCESS, GET_STACKS_FAIL, CHANGETICKER, 
  ADD_PARTNERPLAN_STACKS, ADD_PARTNERPLAN_STACKS_FAIL, ADD_PARTNERPLAN_STACKS_SUCCESS,
  DELETE_PARTNERPLAN_STACKS, DELETE_PARTNERPLAN_STACKS_SUCCESS, DELETE_PARTNERPLAN_STACKS_FAIL, DELETE_PARTNERPLAN_STACKS_RESET
} from '../type';
import { API_ENDPOINT } from '../apiConstant';


export const getStacks = (partnerID) => {
  const url = `${API_ENDPOINT}/api/stacks/get-stacks-details/${partnerID}`;
  
  return (dispatch) => {
    dispatch({ type: GET_STACKS, payload: [] });
    axios.get(url, getConfig()).then((response) => {
        if (response.status === 200) {
            const stacks = _.keyBy(response.data.docs, 'ticker');
            dispatch({ type: GET_STACKS_SUCCESS, payload: stacks});
        } else {
            dispatch({ type: GET_STACKS_FAIL, payload: []});
        }
    }).catch((err) => {
        dispatch({ type: GET_STACKS_FAIL, payload: err});
    })
  };
    
}

export const selectTicker = (ticker) => {
  return (dispatch) => {
    dispatch({ type: CHANGETICKER, payload: ticker });
  }
}

export const addPartnerPlan = (stackParnter) => {
  console.log('partneradd', stackParnter)
  const url = `${API_ENDPOINT}/api/stacks/addStackPartners`;
  return (dispatch) => {
    dispatch({ type: ADD_PARTNERPLAN_STACKS, payload: [] });
    axios.post(url, stackParnter, getConfig()).then((response) => {
        if (response.status === 200) {
            dispatch({ type: ADD_PARTNERPLAN_STACKS_SUCCESS, payload: response.data.result});
        } else {
            dispatch({ type: ADD_PARTNERPLAN_STACKS_FAIL, payload: 'Error Adding Stack Partner'});
        }
    }).catch((err) => {
        dispatch({ type: ADD_PARTNERPLAN_STACKS_FAIL, payload: err});
    })
  };
}

export const deletePartnerPlan = (ticker, partnerID, planID) => {
  const url = `${API_ENDPOINT}/api/stacks/${ticker}/partner/${partnerID}/${planID}`;
  return (dispatch) => {
    dispatch({ type: DELETE_PARTNERPLAN_STACKS, payload: [] });
    axios.delete(url, getConfig()).then((response) => {
        if (response.status === 200) {
            dispatch({ type: DELETE_PARTNERPLAN_STACKS_SUCCESS, payload: response.data.result});
            setTimeout(() => {
              dispatch({ type: DELETE_PARTNERPLAN_STACKS_RESET, payload: ''});
            }, 1000);
        } else {
            dispatch({ type: DELETE_PARTNERPLAN_STACKS_FAIL, payload: 'Error Deleting Stack Partner'});
            setTimeout(() => {
              dispatch({ type: DELETE_PARTNERPLAN_STACKS_RESET, payload: ''});
            }, 15000);
        }
    }).catch((err) => {
        dispatch({ type: DELETE_PARTNERPLAN_STACKS_FAIL, payload: err});
        setTimeout(() => {
          dispatch({ type: DELETE_PARTNERPLAN_STACKS_RESET, payload: ''});
        }, 15000);
    })
  };
}