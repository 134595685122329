import React, { Component, Fragment } from 'react';
import { getDocumentStatus } from 'utils/common';
import _ from 'lodash';
import basicImage from '../../assets/images/basic.svg';
import addressImage from '../../assets/images/address.svg';
import employmentImage from '../../assets/images/employment.svg';
import investingImage from '../../assets/images/investing.svg';


let status_json = [
  'accountStatus',
  'addressProofStatus',
  'idStatus',
  // 'kycStatus',
];
export class DWDetails extends Component {


  renderSideMenu () {
    console.log();
    return (<Fragment>
      {this.props.dw[0].accountStatus === 2 && (
      <div className='row d-flex justify-content-end'>
        <div data-toggle='tooltip' data-placement='bottom' title='Edit Basic Details' className='col-2'>
          <img alt= '' type='button' src={basicImage} data-toggle='modal' data-target='#exampleModalCenter' value='basic_detail'
            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Basic Details" />
        </div>
        <div data-toggle='tooltip' data-placement='bottom' title='Edit Personal Details' className='col-2'>
          <img alt= '' type='button' src={addressImage} data-toggle='modal' data-target='#exampleModalCenter' value='address' />
        </div>
        <div data-toggle='tooltip' data-placement='bottom' title='Edit Address' className='col-2'>
          <img alt='' type='button' src={employmentImage} data-toggle='modal' data-target='#exampleModalCenter' value='employment' />
        </div>
        <div data-toggle='tooltip' data-placement='bottom' title='Edit Investin Profile' className='col-2'>
          <img alt='' type='button' src={investingImage} data-toggle='modal' data-target='#exampleModalCenter' value='investing_profile' />
        </div>
      </div>
    )}
    </Fragment>)
  }
  render() {
    let dw = this.props.dw;
    if (dw && dw.length > 0) {
      var keys = _.keys(dw[0]);
    }
    return (
      <div className='card'>
        <div className='card-header ' id='headingOne'>
          <div className="pull-left"> Broker Data</div>
          {/* <div className="pull-right"> {this.renderSideMenu()}</div> */}
        </div>
        <div
          id='collapseOne'
          className='collapse show'
          aria-labelledby='headingOne'
          data-parent='#accordion'
        >
          <div className='card-body'>
            <table className='table'>
              {keys &&
                keys.length > 0 &&
                keys.map((k) => {
                  return (
                    dw[0][k] !== '' && (
                      <tr key={k}>
                        {typeof dw[0][k] === 'object' &&
                        !Array.isArray(dw[0][k]) &&
                        dw[0][k] !== null ? null : (
                          <th>{k.toUpperCase()}</th>
                        )}
                        {typeof dw[0][k] === 'object' &&
                        !Array.isArray(dw[0][k]) &&
                        dw[0][k] !== null ? null : (<Fragment>
                          <td>
                            {status_json.includes(k)
                              ? dw[0][k] &&
                                getDocumentStatus({ statusCode: dw[0][k] })
                              : dw[0][k]}
                          </td>
                          <td>
                            <span className="fas fa-edit mr-2"></span>
                          </td></Fragment>
                        )}
                      </tr>
                    )
                  );
                })}
            </table>
            {/* <table className="table"> 
              <thead>
                <tr> 
                  <th>Account Status</th>
                  <th>Address Proof</th>
                  <th>Id Proof</th>
                  <th>Annual Income</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-toggle="tooltip" data-placement="top" title={accountReason}>{accountStatus && getDocumentStatus({statusCode: accountStatus})}</td>
                  <td data-toggle="tooltip" data-placement="top" title={addressProofReason} >{addressProofStatus && getDocumentStatus({statusCode: addressProofStatus})}</td>
                  <td data-toggle="tooltip" data-placement="top" title={idReason}>{idStatus && getDocumentStatus({statusCode: idStatus})}</td>
                  <td>{annualIncome}</td>
                  <td>{username}</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
    );
  }
}

// accountApprovedWhen: null
// accountReason: " Background check PENDING."
// accountStatus: 1
// addressLine1: "kayyoonnupara , City Hospital Road"
// addressLine2: "Kottayam"
// addressProofReason: "Proof of Address is approved!"
// addressProofStatus: 2
// annualIncome: "$25,000 - $99,999"
// citizenship: "IND"
// city: "Kottayam"
// countryID: "IND"
// director: ""
// directorOf: ""
// dob: "1981-01-25"
// emailAddress1: "arun2151@gmail.com"
// employerIsBroker: ""
// employmentStatus: "Employed/Self-Employed"
// firstName: "Arun"
// gender: "male"
// idNo: "AHUPA6567H"
// idReason: "Picture ID is approved!"
// idStatus: 2
// investmentExperience: "Extensive"
// investmentObjectives: "Growth"
// kycStatus: 1
// kycWhen: null
// lastName: "Aravind"
// networthLiquid: "$5,000 - $99,999"
// networthTotal: "$200,000+"
// phoneHome: "9388643890"
// politicallyExposed: ""
// politicallyExposedNames: ""
// riskTolerance: "Aggressive"
// stateProvince: "Kerala"
// userID: "c1a8c2af-c161-4b04-83c1-b7ac10e7b0e2"
// username: "arun2151"
// zipPostalCode: "686001
