import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dateToString } from 'utils/common';
import { updateCustomer } from 'Redux/actions/customers';

class PersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount () {
        this.setState({
            dob: this.props.dw.dob,
            politicallyExposed: this.props.dw.politicallyExposed,
            politicallyExposedSelect: (this.props.dw.politicallyExposed === '' || this.props.dw.politicallyExposed === null) ? 'No': 'Yes',
            politicallyExposedNames: this.props.dw.politicallyExposedNames,
            gender: this.props.dw.gender
        });
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    onChange(e) {
        console.log('name',e.target.name,e.target.value)
        this.setState({[e.target.name]: e.target.value})
    }

    onDateChange(d) {
        const dob = dateToString(d.toString());
        this.setState({ dob})
    }
    async onSubmit() {
        const update = {
            type: 'PERSONAL_INFO',
            body: {
                dob: this.state.dob,
                // politicallyExposed: (this.state.politicallyExposedSelect === 'Yes') ? true : false,
                politicallyExposedNames: (this.state.politicallyExposedSelect === 'Yes') ? this.state.politicallyExposedNames : "null",
                gender: this.state.gender,
            },
            userID: this.props.dw.userID,
        };
        await this.props.updateCustomer(update);
        // await this.props.getDetails();
        this.setState({ edit: false });
        // await this.props.getCustomerDetails();
    }
    renderBasicDetails () {
        console.log('details', this.props);
        console.log(new Date(this.state.dob))
        return (<Fragment>
            <div className='card'>
            <div className='card-header ' id='headingOne'>
                <div className='pull-left'>Personal Details</div>
                <div className='pull-right'>
                {
                    !this.state.edit ? (
                        <span className="fas fa-edit mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    ) 
                    : 
                    (
                        <span className="fas fa-times mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    )
                }
                
                </div>
            </div>
            <div className={this.state.edit? 'hide' : 'card-body'}>
                <div className= 'row my-0'>
                    <div className='col-md-2'><strong>Date Of Birth:</strong></div>
                    <div className='col-md-2'>{this.props.dw.dob}</div>
                    <div className='col-md-2'><strong>Politically Exposed:</strong></div>
                    <div className='col-md-2'>{(this.props.dw.politicallyExposed === '' || this.props.dw.politicallyExposed === null) ? 'No' : this.props.dw.politicallyExposed }</div>
                    <div className='col-md-1'><strong>Gender:</strong></div>
                    <div className='col-md-2'>{this.props.dw.gender}</div>
                </div>
            </div>
            <div className={!this.state.edit? 'hide' : 'card-body'}>
                <div className= 'row my-0'>
                    <div className='col-md-2 col-form-label'><strong>Date Of Birth</strong></div>
                    <div className='col-md-2'>
                        <DatePicker
                            dateFormat='yyyy-MM-dd'
                            selected={this.state.dob? new Date(this.state.dob) : new Date()}
                            onChange={this.onDateChange}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // minDate={start_date && start_date}
                            // maxDate={ end_date }
                        />
                    </div>
                    <div className='col-md-2 col-form-label'><strong>Politically Exposed:</strong></div>
                    <div className='col-md-1'>
                        <select className="custom-select custom-select-md" value = { this.state.politicallyExposedSelect }
                            name="politicallyExposedSelect" onChange={this.onChange}
                        >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    {
                        this.state.politicallyExposedSelect === 'Yes' ? (
                            <React.Fragment>
                        <div className='col-md-1 col-form-label'><strong>Name</strong></div>    
                        <div className='col-md-1'>
                            <input type="text" name="politicallyExposedNames" className="form-control" value={this.state.politicallyExposedNames} onChange={this.onChange} />
                        </div>
                        </React.Fragment>): ''
                    }
                    
                    <div className='col-md-1 col-form-label'><strong>Gender:</strong></div>
                    <div className='col-md-2'>
                        <select className="custom-select custom-select-md" name="gender" value={this.state.gender|| ''} onChange={this.onChange}>
                            <option>select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div>
                <div className= 'row pt-4 justify-content-center'>
                <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Save</button>
                </div>
            </div>
            </div>
        </Fragment>);
    }
    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}

export default connect(null, {updateCustomer})(PersonalDetails)
