import axios from 'axios'
import { API_ENDPOINT_V2, SECRET_KEY } from 'Redux/apiConstant'


export const getPortfolioSearch = async ({ID, userId}) => {
    const config = {  headers : {
        'stockal-secret-key': SECRET_KEY,
        adminid: userId,
    }};
  ID = encodeURIComponent(ID);
    const url = `${API_ENDPOINT_V2}/users-dashboard/search?q=${ID}`
    try{
       let response = await axios.get(url, config)
       console.log('responseReport',response)
       if (response.data.code === 200){
          return response
       }
       else
          throw response
    }
    catch (e) {
         alert("no account found")
    }
 }
