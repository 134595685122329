export const API_ENDPOINT = process.env.REACT_APP_API;
export const API_ENDPOINT_V2 = process.env.REACT_APP_API_V2;
export const DW_API_ENDPOINT = process.env.REACT_APP_DW;
export const SECRET_KEY = process.env.REACT_APP_KEY;
export const DW_KEYS = {
  key: {
    wlpID: process.env.REACT_APP_WLPID,
    referralCode: process.env.REACT_APP_REFERRALCODE, //BO - prod code
  },
};

export const API_ENV = process.env.REACT_APP_API_ENV;
