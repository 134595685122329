import React, { Component } from 'react';
import {verifyUser, verifyOTP} from 'Redux/actions/auth';
import { isUserExist } from 'Redux/actions/auth';

export class ForgotPassword extends Component {
 constructor(props){
   super(props);
   this.state={
     email:"",
     otp:"",
     error:'',
     isVerifyemail:false
     
  }
 }




 onChange = (event) => {
  this.setState({
    [event.target.name]: event.target.value
  })
}
isValid = () => {
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regexEmail.test(this.state.email)) {
    this.setState({
        error: "Invalid Email"
    })
    return false
  }
  return true
}
onSubmit = (e) => {
  e.preventDefault()
  if (this.state.isVerifyemail===true) {
     let{email,otp} = this.state
     if(otp.length !== 6){
       this.setState({
         error: "Please enter a 6 digit OTP"
       })
     }
     else{
        verifyOTP({
          email:email,
          otp:otp
        })
        .then((response)=>{
            if(response.status === 200){
              this.setState({error:""});
              this.props.history.push({
                pathname: '/ForgotChangePassword',
                //search: '?query=abc',
                state: {email: email}
              })
            }
            if(response.status === 404){
              this.setState({error:"Otp incorrect, Please try again"})
            }
            else{
              this.setState({error:response.message})
            }
        })
     }
  }
  else{
  
  let {email} = this.state
  if (email !== '')  {
    if(this.isValid()){
      isUserExist({email})
      .then(response => {
        if(response.status === 200){
          verifyUser({email})
          .then(response => {
              if(response.status === 200){
                this.setState({isVerifyemail:true, error: ""})
              }
          })
          .catch((err) => {
            console.log("error ", err);
          })
         }
         else{
           this.setState({error: "User doesn't Exist"})
         }
      })
      .catch((err) => {
        console.log("err ", err)
      })
  }       
  else {
    this.setState({
      error: "Please enter valid Email "
    })
  }
}
}
}

  render() {
    let { error, isVerifyemail } = this.state
    let showOtp = (!isVerifyemail ? 'hidden' : '')
    return (
     
      <div className="back">
      <div className="container">
        <div className="row justify-content-center" style={{padding:'10%'}}>
          <div className="col-xl-6 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Forgot Password</h1>
                      </div>
                      <form className="user" >
                        <div className="form-group">
                          <input 
                          type="email" 
                          className="form-control form-control-user" 
                          name="email" 
                          id="InputEmail" 
                          aria-describedby="emailHelp" 
                          placeholder="Enter Email Address..."
                          value={this.state.email}
                          onChange={this.onChange}
                          disabled={isVerifyemail}
                          />
                        </div>
                        
                        <div className = {`form-group ${showOtp}`}>  
                          <input
                          type="text"
                          name="otp"
                          className="form-control form-control-user" 
                          placeholder="Enter OTP"
                          maxLength="6"
                          value={this.state.otp}
                          onChange={this.onChange}
                          />
                        </div>
                        <div  className="error-msg">
                          <span>{this.state.error}</span>
                        </div>
                        <hr />
                        <div>
                        <input type="button" value="Submit" onClick={this.onSubmit}  className="btn btn-stockal btn-user btn-block"/>
                        </div>
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div> 
    )
  }
}
export default ForgotPassword