import axios from "axios"
import {API_ENDPOINT , API_ENDPOINT_V2, SECRET_KEY} from 'Redux/apiConstant'
import {
   GET_TRANSACTION_REPORT_START,
   GET_TRANSACTION_REPORT_SUCCESS,
   GET_TRANSACTION_REPORT_FAIL,
   GET_TRANSACTION_SEARCH_REPORT_START,
   GET_TRANSACTION_SEARCH_REPORT_SUCCESS,
   GET_ALL_TRANSACTION_DETAIL,
   GET_ALL_TRANSACTION_SUCCESS,
   GET_ALL_TRANSACTION_FAIL,
   GET_ALL_TRANSACTION_RESET
} from "../type";
import {getConfig} from '../getConfig'

const generateHeadersForTransaction = (fromDate, toDate,  partnerId, skip, limit, userId = "5e33c2335047cc3ee4ad548b") => {
   const headers = getConfig();
   headers.headers.fromDate = fromDate;
   headers.headers.toDate = toDate;
   headers.headers.partnerId = partnerId;
   headers.headers.skip = skip;
   headers.headers.limit = limit;
   headers.headers.userId = userId;
   return headers;
}
export const getAllTransactionDetails = (fromDate, toDate,  partnerId, skip = 0, limit = 0, userId) => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/transaction/report`;
   const headers = generateHeadersForTransaction(fromDate, toDate,  partnerId, skip, limit, userId);
   return axios.get(url, headers);
}

export const getTransactionDetail = (fromDate, toDate,  partnerId, skip, limit, userId) => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/transaction/report`;
   const headers = generateHeadersForTransaction(fromDate, toDate,  partnerId, 0, 0, userId);
   return (dispatch) => {
      dispatch({type: GET_TRANSACTION_REPORT_START, payload: ''});
      axios.get(url, headers).then((response) => {
         if(response.data.status === 200) {
            dispatch({type: GET_TRANSACTION_REPORT_SUCCESS, payload: response.data})
         }
         else {
            dispatch({type: GET_TRANSACTION_REPORT_FAIL, payload: response.data.error })
         }
      }).catch((err) => {
         dispatch({type: GET_TRANSACTION_REPORT_FAIL, payload: err })
      });
   };
}

export const getTransactionDetailBySearch = (key, value) => {
    return (dispatch) => {
       dispatch({ type: GET_TRANSACTION_SEARCH_REPORT_START, payload: ''});
       dispatch({ type: GET_TRANSACTION_SEARCH_REPORT_SUCCESS, payload:{ key, value } });
    }
}

export const getAlltransactionsDetail = (clickUserAccount, adminid) => {
   return (dispatch) => {
       dispatch({ type: GET_ALL_TRANSACTION_DETAIL, payload: ''});
       const config = {  headers : {
           'stockal-secret-key': SECRET_KEY,
           adminid,
       }};
      //  const url = `${API_ENDPOINT_V2}/trades/transactions/`;
       const url = `${API_ENDPOINT_V2}/dashboard/transactions?q=${clickUserAccount}`;
       axios.get(url, config ).then((res) => {
           if (res.data.code === 200) {
               console.log('transactionResponse',res)
               dispatch({ type: GET_ALL_TRANSACTION_SUCCESS, payload: res.data.data });
           } else {
               dispatch({ type: GET_ALL_TRANSACTION_FAIL, payload: [] });
               setTimeout(() => {
                   dispatch({ type: GET_ALL_TRANSACTION_RESET, payload: [] });
               }, 6000)
           }
       }).catch((err) => {
           dispatch({ type: GET_ALL_TRANSACTION_FAIL, payload: [] });
           setTimeout(() => {
               dispatch({ type: GET_ALL_TRANSACTION_RESET, payload: [] });
           }, 6000)
       })
   };
}