import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCountryList, updateCustomer } from 'Redux/actions/customers';

class InvestingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
        // this.edit = this.edit.bind(this);
        // this.onChange = this.onChange.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
    }

    renderBasicDetails () {
        console.log(this.props);
        return (<Fragment>
            <div className='card my-4'>
                <div className='card-header ' id='headingOne'>
                    <div className='pull-left'>Investing Details</div>
                </div>
                <div className={this.state.edit? 'hide' : 'card-body'}>
                    <div className= 'row'>
                        <div className='col-md-2'><strong>Objective:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.investmentObjectives}</div>
                        <div className='col-md-2'><strong>Experience:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.investmentExperience}</div>
                        <div className='col-md-1'><strong>Annual Income:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.annualIncome}</div>
                    </div>
                    <hr></hr>
                    <div className= 'row'>    
                        <div className='col-md-2'><strong>Net worth:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.networthLiquid}</div>

                        <div className='col-md-2'><strong>Total Net Worth:</strong></div>
                        <div className='col-md-2 text-left'>{ this.props.dw.networthTotal }</div>
                    </div>
                </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {getCountryList, updateCustomer})(InvestingDetails)