import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getClientId } from 'utils/common';
import CustomDate from './CustomDate';
import { getPartnerOptions } from 'utils/common';
import { fetchKPI } from 'Redux/actions/landing';

class LandingHeader extends Component {
    
    constructor(props) {
        super(props);
        const to = new Date();
        const from = new Date();
        from.setDate(from.getUTCDate() - from.getUTCDate() +1);
        this.state = {
            mtd: true,
            ytd: false,
            custom: false,
            from,
            to,
            partnerId: '',
            dateText: 'DATE',
        }
        
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    componentDidMount = async () => {
        const {roleId, clientCode} = this.props.auth;
        this.PARTNER_OPTIONS = getPartnerOptions({roleId, clientCode})
        const partnerId = getClientId(this.props.auth);
        this.setState({ partnerId });
        if (this.props.landing.from !== null && this.props.landing.to !== null) {
            const { mtd, ytd, custom, from, to, partnerId } = this.props.landing;
            this.setState({ mtd, ytd, custom, from, to, partnerId })
        }
        this.props.fetchKPI({...this.state, partnerId, from: moment(this.state.from).format('YYYY-MM-DD'), to: moment(this.state.to).format('YYYY-MM-DD') });
    }
    
    returnDateText (from, to) {
        const f = from ? moment(from).format('YYYY-MM-DD'): 'Select Start Date';
        const t = to ? moment(to).format('YYYY-MM-DD'): 'Select End Date';
        return `${f} \\ ${t}`
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    
    
    changeStateButton = async (e) => {
        const to = new Date();
        const from = new Date();
        const { partnerId } = this.state;
        if (e.target.name === 'mtd') {
            from.setDate(1);
            await this.setState ({ mtd: true, ytd: false, custom: false, from, to, dateText: 'DATE' });
            this.props.fetchKPI({ partnerId, mtd: true, ytd: false, custom: false, from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD'), dateText: 'DATE' });
        }
        else if (e.target.name === 'ytd') {
            from.setDate(1);
            from.setMonth(0);
            await this.setState ({ mtd: false, ytd: true, custom: false, from, to, dateText: 'DATE' });
            this.props.fetchKPI({ partnerId, mtd: false, ytd: true, custom: false, from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD'), dateText: 'DATE' });
        }
        else {
            // from.setDate(from.getDate() - 1);
            // const date = this.returnDateText(from, to);
            await this.setState ({ mtd: false, ytd: false, custom: true, from:'', to: '' });
        }
    }

    changeDates = async (key, value) =>{
        let obj = {};
        obj[key] = value;
        await this.setState(obj);
        const dateText = this.returnDateText(this.state.from, this.state.to);
        this.setState({ dateText});
        if (key === 'to' && this.state.from) {
            this.props.fetchKPI(this.state);
        }
    }
    
    render() {
        return (<Fragment>
            <div className="card">
                <div className="card-body">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-4">
                        {
                            Array.isArray(this.PARTNER_OPTIONS) ?
                                (<Fragment>
                                    <select 
                                        name='partnerId'
                                        className="color-black custom-select custom-select-md landing-header-btn  btn-light" 
                                        style={{width:'auto'}}
                                        onChange={this.onChange}
                                        value={this.state.partnerId}
                                    > 
                                        {
                                        this.PARTNER_OPTIONS.map((partner, id) => (
                                            <option key={id} value={partner.code}>{partner.name}</option>
                                        )) 
                                        }
                                    </select></Fragment>)
                                    : ''
                        }
                    </div>
                    <div className="col-1 mr-4">
                        <button className={`color-black btn landing-header-btn btn-light active ${this.state.mtd? 'selectedActive': ''}`} aria-pressed="true" placeholder="Month To Date" name="mtd" onClick= { this.changeStateButton }> MTD</button>
                    </div>
                    <div className="col-1 mr-4">
                        <button className={`color-black btn landing-header-btn btn-light active ${this.state.ytd? 'selectedActive': ''}`}  aria-pressed="true" placeholder="Month To Date" name="ytd" onClick= { this.changeStateButton }> YTD</button>
                    </div>
                    <div className="col-3">
                        <CustomDate { ...this.state} onClicked= { this.changeStateButton } changeDates={this.changeDates} name="custom"/>
                    </div>
                </div>
                
                

                    
                </div>
            </div>
        </Fragment>);
    }
}

const mapStateToProps = (state, ownProps) => {
    const { auth, landing } = state;
    return { auth, landing }
}

export default connect(mapStateToProps, { fetchKPI })(LandingHeader)