import axios from 'axios'
import { API_ENDPOINT_V2 } from 'Redux/apiConstant'


export const getPaymentsDetail = async ({nstart, nend, partnerId}) => {
   console.log('apidata',nstart)
    // const headers = {
    //  'stockal-secret-key': SECRET_KEY,
    // }; 
    const url = `${API_ENDPOINT_V2}/subscription/payments/paidSubscriptions?to=${nend}&from=${nstart}&partnerCode=${partnerId}`
    try{
       let response = await axios.get(url)
       console.log('responseReport',response)
       if (response.data.code === 200){
          return response
       }
       else
          throw response
    }
    catch (e) {
         alert(e)
    }
 }