import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { partnerStatus, getPlans } from '../../utils/common';
import { addPartnerCode, getPartnerCodes } from 'Redux/actions/common';

class CreatePartner extends Component {
  constructor(props) {
    super(props);
    const plans = getPlans();
    this.state = {
      tz: '',
      error: '',
      partnerName: '',
      partnerCode: '',
      selectedPartnerStatus: 'active',
      plans,
      selectedPlans: [],
      selectedPlansText: 'Select Plans Applicable',
    };

    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.partnerCodeChange = this.partnerCodeChange.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  partnerCodeChange(e) {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  }

  addPartnerCodeApi = (e) => {
    this.setState({ error: '' });
    let { partnerName, partnerCode, selectedPartnerStatus } = this.state;
    let data = {
      name: partnerName,
      code: partnerCode,
      createdBy: this.props.auth.id,
      status: selectedPartnerStatus,
      createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss:SSS'),
      createdByUser: this.props.auth.email,
      plans: this.state.selectedPlans,
    };
    let userPartnerCode = this.props.auth.clientCode;
    this.props
      .addPartnerCode(data, userPartnerCode)
      .then((response) => {
        this.props.getPartnerCodes();
        this.setState({
          partnerName: '',
          partnerCode: '',
          selectedPartnerStatus: '',
          error: response,
        });
        this.props.history.push(`/partner/stacks/${partnerCode}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: 'Error while creating partners' });
      });
  };

  changeSelectedPlanChange(plan) {
    console.log(plan);
    const { selectedPlans } = this.state;
    if (Object.keys(selectedPlans).length > 0) {
      if (selectedPlans[plan]) {
        delete selectedPlans[plan];
      } else {
        selectedPlans[plan] = this.state.plans[plan];
      }
    } else {
      selectedPlans[plan] = this.state.plans[plan];
    }
    let planName = [];
    Object.keys(selectedPlans).forEach((sp) => {
      planName.push(this.state.plans[sp]);
    });
    if (Object.keys(selectedPlans).length === 0) {
      planName.push('Select Plans Applicable');
    }
    this.setState({ selectedPlans, selectedPlansText: planName.join(',') });
  }

  handleCheckChange(e) {
    const plan = e.target.value;
    this.changeSelectedPlanChange(plan);
  }

  render() {
    //  const options = [/* { value: 'chocolate', label: 'Chocolate' }*/];
    let options = [];
    Object.keys(this.state.plans).forEach((plan) => {
      options.push({
        label: `${this.state.plans[plan]} - (${plan})`,
        value: plan,
      });
    });

    let { partnerCode, partnerName, selectedPartnerStatus } = this.state;
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-6 col-lg-12 col-md-9'>
            <div className='card border-0 shadow-lg my-5'>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='p-5'>
                      <div className='text-center'>
                        <h1 className='h4 text-gray-900 mb-4'>
                          Create Partner
                        </h1>
                      </div>

                      <div className='form-group '>
                        <input
                          type='text'
                          className='form-control form-control-user'
                          name='partnerName'
                          id='PartnerName'
                          placeholder='Partner Name'
                          onChange={this.onChange}
                          value={partnerName}
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <input
                          type='text'
                          className='form-control form-control-user'
                          id='partnerCode'
                          name='partnerCode'
                          placeholder='Partner Code'
                          onChange={this.partnerCodeChange}
                          value={partnerCode}
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <select
                          className='form-control'
                          name='selectedPartnerStatus'
                          id='selectedPartnerStatus'
                          onChange={this.onChange}
                          value={selectedPartnerStatus}
                          required
                        >
                          {partnerStatus.map((key) => (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group'>
                        {/* <div className="dropdown h-auto form-control px-auto py-auto">
                                    {this.state.selectedPlansText }
                                    <button type="button" className="btn btn-default dropdown-toggle float-right" data-toggle="dropdown" placeholder="Select Plan">
                                    
                                    </button>
                                      <ul className="dropdown-menu dropdown-menu-left row">
                                        { Object.keys(this.state.plans).map((key) => (
                                          <li key={key}>
                                            <button className="dropdown-item" data-value="option1" tabIndex="-1" onClick={() => this.changeSelectedPlanChange(key)}>
                                              <input value={key} onChange={this.handleCheckChange} type="checkbox"/>&nbsp;{this.state.plans[key]}
                                            </button>
                                          </li>
                                        ))}
                                    </ul>
                                  </div> */}
                        <Select
                          options={options}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          defaultValue={this.state.selectedPlans}
                          // value={this.state.selectedPlans}
                          onChange={(selectedOptions) => {
                            const plns = [];
                            selectedOptions.forEach((option) => {
                              plns.push(option.value);
                            });
                            this.setState({ selectedPlans: plns });
                          }}
                        />
                      </div>
                      <input
                        type='submit'
                        onClick={this.addPartnerCodeApi}
                        className='btn btn-primary btn-stockal btn-user btn-block'
                        value='Create Partner Code'
                      />

                      <hr />
                      <div>
                        <span style={{ color: 'red' }}>{this.state.error}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, { addPartnerCode, getPartnerCodes })(
  CreatePartner
);
