import React, { Component } from 'react'

import {
	connect
} from 'react-redux'

export default (ChildComponent) => {
	class ComposedComponent extends Component {

		componentDidMount = () => {
            this.isUserLoggedIn()
        }
    
        componentDidUpdate = () => {
            this.isUserLoggedIn()
        }
    
        isUserLoggedIn = () => {
			if(!this.props.isLoggedIn){
				this.props.history.push('/login')
			}
		}


		render(){
			return(
				<ChildComponent {...this.props}/>
			)
		}
	}

	const mapStateToProps = (state) => {
		return {
			isLoggedIn: state.auth.isLoggedIn,
		}
	}

	return connect(mapStateToProps)(ComposedComponent)
}