import React from "react";

function SearchBar(){
    return(
        <div className="row">

        
            <div className="col-xl-12 col-lg-7">
                <div className="card shadow mb-4">
                    
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">Advanced Search</h6>
                            <div className="dropdown no-arrow">
                                {/* <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a> */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                <div className="dropdown-header">Dropdown Header:</div>
                                {/* <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a> */}
                                <div className="dropdown-divider"></div>
                                {/* <a className="dropdown-item" href="#">Something else here</a> */}
                                </div>
                            </div>
                    </div>



                    <div className="container search-outline col-lg-12">
                        <div className="search-sec">
                    
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <input type="text" className="form-control search-slt" placeholder="Name"/>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                                                <input type="text" className="form-control search-slt" placeholder="Email"/>
                                            </div>
                                            <div className="col-lg-4 col-md-3 col-sm-12 p-0">
                                                <select className="form-control search-slt" id="exampleFormControlSelect1">
                                                    <option>Select date</option>
                                                    <option>JAN-FEB</option>
                                                    <option>FEB-MAR</option>
                                                    <option>MAR-APR</option>
                                                    <option>APR-MAY</option>
                                                    <option>MAY-JUN</option>
                                                    <option>JUN-JUL</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-sm-12 p-0">
                                                <button type="button" className="btn btn-danger wrn-btn">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>   
                    </div>

            

                </div>
            </div>   
        </div> 
    );
}


export default SearchBar;