import React from "react"

function Details(){
    return(
        <div>
            <table className="table">
                <tr>
                    <th scope="row">Name</th>
                    <td>Ishan Gupta</td>  
                    <th>ID</th>
                    <td>#123</td>  
                </tr>
                <tr>
                    <th scope="row">Email</th>
                    <td>Ishan6380@gmail.com</td>
                    <th>Status</th>
                    <td>Online</td>
                </tr>
                <tr>
                    <th>Plan Type</th>
                    <td>Premium</td>
                    <th>Created Date</th>
                    <td>19/03/2019</td>
                </tr>
                <tr>
                    <th>Contact Number</th>
                    <td>8755140457</td>
                    <th>Location</th>
                    <td>Banglore</td>
                </tr>
                
            </table>
        </div>
    );
}


export default Details