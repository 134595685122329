import React from 'react'


function populateOptions(options) {
    if (Array.isArray(options)) {
        return options.map((opt) => (<option key={opt.key} value={opt.key}>{opt.value}</option>));
    } else {
        return Object.keys(options).map((k) => (<option key={k} value={k}>{options[k]}</option>));
    }
}

export function SelectFilter ({props}) {
    return (<div className="form-group">
    
    <select className="form-control" value={props.value} onChange={e => props.onChange(e.target.value)}>
        <option key="emp" value=""></option>
        {
            populateOptions(props.options)     
        }
    </select>
  </div>)
}