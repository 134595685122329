
import React from "react";
import SearchBar from "./SearchBar";
import Table from "./Table";

function User(){
    let users=[
        {userId:"#123",name:"Rahul Sir",email:"rahuldub@gmail.com",date:"12/11/15"},
        {userId:"#124",name:"Saurav Sir",email:"sauravsir@gmail.com",date:"12/11/15"},
        {userId:"#125",name:"Ronit sir",email:"Ronitkar@gmail.com",date:"12/11/15"},
    ]
    
    return(
      <div className="container-fluid">
          <SearchBar />
          <Table users={users} />
      </div>



      
    );
}


export default User;