import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { updateCustomer } from 'Redux/actions/customers';

class EmploymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount () {
        this.setState({
            employmentStatus: this.props.dw.employmentStatus,
            employmentPosition: this.props.dw.employmentPosition,
            employmentCompany: this.props.dw.employmentCompany,
            employmentType: this.props.dw.employmentType,
            isBroker: this.props.dw.isBroker,
            directorOf: this.props.dw.directorOf,
            directorOfSelect: this.props.directorOf === null || this.props.directorOf === "" ? 'No' : 'Yes',
        });
    }

    onChange(e) {
        switch(e.target.name) {
            case 'isBroker': {
                this.setState({ isBroker: e.target.value === 'true'? true : false });
                break;
            }
            case 'directorOfSelect': {
                const directorOf = e.target.value === 'No' ? null : this.state.directorOf;
                this.setState({ directorOfSelect: e.target.value, directorOf })
                break;
            }
            default: {
                this.setState({[e.target.name]: e.target.value});
            }
        }
        console.log([e.target.name], e.target.value)
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    async onSubmit() {
        const update = {
            type: 'EMPLOYMENT_INFO',
            body: {
                employmentStatus: this.state.employmentStatus,
                employmentPosition: this.state.employmentPosition,
                employmentCompany: this.state.employmentCompany,
                employmentType: this.state.employmentType,
                isBroker: this.state.isBroker,
                directorOf: this.state.directorOf, 
            },
            userID: this.props.dw.userID,
        };
        await this.props.updateCustomer(update);
        console.log('update complete');
        await this.props.getDetails();
        this.setState({ edit: false });
        // await this.props.getCustomerDetails();
    }

    renderBasicDetails () {
        console.log("employment",this.state);
        return (<Fragment>
            <div className='card my-4'>
                <div className='card-header ' id='headingOne'>
                    <div className='pull-left'>Employment Details</div>
                    <div className='pull-right'>
                    {
                        !this.state.edit ? (
                            <span className="fas fa-edit mr-2" 
                        value='Basic Details' onClick={this.edit}></span>
                        ) 
                        : 
                        (
                            <span className="fas fa-times mr-2" 
                        value='Basic Details' onClick={this.edit}></span>
                        )
                    }
                    
                    </div>
                </div>
                <div className={this.state.edit? 'hide' : 'card-body'}>
                    <div className= 'row'>
                        <div className='col-md-1'><strong>Status:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.employmentStatus}</div>
                        <div className='col-md-1'><strong>Postion:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.employmentPosition || 'N/A'}</div>
                        <div className='col-md-1'><strong>Company:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.employmentCompany || 'N/A' }</div>
                        <div className='col-md-1 px-0'><strong>Business:</strong></div>
                        <div className='col-md-2 text-left px-0'>{this.props.dw.employmentType || 'N/A'}</div>
                    </div>
                    <hr></hr>
                    <div className = 'row'>
                        <div className='col-md-3'><strong>Is your employer a brokerage firm?</strong></div>
                        <div className='col-md-3 text-left'>{ this.props.isBroker ? this.props.isBroker : "--" }</div>

                        <div className='col-md-3'><strong>Director/Officer of public Company?</strong></div>
                        <div className='col-md-3 text-left'>{ this.props.directorOf ? this.props.directorOf : "--"}</div>
                    </div>
                    
                </div>
                
                <div className={!this.state.edit? 'hide' : 'card-body px-5'}>
                    <div className= 'row my-2'>
                        <div className='col-md-1 col-form-label'><strong>Status:</strong></div>
                        <div className='col-md-2'>
                            <select className="custom-select custom-select-md" name="employmentStatus" value = {this.state.employmentStatus} onChange= {this.onChange}>
                                <option value = "Employed">Employed/Self Employed</option>
                                <option value = "Retired">Retired</option>
                                <option value = "Student">Student</option>
                                <option value = "Unemployed">Not Employed</option>
                                {/* <option value = "SELF_EMPLOYED">Self Employed / Business Owner</option> */}
                            </select>
                        </div>
                        <div className='col-md-1 col-form-label'><strong>Position:</strong></div>
                        <div className='col-md-2'>
                            <select className="custom-select custom-select-md" name="position" value = {this.state.employmentPosition} 
                                onChange= {this.onChange}
                                disabled= { this.state.employmentStatus === 'Employed' ? false : true }
                                >
                                <option value = ""></option>
                                <option value = "ACCOUNTANT"> Accountant/CPA/Bookkeeper/Controller</option>
                                <option value = "ACTUARY"> Actuary</option>
                                <option value = "ADJUSTER"> Adjuster</option>
                                <option value = "ADMINISTRATOR"> Administrator</option>
                                <option value = "ADVERTISER"> Advertiser/Marketer/PR Professional</option>
                                <option value = "AGENT"> Agent</option>
                                <option value = "ATC"> Air Traffic Controller</option>
                                <option value = "AMBASSADOR"> Ambassador/Consulate Professional</option>
                                <option value = "ANALYST"> Analyst</option>
                                <option value = "APPRAISER"> Appraiser</option>
                                <option value = "ARCHITECT"> Architect/Designer</option>
                                <option value = "ARTIST"> Artist/Performer/Actor/Dancer</option>
                                <option value = "ASSISTANT"> Assistant</option>
                                <option value = "ATHLETE"> Athlete</option>
                                <option value = "ATTENDANT"> Attendant</option>
                                <option value = "ATTORNEY"> Attorney/Judge/Legal Professional</option>
                                <option value = "AUCTIONEER"> Auctioneer</option>
                                <option value = "AUDITOR"> Auditor</option>
                                <option value = "BARBER"> Barber/Beautician/Hairstylist</option>
                                <option value = "BROKER"> Broker</option>
                                <option value = "BUSINESS_EXEC"> Business Executive (VP, Director, etc.)</option>
                                <option value = "BUSINESS_OWNER"> Business Owner</option>
                                <option value = "CAREGIVER"> Caregiver</option>
                                <option value = "CARPENTER"> Carpenter/Construction Worker</option>
                                <option value = "CASHIER"> Cashier</option>
                                <option value = "CHEF"> Chef/Cook</option>
                                <option value = "CHIROPRACTOR"> Chiropractor</option>
                                <option value = "CIVIL"> Civil Servant</option>
                                <option value = "CLERGY"> Clergy</option>
                                <option value = "CLERK"> Clerk</option>
                                <option value = "COMPLIANCE"> Compliance/Regulatory Professional</option>
                                <option value = "CONSULTANT"> Consultant</option>
                                <option value = "CONTRACTOR"> Contractor</option>
                                <option value = "COUNSELOR"> Counselor/Therapist</option>
                                <option value = "CUSTOMER_SERVICE"> Customer Service Representative</option>
                                <option value = "DEALER"> Dealer</option>
                                <option value = "DEVELOPER"> Developer</option>
                                <option value = "DISTRIBUTOR"> Distributor</option>
                                <option value = "DOCTOR"> Doctor/Dentist/Veterinarian/Surgeon</option>
                                <option value = "DRIVER"> Driver</option>
                                <option value = "ENGINEER"> Engineer</option>
                                <option value = "EXAMINER"> Examiner</option>
                                <option value = "EXTERMINATOR"> Exterminator</option>
                                <option value = "FACTORY"> Factory/Warehouse Worker</option>
                                <option value = "FARMER"> Farmer/Rancher</option>
                                <option value = "FINANCIAL"> Financial Planner</option>
                                <option value = "FISHERMAN"> Fisherman</option>
                                <option value = "FLIGHT"> Flight Attendant</option>
                                <option value = "HR"> Human Resources Professional</option>
                                <option value = "IMPEX"> Importer/Exporter</option>
                                <option value = "INSPECTOR"> Inspector/Investigator</option>
                                <option value = "INTERN"> Intern</option>
                                <option value = "INVESTMENT"> Investment Advisor/Investment Manager</option>
                                <option value = "INVESTOR"> Investor</option>
                                <option value = "IT"> IT Professional/IT Associate</option>
                                <option value = "JANITOR"> Janitor</option>
                                <option value = "JEWELER"> Jeweler</option>
                                <option value = "LABORER"> Laborer</option>
                                <option value = "LANDSCAPER"> Landscaper</option>
                                <option value = "LENDING"> Lending Professional</option>
                                <option value = "MANAGER"> Manager</option>
                                <option value = "MECHANIC"> Mechanic</option>
                                <option value = "MILITARY"> Military, Officer or Associated</option>
                                <option value = "MORTICIAN"> Mortician/Funeral Director</option>
                                <option value = "NURSE"> Nurse</option>
                                <option value = "NUTRITIONIST"> Nutritionist</option>
                                <option value = "OFFICE"> Office Associate</option>
                                <option value = "PHARMACIST"> Pharmacist</option>
                                <option value = "PHYSICAL"> Physical Therapist</option>
                                <option value = "PILOT"> Pilot</option>
                                <option value = "POLICE"> Police Officer/Firefighter/Law Enforcement Professional</option>
                                <option value = "POLITICIAN"> Politician</option>
                                <option value = "PM"> Project Manager</option>
                                <option value = "REP"> Registered Rep</option>
                                <option value = "RESEARCHER"> Researcher</option>
                                <option value = "SAILOR"> Sailor/Seaman</option>
                                <option value = "SALES"> Salesperson</option>
                                <option value = "SCIENTIST"> Scientist</option>
                                <option value = "SEAMSTRESS"> Seamstress/Tailor</option>
                                <option value = "SECURITY"> Security Guard</option>
                                <option value = "SOCIAL"> Social Worker</option>
                                <option value = "TEACHER"> Teacher/Professor</option>
                                <option value = "TECHNICIAN"> Technician</option>
                                <option value = "TELLER"> Teller</option>
                                <option value = "TRADESPERSON"> Tradesperson/Craftsperson</option>
                                <option value = "TRAINER"> Trainer/Instructor</option>
                                <option value = "TRANSPORTER"> Transporter</option>
                                <option value = "UNDERWRITER"> Underwriter</option>
                                <option value = "WRITER"> Writer/Journalist/Editor</option>
                            </select>
                        </div>
                        <div className='col-md-1 col-form-label'><strong>Company:</strong></div>
                        <div className='col-md-2'>
                            <input className="form-control" name="employmentCompany" value={this.state.employmentCompany} onChange={this.onChange} 
                                disabled= { this.state.employmentStatus === 'Employed' ? false : true }
                            />
                        </div>
                        <div className='col-md-1 col-form-label'><strong>Business:</strong></div>
                        <div className='col-md-2'>
                            <select className="custom-select custom-select-md" name="employmentType" value = {this.state.employmentType} 
                                onChange= {this.onChange}
                                disabled= { this.state.employmentStatus === 'Employed' ? false : true }
                                >
                                <option value = ""></option>
                                <option value= "AGRICULTURE">Agriculture, Forestry, Fishing and Hunting</option>
                                <option value= "MINING">Mining, Quarrying, and Oil and Gas Extraction</option>
                                <option value= "UTILITIES">Utilities</option>
                                <option value= "CONSTRUCTION">Construction</option>
                                <option value= "MANUFACTURING">Manufacturing</option>
                                <option value= "WHOLESALE">Wholesale Trade</option>
                                <option value= "RETAIL">Retail Trade</option>
                                <option value= "TRANSPORT">Transportation and Warehousing</option>
                                <option value= "INFORMATION">Information</option>
                                <option value= "FINANCE">Finance and Insurance</option>
                                <option value= "REAL_ESTATE">Real Estate and Rental and Leasing</option>
                                <option value= "PROFESSIONAL">Professional, Scientific, and Technical Services</option>
                                <option value= "MANAGEMENT">Management of Companies and Enterprises</option>
                                <option value= "EDUCATION">Educational Services</option>
                                <option value= "HEALTH">Health Care and Social Assistance</option>
                                <option value= "ART">Arts, Entertainment, and Recreation</option>
                                <option value= "FOOD">Accommodation and Food Services</option>
                                <option value= "PUBLIC">Public Administration</option>
                                <option value= "WASTE">Administrative and Support and Waste</option>
                            </select>
                        </div>
                    </div>

                    <div className = "row my-4">
                        <div className='col-md-3 col-form-label'><strong>Is your employer a brokerage firm?</strong></div>
                        <div className='col-md-3 text-left'>
                            <select className="custom-select custom-select-md" name="isBroker" value = {this.state.isBroker} onChange={this.onChange}>
                                <option value="true"> True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                    
                    <div className = "row my-4">
                        <div className='col-md-3 col-form-label'><strong>Director/Officer of public Company?</strong></div>
                        <div className='col-md-1 text-left'>
                            <select className="custom-select custom-select-md" name="directorOfSelect" value = {this.state.directorOfSelect} onChange={this.onChange}>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                        { this.state.directorOfSelect === 'Yes' ? (
                        <div className='col-md-1 text-left'>
                            <input className="form-control" type="text" name="directorOf" value={this.state.directorOf} onChange={this.onChange} />
                        </div>) : ''
                        }
                    </div>

                    <div className = "row my-4">
                        <div className='offset-md-1 justify-content-left'>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, { updateCustomer})(EmploymentDetails)