
import React, { Component } from 'react'
import {} from './style.css'

export default class OverlayLoader extends Component {

  render() {
    if(this.props.loaded) {
      var style = {display: 'block'}
    }
    else {
      style = {display: 'none'}
    }
    return (
      <div style={style} className="overlay_screen_loader">
        <div className="overlay_screen_loader-content">
          <div className="overlay-loader"></div>
        </div>
      </div>
    )
  }
}
