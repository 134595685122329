

import {
  SAVE_CUSTOMER_DETAILS
  } from '../type'
const INTIAL_STATE = {}

const CustomerDetailsReducer = (state=INTIAL_STATE, action) => {
switch(action.type){
  case SAVE_CUSTOMER_DETAILS:
    return {
        ...state,
        customer_details:action.payload
      }

  default:
    return state
}
}

export default CustomerDetailsReducer;
