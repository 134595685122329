

import React, { Component } from 'react'
import { PLANS } from 'utils/common'
import moment from 'moment'

export default class PaymentDetails extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-header">
          Payment Details
        </div>
        <div className="card-body">
          {
            this.props.payment && this.props.payment.length > 0 ?
                <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Amount</th>
                        <th scope="col">plan</th>
                        <th scope="col">Payment Gateway</th>
                        <th scope="col">Coupon Code</th>
                        <th scope="col">Partner Code</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Transaction ID</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                    {
                      this.props.payment.map((p, index) => {
                          if (p){
                            var {
                              paymentStatus,
                              couponCode,
                              currency,
                              paymentAmount,
                              paymentGetwayName,
                              paymentType,
                              partnerCode,
                              date,
                              transactionId,
                              subscriptionType,
                              planId
                            } = p
                          }
                          let planInfo
                          if (subscriptionType) {
                            [planInfo] = this.props.plans.filter((p) => p.planId === subscriptionType);
                          } else {
                            [planInfo] = this.props.plans.filter((p) => p.planId === planId);
                          }
                          return(
                            <tr key={index}>
                              <td>{date && moment(date).format('YYYY-MM-DD')}</td>
                              <td>{paymentStatus && paymentStatus.toUpperCase()}</td>
                              <td>{currency ? currency : "INR"} {paymentAmount}</td>
                              <td>{planInfo && planInfo.planName && planInfo.planName !== undefined ? planInfo.planName : ''}</td>
                              <td>{paymentGetwayName && paymentGetwayName.toUpperCase()}</td>
                              <td>{couponCode && couponCode.toUpperCase()}</td>
                              <td>{partnerCode}</td>
                              <td>{paymentType && paymentType.toUpperCase()}</td>
                              <td>{transactionId}</td>
                          </tr>
                          )
                      })
                    }
                    </tbody>
                  </table> : null
          }
        </div>
      </div>
    )
  }
}

// couponCode: "FLATZERO"
// date: "2019-11-12 13:01:50:414"
// dwSubscriptionId: "01fc207d-a127-43dc-9429-e2b45104a5f5"
// loginUsername: "bhargavmaddie@gmail.com"
// orderId: "cpnR_fyv6pdv"
// partnerCode: "STOCKAL"
// paymentAmount: "0"
// paymentGetwayName: "offline"
// paymentStatus: "successful"
// paymentType: "subscription"
// subscriptionDate: "2019-11-12 13:01:50:415"
// subscriptionStatus: "start"
// subscriptionType: "sub13999"
// timestamp: 1573563710414
// transactionId: "cpnR_fyv6pdv"
// userEmail: "bhargavmaddie@gmail.com"