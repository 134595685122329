

import React, { PureComponent } from 'react' 
import moment from 'moment'
import {connect} from 'react-redux'
import {logout} from 'Redux/actions/auth'
import { setTimeZone} from 'Redux/actions/common'
import { TIMEZONES } from '../utils/common'
import { NavLink } from "react-router-dom"

class Header extends PureComponent{ 

  constructor(props){
    super(props);
    this.state = {
      tz:'',
    }
  }
  
  setTimeZone = (e) => {
    this.setState({tz:e.target.value}, () => this.props.setTimeZone(this.state.tz))
  }


  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
 

  render() {
    return ( 
      <React.Fragment>
      <div 
      style={{marginTop: '10px'}}
      className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow pt-4">
  
           
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>

     
      {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2"/>
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i className="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form> */}

     
      <ul className="navbar-nav ml-auto">

       
        <li className="nav-item dropdown no-arrow d-sm-none">
          <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-search fa-fw"></i>
          </a>
         
          <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2"/>
                <div className="input-group-append">
                  <button className="btn btn-primary btn-stockal" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

       
        {/* <li className="nav-item dropdown no-arrow mx-1">
          <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-bell fa-fw"></i>
           
            <span className="badge badge-danger badge-counter">3+</span>
          </a>
          
          <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
            <h6 className="dropdown-header">
              Alerts Center
            </h6>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-primary">
                  <i className="fas fa-file-alt text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 12, 2019</div>
                <span className="font-weight-bold">A new monthly report is ready to download!</span>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-success">
                  <i className="fas fa-donate text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 7, 2019</div>
                $290.29 has been deposited into your account!
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-warning">
                  <i className="fas fa-exclamation-triangle text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 2, 2019</div>
                Spending Alert: We've noticed unusually high spending for your account.
              </div>
            </a>
            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
          </div>
        </li>

        
        <li className="nav-item dropdown no-arrow mx-1">
          <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-envelope fa-fw"></i>
            
            <span className="badge badge-danger badge-counter">7</span>
          </a>
         
          <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
            <h6 className="dropdown-header">
              Message Center
            </h6>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img className="rounded-circle" src="https://source.unsplash.com/fn_BT9fwg_E/60x60" alt=""/>
                <div className="status-indicator bg-success"></div>
              </div>
              <div className="font-weight-bold">
                <div className="text-truncate">Hi there! I am wondering if you can help me with a problem I've been having.</div>
                <div className="small text-gray-500">Emily Fowler · 58m</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img className="rounded-circle" src="https://source.unsplash.com/AU4VPcFN4LE/60x60" alt=""/>
                <div className="status-indicator"></div>
              </div>
              <div>
                <div className="text-truncate">I have the photos that you ordered last month, how would you like them sent to you?</div>
                <div className="small text-gray-500">Jae Chun · 1d</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img className="rounded-circle" src="https://source.unsplash.com/CS2uCrpNzJY/60x60" alt=""/>
                <div className="status-indicator bg-warning"></div>
              </div>
              <div>
                <div className="text-truncate">Last month's report looks great, I am very happy with the progress so far, keep up the good work!</div>
                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt=""/>
                <div className="status-indicator bg-success"></div>
              </div>
              <div>
                <div className="text-truncate">Am I a good boy? The reason I ask is because someone told me that people say this to all dogs, even if they aren't good...</div>
                <div className="small text-gray-500">Chicken the Dog · 2w</div>
              </div>
            </a>
            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
          </div>
        </li>
*/}
        {/* <div className="topbar-divider d-none d-sm-block"></div> */}

        {/* <li className="nav-item dropdown no-arrow">

          <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <h4 className="mr-2 d-none d-lg-inline text-gray-600 small">TIMEZONES</h4>
          </NavLink>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" onClick={() => this.setTimeZone('INDIANST')}>INDIA STANDARD TIME</a>
            <a className="dropdown-item" onClick={() => this.setTimeZone('UTC')}>UTC</a>
          </div>
        </li> */}

        {/* <div className="dropdown no-arrow">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Select TimeZone 
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
             <a className="dropdown-item" onClick={() => this.setTimeZone('INDIANST')}>INDIA STANDARD TIME</a>
            <a className="dropdown-item" onClick={() => this.setTimeZone('UTC')}>UTC</a>
          </div>
        </div> */}

        <div className="form-group pr-2">
          <select className="form-control" id="sel1" style={{cursor: 'pointer'}} onChange={this.setTimeZone}>
            <option disabled>Select Timezone</option>
            {
              TIMEZONES.map((timezone, index) => (
               <option key={index} value={timezone.value}>{timezone.name}</option>
              ))
            }
          </select>
        </div>
         
        <li className="nav-item dropdown no-arrow mt-0 nounderline">
            <div className="form-control">
            <a className="navdropdown-toggle" style={{textDecoration: 'none'}} href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600">{this.props.auth && this.props.auth.name}</span>
            <i className="fas fa-cog" aria-hidden="true" style={{color:"#0061D3"}}></i>
          </a> 
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <NavLink className="dropdown-item" activeClassName="selectedDropDown" to="/update_password">
              Change Password
            </NavLink>
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={() => {this.props.logout()}}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
            </div> 
          </div>
        </li>
        
        {/* <li className="nav-item dropdown no-arrow mt-1 nounderline">
          <a className="navdropdown-toggle" style={{textDecoration: 'none'}} href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600">{this.props.auth && this.props.auth.name}</span>
          </a>   
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <NavLink className="dropdown-item" activeClassName="selectedDropDown" to="/change_password">
              Change Password
            </NavLink>
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={() => {this.props.logout()}}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li> */}

      </ul>
    </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({auth}) => {
  return {
    auth
  }
}

export default connect(mapStateToProps, {logout, setTimeZone})(Header)