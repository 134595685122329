import {
  GET_INSTAPAYMENT_REPORT,
  GET_INSTAPAYMENT_REPORT_SUCCESS,
  GET_INSTAPAYMENT_REPORT_FAIL,
  GET_INSTAPAYMENT_REPORT_RESET,
  } from '../type'

const INTIAL_STATE ={
  payments: [],
  paymentsReportMessage: '',
  page: 1,
  pageSize: 500,
}

const InstapaymentReportReducer = (state=INTIAL_STATE, action) => {
  switch(action.type){      
    case GET_INSTAPAYMENT_REPORT:
      let payments = state.payments
      if (action.payload.page === 1) {
        payments = [];
      } 
      return { ...state, payments, page: action.payload.page, pageSize: action.payload.pageSize, paymentsReportMessage: 'Fetching Payments Report' };

    case GET_INSTAPAYMENT_REPORT_SUCCESS:
      let successUsers = [...state.payments, ...action.payload];
      successUsers = successUsers.map((usr) => {
        console.log(usr); 
        return { 
          ...usr, 
          username: usr.userId.dwUsername, 
          fullname: usr.userId.fullname,
          email: usr.userId.username,
          phone: usr.userId.phone,
          riaId: usr.userId.riaId,
        }});
      console.log('successUsers', successUsers);
      return { ...state, payments: successUsers, paymentsReportMessage: '' };

    case GET_INSTAPAYMENT_REPORT_FAIL:
      return { ...state, payments: [], paymentsReportMessage: 'Failed Fetching Payments. Try Again Later' };

    case GET_INSTAPAYMENT_REPORT_RESET:
      return { ...state,  paymentsReportMessage: '' };

    default:
      return state
  }
}

export default InstapaymentReportReducer;
