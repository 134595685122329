import {
  GET_TRANSACTION_REPORT_START,
  GET_TRANSACTION_REPORT_SUCCESS,
  GET_TRANSACTION_REPORT_FAIL,
  GET_TRANSACTION_SEARCH_REPORT_START,
  GET_TRANSACTION_SEARCH_REPORT_SUCCESS,
  GET_ALL_TRANSACTION_DETAIL,
  GET_ALL_TRANSACTION_SUCCESS,
  GET_ALL_TRANSACTION_FAIL,
  GET_ALL_TRANSACTION_RESET,

} from "../type";

const INTIAL_STATE = {
  transactionMessage: '',
  transaction: [],
  total: 0,
  searchParams: null,
};

const TransactionReport = (state=INTIAL_STATE, action) => {
    switch(action.type){
      case GET_TRANSACTION_REPORT_START:
        return { ...state, transaction: [], transactionMessage: 'Fetching Data', searchParams: null };
      
      case GET_TRANSACTION_REPORT_SUCCESS:
        return {
            ...state,
            transaction: action.payload.transactions,
            total: action.payload.total,
            transactionMessage: '' }
      
      case GET_TRANSACTION_REPORT_FAIL:
        return { ...state, transaction: [], transactionMessage: action.payload };
      
      case GET_TRANSACTION_SEARCH_REPORT_START:
          return { ...state, transactionMessage: 'Fetching Data', searchParams: null };
     
      case GET_TRANSACTION_SEARCH_REPORT_SUCCESS:
         return { ...state, searchParams: action.payload, transactionMessage: '' }

      // start
      case  GET_ALL_TRANSACTION_DETAIL:
        return { ...state, transaction: [], transactionMessage: 'Fetching Data' };
      case GET_ALL_TRANSACTION_SUCCESS:
        console.log('all Transaction success',action.payload)
        return {
          ...state,
          transaction: action.payload,
          // total: action.payload.total,
          transactionMessage: '' }

      case GET_ALL_TRANSACTION_FAIL:
        console.log('order failed')
        return {  ...state, transaction: [], transactionMessage: ""  };
      case GET_ALL_TRANSACTION_RESET:
          return { ...state, transactionMessage: ""  };
      // end


      default:
        return state
      }
    }
    
    export default TransactionReport;