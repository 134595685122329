import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { getOrderDetail, getStacks, getAllOrderDetail } from 'Redux/actions/order';
import { getClientId, dateToString, PLANS1, getMyPlans } from 'utils/common';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
import { InputFilter, NumberFilter} from 'utils/DataGrid/Components/InputFilter.js';
import { SelectFilter } from 'utils/DataGrid/Components/SelectFilter';
import { BracketSubpartner } from 'utils/DataGrid/Components/BracketSubpartner';
import { getPortfolioSearch } from '../../Redux/actions/portfolioSearch';
import { CAN_SEARCH_ORDERS } from '../../utils/roles'



const subPartner = "STOCKAL"
class Orders extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(start.getUTCDate()-3);
        this.state = {
            start,
            end,
            partnerId: '',
            userId: '',
            orders: [],
            plans: {},
            showCard:false,
            ID:"",
            clickUserAccount : "",
            suggestionBox:false,
            orderLoader:false,
            suggestionBoxLoader:false,
            showSearchOrdersFunctionality:false,
            searchButton:true,
            filterType: 'created'
            // oneTableShow : true
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchOnChange = this.searchOnChange.bind(this);
        this.getSearchCustomerList = this.getSearchCustomerList.bind(this);
        this.setClickUserID = this.setClickUserID.bind(this);
        this.getAllOrder = this.getAllOrder.bind(this);
        this.closeSuggestionBox =this.closeSuggestionBox.bind(this);

        this.stackChildren = this.stackChildren.bind(this);
        this.toggleSubRow = this.toggleSubRow.bind(this);
        this.renderSubMenu = this.renderSubMenu.bind(this);
        this.SubscriptionFormatter = this.SubscriptionFormatter.bind(this);
        const statusTypes = [{ key: "NEW", value: "NEW" },
            { key: "PARTIAL_FILL", value: "PARTIAL_FILL" },
            { key: "CANCELED", value: "CANCELLED" },
            { key: "REJECTED", value: "REJECTED" },
            { key: "FILLED", value: "FILLED" }]
        this.columns = [
            { key: "username", name: "Username", formatter: ({column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                    // return row['user']['username'];
                    return row[key] || '';
                } else {
                    return row[key] || '';
                }
            }, 
            exportFormatter: ({ column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                    // return row['user']['username'];
                    return row[key] || '';
                } else {
                    return row[key] || '';
                }
            },
            filterRenderer: p => <InputFilter props={p} />  },
            { key: 'token', name:"Bank/Partner Token", formatter: ({column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack' && !row['id']) {
                    if (row['children'] && row['children'].length > 0) {
                        return row['children'][0]['token'] || '';
                    }
                    return ''
                } else {
                    return row[key] || '';
                }
                }
            },
            { key: "fullname", name: "Full Name", formatter: ({column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                    return row['user']['fullname'];
                } else {
                    return row[key] || '';
                }
            }, 
            exportFormatter: ({ column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                    return row['user']['fullname'];
                } else {
                    return row[key] || '';
                }
            },
            filterRenderer: p => <InputFilter props={p} />  },
            { key: "accountNo", name: "Account", filterRenderer: p => <InputFilter props={p} /> },
            { key: "orderNo" , name: "Order No", filterRenderer: p => <InputFilter props={p} /> },
            { key: "id", name: "Order Id", filterRenderer: p => <InputFilter props={p} />},
            { key: "symbol",  name: "Symbol", formatter: this.stackChildren, exportFormatter: ({column,row}) => {
                const key = column.key;
                return  row[key];
            }},
            { key: "status" , name: "Status", filterRenderer: (p) => <SelectFilter props={{...p, options: statusTypes}}/>,   },
            { key: 'category', name: 'Category', filterRenderer: p => <InputFilter props={p} />},
            // { key: "accountID", name: "Account ID" },
            
            
            // { key: "createdBy" , name: "Created By" },
            { key: "cumulativeQuantity" , name: "Cumulative Quantity" },
          
            { key: "fees", name: "Fees", filterRenderer: p => <NumberFilter props={p} /> },
            // { key: "fees", name: 'Commission', formatter: ({column, row}) => {
            //     const key = column.key;
            //     if(row['category'] === 'stock') {
            //         console.log('stock')
            //         return row[key];
            //     }
            //     else if(row['stackID']) {
            //         console.log('stackID')
            //         return row[key];
            //     }
            //     return (<b>{row['totalOrderAmount']- row['investmentAmount']}</b>);
            //     }, exportFormatter: ({ column, row}) => {
            //         if(row['category'] === 'stock') {
            //             console.log('stock')
            //             const key = column.key;
            //             return row[key];
            //         }
            //         else if(row['category'] === 'stack'){
            //             console.log('hey')
            //             return '';
            //         }
            //         else if(row['stackID']) {
            //             console.log('stackID')
            //             return '';
            //         }
            //         return row['totalOrderAmount']- row['investmentAmount'];
            //     }
            // },
            { key: "created" , name: "Created", formatter: this.DateFormatter },
            { key: "executedDate" , name: "Executed", formatter: this.DateFormatter },
            { key: "averagePrice" , name: "Average Price" },
            { key: "orderExpires", name: "Expires", formatter: this.DateFormatter},
            { key: "partnerCode", name: "Partner Code", filterRenderer: (p) => <BracketSubpartner props={{...p, options:subPartner}}/> },
            { key: "quantity",  name: "Quantity" },
            { key: "side" , name: "Side" },
            
            
            { key: "totalOrderAmount" , name: "Order Amount" },
            { key: "type", name: "type", filterRenderer: (p) => <SelectFilter props={{...p, options: [{ key: "MARKET", value: "MARKET"}, { key: "LIMIT", value: "LIMIT"}, { key: "STOP", value: "STOP"}]}}/>,},
            { key: "statusMessage" , name: "Rejection Reason", formatter: ({column, row}) => {
                const key = column.key;
                if (row['category'] === 'stack') {
                    return "--"
                }
                if(row['status'] === 'REJECTED' && row['statusMessage'] && row['statusMessage']['message'] ){
                    return row['statusMessage']['message'];
                }   
                else {
                    return '--';
                }
                }
            },
            { key: "subscriptionType", name: "Subscription", formatter: this.SubscriptionFormatter, filterRenderer: (p) => <SelectFilter props={{...p, options:PLANS1 }} /> },
            { key: "extendedHours" , name: "Extended Hours",formatter:({column,row}) => {
                const value = column.key
                if(value && row[value]){
                    return 'Yes'
                }else{
                    return 'No'
                }
          }},
        ];
    }

    formatDate (date) {
        return dateToString(date);
    }
    
    DateFormatter ({column, row}){
        const key = column.key;
        if (row[key]) {
            return dateToString(row[key]);
        } else 
        {
            return '';
        }
    };

    SubscriptionFormatter ({column, row}){
        const key = column.key;
        return `${this.state.plans[[row[key]]]}(${row[key]})` || '';
    }

    renderSubMenu(row) {
        if (row['category'] === 'stock') {
            return ''
        } else if ((row['category'] === 'stack') && row['stackID']) {
            return '';
        } else {
            return (
                <div className="float-right" onClick= { () => {
                    // console.log(row['_id']);
                    this.toggleSubRow(row['_id'])
                }}>
                    <b>
                        {
                            row['isExpanded'] ? (<span>V</span>) : (<span>&gt;</span>)
                        }
                    </b>
                </div>
            );
        }
    }

    stackChildren({column, row}){
        const key = column.key;
        return (<Fragment>
            <div className={row['stackID'] ? 'text-center font-italic': 'font-weight-bold'}>
                {row[key]} 
                
                {
                    this.renderSubMenu(row)
                }
                
            </div>
        </Fragment>)
    }


    toggleSubRow(id) {
        const rows = this.state.orders;
        const rowIndex = rows.findIndex(r => r._id === id);
        const row = rows[rowIndex];
        const { children } = row;
        if (!children) return rows;
      
        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
        if (!row.isExpanded) {
          newRows.splice(rowIndex + 1, 0, ...children);
        } else {
          newRows.splice(rowIndex + 1, children.length);
        }
        this.setState({ orders: newRows});
    }


    componentDidMount () {
        const { auth } = this.props
        const partnerId = getClientId(auth)
        const plans = getMyPlans(this.props.common.plans || []);
        // userId is adminId
        this.setState({ plans, partnerId, userId: auth.id });
        let { start, end, filterType } = this.state;
        this.props.getOrderDetail(start, end, partnerId, filterType, auth.id);
        if(CAN_SEARCH_ORDERS.includes(this.props.auth.roleTitle)) {
            this.setState({showSearchOrdersFunctionality : true })
        }
        else{
            this.setState({showSearchOrdersFunctionality : false})
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.orders.length === 0 ) {
            return { stacks: props.stacks };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orders.length !== this.props.orders.length) {
            this.setState({orders: this.props.orders});
        }
    }

    onSubmit() {
        const { start, end, partnerId, filterType, userId} = this.state;
        this.props.getOrderDetail(start, end, partnerId, filterType, userId);

    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    searchOnChange = (e) => {
        this.setState({ID:e.target.value,searchButton:false})
    }

    getSearchCustomerList(){
        this.setState({suggestionBoxLoader:true})
        let { ID, userId } = this.state
        getPortfolioSearch({ID, userId})
        .then((response) => {
            if(response.data.code === 200){
                this.setState({ searchList:response.data.data,suggestionBox:true,suggestionBoxLoader:false})
            }else{
                this.setState({ searchList:[],suggestionBoxLoader:false})
            }
        }).catch((err) => {
            this.setState({search_error_message:"No Data Found",searchList:[],suggestionBoxLoader:false})
        })
    }

    setClickUserID(e){
        this.setState({clickUserId:e.dw[0].userID,suggestionBox:false},()=>  this.getAllOrder())
    }

    getAllOrder(){
        // this.setState({orderLoader:true, oneTableShow:false })
        let {userId, clickUserId} = this.state
    //  the new one
        this.props.getAllOrderDetail(userId, clickUserId);
    //  end
        // getAllOrderDetail({clickUserAccount})
        // .then((response) => {
        //     if(response.data.code === 200){
        //         this.setState({ orderDetail:response.data.data,orderLoader:false})
        //     }else{
        //         this.setState({ orderDetail:[],orderLoader:false})
        //     }
        // }).catch((err) => {
        //     this.setState({search_error_message:"No Data Found",orderDetail:[],orderLoader:false})
        // })
    }

    closeSuggestionBox(){
        this.setState({suggestionBox:false})
    }

    onDateChange(name,date) {
        const max = new Date();
        
        if (name === 'start_date') { 
            const start = date;
            const end = new Date(date);
            end.setDate(end.getDate() + 90);
            this.setState({ start: start});
            if (max > end) {
                this.setState({ end: end});
            } else {
                this.setState({ end: max});
            }
            // this.setState({ 'start': date }); 
        }
        if (name === 'end_date') { this.setState({ 'end':date }); }
    }

    renderStatusRibbon () {
        if (this.props.orderReportMessage !== '') {
            return (
                <Loader>
                    <div className={`alert text-center ${this.props.orderReportMessage !== '' ? this.props.orders.length > 0 ? 'alert-danger': 'alert-primary' : 'hide' }`} role="alert">
                        { this.props.orderReportMessage }
                    </div>
                </Loader>
            );
        } else {
            return '';
        }
        
    }

    renderSearchFilter() {
        
        return (
            <Fragment>
                {/* <DataFilter searchKeyValues={this.searchKeyValues} type="order" data={this.props.orders} exportHeaders={this.exportHeaders}/> */}
                        <DataGrid columns={this.columns} rows={this.state.orders} message={this.props.orderReportMessage} />
            </Fragment>
        );
    }

    renderOrderDetail() {
        let loader = <div className="loaderProfile"></div>
        const statusTypes = [{ key: "NEW", value: "NEW" },
        { key: "PARTIAL_FILL", value: "PARTIAL_FILL" },
        { key: "CANCELED", value: "CANCELLED" },
        { key: "REJECTED", value: "REJECTED" },
        { key: "FILLED", value: "FILLED" }]
        return (
            <Fragment>
                {this.state.orderLoader ? loader :
                this.state.orderDetail && this.state.orderDetail.length > 0 ?
                        <DataGrid 
                        columns = {[
                            { key: "username", name: "Username", formatter: ({column, row}) => {
                                const key = column.key;
                                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                                    // return row['user']['username'];
                                    return row[key] || '';
                                } else {
                                    return row[key] || '';
                                }
                            }, 
                            exportFormatter: ({ column, row}) => {
                                const key = column.key;
                                // console.log("dusrasunny",row['category'], key, row[key])
                                if (row['category'] === 'stack' && !row['id'] && !row['user']) {
                                    // return row['user']['username'];
                                    return row[key] || '';
                                } else {
                                    return row[key] || '';
                                }
                            },
                            filterRenderer: p => <InputFilter props={p} />  },
                            { key: 'token', name:"Bank/Partner Token", formatter: ({column, row}) => {
                                const key = column.key;
                                // console.log(row[key], row['children']);
                                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                                    // return row['user']['token'];
                                    if (row['children'] && row['children'].length > 0) {
                                        return row['children'][0]['token'] || '';
                                    }
                                    return ''
                                } else {
                                    return row[key] || '';
                                }
                                }
                            },
                            { key: "fullname", name: "Full Name", formatter: ({column, row}) => {
                                const key = column.key;
                                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                                    return row['user']['fullname'];
                                } else {
                                    return row[key] || '';
                                }
                            }, 
                            exportFormatter: ({ column, row}) => {
                                const key = column.key;
                                // console.log(row['category'], key, row[key])
                                if (row['category'] === 'stack' && !row['id'] && row['user']) {
                                    return row['user']['fullname'];
                                } else {
                                    return row[key] || '';
                                }
                            },
                            filterRenderer: p => <InputFilter props={p} />  },
                            { key: "accountNo", name: "Account", filterRenderer: p => <InputFilter props={p} /> },
                            { key: "orderNo" , name: "Order No", filterRenderer: p => <InputFilter props={p} /> },
                            { key: "id", name: "Order Id", filterRenderer: p => <InputFilter props={p} />},
                            { key: "symbol",  name: "Symbol", exportFormatter: ({column,row}) => {
                                const key = column.key;
                                return  row[key];
                            }},
                            { key: "status" , name: "Status", filterRenderer: (p) => <SelectFilter props={{...p, options: statusTypes}}/>,   },
                            { key: 'category', name: 'Category', filterRenderer: p => <InputFilter props={p} />},
                            { key: "cumulativeQuantity" , name: "Cumulative Quantity" },
                            // { key: "fees", name: "Commission", filterRenderer: p => <NumberFilter props={p} /> },
                            { key: "fees", name: 'Commission', formatter: ({column, row}) => {
                                const key = column.key;
                                if(row['category'] === 'stock') {
                                    return row[key];
                                }
                                else if(row['stackID']) {
                                    return row[key];
                                }
                                // else if(row['totalOrderAmount'] && row['investmentAmount']){
                                //     return (
                                //     row['totalOrderAmount']- row['investmentAmount']
                                //     );
                                // } 
                                return row['totalOrderAmount']- row['investmentAmount'];
                                // return (row['totalOrderAmount'] && row['investmentAmount'] ?
                                //         // <b>{row['totalOrderAmount']- row['investmentAmount']}</b> 
                                //         "1234"
                                //         : "--" );
                                }, 
                                exportFormatter: ({ column, row}) => {
                                    if(row['category'] === 'stock') {
                                        const key = column.key;
                                        return row[key];
                                    }
                                    else if(row['stackID']) {
                                        return '';
                                    }
                                    return row['totalOrderAmount']- row['investmentAmount'];
                                }
                            },
                            { key: "created" , name: "Created", formatter: this.DateFormatter },
                            { key: "executedDate" , name: "Executed", formatter: this.DateFormatter },
                            { key: "averagePrice" , name: "Average Price" },
                            { key: "orderExpires", name: "Expires", formatter: this.DateFormatter},
                            { key: "partnerCode", name: "Partner Code", filterRenderer: (p) => <BracketSubpartner props={{...p, options:subPartner}}/> },
                            { key: "quantity",  name: "Quantity" },
                            { key: "side" , name: "Side" },
                            
                            
                            { key: "totalOrderAmount" , name: "Order Amount" },
                            { key: "type", name: "type", filterRenderer: (p) => <SelectFilter props={{...p, options: [{ key: "MARKET", value: "MARKET"}, { key: "LIMIT", value: "LIMIT"}, { key: "STOP", value: "STOP"}]}}/>,},
                            { key: "subscriptionType", name: "Subscription", formatter: this.SubscriptionFormatter, filterRenderer: (p) => <SelectFilter props={{...p, options:PLANS1 }} /> },
                            
                            // { key: "symbol", 
                            //   name: "Ticker", 
                            // },
                            // { 
                            //   key: "category", 
                            //   name: "Category", },
                            // { 
                            //   key: "investmentAmount", 
                            //   name: "Investment", 
                            // },
                            // { key: "partnerCode", name: "Partner"},
                            // // { key: "portfolioStatus", name: "Status"},
                            // { key: "quantity", name: "Quantity"},
                            // { key: "userID", name: "User ID"},
                            // { key: "executedDate", name: "Date"},
                            // { key: "accountNo", name: "Account No"},
                            // { key: "custId", name: "Customer ID"},
                            // { key: "fees", name: "Fee"},
                            // { key: "side", name: "Side"},
                            // { key: "status", name: "Status"},
                            // { key: "subscriptionType", name: "Subscription"},
                            // { key: "type", name: "Order Type"},
                            // // { key: "status", name: "Status"},
                        ]}
                        rows={this.state.orderDetail} 
                        /> : "" }
            </Fragment>
        );
    }

    render() {
        // console.log('partner',this.state.partnerId)
        let loader = <div className="newButtonLoader"></div>
        return (<Fragment>
            {this.state.showSearchOrdersFunctionality ? 
            <SearchFilter
            state={this.state}
            onChange={this.searchOnChange}
            // onSearch={this.onSearch}
            getSearchCustomerList={this.getSearchCustomerList}
            portfolioData={this.getPortFolioTotal}
            searchButton={this.state.searchButton}
            searchList={this.state.searchList}
            setClickUserID ={this.setClickUserID}
            suggestionBox={this.state.suggestionBox}
            closeSuggestionBox={this.closeSuggestionBox}
            loader={loader}
            suggestionBoxLoader={this.state.suggestionBoxLoader}
            /> : ""}
            

            <DateFilter
                start_date={this.state.start}
                end_date={this.state.end}
                partnerId={this.state.partnerId}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onChangeDate={this.onDateChange}
                {...this.props}
                >
                    <div className="col-md-6 col-lg-3 col-xl-2 mr-xl-3">
                    <p className="stackLabelFont">Fetch By</p>
                    <select 
                        name='filterType'
                        className="form-control custom-select" 
                        onChange={this.onChange}
                        value={this.state.filterType}
                    >
                        <option value="created">Created Date</option>
                        <option value="lastExecuted">Executed Date</option>
                    </select> 
                </div>
            </DateFilter>
            {this.renderStatusRibbon()}
            
            {this.renderSearchFilter()}

            {/* this.renderOrderDetail() } */}
            
        </Fragment>);
    }
}

const SearchFilter = ({state, onChange, suggestionBox,
    getSearchCustomerList,searchList,searchButton,
    setClickUserID,closeSuggestionBox,loader,suggestionBoxLoader}) => {
    let {  search_term, term } = state
    let searchInputClassName= (search_term==="phone"?"number-control":"")
    // console.log('searchList',searchList)
    return (
      <React.Fragment>
        <div className="form-row" style={{position:"relative"}}>
            <React.Fragment>
                <div className="form-group col-md-4">
                    <input 
                    className={`${searchInputClassName} form-control`}
                    // style={{width:"400px"}}
                    type= {search_term==="phone"?"number":"text"} 
                    name="term"
                    value={term}
                    onChange={onChange}
                    />
                </div>
                <div className="form-group col-md-2">
                    <button className="btn btn-primary btn-stockal" style={{width:"150px"}} onClick={getSearchCustomerList} disabled={searchButton}>{suggestionBoxLoader ? loader : 'Search'}</button>
                </div>
            </React.Fragment>

            {searchList && searchList.length > 0 && suggestionBox ?
                <div className="SearchSuggestions__Container">
                    <button type="button" className="close" onClick={closeSuggestionBox} style={{position:"sticky",top:"0"}}>
                        <span>&times;</span>
                    </button>
                    {searchList.map((item, index) => (
                        <div className="suggestionCard" style={{cursor:"pointer"}} onClick={item.dw && item.dw[0]  ? (e) => setClickUserID(item) : ''}>
                            <div className="row">
                                <div className="col-6 searchSuggestionFullname">
                                    {item.fullname}
                                </div>
                                <div className="col-6 searchSuggestionUsername">
                                    {item.username}
                                </div>     
                            </div>
                            <div className="row">
                                <div className="col-12 searchSuggestionID" 
                                style={{float:"left"}}>
                                    {item.dw && item.dw[0] && item.dw[0].accountNo  ?
                                    item.dw[0].accountNo : "--" }
                                </div>    
                            </div>    
                        </div>
                    ))}
                </div>
            : "" }
          
        </div>            
       </React.Fragment>
    )
  }






const mapStateToProps = ( { auth, orders }) => {
    return { auth, ...orders};
}

export default connect(mapStateToProps, { getOrderDetail, getStacks, getAllOrderDetail })(Orders);


