import React, { Component } from 'react';
import {connect} from "react-redux";

import { updateProfile, login, logout } from "Redux/actions/auth";

export class ForgotChangePassword extends Component {
 
    constructor(props){
        super(props)
        this.state={
            error:'',
           // old_password:'',
            new_password:'',
            conf_password:'',
            username : this.props.location.state.email,
        }
    }

    clearState = () => {
      this.setState({old_password:'',new_password:'',conf_password:''})
    }

    isValid = () => {
        let { new_password, conf_password} = this.state
        if (!/\d+/.test(new_password) || !/[a-zA-Z]+/.test(new_password) || new_password.length < 8 ) {
          this.setState({error: "Your password should contain at least one numeral and one alphabets & be at least 8 characters long."})
          return false
        }
        if (new_password !== conf_password) {
          this.setState({
              error: "New Password and Confirm Password does not match"
          })
          return false
        }
        return true
      }

    onChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        })
      }

      onSubmit = (e) => {
                e.preventDefault()
                let {new_password,conf_password,username} = this.state
                //let user = loadState();
               // let username = email  
               //console.log(username)
                if( new_password !== '' && conf_password !== '' && new_password === conf_password){
                  if(this.isValid()){
                    updateProfile({
                                user_email: username,
                                password: new_password
                              })
                              .then((response) => {
                                this.setState({error: "Password Updated SuccessFully!", is_created: true}, () => {
                                  setTimeout(() => {
                                    this.clearState()
                                  }, 2000);
                                })
                                this.props.history.push('/')
                              })
                          }
                        else{
                          this.setState({error: "Password must contain atleast 8 characters with atleast 1 alphabet and 1 number"})
                        }    
                        } 
                  else {
                    this.setState({error: "New password and confirm password must match"})
                  } 
                   }         
            
    render() {
      let {old_password,new_password,conf_password}= this.state
        return (
      <div className="container">
        <div className="row justify-content-center" style={{padding:'10%'}}>
          <div className="col-xl-6 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Change Password</h1>
                      </div>
                      <form className="user" onSubmit={this.onSubmit}>
                       
                        <div className="form-group">
                            <input 
                            type="password" 
                            className="form-control form-control-user" 
                            name="new_password" 
                            id="InputPassword" 
                            placeholder="New Password"
                            value={new_password}
                            onChange={this.onChange}
                            required
                            />
                        </div>
                        <div className="form-group">
                            <input 
                            type="password" 
                            className="form-control form-control-user" 
                            name="conf_password" 
                            id="InputPassword" 
                            placeholder="Confirm Password"
                            value={conf_password}
                            onChange={this.onChange}
                            required
                            />
                        </div>
                        <input type="submit"  className="btn btn-primary btn-stockal btn-user btn-block"/>
                      </form>
                        <hr />
                        <div>
                        <span style={{color: 'red'}}>{this.state.error}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
        )
    }
}

const mapStateToProps = ({auth}) => {
  return {
    auth
  }
}

export default connect(mapStateToProps,{login,logout})(ForgotChangePassword)
