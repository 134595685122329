import axios from 'axios';
import { API_ENDPOINT_V2 } from '../apiConstant';
import {GET_INSTAPAYMENT_REPORT,
    GET_INSTAPAYMENT_REPORT_SUCCESS,
    GET_INSTAPAYMENT_REPORT_RESET,
    GET_INSTAPAYMENT_REPORT_FAIL
    } from 'Redux/type'

export const getInstaPaymentData = (userId, start, end, filter, page, pageSize) => {
    return (dispatch) =>{
        dispatch({ type: GET_INSTAPAYMENT_REPORT, payload: { page, pageSize, filter}});
        const config = {  headers : {
            'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
            'adminid': userId
         }};
         let query=`from=${start}&to=${end}&partner=${filter.partnerId}&page=${page}&pageSize=${pageSize}&type=lrs`;
         query+=filter.dwUsername?`&dwUsername=${filter.dwUsername}`:'';
         query+=filter.fUserId?`&fUserId=${filter.fUserId}`:'';
         query+=filter.hashId?`&hashId=${filter.hashId}`:'';         
         const url = `${API_ENDPOINT_V2}/dashboard/remittance/instaPay?${query}`;
         axios.get(url, config).then((res) => {
             console.log(res.data)
            if (res.data.code === 200) {
               dispatch({ type: GET_INSTAPAYMENT_REPORT_SUCCESS, payload: res.data.data });
            } else {
               dispatch({ type: GET_INSTAPAYMENT_REPORT_FAIL, payload: [] });
               setTimeout(() => {
                  dispatch({ type: GET_INSTAPAYMENT_REPORT_RESET, payload: [] });
              }, 15000)
            }
   
         }).catch((err) => {
            dispatch({ type: GET_INSTAPAYMENT_REPORT_FAIL, payload: []})
            setTimeout(() => {
               dispatch({ type: GET_INSTAPAYMENT_REPORT_RESET, payload: [] });
           }, 15000)
         });
    }
};