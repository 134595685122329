import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { uploadKycDocuments } from 'Redux/actions/customers';
import Loader from 'react-loader';


class UploadDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            proofType: '',
            document: '',
            userID: '',
            is_loaded:false
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileChange = this.fileChange.bind(this);
        
    }

    async componentDidMount () {
        this.setState({ userID: this.props.dw.userID });
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        this.setState({is_loaded:true})
    });

    async fileChange(e) {
        const document = await this.toBase64(e.target.files[0]);
        console.log('doc',document);
        setTimeout(
            () => this.setState({ is_loaded:false}), 1000)
            this.setState({ document })    
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    async onSubmit() {
        // await this.props.getCustomerDetails();
        await this.props.uploadKycDocuments(this.state);
    }

    renderBasicDetails () {
        let loader = <div className='loaderProfile' style={{width:"30px",height:"30px",marginLeft:"0px",marginTop:"0px"}}></div>
        return (<Fragment>
            <div className='card'>
                <div className='card-header ' id='headingOne'>
                    <div className='pull-left'>Upload KYC Details</div>
                </div>
                <div className='card-body'>
                    <div className = "row">
                        <div className='col-md-2 col-form-label'><strong>Proof Type:</strong></div>
                        <div className='col-md-2'>
                            <select className="custom-select custom-select-md" name="type" value={this.state.type|| ''} onChange={this.onChange}>
                                <option value=""></option>
                                <option value="PICTURE_ID">Picture ID</option>
                                <option value="PROOF_OF_ADDRESS">Proof of Address</option>
                                <option value="PICTURE_ID_PROOF_OF_ADDRESS">Picture Id and Proof of Address</option>
                            </select>
                        </div>
                        <div className='col-md-1 col-form-label'><strong>Document:</strong></div>
                        <div className='col-md-2'>
                            <select className="custom-select custom-select-md" name="proofType" value={this.state.proofType|| ''} onChange={this.onChange}>
                            <option value= ''></option>
                            <option value= 'PAN Card'>PAN Card</option>
                            <option value= 'Aadhaar Card'>Aadhaar Card</option>
                            <option value= 'Passport'>Passport </option>
                            <option value= 'Other'>Other'</option>
                            <option value= 'Aadhaar Card'>Aadhaar Card</option>
                            <option value= 'Utility Bill'>Utility Bill</option>
                            <option value= 'Mobile Bill'>Mobile Bill</option>
                            </select>
                        </div>  
                        <div className='col-md-4'>
                            <input type='file' name = 'body' onChange={this.fileChange}/> 
                        </div>
                        <div className='col-md-1 justify-content-center'>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onSubmit}>{this.state.is_loaded ? <div className='col-md-4'>{loader}</div> : "Save"}</button>
                    </div>
                    </div>
                    
                    
                </div>
            </div>
        </Fragment>)
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {uploadKycDocuments})(UploadDocuments)