import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCountryList, updateCustomer } from 'Redux/actions/customers';
import { getDocumentStatus } from 'utils/common';


let status_json = [
    'accountStatus',
    'addressProofStatus',
    'idStatus',
    'kycStatus',
  ];
class InvestingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
        // this.edit = this.edit.bind(this);
        // this.onChange = this.onChange.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
    }

    renderBasicDetails () {
        console.log('account',this.props);
        return (<Fragment>
            <div className='card my-4'>
                <div className='card-header ' id='headingOne'>
                    <div className='pull-left'>Account Details</div>
                </div>
                <div className={this.state.edit? 'hide' : 'card-body'}>
                    <div className= 'row'>
                        <div className='col-md-1'><strong>UserId:</strong></div>
                        <div className='col-md-3 text-left'>{this.props.dw.userID}</div>
                        <div className='col-md-2'><strong>Account No:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.accountNo}</div>
                        <div className='col-md-1'><strong>Account Id:</strong></div>
                        <div className='col-md-3 text-left'>{this.props.dw.accountId}</div>
                    </div>
                    <hr></hr>
                    <div className= 'row'>
                        <div className='col-md-2'><strong>Account Reason:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.accountReason}</div>

                        <div className='col-md-2'><strong>ID Reason:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.idReason}</div>

                        <div className='col-md-2'><strong>Address Reason:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.addressProofReason}</div>
                        
                    </div>
                    <hr></hr>
                    <div className ="row">
                        <div className='col-md-2'><strong>Approved When:</strong></div>
                        <div className='col-md-2 text-left'>{this.props.dw.accountApprovedWhen}</div>
                        <div className='col-md-2 '><strong>Status:</strong></div>
                        <div className='col-md-2 text-left '>{getDocumentStatus({ statusCode: this.props.dw.accountStatus})}</div>
                        <div className='col-md-2 '><strong>ID Status:</strong></div>
                        <div className='col-md-2 text-left '>{getDocumentStatus({ statusCode: this.props.dw.idStatus})}</div>   
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className='col-md-2'><strong>Address Status:</strong></div>
                        <div className='col-md-2 text-left '>{getDocumentStatus({ statusCode: this.props.dw.addressProofStatus})}</div>
                        {/* <div className='col-md-2'><strong>Kyc Status:</strong></div>
                        <div className='col-md-2 text-left '>{getDocumentStatus({ statusCode: this.props.dw.kycStatus})}</div> */}
                    </div>
                </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {getCountryList, updateCustomer})(InvestingDetails)