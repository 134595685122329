
import React from "react"
// import CreateUser from "./createUser"
import OnBoard from "./OnBoardUser"

function Create(props){
    let current=[
        {name:"Rahul Sir",email:"rahuldub@gmail.com",designation:"Developer"},
        {name:"Saurav Sir",email:"sauravsir@gmail.com",designation:"Developer"},
        {name:"Ronit sir",email:"Ronitkar@gmail.com",designation:"Manager"},
    ]

    return(
      <div className="container-fluid ">    
        <div className="row col-lg-12">
            <OnBoard current={current} {...props} />
        </div>
      </div>
    );
}

export default Create