import React, { Component, Fragment } from 'react';
import { default as DataGrid } from 'utils/DataGrid/index';
import {
    InputFilter,
    NumberFilter,
  } from 'utils/DataGrid/Components/InputFilter.js';

class VerifiedPortfolio extends Component{
    constructor(props){
        super(props)
        this.state = {
            stacks: []
        };
        this.columns = [
            { key: 'userID', name: 'USERID', frozen: true },
            { key: 'partnerCode', name: 'Partner Code' },
            // { key: 'side', name: 'Side' },
            // { key: 'status', name: 'Status'},
            {
              key: 'ticker',
              name: 'Symbol',
              formatter: this.stackChildren,
              exportFormatter: ({ column, row }) => {
                const key = column.key;
                return row[key];
              },
            },
            { key: 'averagePrice', name: 'Avergae Price' },
            { key: 'qty', name: 'Quantity' },
            { key: 'totalInvestment', name: 'Invested Amount' },
            { key: 'category', name: 'Category' },
      
            { key: 'indexPrice', name: 'Index Price' },
            { key: 'status', name: 'Status' },
            { key: 'verify', name: 'Verified' },
            { key: 'totalUnit', name: 'Unit' },
            // {
            //   key: 'commission',
            //   name: 'Commission',
            //   formatter: ({ column, row }) => {
            //     if (row['stackID']) {
            //       return row['fees'];
            //     }
            //     return <b>{row['totalOrderAmount'] - row['investmentAmount']}</b>;
            //   },
            //   exportFormatter: ({ column, row }) => {
            //     if (row['stackID']) {
            //       return '';
            //     }
            //     return row['totalOrderAmount'] - row['investmentAmount'];
            //   },
            // },
            // {
            //   key: 'username',
            //   name: 'Username',
            //   filterRenderer: (p) => <InputFilter props={p} />,
            // },
            // {
            //   key: 'fullname',
            //   name: 'Full Name',
            //   filterRenderer: (p) => <InputFilter props={p} />,
            //   formatter: ({ column, row }) => {
            //     const key = column.key;
            //     if (row['category'] === 'stack' && !row['id']) {
            //       return row['user']['fullname'];
            //     } else {
            //       return row[key] || '';
            //     }
            //   },
            // },
            // {
            //   key: 'orderNo',
            //   name: 'Order No',
            //   filterRenderer: (p) => <InputFilter props={p} />,
            // },
            // {
            //   key: 'id',
            //   name: 'Order Id',
            //   filterRenderer: (p) => <InputFilter props={p} />,
            // },
            // {
            //   key: 'stackID',
            //   name: 'Stack ID',
            //   formatter: ({ column, row }) => {
            //     const key = column.key;
            //     if (row['category'] === 'stack' && !row['id']) {
            //       return row['_id'];
            //     } else {
            //       return row[key] || '';
            //     }
            //   },
            // },
            // // { key: "accountID", name: "Account ID" },
            // { key: 'averagePrice', name: 'Average Price' },
            // { key: 'created', name: 'Created', formatter: this.DateFormatter },
            // // { key: "createdBy" , name: "Created By" },
            // { key: 'cumulativeQuantity', name: 'Cumulative Quantity' },
            // {
            //   key: 'fees',
            //   name: 'Fees',
            //   filterRenderer: (p) => <NumberFilter props={p} />,
            // },
            // { key: 'orderExpires', name: 'Expires', formatter: this.DateFormatter },
      
            // { key: 'quantity', name: 'Quantity' },
      
            // { key: 'type', name: 'type'},
            // // { key: "userID", name: "User ID" },
            // { key: 'subscriptionType', name: 'Subscription' },
        ];
    }

    renderSearchFilter() {
        return (
          <Fragment>
            {/* <DataFilter searchKeyValues={this.searchKeyValues} type="order" data={this.props.orders} exportHeaders={this.exportHeaders}/> */}
    
            <DataGrid
              columns={this.columns}
              rows={this.state.stacks}
              message={this.props.stacksReportMessage}
              toggleSubRow={this.toggleSubRow}
            />
          </Fragment>
        );
      }
    
    render(){
        return(
            <div>
               {this.renderSearchFilter()}
            </div>
        )
    }
}

export default VerifiedPortfolio