
import React from 'react'
import {
    BrowserRouter,
    Route,
    Switch,
    Link
} from 'react-router-dom'
// import App from './App';
import Login from './components/auth/login/Login';
import Home from './Home'
import ForgotPassword from './components/auth/login/ForgotPassword.js'
import ForgotChangePassword from './components/auth/login/ForgotChangePassword.js';



export const Routes = () => {
  return (
    <div> 
      <main>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Home} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/ForgotChangePassword" component={ForgotChangePassword} />
          <Route path="/" component={Home} /> 
      
        </Switch>
      </main>
    </div>
  )
  }