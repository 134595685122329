import React, { Component } from 'react';
import { PLANS1, viewIntoSpecificTimeZone } from 'utils/common';


export default class PartnerDataGrid extends Component {
    constructor (props) {
        super(props);
        this.state = {};
        this.editStackPlan= this.editStackPlan.bind(this);
        this.deleteStackPlan= this.deleteStackPlan.bind(this);
        this.deleteModal = this.deleteModal.bind(this);
    }
    
    editStackPlan(e) {
        this.props.editPlan(e.target.getAttribute('value'));
    }

    deleteStackPlan(e) {
        this.props.deletePlan(e.target.getAttribute('value'));
    }

    deleteModal(e) {
        this.props.deletePlan(e.target.getAttribute('value'));
    } 

    renderSavedPlans() {
        // console.log('state',this.props.myPlans)
        // console.log('plan',PLANS1)
        console.log('plan',this.props.currentStack.stackPartner)
        return this.props.currentStack.stackPartner.map((plan) => {
            return (<React.Fragment>
                <tr key={plan.planID}>
                <th scope="stackLabelFont row" style={{width:"200px"}}>{this.props.myPlans[plan.planID]}</th>
                <td className="stackLabelFont text-center" >{plan.aumForBuy}</td>
                <td className="stackLabelFont text-center" >{plan.activationFee}</td>
                <td className="stackLabelFont text-center" >{plan.activationFeeCurrency}</td>
                <td className="stackLabelFont text-center" >{plan.commissionForSell}</td>
                <td className="stackLabelFont text-center" >{plan.minimum}</td>
                <td className="stackLabelFont text-center" >{plan.entryFee}</td>
                <td className="stackLabelFont text-center" >{plan.exitFee}</td>
                <td className="stackLabelFont text-center" >{plan.aumFrequency}</td>
                <td className="stackLabelFont text-center" >{viewIntoSpecificTimeZone(new Date(plan.date)) }</td>
                <td className="stackLabelFont text-center" >
                    <span className="fas fa-edit mr-2" value ={plan.planID} onClick={this.editStackPlan}></span>
                    <span className="fas fa-trash-alt" value ={plan.planID} onClick={this.deleteStackPlan}></span>
                </td>
            </tr>
            {/* <div className="row">
            comment : hey this is ishan gupta i dont wanted to have anything to do with you bruhh !
        </div>  */}
        </React.Fragment> )
        })
    }

    render () {
        return (<table className="table table-bordered">
            <thead>
                <tr>
                    <th className="stackLabelFont" scope="colspan-2">Plans</th>
                    <th className="stackLabelFont text-center" scope="col">Buy Aum</th>
                    <th className="stackLabelFont text-center" scope="col">Activation Fee</th>
                    <th className="stackLabelFont text-center" scope="col">Currency</th>
                    <th className="stackLabelFont text-center" scope="col">Commission</th>
                    <th className="stackLabelFont text-center" scope="col">Minimum</th>
                    <th className="stackLabelFont text-center" scope="col">Entry Fee</th>
                    <th className="stackLabelFont text-center" scope="col">Exit Fee</th>
                    <th className="stackLabelFont text-center" scope="col">Aum Frequency</th>
                    <th className="stackLabelFont text-center" scope="col">Date</th>
                    <th className="stackLabelFont text-center" scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                { this.renderSavedPlans() }
                
            
            </tbody>
        </table>)
    }


}