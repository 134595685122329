import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { getStacks, getAllStackDetail } from 'Redux/actions/order';
import { getClientId, dateToString, PLANS1 } from 'utils/common';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
import {
  InputFilter,
  NumberFilter,
} from 'utils/DataGrid/Components/InputFilter.js';
import { SelectFilter } from 'utils/DataGrid/Components/SelectFilter';
import { CAN_SEARCH_STACKS } from '../../utils/roles'
import { getPortfolioSearch } from '../../Redux/actions/portfolioSearch';

class Stacks extends Component {
  constructor(props) {
    super(props);
    const start = new Date();
    const end = new Date();
    start.setDate(start.getUTCDate() - 3);
    this.state = {
      start,
      end,
      partnerId: '',
      userId: '',
      stacks: [],
      showSearchStacksFunctionality:false,
      searchButton:true,
      suggestionBox:false,
      suggestionBoxLoader:false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchOnChange = this.searchOnChange.bind(this);
    this.getSearchCustomerList = this.getSearchCustomerList.bind(this);
    this.setClickUserID = this.setClickUserID.bind(this);
    this.getAllStack = this.getAllStack.bind(this);
    this.closeSuggestionBox =this.closeSuggestionBox.bind(this);
    this.stackChildren = this.stackChildren.bind(this);
    this.toggleSubRow = this.toggleSubRow.bind(this);
    const statusTypes = [
      { key: 'NEW', value: 'NEW' },
      { key: 'PARTIAL_FILL', value: 'PARTIAL_FILL' },
      { key: 'CANCELED', value: 'CANCELLED' },
      { key: 'REJECTED', value: 'REJECTED' },
      { key: 'FILLED', value: 'FILLED' },
    ];

    this.columns = [
      { key: 'accountNo', name: 'Account', frozen: true },
      { key: 'partnerCode', name: 'Partner Code' },
      { key: 'side', name: 'Side' },
      {
        key: 'status',
        name: 'Status',
        filterRenderer: (p) => (
          <SelectFilter props={{ ...p, options: statusTypes }} />
        ),
      },
      {
        key: 'symbol',
        name: 'Symbol',
        formatter: this.stackChildren,
        exportFormatter: ({ column, row }) => {
          const key = column.key;
          return row[key];
        },
      },

      { key: 'totalOrderAmount', name: 'Order Amount' },
      { key: 'investmentAmount', name: 'Invested Amount' },
      {
        key: 'commission',
        name: 'Commission',
        formatter: ({ column, row }) => {
          if (row['stackID']) {
            return row['fees'];
          }
          return <b>{row['totalCommission']}</b>;
        },
        exportFormatter: ({ column, row }) => {
          if (row['stackID']) {
            return '';
          }
          return row['totalOrderAmount'] - row['investmentAmount'];
        },
      },
      {
        key: 'username',
        name: 'Username',
        filterRenderer: (p) => <InputFilter props={p} />,
      },
      {
        key: 'fullname',
        name: 'Full Name',
        filterRenderer: (p) => <InputFilter props={p} />,
        formatter: ({ column, row }) => {
          const key = column.key;
          if (row['category'] === 'stack' && !row['id']) {
            return row['user']['fullname'];
          } else {
            return row[key] || '';
          }
        },
      },
      {
        key: 'orderNo',
        name: 'Order No',
        filterRenderer: (p) => <InputFilter props={p} />,
      },
      {
        key: 'id',
        name: 'Order Id',
        formatter: ({ column, row }) => {
          const key = column.key;
          if (row['orderId'] == null || row['orderId'] == "") {
            return '--';
          } else {
            return row[key] || '';
          }
        },
        filterRenderer: (p) => <InputFilter props={p} />,
      },
      {
        key: 'stackID',
        name: 'Stack ID',
        formatter: ({ column, row }) => {
          const key = column.key;
          if (row['category'] === 'stack' && !row['id']) {
            return row['_id'];
          } else {
            return row[key] || '';
          }
        },
      },
      // { key: "accountID", name: "Account ID" },
      { key: 'averagePrice', name: 'Average Price' },
      { key: 'created', name: 'Created', formatter: this.DateFormatter },
      // { key: "createdBy" , name: "Created By" },
      { key: 'cumulativeQuantity', name: 'Cumulative Quantity' },
      {
        key: 'fees',
        name: 'Fees',
        filterRenderer: (p) => <NumberFilter props={p} />,
      },
      { key: 'orderExpires', name: 'Expires', formatter: this.DateFormatter },

      { key: 'quantity', name: 'Quantity' },

      {
        key: 'type',
        name: 'type',
        filterRenderer: (p) => (
          <SelectFilter
            props={{
              ...p,
              options: [
                { key: 'MARKET', value: 'MARKET' },
                { key: 'LIMIT', value: 'LIMIT' },
                { key: 'STOP', value: 'STOP' },
              ],
            }}
          />
        ),
      },
      // { key: "userID", name: "User ID" },
      {
        key: 'subscriptionType',
        name: 'Subscription',
        formatter: this.SubscriptionFormatter,
        filterRenderer: (p) => (
          <SelectFilter props={{ ...p, options: PLANS1 }} />
        ),
      },
    ];
  }

  SubscriptionFormatter({ column, row }) {
    const key = column.key;
    return PLANS1[row[key]] || '';
  }

  stackChildren({ column, row }) {
    const key = column.key;
    return (
      <Fragment>
        <div
          className={
            row['stackID'] ? 'text-center font-italic' : 'font-weight-bold'
          }
        >
          {row[key]}

          {row['stackID'] ? (
            ''
          ) : (
            <div
              className='float-right'
              onClick={() => {
                this.toggleSubRow(row['_id']);
              }}
            >
              <b>{row['isExpanded'] ? <span>V</span> : <span>&gt;</span>}</b>
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  toggleSubRow(id) {
    const rows = this.state.stacks;
    const rowIndex = rows.findIndex((r) => r._id === id);
    const row = rows[rowIndex];
    const { children } = row;
    if (!children) return rows;

    const newRows = [...rows];
    newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
    if (!row.isExpanded) {
      newRows.splice(rowIndex + 1, 0, ...children);
    } else {
      newRows.splice(rowIndex + 1, children.length);
    }
    this.setState({ stacks: newRows });
  }

  formatDate(date) {
    return dateToString(date);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stacksReportMessage === 'Fetching Stacks...') {
      return { stacks: [] };
    }
    if (state.stacks.length === 0) {
      return { stacks: props.stacks };
    }
    return null;
  }
  componentDidMount() {
    const { auth } = this.props;
    const partnerId = getClientId(auth);
    this.setState({ partnerId, userId: auth.id });
    let { start, end } = this.state;
    this.props.getStacks(start, end, partnerId, auth.id);
    if(CAN_SEARCH_STACKS.includes(auth.roleTitle)) {
      this.setState({showSearchStacksFunctionality : true })
    }
    else{
        this.setState({showSearchStacksFunctionality : false})
    }
  }

  onSubmit() {
    const { start, end, partnerId, userId } = this.state;
    this.props.getStacks(start, end, partnerId, userId);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  searchOnChange = (e) => {
    this.setState({ID:e.target.value,searchButton:false})
  }

  getSearchCustomerList(){
    this.setState({suggestionBoxLoader:true})
    let { ID, userId } = this.state
    getPortfolioSearch({ID, userId})
    .then((response) => {
        if(response.data.code === 200){
            this.setState({ searchList:response.data.data,suggestionBox:true,suggestionBoxLoader:false})
        }else{
            this.setState({ searchList:[],suggestionBoxLoader:false})
        }
    }).catch((err) => {
        this.setState({search_error_message:"No Data Found",searchList:[],suggestionBoxLoader:false})
    })
  }

  setClickUserID(e){
    this.setState({clickUserId:e.dw[0].userID,suggestionBox:false},()=>  this.getAllStack())
  }
  getAllStack(){
    // this.setState({orderLoader:true, oneTableShow:false })
    let {userId, clickUserId} = this.state
//  the new one
    this.props.getAllStackDetail(userId, clickUserId);
//  end
    // getAllStackDetail({clickUserAccount})
    // .then((response) => {
    //     if(response.data.code === 200){
    //         this.setState({ orderDetail:response.data.data,orderLoader:false})
    //     }else{
    //         this.setState({ orderDetail:[],orderLoader:false})
    //     }
    // }).catch((err) => {
    //     this.setState({search_error_message:"No Data Found",orderDetail:[],orderLoader:false})
    // })
  }
  closeSuggestionBox(){
    this.setState({suggestionBox:false})
  }

  onDateChange(name, date) {
    const max = new Date();
    if (name === 'start_date') { 
        const start = date;
        const end = new Date(date);
        end.setDate(end.getDate() + 90);
        this.setState({ start: start});
        if (max > end) {
            this.setState({ end: end});
        } else {
            this.setState({ end: max});
        }
        // this.setState({ 'start': date }); 
    }
    if (name === 'end_date') { this.setState({ 'end':date }); }
  }

  renderStatusRibbon() {
    if (this.props.stacksReportMessage !== '') {
      return (
        <Loader>
          <div
            className={`alert text-center ${
              this.props.stacksReportMessage !== ''
                ? this.props.orders.length > 0
                  ? 'alert-danger'
                  : 'alert-primary'
                : 'hide'
            }`}
            role='alert'
          >
            {this.props.stacksReportMessage}
          </div>
        </Loader>
      );
    } else {
      return '';
    }
  }

  renderSearchFilter() {
    return (
      <Fragment>
        {/* <DataFilter searchKeyValues={this.searchKeyValues} type="order" data={this.props.orders} exportHeaders={this.exportHeaders}/> */}

        <DataGrid
          columns={this.columns}
          rows={this.state.stacks}
          message={this.props.stacksReportMessage}
          toggleSubRow={this.toggleSubRow}
        />
      </Fragment>
    );
  }

  render() {
    let loader = <div className="newButtonLoader"></div>
    return (
      <Fragment>
        {this.state.showSearchStacksFunctionality ? 
            <SearchFilter
            state={this.state}
            onChange={this.searchOnChange}
            // onSearch={this.onSearch}
            getSearchCustomerList={this.getSearchCustomerList}
            portfolioData={this.getPortFolioTotal}
            searchButton={this.state.searchButton}
            searchList={this.state.searchList}
            setClickUserID ={this.setClickUserID}
            suggestionBox={this.state.suggestionBox}
            closeSuggestionBox={this.closeSuggestionBox}
            loader={loader}
            suggestionBoxLoader={this.state.suggestionBoxLoader}
            /> : ""}
            
        <DateFilter
          start_date={this.state.start}
          end_date={this.state.end}
          partnerId={this.state.partnerId}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          onChangeDate={this.onDateChange}
          {...this.props}
        />

        {this.renderStatusRibbon()}
        {this.renderSearchFilter()}
      </Fragment>
    );
  }
}

const SearchFilter = ({state, onChange, suggestionBox,
  getSearchCustomerList,searchList,searchButton,
  setClickUserID,closeSuggestionBox,loader,suggestionBoxLoader}) => {
  let {  search_term, term } = state
  let searchInputClassName= (search_term==="phone"?"number-control":"")
  // console.log('searchList',searchList)
  return (
    <React.Fragment>
      <div className="form-row" style={{position:"relative"}}>
          <React.Fragment>
              <div className="form-group col-md-4">
                  <input 
                  className={`${searchInputClassName} form-control`}
                  // style={{width:"400px"}}
                  type= {search_term==="phone"?"number":"text"} 
                  name="term"
                  value={term}
                  onChange={onChange}
                  />
              </div>
              <div className="form-group col-md-2">
                  <button className="btn btn-primary btn-stockal" style={{width:"150px"}} onClick={getSearchCustomerList} disabled={searchButton}>{suggestionBoxLoader ? loader : 'Search'}</button>
              </div>
          </React.Fragment>

          {searchList && searchList.length > 0 && suggestionBox ?
              <div className="SearchSuggestions__Container">
                  <button type="button" className="close" onClick={closeSuggestionBox} style={{position:"sticky",top:"0"}}>
                      <span>&times;</span>
                  </button>
                  {searchList.map((item, index) => (
                      <div className="suggestionCard" style={{cursor:"pointer"}} onClick={item.dw && item.dw[0]  ? (e) => setClickUserID(item) : ''}>
                          <div className="row">
                              <div className="col-6 searchSuggestionFullname">
                                  {item.fullname}
                              </div>
                              <div className="col-6 searchSuggestionUsername">
                                  {item.username}
                              </div>     
                          </div>
                          <div className="row">
                              <div className="col-12 searchSuggestionID" 
                              style={{float:"left"}}>
                                  {item.dw && item.dw[0] && item.dw[0].accountNo  ?
                                  item.dw[0].accountNo : "--" }
                              </div>    
                          </div>    
                      </div>
                  ))}
              </div>
          : "" }
        
      </div>            
     </React.Fragment>
  )
}

const mapStateToProps = ({ auth, orders }) => {
  return { auth, ...orders };
};

export default connect(mapStateToProps, { getStacks, getAllStackDetail})(Stacks);
