import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
import { getClientId, dateToString, PLANS1, getDocumentStatus, getMyPlans, viewIntoSpecificTimeZone } from 'utils/common';
import { AddNewExportReport, getExportReport } from 'Redux/actions/export';


class ExportReports extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(start.getUTCDate()-3);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            minStart: new Date('2019-08-15'),
            start,
            end,
            partnerId: '',
            reports: [],
            message: 'Fetching Data',
            filterBy: 'Registration'
        };
        
    }

    DateFormatter ({column, row}){
        const key = column.key;
        if (row[key]) {
            // console.log(row[key], row);
            return dateToString(row[key]);
        } else 
        {
            return '';
        }
    }

    componentDidMount () {
        const { auth, common } = this.props;
        console.log(common);
        const partnerId = getClientId(auth)
        const plans = getMyPlans(this.props.common.plans || []);
        this.setState({ partnerId, plans });
        this.getReportsList();
    }

    getReportsList () {
        getExportReport(this.props.auth.id)
        .then((result) => {
            setTimeout(() => {
                this.setState({ message: '', reports: result.data.docs });
            }, 2000);
        })
        .catch((err) => {
            setTimeout(() => {
                this.setState({ message: 'Error Fetching Reports', reports: [] });
            }, 2000);
        });
    }
    static getDerivedStateFromProps(props, state) {  
        return null;
    }

    async onSubmit() {
        this.setState({ message: 'Posting Request', reports: []})
        const { start, end, partnerId, filterBy } = this.state;
        console.log(start, end);
        try {
            const res = await AddNewExportReport(start, end, partnerId, this.props.auth.id, this.props.common.tz, filterBy);
            this.getReportsList();
        } catch (err) {
            console.log(err);
        }
    }

    onDateChange(name,date) {
        if (name === 'start_date') { this.setState({ 'start': date }); }
        if (name === 'end_date') { this.setState({ 'end':date }); }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    renderStatusRibbon () {
        if (this.state.message !== '') {
            return (
                <Loader>
                     <div className={`alert text-center ${this.state.message !== '' ? this.state.reports.length > 0 ? 'alert-danger': 'alert-primary' : 'hide' }`} role="alert">
                         {this.state.message}
                    </div>
                </Loader>
            );
        } else {
            return '';
        }
        
    }

    renderTableBody() {
        let i = 0;
        return this.state.reports.map((report) => {
            i++;
            return (<tr key={`tr_${i}`}>
                <td>{ i }</td>
                <td>{dateToString(report.start)}</td>
                <td>{dateToString(report.end)}</td>
                <td>{report.partner}</td>
                <td>{report.filter}</td>
                <td>{report.tz}</td>
                <td>{report.status === 0? 'Pending' : report.status === 1? 'Completed': report.status === 2? 'Pending' : ''}</td>
                <td>
                    {
                        report.fileName ? (<a href={report.path} className="fa fa-download" aria-hidden="true" />)
                            :
                            (<a placeholder='Click to reload Status' className="fa fa-sync" aria-hidden="true" onClick={(e) => {
                                console.log(report);
                            }}/>)
                    }
                </td>
            </tr>)
        });
    }

    renderSearchFilter() { 
        return (
            <Fragment>
                {/* <DataFilter searchKeyValues={this.searchKeyValues} type="order" data={this.props.orders} exportHeaders={this.exportHeaders}/> */}
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Start</th>
                            <th scope="col">End</th>
                            <th scope="col">Partner</th>
                            <th scope="col">Fetch By</th>
                            <th scope="col">Timezone</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead> 
                    <tbody>
                        { this.renderTableBody()}
                    </tbody>
                </table>
            </Fragment>
        );
    }


    render() {
        return (<Fragment>
            
            <DateFilter
                start_date={this.state.start}
                minStart = {this.state.minStart }
                end_date={this.state.end}
                partnerId={this.state.partnerId}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onChangeDate={this.onDateChange}
                {...this.props}
            >
                <div className="col-md-6 col-lg-3 col-xl-2 mr-xl-3">
                    <p className="stackLabelFont">Fetch By</p>
                    <select 
                        name='filterBy'
                        className="form-control custom-select" 
                        onChange={this.onChange}
                        value={this.state.filterBy}
                    >
                    <option value="Registration">Registration Date</option>
                    <option value="Payment">Payment Date</option>
                    
                    {/* <option value="accountCreation">First KYC Upload Date</option> */}
                    </select> 
                </div>
            </DateFilter>
            {this.renderStatusRibbon()} 
            {this.renderSearchFilter()}
        </Fragment>);
    }
}

const mapStateToProps = (props) => {
    return {
        auth: props.auth,
        common: props.common,
    }
}

export default connect(mapStateToProps, null)(ExportReports);