

import React, {PureComponent} from "react";
import {connect} from 'react-redux';
import stockalNewLogo from 'assets/images/stockalNewLogo.png'
import {
	// Reports, Trade, 
	Register, Export, Dashboard, LRS, Customers, 
	CreateNewCustomers, ExportReports, Orders, Stacks, Transactions, Partners,
	Reports1, Payments, Portfolio, Coupons, Discrepency, VerifiedPortfolio, Instapayment
} from './nav'

const ClientNavOptions = (props) => (
  <>
    {/* <Dashboard /> */}
    {/* <Export /> */}
    <Reports1 />
    <LRS />
    <Orders />
    <Stacks />
    <Transactions />
    {/* <Orders /> */}
    <ExportReports />
	<Portfolio/>
  </>
);

const OperationManagerOptions = (props) => (
	<>
		{/* <Dashboard /> */}
		{/* <Export /> */}
		<Reports1/>
		<LRS />
		<Transactions/>
		<Customers />
		<Orders />
		<Stacks/>
		<CreateNewCustomers />
		<ExportReports />
		<Portfolio />
		<Coupons {...props}/>
	</>
)

const OperationNavOptions = (props) => (
	<>
		{/* <Dashboard /> */}
		{/* <Export /> */}
		<Reports1/>
		<LRS />
		<Transactions/>
		<Orders />
		<Stacks/>
		<Customers />
		<ExportReports />
		<Portfolio />
		<Coupons {...props}/>
	</>
)

const AdminNavOptions = (props) => (
  <>
    {/* <Dashboard /> */}
    <Register />
    {/* <Trade />
		<Reports /> */}
		<LRS />
		<Transactions/>
		{/* <Export /> */}
		<Reports1/>
		<Orders />
		<Stacks />
		<Customers />
		<CreateNewCustomers />
		<ExportReports />
		<Partners/>
		<Payments />
		<Portfolio />
		<Coupons {...props}/>
		<Discrepency />
		<VerifiedPortfolio />
		<Instapayment />
		{/* <Portfolio /> */}
	</>
)

const ManagerNavOptions = (props) => (
  <>
	{/* <Dashboard /> */}
	 {/* <Export /> */}
	 <Reports1/>
	 <LRS />
	 <Transactions/>
	 <Customers />
	 <Orders />
	 <Stacks/>
	 <CreateNewCustomers />
	 <ExportReports />
	 <Partners/>
	 {/* <Payments /> */}
	 <Portfolio />
	 <Coupons {...props}/>
	</>
)

const ProductManagerNavOptions = (props) => (
	<>
	  {/* <Dashboard /> */}
	   <Reports1/>
	   <LRS />
		<Transactions/>
	   <Customers />
		<Orders />
	   <Stacks/>
		<CreateNewCustomers />
	   <ExportReports />
	   <Portfolio />
		<Partners/>
		<Discrepency />
	   <Coupons {...props}/>
	   <Instapayment />
	  </>
  )

const GuestNavOptions = (props) => (
  <>
		{/* <Dashboard /> */}
		{/* <Export /> */}
		<Reports1/>
		<LRS />
		{/* <Transactions/> */}
		<Customers />
		{/* <Orders /> */}
		<ExportReports />
	</>
)

class SideNav extends PureComponent{
	
	render(){

		const navoptions = 
			this.props.auth && 
			this.props.auth.roleTitle === 'client' ? <ClientNavOptions {...this.props}/> :
			this.props.auth.roleTitle === 'admin' ? <AdminNavOptions {...this.props}/> :
			this.props.auth.roleTitle === 'manager' ? <ManagerNavOptions {...this.props}/> : 
			this.props.auth.roleTitle === 'guest' ? <GuestNavOptions {...this.props}/> : 
			this.props.auth.roleTitle === 'operation'  ? <OperationNavOptions {...this.props}/> :
			this.props.auth.roleTitle === 'operation_manager'  ? <OperationManagerOptions {...this.props}/> : 
			this.props.auth.roleTitle === 'product_manager'  ? <ProductManagerNavOptions {...this.props}/> : null

		const sidenav = {
			height: '100%', /* Full-height: remove this if you want "auto" height */
			// width: '100px', /* Set the width of the sidebar */
			position: 'fixed', /* Fixed Sidebar (stay in place on scroll) */
			zIndex: '1', /* Stay on top */
			top: '0', /* Stay at the top */
			left: '0',
			// backgroundColor: '#111', /* Black */
			// overflowX: 'hidden', /* Disable horizontal scroll */
			// paddingTop: '20px'
		
		}
	
	return(
		<div className="navbro">
			<ul className="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">		
			<a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
				<div className="sidebar-brand-icon rotate-n-15">	
				</div>
				<div>
				<img className="logo" src = {stockalNewLogo} />
				</div>
				{/*<div className="sidebar-brand-text mx-3">Admin</div>*/}
			</a>


	
			
	
			
			<hr className="sidebar-divider"/>

			{navoptions}
			
		

		      
			<hr className="sidebar-divider d-none d-md-block"/>

			

			</ul>
		
	</div>
	
	
	 
	)
	}
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(SideNav);
