//test comments

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Pagination extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      currentPage:1,
      count: 0,
      showPer:100,
      pageNumbers:[],
      lowerBound: 0,
      upperBound: 5,
      pageCount: 0,
      pageBound: 5,
    }
  }

  componentDidMount = () => { 
    this.setState({
      count: this.props.count,
      pageCount: Math.ceil(this.props.count / this.state.showPer ),
      pageBound: this.props.pageBound 
      
    }, () => {
      this.setState({
        upperBound: this.state.pageCount < this.state.pageBound ? this.state.pageCount : this.state.pageBound
      })
    })
  }

  onNext = () => {
    let { upperBound, lowerBound, pageCount } = this.state
    if (upperBound < pageCount) {
      this.setState({
        lowerBound: lowerBound + 1,
        upperBound: upperBound + 1
      })
    }  
  }

  onPrev = () => {
    let { upperBound, lowerBound } = this.state
    if (lowerBound >= 0) {
      this.setState({
        lowerBound: lowerBound - 1,
        upperBound: upperBound - 1
      })
    }  
  }

  onFirst = () => {
      this.setState({
        lowerBound: 0,
        upperBound: this.state.pageCount < this.state.pageBound ? this.state.pageCount : this.state.pageBound
      })
  }

  onLast = () => {
    this.setState({
      lowerBound: this.state.pageCount - this.state.pageBound,
      upperBound: this.state.pageCount
    })
}

  onPageClick = (page) => {
    this.setState({
      currentPage: page
    })
    this.props.onClick({page})
  }

  render() {
    let { lowerBound, upperBound, pageCount } = this.state
    let disabledPrevClassName = lowerBound == 0 ? "disabled" : ""
    let disabledNextClassName = upperBound == pageCount ? "disabled" : ""  
    let pageNumbers = []
   
    // let pageBound = upperBound;
    let k = 0
    for(var i=lowerBound; i < upperBound; i++ ){
      if (k > 6) {
        break;
      }
      pageNumbers.push(i)
      k += 1
    }

    return (
      <div style={{display: 'flex', justifyContent:"center"}}>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
          <li className={`page-item ${disabledPrevClassName}`}>
              <NavLink to=""className="page-link" aria-label="First" onClick={this.onFirst}>
                <span aria-hidden="true">&#8810;</span>
                <span className="sr-only">First</span>
              </NavLink>
            </li>
            <li className={`page-item ${disabledPrevClassName}`}>
              <NavLink to=""className="page-link" aria-label="Previous" onClick={this.onPrev}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </NavLink>
            </li>
            {
              pageNumbers.length > 0 && pageNumbers.map((page, index) => {
                return (
                <li 
                className="page-item" 
                key={index}>
                  <NavLink to=""className="page-link" onClick={() => this.onPageClick(page+1)}>{page + 1}</NavLink></li>)
              })
            }
            <li className={`page-item ${disabledNextClassName}`}>
              <NavLink to=""className="page-link" aria-label="Next" onClick={this.onNext}>
                <span aria-hidden="true" >&raquo;</span>
                <span className="sr-only" >Next</span>
              </NavLink>
            </li>
            <li className={`page-item ${disabledNextClassName}`}>
              <NavLink to=""className="page-link" aria-label="last" onClick={this.onLast}>
                <span aria-hidden="true" >&#8811;</span>
                <span className="sr-only" >Last</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}
