import {
  SET_TIMEZONE, SET_PARTNER_CODES, ADD_PARTNER_CODE, SET_PARTNER_CODES_FAIL,
  GET_PLANS_FAIL, GET_PLANS_SUCCESS, GET_PLANS
} from '../type'

let inital_data = {
  tz: 'Asia/Kolkata',
  partnerCodes: [],
  plans: [],
}

const CommonReducer = (state=inital_data, action) => {
  switch(action.type){
    case SET_TIMEZONE:
     return {
      ...state, 
      tz: action.payload
      }
    case SET_PARTNER_CODES:
      action.payload.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      return{
        ...state,
        partnerCodes: [...action.payload ]
      }
    
    case SET_PARTNER_CODES_FAIL: 
      return { ...state, partnerCodes: []};

    case ADD_PARTNER_CODE:
         let newPartnerCodes = state.partnerCodes
         const data = {
            _id:action.payload.createdBy,
            name:action.payload.name,
            code:action.payload.code
         }
         newPartnerCodes.push(data)
         return{
           ...state,
           partnerCodes:newPartnerCodes
         }
    
    case GET_PLANS: 
      return { ...state, plans: []};
    case GET_PLANS_SUCCESS:
      return { ...state, plans: action.payload};
    case GET_PLANS_FAIL: 
      return { ...state, plans: []};
      
    default: 
    return {...state}
  }
}

export default CommonReducer