
import React, {Component} from "react"
import {connect} from 'react-redux'

import { getOnboardUsers } from 'Redux/actions/dashboard'
import CreateUser from './createUser'
import { CAN_CREATE_USER } from "../../utils/roles";
import {EditRegisterModal} from './Edit'

const roles = {
  'Admin': 1, 
  'Manager' : 2,
  'Developer' : 3,
  'Guest' : 4,
  'Client': 5
}

class OnBoard extends Component {

  constructor (props) {
    super(props);
    this.state = {
      userList: []
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
        return;
    };
  }
  componentDidMount = () => {
    if (!CAN_CREATE_USER.includes(this.props.auth.roleTitle)) {
      this.props.history.push("/")
    } else {
      this.props.getOnboardUsers({email: this.props.auth.email})
      .then((list) => {
        list.docs.map((data, index) => 
          list.docs[index]["toggle_edit"] = false
        )
        this.setState({userList:list.docs})
      }).catch((error) => console.log("error ", error))
    }
  }

  render (){
    let { userList } = this.state
    return(
      <div className="col-lg-12">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="p-5">
              <div className="text-center">
              <h1 className="h4 text-gray-900 mb-4">DashBoard User</h1>
              </div>
              <div style={{float:"right"}}>
              <CreateUser {...this.props} />
              </div>
              <table className="table">
                    <thead>
                        <tr>   
                          <th scope="col">NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">ROLE</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                      {
                        userList && userList.length > 0 &&
                        userList.map((user, index) => (
                          <tr key={index}> 
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.roleTitle}</td>
                            {/* <td>
                              <EditRegisterModal 
                                index={index}
                                state={{name: user.name, email: user.email, roleId: roles[user.roleTitle]}}
                                toggle_edit={user.toggle_edit}
                                {...this.props}/>
                              </td> */}
                            {/* <td>
                              <ConfirmAlert
                                className='userDelete'
                                name="delete"
                                confirmAlert = {() => props.deleteUser(index)}
                              />
                              </td> */}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table> 
              </div>
          </div>
        </div>
      </div>        
    );
  }
    
}



export default connect(null,{getOnboardUsers})(OnBoard);