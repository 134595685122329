
export const roles = {
    1: 'admin', 
    2: 'manager',
    3: 'developer',
    4: 'operation',
    5: 'client',
    6: 'guest',
    7: 'operation_manager',
    8: 'product_manager',
  }

export const CAN_CREATE_USER = ['admin']
export const CAN_EDIT_LRS = ['admin', 'manager', 'operation', 'operation_manager', 'product_manager']
export const CAN_EXPORT_LRS = ['admin', 'manager','client', 'operation', 'operation_manager', 'guest', 'product_manager']

export const CAN_VIEW_LRS_DETAILS=['admin', 'manager', 'operation', 'operation_manager', 'guest', 'product_manager']

export const CAN_ADD_PARTNER = ['admin', 'manager', 'product_manager']
export const CAN_ACCESS_PARTNER = ['admin', 'manager', 'operation', 'operation_manager', 'guest', 'product_manager']

export const CAN_PORTFOLIO_REGENERATEBUTTON = ['admin', 'product_manager']
export const CAN_PORTFOLIO_SIDENAV = ['admin','manager', 'operation', 'operation_manager', 'product_manager']

export const CAN_CREATE_COUPON = ['admin', 'product_manager'];
export const CAN_EDIT_COUPON = ['admin', 'product_manager'];

export const CAN_EDIT_CUSTOMER = ['admin', 'product_manager'];
export const CAN_ADD_STACKS = ['admin', 'operation', 'operation_manager', 'product_manager']

export const CAN_SEARCH_ORDERS = ['admin', 'operation_manager', 'operation', 'product_manager', 'manager',  'guest']
export const CAN_SEARCH_STACKS = ['admin', 'operation_manager', 'operation', 'product_manager', 'manager',  'guest']
