import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCountryList, updateCustomer } from 'Redux/actions/customers';

class IdentificationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount () {
        this.setState({
            idNo: this.props.dw.idNo,
        });
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    async onSubmit() {
        const update = {
            type: 'IDENTIFICATION_INFO',
            body: {
                idNo: this.state.idNo,
            },
            userID: this.props.dw.userID,
        };
        await this.props.updateCustomer(update);
        // console.log('update complete');
        // await this.props.getDetails();
        this.setState({ edit: false });
        
    }

    renderBasicDetails () {
        console.log(this.props);
        return (<Fragment>
            <div className='card my-4'>
            <div className='card-header ' id='headingOne'>
                <div className='pull-left'>Identification</div>
                <div className='pull-right'>
                {
                    !this.state.edit ? (
                        <span className="fas fa-edit mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    ) 
                    : 
                    (
                        <span className="fas fa-times mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    )
                }
                
                </div>
            </div>
            <div className={this.state.edit? 'hide' : 'card-body'}>
                <div className= 'row my-2'>
                    <div className='col-md-2'><strong>Government Id No:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.idNo}</div>
                    <div className='col-md-2'><strong>Citizenship:</strong></div>
                    <div className='col-md-1 text-left'>{this.props.dw.citizenship}</div>
                </div>
            </div>
            <div className={!this.state.edit? 'hide' : 'card-body px-5'}>
                <div className= 'row my-2'>
                    <div className='col-md-2 col-form-label'><strong>Government Id No:</strong></div>
                    <div className='col-md-3'>
                        <input type='text' name='idNo' className='form-control' onChange={this.onChange} value= {this.state.idNo || ''} />
                    </div>
                    <div className='col-md-2 col-form-label'><strong>Citizenship:</strong></div>
                    <div className='col-md-2'>
                        {this.props.dw.citizenship}
                    </div>
                    <div className='col-md-2 justify-content-center'>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onSubmit}>Save</button>
                    </div>
                </div>
            </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {getCountryList, updateCustomer})(IdentificationDetails)