import React, { Component, useMemo } from 'react';
import DataGrid from 'react-data-grid';
import { exportFile } from './Export/exportFile';
import EmptyRowsRenderer from './Components/EmptyRowsRenderer';
import 'react-data-grid/dist/react-data-grid.css';

export default class DataGrids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      filters: {},
      filteredRows: [],
      setFilters: false,
    };
    this.defaultColumnProperties = {
      resizable: true,
      width: 120,
      filterable: true,
    };
    this.resetFilters = {};
    this.setFilter = this.setFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.toggleSubRow = this.toggleSubRow.bind(this);
    this.emptyRowsRenderer = this.emptyRowsRenderer.bind(this);
  }

  addColumnProperties() {
    let filters = {};
    let columns = this.props.columns.map((c) => {
      if (c.filterRenderer) {
        filters[c.key] = '';
      }
      return { ...c, ...this.defaultColumnProperties };
    });
    return { filters, columns };
  }

  buildFilters() {}

  componentDidMount() {
    const { filters, columns } = this.addColumnProperties();
    this.setState({ filters, columns });
    this.resetFilters = filters;
  }

  static getDerivedStateFromProps(props, state) {
    const filterKeys = Object.keys(state.filters);
    let r =
      state.rows.length === 0 || state.rows.length !== props.rows.length
        ? props.rows
        : state.rows;
    const rows = r.filter((row) => {
      let flag = true;
      filterKeys.forEach((key) => {
        if (row[key]) {
          if (
            row[key]
              .toString()
              .toLowerCase()
              .indexOf(state.filters[key].toString().toLowerCase()) === -1
          ) {
            flag = false;
            return;
          }
        }
        return;
      });
      return flag;
    });
    if(state.filteredRows.length !== rows.length && props.isCountCheck){
      props.countFilterUser(rows.length)
    }
    return { rows: props.rows, filteredRows: rows };
  }

  toggleSubRow(index) {
    this.props.toggleSubRow(index);
  }

  setFilter(filters) {
    if (this.props.setFilter) {
      this.props.setFilter(filters);
    }
    this.setState({ filters });
  }

  clearFilter() {
    const { setFilters } = this.state;
    this.setState({ setFilters: !setFilters, filters: this.resetFilters });
  }

  addFilter(filter) {
    console.log(filter);
  }

  getValidFilterValues(rows, filter) {
    const key = Object.keys(filter)[0];
    const value = filter[key];
    return rows.filter((item, i) => {
      if (item[key]) {
        return (
          item[key].toString().toLowerCase().indexOf(value.toLowerCase()) > -1
        );
      } else {
        return false;
      }
    });
  }

  emptyRowsRenderer() {
    let message = this.props.message;
    if (this.props.rows.length === 0 && !this.props.message) {
      message = 'No Data Available For Selected Dates';
    }

    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h5>{message}</h5>
      </div>
    );
  }

  render() {
    const exp = this.props.export === false ? false : true;
    return (
      <div className='card mt-1'>
        <div className={`card-header ${!exp ? 'd-none' : ''}`}>
          <span
            className=' fas fa-file-csv fa-2x '
            onClick={(e) => {
              exportFile(this.state.columns, this.state.filteredRows, 'Data');
            }}
          ></span>

          <span className='float-right' onClick={this.clearFilter}>
            <i className='fas fa-filter fa-2x primary'></i>
            {this.state.setFilters ? <i className='fas fa-times'></i> : ''}
          </span>
          <span className='float-right mr-1'>{this.props.children}</span>
        </div>
        <div className='card-body px-0 py-0'>
          <DataGrid
            columns={this.state.columns}
            rows={this.state.filteredRows}
            rowsCount={this.state.filteredRows.length}
            height={this.state.filteredRows.length > 0 ? 600 : 100}
            onColumnResize={(idx, width) =>
              console.log(`Column ${idx} has been resized to ${width}`)
            } />
          </div>
        </div>
        );
    }

    emptyRowsRenderer() {
        let message = this.props.message;
        if(this.props.rows.length === 0 && !this.props.message) {
            message = 'No Data Available For Selected Dates';
        }

        return (<div style={{ textAlign: 'center', marginTop: '20px' }}><h5>{message}</h5></div>);
    }

    render() {
        const exp = (this.props.export === false) ? false : true;
           return (
            <div className="card mt-1">

                <div className={`card-header ${!exp ? 'd-none': ''}`}>
                    <span className=" fas fa-file-csv fa-2x " onClick={(e) => { exportFile(this.state.columns, this.state.filteredRows, 'Data') }}></span>
                    
                    <span className="float-right" onClick={ this.clearFilter}>
                        <i className="fas fa-filter fa-2x primary"></i>
                        {
                            (this.state.setFilters )? (
                                <i className="fas fa-times"></i>
                            ) : ''
                        }
                        
                    </span>
                    <span className="float-right mr-1">{ this.props.children }</span>
                </div>
                <div className="card-body px-0 py-0">
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.filteredRows}
                        rowsCount={this.state.filteredRows.length}
                        height={this.state.filteredRows.length> 0? 600 : 200}
                        onColumnResize={(idx, width) =>
                            console.log(`Column ${idx} has been resized to ${width}`)
                        }
                        enableFilters={this.props.enableFilters? this.props.enableFilters : this.state.setFilters}
                        filters={this.state.filters}
                        onFiltersChange={this.setFilter}
                        onAddFilter={this.addFilter}
                        onClearFilters={this.clearFilter}
                        // getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
                        getValidFilterValues={this.addFilter}
                        emptyRowsRenderer={this.emptyRowsRenderer}
                        onScroll={ (e) => {
                            if ((e.target.offsetHeight + e.target.scrollTop) > e.target.scrollHeight) {
                                if(this.props.scrollEvent) {
                                    this.props.scrollEvent();
                                }
                            }
                        } }
                        // summaryRows={[{key:'accountAmmount', value: '123'}]}
                    />
                </div>
            </div>);
    }
}
