

import React, { useState } from 'react'
import Toast from 'utils/Toast'
import { postComments } from "Redux/actions/comments"
import { goToComments } from './navigations'


let initialState = {
  comment: "",
  alertMessage: "",
  showAlert: false,
  autoClose: 1500,
}

export const CommentBox = (props) => {
  let [state, setState] = useState(initialState);

  const addComments = () => {
    if (state.comment === undefined || state.comment === "" || state.comment.replace(/\s/g, "") === "") {
      alert("please add")
      setState({
        showAlert: true,
        alertMessage: "Please add comment before Updating",
      })
    }
    else {
      postComments({
        category: props.category,
        categoryID: props.userId,
        comment:state.comment,
        customerId: props.userId,
        createdBy: props.createdBy.id
      })
      .then((data) => {
        setState({
          state: {},
          showAlert: true,
          alertMessage: "Updated",
        })
      })
      .catch((err) => {
        setState({
          state: {},
          showAlert: true,
          alertMessage: "Error While Updating Comment",
        })
      })
    }
  }

  // toast off
  const setAlertOff = () => {
    setTimeout(() => setState({showAlert:false}), state.autoClose)}

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <textarea 
        className="form-control" 
        rows="4" 
        name="comment" 
        value={state.comment} 
        placeholder="Add Comments"
        onChange={(e) => {setState({comment: e.target.value})}}
        disabled={state.inputComment}
      >  
      </textarea>
        <div style={{marginTop: '.5rem', display: 'flex', justifyContent: 'space-evenly'}}>
          <button className="btn btn-primary btn-stockal" style={{width: '200px'}} onClick={addComments}> Add Comments </button>
          <button className="btn btn-secondary" style={{width: '200px'}} onClick={() => goToComments({
            userId: props.userId,
            applicantEmail: props.applicantEmail,
            applicantName: props.applicantName,
            categoryID: props.categoryID,
            category: props.category
          })}> View All Comments</button>
        </div>
        <Toast 
          showAlert={state.showAlert}
          alertMessage={state.alertMessage}
          autoClose={state.autoClose}
          setAlertOff={setAlertOff}
        />
    </div>
  )
}