import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCountryList, updateCustomer } from 'Redux/actions/customers';

class BasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            countryList: [],
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount () {
        const result = await this.props.getCountryList();
        this.setState({
            firstName: this.props.dw.firstName,
            lastName: this.props.dw.lastName,
            emailAddress1: this.props.dw.emailAddress1,
            phoneHome: this.props.dw.phoneHome,
            countryID: this.props.dw.countryID,
            countryObj: result.find(obj => this.props.dw.countryID === obj.code3),
            // countryObj : result.find(obj=> console.log('obj',obj.code3)),
            countryList: result
        });
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    async onSubmit() {
        const update = {
            type: 'BASIC_INFO',
            body: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phone: this.state.phoneHome,
                email: this.state.emailAddress1,
                country: this.state.countryID,
            },
            userID: this.props.dw.userID,
        };
        await this.props.updateCustomer(update);
        console.log('update complete');
        await this.props.getDetails();
        this.setState({ edit: false });
        // await this.props.getCustomerDetails();
    }

    renderBasicDetails () {
        // console.log('coutry',this.state.countryList)   ;
        return (<Fragment>
            <div className='card my-4'>
            <div className='card-header ' id='headingOne'>
                <div className='pull-left'>Basic Details</div>
                <div className='pull-right'>
                {
                    !this.state.edit ? (
                        <span className="fas fa-edit mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    ) 
                    : 
                    (
                        <span className="fas fa-times mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    )
                }
                
                </div>
            </div>
            <div className={this.state.edit? 'hide' : 'card-body'}>
                <div className= 'row'>
                    <div className='col-md-2'><strong>First Name:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.firstName}</div>
                    <div className='col-md-2'><strong>Last Name:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.lastName}</div>
                    <div className='col-md-1'><strong>Email:</strong></div>
                    <div className='col-md-3 text-left'>{this.props.dw.emailAddress1}</div>
                </div>  
                <hr></hr>  
                <div className= 'row'>
                    <div className='col-md-2'><strong>Phone:</strong></div>
                    <div className='col-md-2 text-left px-0'>{this.props.dw.phoneHome}</div>

                    <div className='col-md-2'><strong>Country:</strong></div>
                    <div className='col-md-2 text-left'>{ this.state.countryObj ? this.state.countryObj.name || '': '' }</div>
                </div>
            </div>
            <div className={!this.state.edit? 'hide' : 'card-body px-5'}>
                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>First Name:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='firstName' className='form-control' onChange={this.onChange} value= {this.state.firstName || ''} />
                    </div>
                    <div className='col-md-1 col-form-label'><strong>Last Name:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='lastName' className='form-control' onChange={this.onChange} value= {this.state.lastName || ''} />
                    </div>
                </div>
                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>Email:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='emailAddress1' className='form-control' onChange={this.onChange} value= {this.state.emailAddress1 || ''} />
                    </div>
                    <div className='col-md-1 col-form-label'><strong>Phone:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='phoneHome' className='form-control' onChange={this.onChange} value= {this.state.phoneHome || ''} />
                    </div>
                </div>

                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>Country:</strong></div>
                    <div className='col-md-5'>
                            <select className="custom-select custom-select-md">
                            {
                                this.state.countryList.map(country => {
                                    return (<option key={country.code3} value={country.code3}>{country.name}</option>);
                                })
                            }
                            </select>
                    </div>
                    <div className='offset-md-2 col-md-2 justify-content-center'>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onSubmit}>Save</button>
                    </div>
                </div>
            </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {getCountryList, updateCustomer})(BasicDetails)