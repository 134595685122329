import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { getClientId, dateToString, PLANS1, getDocumentStatus, getMyPlans, viewIntoSpecificTimeZone } from 'utils/common';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
import { InputFilter, NumberFilter} from 'utils/DataGrid/Components/InputFilter.js';
import { SelectFilter } from 'utils/DataGrid/Components/SelectFilter';
import { getCustomerReportV2 } from 'Redux/actions/export';
// import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';
import { debounce } from 'lodash';
import { getPartnerOptions } from 'utils/common';


const { afterToday } = DateRangePicker;


class UsersList extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(1);
        start.setMonth(0);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.debouncedSave= undefined;
        // this.handleFocusOut = this.handleFocusOut.bind(this);
        this.countFilteredUser = this.countFilteredUser.bind(this);
        this.state = {
            minStart: new Date('2019-08-15'),
            start,
            end,
            partnerId: '',
            users: [],
            plans: {},
            filterBy: 'Registration',
            skip: 0,
            limit: 50,
            apiCall: false,
            key: '',
            value: 'all',
            page : 1,
            countUser: [],
            pageLimitMessage:'',
            openModal:false
        }; 
        this.partnerOptions = getPartnerOptions({...props.auth, ...props.common});
        this.columns = [
            { key: "createdAt", 
                name: "Registration", 
                formatter: this.DateFormatter, 
                filterRenderer: p => 
                <DateRangePicker block placeholder="2021-01-01"
                    showOneCalendar
                    onChange= {(range) => {
                        this.setState({start: range[0], end: range[1], page:1});
                        const { partnerId, filterBy, limit,value} = this.state;
                        this.props.getCustomerReportV2(this.props.auth.id,viewIntoSpecificTimeZone(range[0]), viewIntoSpecificTimeZone(range[1]), partnerId, 'Registration', 0, limit, '', 'all', 1);
                    }}
                />
            },
            { 
                key: "fullname", 
                name: "Name", 
                filterRenderer: p => <InputFilter props={p} />
                },
            { 
                key: "username", 
                name: "Email", 
                filterRenderer: p => <InputFilter props={p} />
            },
            { key: "paymentDate", 
                name: "Pay Date", 
                formatter: this.DateFormatter,
                filterRenderer: p => 
                    <DateRangePicker block placeholder="Select"
                        showOneCalendar
                        allowedMaxDays
                        onChange= {(range) => {
                            this.setState({start: range[0], end: range[1], page:1});
                            const { partnerId, limit, filterBy,value} = this.state;
                            this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(range[0]), viewIntoSpecificTimeZone(range[1]), partnerId, 'Payment', 0, limit, 1, value, 1);
                        }}
                    />
            },
            { key: "paymentId", name: "Pay Id"},
            { key: "phone", name: "Mobile"},
            { key: "paymentStatus", name: "Payment Status"},
            { 
                key: "plan", 
                name: "Plan", 
                formatter: ({ column, row }) => {
                    if (row['planId'] !== undefined && row['planId'] !== null && row['planId'] ){
                        if(this.state.plans && Object.keys(this.state.plans).length > 0){
                            return this.state.plans[row['planId']] ? this.state.plans[row['planId']]  : row['planId'] ? row['planId'] : "" ;
                        } else{
                            return ''
                        }
                    }
                    if(row['paymentType'] && row['paymentType'] === 'subscription' && row['subscriptionType']) {
                        if(this.state.plans && Object.keys(this.state.plans).length > 0){
                            return this.state.plans[row['subscriptionType']] ? this.state.plans[row['subscriptionType']] : row['subscriptionType'] ? row['subscriptionType'] :"";
                        } else{
                            return ''
                        }
                    } 
                    if (row['paymentType'] !== undefined && row['paymentType'] !== null && row['paymentType'] && row['paymentType'] !== 'subscription'){
                        if(this.state.plans && Object.keys(this.state.plans).length > 0){
                            return this.state.plans[row['paymentType']] ? this.state.plans[row['paymentType']] : row['paymentType'] ? row['paymentType'] : "";
                        } else{
                            return ''
                        }
                    }
                    return ''
                }
            },
            
            { key: "dwUsername", name: "User Name", 
                filterRenderer: p => <InputFilter props={p} />
            },
            { key: "drivewealthUsername", name: "Drivewealth Username", formatter: ({ column, row}) => 
                {
                    if(row['drivewealthUsername'] && row['drivewealthUsername'] !== '') {
                        return row['drivewealthUsername'];
                    } else if(row['dwUsername']) {
                        return row['dwUsername'];
                    } else {
                        return '';
                    }
                } 

            },
            { key: 'userReferralCode', name: 'Referral Code' },
            { key: "couponCode", name: "Coupon"},
            { key: "kyc", name: "Account Creation Status", formatter: ({ column, row}) => 
            {
                if(row['accountNo']) {
                    return 'Done';
                } else {
                    return '';
                }
            } },
            { key: "document", name: "Kyc Upload Status", formatter: ({ column, row}) => 
            {
                if(row['accountNo'] && row['document'].length > 0) {
                    return 'Done';
                } else {
                    return '';
                }
            }},
            { key: "kycStatusCode", name: "KYC Status"},
            { key: "kycWhen", name: "KYC last Reviewed"},
            { key: "stateProvince", name: "State"},
            { key: "riaId", name: "Partner", filterRenderer: p => 
                    Array.isArray(this.partnerOptions) && this.partnerOptions.length > 0?
                    (<select 
                    name='riaId'
                    className="form-control custom-select" 
                    onChange={this.handleInputChange}
                    // onBlur= {this.handleFocusOut }
                    > 
                    {
                      this.partnerOptions.map((partner, id) => (
                        <option key={id} value={partner.code}>{partner.name}</option>
                      )) 
                    }
                  </select> ):''
            },

            { key: "partnerToken", name: "Partner Token",
            },
            { key: "idStatus", name: "ID Proof", formatter: ({column, row}) => row['idStatus'] !== '' ?(getDocumentStatus({statusCode: row['idStatus']}) || '') : ''},
            { key: "addressProofStatus", name: "Address Proof", formatter: ({column, row}) => row['addressProofStatus'] !== '' ?(getDocumentStatus({ statusCode: row['addressProofStatus'] }) || '') : '' },
            { key: "accountApprovedWhen", name: "Account Approval Date", formatter: this.DateFormatter }
        ];
    }
    handleSelect(date){
        console.log(date); // native Date object
    }

    handleInputChange(e) {
        console.log('e',e.target.name, e.target.value)
        this.setState({key: e.target.name, value: e.target.value.toLowerCase(), page:1});
        if (!this.debouncedSave) {
            this.debouncedSave = debounce(() => { 
                const { start, end, partnerId, limit, key, value, page} = this.state
                let filterValue = value === 'all' ? 'Registration' : "Filter"
                if (value !== '') {
                    this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, filterValue, 0, limit, key, value, 1); 
                }
                
            }, 1000);
        }
        
		this.debouncedSave();
    }

    // handleFocusOut(e) {
    //     console.log(e);
    //     e.target.value = '';
    //     this.setState({ key: '', value: '', skip: 0});
    // }

    DateFormatter ({column, row}){
        const key = column.key;
        if (row[key]) {
            return dateToString(row[key]);
        } else 
        {
            return '';
        }
    };
    componentDidMount () {
        const { auth } = this.props
        const partnerId = getClientId(auth) === 'STOCKAL' ? 'ALL' : getClientId(auth);
        const plans = getMyPlans(this.props.common.plans || []);
        this.setState({ partnerId, plans });
        const { start, end, filterBy, skip, limit, key, value, page} = this.state;
        this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, 'Registration', skip, limit, key, value, page);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.users.length === 0 ) {
            return { users: props.users };
        }
        if (props.skip !== state.skip) {
            return { skip: props.skip };
        }
        if(Object.keys(state.plans).length === 0) {
            const plans = getMyPlans(props.common.plans);
            return { plans };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users.length !== this.props.users.length) {
            this.setState({ users: this.props.users, apiCall: false });
        }
    }

    onSubmit() {
        const { start, end, partnerId, filterBy } = this.state;
        this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, filterBy);
    }

    onChange(e) {
        if (e.target.name === 'filterBy') {
            console.log(e.target.value);
            this.setState({ skip: 0, filterBy: e.target.value, page:1})
            const { start, end, partnerId, limit, filterBy, key, value, page} = this.state;
            console.log(start, end, partnerId, limit, filterBy, e.target.value);
            this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, e.target.value, 0, limit, key, value, page);
        }
        // this.setState({[e.target.name]: e.target.value})
    }

    onDateChange(name,date) {
    const max = new Date();
        if (name === 'start_date') { 
            const start = date;
            const end = new Date(date);
            end.setDate(end.getDate() + 60);
            
            this.setState({ start, end }); 
            if (max > end) {
                this.setState({ end});
            } else {
                this.setState({ end: max});
            }
        }
        if (name === 'end_date') { 
            this.setState({ 'end':date }); 
        }
    }

    scrollEvent() {
        if (!this.state.apiCall && this.state.page <= 20) {
            let { skip, limit, start, end, partnerId, filterBy, key, value, page } = this.state;
            skip = skip + 1;
            let pageIncrement = page + 1;
            // if (key !== '') {
            //     filterBy = 'Filter';
            // }
            if(value !== "all"){
                filterBy = 'Filter'
            }
            this.props.getCustomerReportV2( this.props.auth.id,viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, filterBy, skip, limit, key, value,pageIncrement);
            this.setState({ apiCall: true});
            this.setState({ page : pageIncrement})
            if(pageIncrement === 20){
                this.setState({page:21})
            }
        } if(this.state.page === 21){
            this.setState({pageLimitMessage:'Please change the search criteria . Page limit exceeded.', openModal: true})

        }
    }

    renderStatusRibbon () {
        if (this.props.message !== '') {
            return (
                <Loader>
                     <div className={`alert text-center ${this.props.message !== '' ? this.props.users.length > 0 ? 'alert-danger': 'alert-primary' : 'hide' }`} role="alert">
                         {this.props.message}
                    </div>
                </Loader>
            );
        } else {
            return '';
        }
        
    }

    setFilter(filters) {
        console.log(filters);
    }

    countFilteredUser(value){
        this.setState({countUser:value})
    }

    handleClick() {
        this.setState({openModal:false})
    }
    

    renderSearchFilter() {
        return (
            <Fragment>  
                {this.state.openModal ? 
                // <div className="createStackModal">
                    <div id="popup1 mx-auto" className="overlay createStackModalCard" style={{width:"45%"}}>
                        <div className="popup">
                            <h2 className="mt-4 text-left" style={{paddingLeft:"14px", fontSize:"23px"}}>{this.state.pageLimitMessage}</h2>
                                <div className="modal-body">
                                </div>
                                <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClick} >Cancel</button>
                                </div>
                        </div>
                    </div>
                : ""}  
                {Object.keys(this.state.plans).length > 0 ?    
                <DataGrid columns={this.columns} rows={this.state.users} message={this.props.message} 
                    scrollEvent={this.scrollEvent}
                    setFilter={this.setFilter}
                    enableFilters = {true}
                    countFilterUser={this.countFilteredUser}
                    isCountCheck={true}
                >
                    <span className="form-control"><strong>Count: {this.state.countUser}</strong></span>
                </DataGrid> : ""}
            </Fragment>
        );
    }

    render() {
        return (<Fragment>
            
            
             {this.renderStatusRibbon()} 
             {this.renderSearchFilter()}
        </Fragment>);
    }
}

const mapStateToProps = (props) => {
    return {
        common: props.common,
        auth: props.auth,
        users: props.report.users,
        skip: props.report.skip,
        filter: props.report.filter,
        message: props.report.usersReportMessage,
    };
}

export default connect(mapStateToProps, { getCustomerReportV2 })(UsersList);


