
/**
 * This library is used to show alert after every api updates. 
 * show toast after autoClose. Alert Mesage, autoClose, showAlert, setAlertOff 
 * are coming from parent component.
 *  <Toast 
        showAlert={showAlert}
        alertMessage={alertMessage}
        autoClose={autoClose}
        setAlertOff={this.setAlertOff}
      />

  setAlertOff = () => {
    setTimeout(() => this.setState({showAlert: false}), this.state.autoClose)
  }

  defualt values to put in constructor
    alertMessage: "",
    showAlert: false,
    autoClose: 1500,
 */
 
  import React, { Component } from 'react';
  import { ToastContainer, toast, Slide } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // minified version is also included
  // import 'react-toastify/dist/ReactToastify.min.css';
  // import {} from './style.css' 
 
  // class Toast extends Component {

  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       alertVisible: false
  //     }
  //   }
    
  //   componentDidUpdate = (prevProps) => {
  //     if (prevProps.alertVisible !== this.props.alertVisible)
  //       this.setState({
  //         alertVisible: this.props.alertVisible
  //       })
  //   }
 
  //   render(){
  //     if (this.state.alertVisible) {
  //       setTimeout(() => this.setState({alertVisible: false}), this.props.alertTime)
  //     }
  //     let className = `alert alert-${this.props.alertType} Toast_top_center`
  //     return (
  //       <div>
  //         {
  //           this.state.alertVisible &&   
  //           <div 
  //             className={className} 
  //             role="alert"
  //           >
  //             {this.props.alertMessage}
  //           </div>
  //         }
         
  //       </div>
  //     );
  //   }
  // }

  
  class Toast extends Component {

    notifyA = () => toast(this.props.alertMessage, {containerId: 'A'});
  
    render() {
      if(this.props.showAlert){
        this.notifyA()
        this.props.setAlertOff()
      }
      return (
        <div>
           <ToastContainer 
            transition={Slide}
            // hideProgressBar
            autoClose={this.props.autoClose}
            enableMultiContainer 
            containerId={'A'} 
            position={toast.POSITION.TOP_CENTER}           
          />
        </div>
      )
    }
  }
  

  export default Toast