import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

export default class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.getDate = this.getDate.bind(this);
  }
  getDate = (date = new Date()) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  render() {
    let { user, refCode } = this.props;
    if (user) {
      var partnerId = user.riaId;
      if (user.dw && user.dw.length > 0)
        var { phoneHome, addressLine2, addressLine1, city } = user.dw[0];
    }
    if (refCode && refCode.length > 0) var refcode = refCode[0].bankToken;
    return (
      <div className='card w-100'>
        <div className='card-body customer-details-body'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-3'>
                <b>First Name:</b>
              </div>
              <div className='col-3'>
                <input
                  className='form-control form-control-user '
                  placeholder={user.dw[0].firstName}
                  name='firstName'
                  value={this.props.parentState.firstName}
                  onChange={this.props.parentHandleChange}
                />
              </div>
              <div className='col-3'>
                <b>Last Name:</b>
              </div>
              <div className='col-3'>
                <input
                  className='form-control form-control-user '
                  placeholder={user.dw[0].lastName}
                  name='lastName'
                  value={this.props.parentState.lastName}
                  onChange={this.props.parentHandleChange}
                />
              </div>
            </div>

            <br />
            <div className='row'>
              <div className='col-3'>
                <b>Email Address:</b>
              </div>
              <div className='col-9'>
                <input
                  className='form-control form-control-user '
                  placeholder={user.dw[0].emailAddress1}
                  name='emailAddress1'
                  value={this.props.parentState.emailAddress1}
                  onChange={this.props.parentHandleChange}
                />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-3'>
                <b>Phone No:</b>
              </div>
              <div className='col-9'>
                <input
                  className='form-control form-control-user '
                  placeholder={user.dw[0].phoneHome}
                  name='phoneHome'
                  value={this.props.parentState.phoneHome}
                  onChange={this.props.parentHandleChange}
                />
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-3'>
                <b>Date Of Birth:</b>
              </div>
              <div className='col-9'>
                <DatePicker
                  className='form-control form-control-sm '
                  dateFormat='yyyy-MM-dd'
                  value={user.dw[0].dob}
                  onChange={(date) => {
                    date = this.getDate(date);
                    this.props.parentHandleChange(date);
                    // this.setState({
                    //   fields: {
                    //     ...this.state.fields,
                    //     expiryDate: date.toString(),
                    //     expiry: date.toString(),
                    //   },
                    // });
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                />
                {/* <input
                  className='form-control form-control-user '
                  placeholder={user.dw[0].dob}
                  name='dob'
                  p
                  onChange={this.props.parentHandleChange}
                /> */}
              </div>
            </div>
            <br />

            <div className='row'>
              <div className='col-3'>
                <b>Gender:</b>
              </div>
              <div className='col-9'>
                <select
                  className='form-control form-control'
                  name='gender'
                  onChange={this.props.parentHandleChange}
                  value={user.dw[0].gender}
                >
                  <option value='male'>male</option>
                  <option value='female'>female</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
