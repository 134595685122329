



import axios from 'axios'
import {API_ENDPOINT, API_ENDPOINT_V2} from 'Redux/apiConstant'
import {getConfig} from '../getConfig'

// forgot password apis
export const postComments = async (data) => {
   let response = await axios.post(`${API_ENDPOINT}/api/streams/dashboard/comment/create`, data ,getConfig())
   if (response.status === 200) {
     return response.data
   }
}

export const postCommentsV2 = async (data) => {
   const config = {  headers : {
      'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
      'adminid' : data.createdBy
   }};
   let response = await axios.post(`${API_ENDPOINT_V2}/dashboard/comment`, data , config)
   if (response.data.code === 200) {
      return response.data.data
   }
}

export const getComments = async ({customerId}) => {

  const url = `${API_ENDPOINT}/api/streams/dashboard/comment/list/${customerId}`
  try{
     let response = await axios.get(url, getConfig())
     if (response.data.status === 200){
        return response.data
     }
     else
        throw response.data.status
  }
  catch (e) {
       alert(e)
  }
 }

 export const getCommentsByCategory = async ({categoryID, customerId, category}) => {
   console.log("category ID ", categoryID)
  let headers = getConfig().headers
  headers["category"] = category
  headers["categoryID"] = categoryID

  const url = `${API_ENDPOINT}/api/streams/dashboard/comment/list-by-category/${customerId}`
  try{
     let response = await axios.get(url, {headers})
     if (response.data.status === 200){
        return response.data
     }
     else
        throw response.data.status
  }
  catch (e) {
       alert(e)
  }
 }

 export const getCommentsByCategoryV2 = async ({adminId, categoryID, category}) => {
   const config = {  headers : {
      'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
      'adminid' : adminId
   }};

   const url = `${API_ENDPOINT_V2}/dashboard/comment/list-by-category/${categoryID}?category=${category}`
   try{
      let response = await axios.get(url, config)
      if (response.data.code === 200){
         return response.data.data
      }
      else
         throw response.data.message
   }
   catch (e) {
        alert(e)
   }
  }
 