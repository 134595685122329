import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { getTransactionDetail , getAlltransactionsDetail} from 'Redux/actions/transaction';
import { getClientId, dateToString, PLANS1, TRANSACTIONTYPES, getMyPlans, viewIntoSpecificTimeZone } from 'utils/common';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
import { InputFilter, DFilter} from 'utils/DataGrid/Components/InputFilter.js';
import { SelectFilter } from 'utils/DataGrid/Components/SelectFilter';
import { getPortfolioSearch } from '../../Redux/actions/portfolioSearch';


class Transactions extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(start.getUTCDate()-3);
        this.state = {
            start,
            end,
            partnerId: '',
            userId: '',
            amountSummary: 0,
            ID:"",
            suggestionBoxLoader:false,
            searchButton:true
        };
        // this.plans = getPlans();
        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.SubscriptionFormatter = this.SubscriptionFormatter.bind(this);
        this.searchOnChange = this.searchOnChange.bind(this);
        this.getSearchCustomerList = this.getSearchCustomerList.bind(this);
        this.setClickUserID = this.setClickUserID.bind(this);
        this.closeSuggestionBox =this.closeSuggestionBox.bind(this);
        this.columns = [
            { key: 'fullname', name:"Full Name", editable: true },
            { key: 'username', name:"User Name", filterRenderer: p => <InputFilter props={p} />, editable: true },
            { key: 'accountNo', name:"Account No", },
            { key: 'token', name: "Token", },
            { key: 'accountType', name:"Account Type", },
            { key: 'finTranTypeID', name:"Transaction Type", filterRenderer: p => <SelectFilter props={{...p, options: TRANSACTIONTYPES}} />},
            { key: 'accountAmount', name:"Amount", summaryFormatter: this.amountSummaryFormatter, sortable: true},
            { key: 'accountBalance', name:"Balance", },
            { key: 'tranAmount', name:"Transaction Amount", },
            { key: 'tranSource', name:"Transaction Source", },
            { key: "subscriptionType", name: "Subscription", formatter: this.SubscriptionFormatter, filterRenderer: (p) => <SelectFilter props={{...p, options:PLANS1 }} /> },
            { key: 'partnerCode', name:"Partner",},
            { key: 'tranWhen', name:"Date", formatter: this.DateFormatter, filterRenderer: p => <DFilter props={p} /> },
            { key: 'comment', name:"Comment", },
            // { key: 'dividend.amountPerShare', name:"Divident Amount/Share", formatter: ({column, row}) => { console.log(row); return true; } /*row.dividend.amountPerShare*/ },
            // { key: 'dividend.type', name:"Divident Type", },
            { key: 'dnb', name:"DNB", },
            { key: 'feeBase', name:"Base Fee", },
            { key: 'feeSec', name:"Sec Fee", },
            { key: 'feeTaf', name:"Taf Fee", },
            { key: 'feeXtraShares', name:"Fee Extra Shares", },
            { key: 'fillPx', name:"Fill Px", },
            { key: 'fillQty', name:"Fill Quantity", },
            { key: 'orderID', name:"Order",  filterRenderer: p => <InputFilter props={p} />},
            { key: 'sendCommissionToInteliclear', name:"Send Commission To Inteliclear",},
            { key: 'systemAmount', name:"System Amount", }
        ];
    }

    amountSummaryFormatter({ row}) {
        return <>{row.totalCount}</>;
    }

    formatDate (date) {
        return dateToString(date);
    }

    fetchTransactionDetail (start, end, partnerId, userId= this.state.userId) {
        const nstart = this.formatDate(start);
        const nend = this.formatDate(end);
        this.props.getTransactionDetail(nstart, nend,  partnerId, 0, 0, userId);
    }

    DateFormatter ({column, row}){
        const key = column.key;
        return dateToString(row[key]);
    };

    SubscriptionFormatter ({column, row}){
        
        const key = column.key;
        return `${this.state.plans[[row[key]]]}(${row[key]})` || '';
    }

    componentDidMount () {
        const { auth } = this.props
        const partnerId = getClientId(auth)
        const plans = getMyPlans(this.props.common.plans || []);
        this.setState({ partnerId, plans });
        this.setState({ partnerId, userId: auth.id });
        let { start, end } = this.state;
        this.fetchTransactionDetail(start, end, partnerId, auth.id);
    }

    onSubmit() {
        const { start, end, partnerId } = this.state;
        start.setHours(0, 0, 0);
        end.setHours(23,59, 59);
        // Convert start date to date 00:00:00
        // convert end date to date 23:59:59
        // Pass both the dates to dateToString method
        this.fetchTransactionDetail(start, end, partnerId);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    onDateChange(name,date) {
        const max = new Date();
        
        if (name === 'start_date') { 
            const start = date;
            const end = new Date(date);
            end.setDate(end.getDate() + 90);
            this.setState({ start: start});
            if (max > end) {
                this.setState({ end: end});
            } else {
                this.setState({ end: max});
            }
            // this.setState({ 'start': date }); 
        }
        if (name === 'end_date') { this.setState({ 'end':date }); }
    }

    searchOnChange = (e) => {
        this.setState({ID:e.target.value,searchButton:false})
    }

    getSearchCustomerList(){
        this.setState({suggestionBoxLoader:true})
        let { ID, userId } = this.state
        getPortfolioSearch({ID, userId})
        .then((response) => {
            if(response.data.code === 200){
                this.setState({ searchList:response.data.data,suggestionBox:true,suggestionBoxLoader:false})
            }else{
                this.setState({ searchList:[],suggestionBoxLoader:false})
            }
        }).catch((err) => {
            this.setState({search_error_message:"No Data Found",searchList:[],suggestionBoxLoader:false})
        })
    }

    setClickUserID(e){
        this.setState({clickUserAccount:e.dw[0].accountNo,suggestionBox:false},()=>  this.getAlltransactions())
    }

    getAlltransactions(){
        let {clickUserAccount} = this.state
        this.props.getAlltransactionsDetail(clickUserAccount, this.props.auth.id);
    }

    closeSuggestionBox(){
        this.setState({suggestionBox:false})
    }

    renderStatusRibbon () {
        console.log('messsage',this.props.transactionMessage)
        if (this.props.transactionMessage !== '') {
            return (
                <Loader>
                    <div className={`alert text-center ${this.props.transactionMessage !== '' ? this.props.transaction.length > 0 ? 'alert-danger': 'alert-primary' : 'hide' }`} role="alert">
                        { this.props.transactionMessage }
                    </div>
                </Loader>
            );
        } else {
            return '';
        }
        
    }

    renderSearchFilter() {
        console.log('trans',this.props.transaction)
        return (
            <Fragment>
                {/* <DataFilter searchKeyValues={this.searchKeyValues} type="transactions" data={this.props.transaction} exportHeaders={this.exportHeaders}/> */}
                <DataGrid columns={this.columns} rows={this.props.transaction} message={this.props.transactionMessage} />
            </Fragment>
        );
        
    }

    render() {
        let loader = <div className="newButtonLoader"></div>
        console.log('sugges',this.state.ID)
        return (<Fragment>

            <SearchFilter
            state={this.state}
            onChange={this.searchOnChange}
            // onSearch={this.onSearch}
            getSearchCustomerList={this.getSearchCustomerList}
            portfolioData={this.getPortFolioTotal}
            searchButton={this.state.searchButton}
            searchList={this.state.searchList}
            setClickUserID ={this.setClickUserID}
            suggestionBox={this.state.suggestionBox}
            closeSuggestionBox={this.closeSuggestionBox}
            loader={loader}
            suggestionBoxLoader={this.state.suggestionBoxLoader}
            /> 


            <DateFilter
                start_date={this.state.start}
                end_date={this.state.end}
                partnerId={this.state.partnerId}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onChangeDate={this.onDateChange}
                {...this.props}
            />
            {this.renderStatusRibbon()}
            {this.renderSearchFilter()}
        </Fragment>);
    }
}


const SearchFilter = ({state, onChange, suggestionBox,
    getSearchCustomerList,searchList,searchButton,
    setClickUserID,closeSuggestionBox,loader,suggestionBoxLoader}) => {
    let {  search_term, term } = state
    let searchInputClassName= (search_term==="phone"?"number-control":"")
    // console.log('searchList',searchList)
    return (
      <React.Fragment>
        <div className="form-row" style={{position:"relative"}}>
            <React.Fragment>
                <div className="form-group col-md-4">
                    <input 
                    className={`${searchInputClassName} form-control`}
                    // style={{width:"400px"}}
                    type= {search_term==="phone"?"number":"text"} 
                    name="term"
                    value={term}
                    onChange={onChange}
                    />
                </div>
                <div className="form-group col-md-2">
                    <button className="btn btn-primary btn-stockal" style={{width:"150px"}} onClick={getSearchCustomerList} disabled={searchButton}>{suggestionBoxLoader ? loader : 'Search'}</button>
                </div>
            </React.Fragment>

            {searchList && searchList.length > 0 && suggestionBox ?
                <div className="SearchSuggestions__Container">
                    <button type="button" className="close" onClick={closeSuggestionBox} style={{position:"sticky",top:"0"}}>
                        <span>&times;</span>
                    </button>
                    {searchList.map((item, index) => (
                        <div className="suggestionCard" style={{cursor:"pointer"}} onClick={item.dw && item.dw[0]  ? (e) => setClickUserID(item) : ''}>
                            <div className="row">
                                <div className="col-6 searchSuggestionFullname">
                                    {item.fullname}
                                </div>
                                <div className="col-6 searchSuggestionUsername">
                                    {item.username}
                                </div>     
                            </div>
                            <div className="row">
                                <div className="col-12 searchSuggestionID" 
                                style={{float:"left"}}>
                                    {item.dw && item.dw[0] && item.dw[0].accountNo  ?
                                    item.dw[0].accountNo : "--" }
                                </div>    
                            </div>    
                        </div>
                    ))}
                </div>
            : "" }
          
        </div>
       </React.Fragment>
    )
  }



const mapStateToProps = ( { transactions }) => {
    return transactions;
}

export default connect(mapStateToProps, { getTransactionDetail , getAlltransactionsDetail })(Transactions);


