import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from 'react-loader';
import { API_ENDPOINT_V2, SECRET_KEY} from '../../Redux/apiConstant';
import { getConfig } from '../../Redux/getConfig';
import { API_ENV } from '../../Redux/apiConstant';
import { CAN_EDIT_COUPON, CAN_CREATE_COUPON } from '../../utils/roles';
import { default as DataGrids } from '../../utils/DataGrid/index';
import { InputFilter } from 'utils/DataGrid/Components/InputFilter.js';

class ActiveCouponList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      updated: false,
      items: [],
      isLoaded: false,
      discountName: '',
      amount: '',
      fields: {
        code: '',
        title: '',
        discountAmount: '',
        discountPer: '',
        flatAmount: '',
        validPaymentMode: '',
        validPlan: '',
        expiry: '',
        activationDate: '',
        expiryDate: '',
        maxUsers: '',
        usesCount: '',
        status: '',
        partnerCode:'',
        isEdit:false,
        isCreate: false,
      },
    };
    this.getData = this.getData.bind(this);
    this.setDisplay = this.setDisplay.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getData = this.getData.bind(this);
    this.updateAPI = this.updateAPI.bind(this);
  }
  changeHandler = (e) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value,
      },
    });
  };

  async updateAPI() {
    const id = this.state.id;
    try {
      const res = await axios.put(
        `${API_ENDPOINT_V2}/coupons-dashboard/${id}`,
        {
          expiryDate: this.state.fields.expiryDate,
          maxUsers: this.state.fields.maxUsers,
          status: this.state.fields.status
        },
        {
          headers: {
            'content-type': 'application/json',
            'stockal-secret-key': SECRET_KEY,
            'api-env': API_ENV,
          },
        }
      );
      if (res.status === 200) {
        this.setState({ updated: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  getDate = (date = new Date()) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };
  setDisplay(item) {
    item.discountPer > 0
      ? this.setState({
          discountName: 'Discount Percent',
          amount: item.discountPer,
        })
      : item.discountAmount > 0
      ? this.setState({
          discountName: 'Discount Amount',
          amount: item.discountAmount,
        })
      : item.flatAmount > 0 &&
        this.setState({
          discountName: 'Flat Amount',
          amount: item.flatAmount,
        });
    this.setState({
      id: item._id,
      fields: {
        ...this.state.fields,
        code: item.code,
        title: item.title,
        discountAmount: item.discountAmount,
        discountPer: item.discountPer,
        flatAmount: item.flatAmount,
        validPaymentMode: item.validPaymentMode,
        validPlan: item.validPlan,
        expiry: item.expiry,
        activationDate: item.activationDate,
        expiryDate: item.expiryDate,
        maxUsers: item.maxUsers,
        usesCount: item.usesCount,
        status: item.status,
        partnerCode: item.partnerCode
      },
    });
  }

  async getData() {
    const newdate = new Date();
    let { expiryDate, usesCount, maxUsers } = this.state.fields;
    expiryDate = expiryDate.split('-');

    const expday = parseInt(expiryDate[0]);
    const expmonth = parseInt(expiryDate[1]);
    const expyear = parseInt(expiryDate[2]);
    const currday = newdate.getDate();
    const currmonth = newdate.getMonth() + 1;
    const curryear = newdate.getFullYear();
    if (usesCount < maxUsers) {
      if (expyear >= curryear) {
        if (expmonth >= currmonth) {
          if (expday >= currday) {
            this.setState({
              fields: {
                ...this.state.fields,
                status: 'ACTIVE',
              },
            });
          }
        }
      }
    }
    const res = await axios.get(
      `${API_ENDPOINT_V2}/coupons-dashboard`,
      getConfig()
    );

    if (res.status === 200) {
      const temp = res.data.data.map((item) => {
        return item.expiryDate
          ? item.activationDate
            ? {
                ...item,
                expiryDate: item.expiryDate.slice(0, 10),
                activationDate: item.activationDate.slice(0, 10),
                status: (
                  // <div
                  //   className={`text-${
                  //     item.status === 'ACTIVE' ? 'success' : 'danger'
                  //   }`}
                  // >
                  // {item.status}
                  // </div>
                  item.status
                ),
                button: (
                  <button
                    onClick={() => this.setDisplay(item)}
                    type='button'
                    data-toggle='modal'
                    data-target='#exampleModalCenter'
                    className='btn-sm btn-primary btn-stockal btn-user btn-block'
                    disabled={!this.state.isEdit}
                  >
                    EDIT
                  </button>
                ),
              }
            : {
                ...item,
                expiryDate: item.expiryDate.slice(0, 10),
                button: (
                  <button
                    onClick={() => this.setDisplay(item)}
                    type='button'
                    data-toggle='modal'
                    data-target='#exampleModalCenter'
                    className='btn-sm btn-primary btn-stockal btn-user btn-block'
                    disabled={!this.state.isEdit}
                  >
                    EDIT
                  </button>
                ),
              }
          : {
              ...item,
              button: (
                <button
                  onClick={() => this.setDisplay(item)}
                  type='button'
                  data-toggle='modal'
                  data-target='#exampleModalCenter'
                  className='btn-sm btn-primary btn-stockal btn-user btn-block'
                  disabled={!this.state.isEdit}
                >
                  EDIT
                </button>
              ),
            };
      });

      this.setState({
        items: temp,
        isLoaded: true,
      });
    }
  }
  catch(err) {
    console.error(err);
  }

  componentDidMount() {
    if(CAN_EDIT_COUPON.includes(this.props.auth.roleTitle)){
      this.setState({isEdit: true });
    }
    this.getData();
  }

  render() {
    console.log('coupon data',this.state.items)
    return (
      <div>
        <div
          className='modal fade'
          id='exampleModalCenter'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-lg modal-dialog-centered '
            role='document'
          >
            <div className='modal-content'>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-xl-7'>
                    <div className='card border-0 shadow-lg my-5'>
                      <div className='card-body p-0'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='p-5'>
                              <div className='text-center'>
                                <h1 className='h4 text-gray-900 mb-4'>
                                  Update Coupon
                                </h1>

                                <form>
                                  <div className='form-group '>
                                    <input
                                      type='text'
                                      className='form-control form-control-user'
                                      value={this.state.fields.code}
                                      disabled
                                    />
                                  </div>

                                  <div className='form-group '>
                                    <input
                                      type='text'
                                      className='form-control form-control-user'
                                      value={this.state.fields.title}
                                      disabled
                                    />
                                  </div>
                                </form>

                                <div className='row'>
                                  <div className='col-6'>
                                    <div className='float-left'>
                                      <select
                                        className='form-control form-control-sm'
                                        style={{ width: '150px' }}
                                        disabled
                                      >
                                        <option>
                                          {this.state.discountName}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className='col-6'>
                                    <div className='float-right'>
                                      <input
                                        type='number'
                                        className='form-control form-control-sm '
                                        value={this.state.amount}
                                        style={{ width: '150px' }}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                  <div className='col-6'>
                                    <div className='float-left'>
                                      <select
                                        className='form-control form-control-sm '
                                        style={{ width: '150px' }}
                                        disabled
                                      >
                                        <option>
                                          {this.state.fields.validPlan}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                      <select 
                                      name='partnerCode'
                                      className="form-control form-control-sm" 
                                      value={this.state.fields.partnerCode}
                                      disabled
                                      >                           
                                        <option>{this.state.fields.partnerCode}</option>
                                      </select>
                                  </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                  <div className='col-6'>
                                    <input
                                      type='number'
                                      placeholder='Maximum Users'
                                      className='form-control form-control-sm '
                                      name='maxUsers'
                                      value={this.state.fields.maxUsers}
                                      onChange={this.changeHandler}
                                      style={{ width: '150px' }}
                                    />
                                  </div>
                                  <div className='col-6'>
                                    <select
                                      name='status'
                                      onChange={this.changeHandler}
                                      className='form-control form-control-sm '
                                      value={this.state.fields.status}
                                      style={{ width: '150px' }}
                                    >
                                      <option>Select Status</option>
                                      <option value='ACTIVE'>ACTIVE</option>
                                      <option value='INACTIVE'>INACTIVE</option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  className='float-left'
                                  style={{
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                  }}
                                >
                                  <div>
                                    <label style={{ paddingRight: '10px' }}>
                                      Expiry date :
                                    </label>

                                    <DatePicker
                                      className='form-control form-control-sm '
                                      dateFormat='yyyy-MM-dd'
                                      value={this.state.fields.expiryDate.slice(
                                        0,
                                        10
                                      )}
                                      onChange={(date) => {
                                        date = this.getDate(date);
                                        this.setState({
                                          fields: {
                                            ...this.state.fields,
                                            expiryDate: date.toString(),

                                            expiry: date.toString(),
                                          },
                                        });
                                      }}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode='select'
                                    />
                                  </div>
                                  <br />
                                  <div className='row'>
                                    <div className='col-6'>
                                      <input
                                        type='submit'
                                        onClick={this.updateAPI}
                                        className='btn btn-primary btn-stockal btn-user btn-block'
                                        value='Update'
                                      />
                                    </div>
                                    <div className='col-6'>
                                      <button
                                        type='button'
                                        className='btn btn-primary btn-stockal btn-user btn-block '
                                        data-dismiss='modal'
                                        onClick={() => window.location.reload()}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                  <br />
                                  {this.state.updated ? (
                                    <div
                                      class='alert alert-success'
                                      role='alert'
                                    >
                                      Coupon Updated Successfully !
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoaded ? (
          this.state.items ? (
            <div>
              <div className='float-right'>
                <Link to='/createCoupon'>
                  <input
                    type='submit'
                    className='btn btn-primary btn-stockal btn-user btn-block'
                    value='Create Coupon'
                    disabled = {!CAN_CREATE_COUPON.includes(this.props.auth.roleTitle)}
                  />
                </Link>
              </div>
              <br />
              <br />
              <DataGrids
                columns={[
                  { key: 'code', name: 'CODE', frozen: true , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'title', name: 'TITLE', filterRenderer: p => <InputFilter props={p} />  },
                  {
                    key: 'discountPer',
                    name: 'DISCOUNT PERCENTAGE',
                    resizable: false,
                    filterRenderer: p => <InputFilter props={p} />
                  },
                  { key: 'discountAmount', name: 'DISCOUNT AMOUNT' , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'flatAmount', name: 'FLAT AMOUNT' , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'validPlan', name: 'PLAN ID', filterRenderer: p => <InputFilter props={p} /> },
                  { key: 'maxUsers', name: 'MAXIMUM USERS' , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'usesCount', name: 'USED' , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'validPaymentMode', name: 'PAYMENT MODE', filterRenderer: p => <InputFilter props={p} /> },
                  { key: 'activationDate', name: 'CREATED AT' , filterRenderer: p => <InputFilter props={p} />},
                  { key: 'expiryDate', name: 'EXPIRING ON', filterRenderer: p => <InputFilter props={p} /> },
                  { key: 'status', name: 'STATUS', filterRenderer: p => <InputFilter props={p} /> },
                  { key: 'partnerCode', name: 'PARTNER', filterRenderer: p => <InputFilter props={p} /> },
                  { key: 'button', name: '', exportFormatter: () => ''},
                ]}
                rows={this.state.items}
                onScroll={(e) => {
                  if (
                    e.target.offsetHeight + e.target.scrollTop >
                    e.target.scrollHeight
                  ) {
                    if (this.props.scrollEvent) {
                      this.props.scrollEvent();
                    }
                  }
                }}
              />
            </div>
          ) : (
            <h3>No Data Found</h3>
          )
        ) : (
          <div className='d-flex align-items-center'>
            <Loader className='d-flex align-items-center' />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  return {
    auth
  }
}
export default connect(mapStateToProps, null)(ActiveCouponList);
