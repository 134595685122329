import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCountryList, updateCustomer } from 'Redux/actions/customers';

class AddressDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
        this.edit = this.edit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount () {
        this.setState({
            address1: this.props.dw.addressLine1,
            address2: this.props.dw.addressLine2,
            city: this.props.dw.city,
            state: this.props.dw.stateProvince,
            postalCode: this.props.dw.zipPostalCode
            
        });
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    edit (event) {  
        this.setState({ edit: !this.state.edit });
    }

    async onSubmit() {
        const update = {
            type: 'ADDRESS_INFO',
            body: {
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                postalCode: this.state.postalCode,
            },
            userID: this.props.dw.userID,
        };
        await this.props.updateCustomer(update);
        // console.log('update complete');
        // await this.props.getDetails();
        this.setState({ edit: false });
        
    }

    renderBasicDetails () {
        console.log(this.props);
        return (<Fragment>
            <div className='card my-4'>
            <div className='card-header ' id='headingOne'>
                <div className='pull-left'>Address Details</div>
                <div className='pull-right'>
                {
                    !this.state.edit ? (
                        <span className="fas fa-edit mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    ) 
                    : 
                    (
                        <span className="fas fa-times mr-2" 
                    value='Basic Details' onClick={this.edit}></span>
                    )
                }
                
                </div>
            </div>
            <div className={this.state.edit? 'hide' : 'card-body'}>
                <div className= 'row'>
                    <div className='col-md-2'><strong>Address1:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.addressLine1}</div>
                    <div className='col-md-2'><strong>Address2:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.addressLine2}</div>
                    <div className='col-md-1'><strong>City:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.city}</div>
                </div>
                <hr></hr>
                <div className= 'row'>
                    <div className='col-md-2'><strong>State:</strong></div>
                    <div className='col-md-2 text-left'>{this.props.dw.stateProvince}</div>

                    <div className='col-md-2'><strong>Postal Code:</strong></div>
                    <div className='col-md-2 text-left'>{ this.props.dw.zipPostalCode }</div>
                </div>
            </div>
            <div className={!this.state.edit? 'hide' : 'card-body px-5'}>
                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>Address1:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='address1' className='form-control' onChange={this.onChange} value= {this.state.address1 || ''} />
                    </div>
                    <div className='col-md-1 col-form-label'><strong>Address2:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='address2' className='form-control' onChange={this.onChange} value= {this.state.address2 || ''} />
                    </div>
                </div>
                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>City:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='city' className='form-control' onChange={this.onChange} value= {this.state.city || ''} />
                    </div>
                    <div className='col-md-1 col-form-label'><strong>State:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='state' className='form-control' onChange={this.onChange} value= {this.state.state || ''} />
                    </div>
                </div>

                <div className= 'row my-2'>
                    <div className='col-md-1 col-form-label'><strong>Postal Code:</strong></div>
                    <div className='col-md-5'>
                        <input type='text' name='postalCode' className='form-control' onChange={this.onChange} value= {this.state.postalCode || ''} />
                    </div>
                    <div className='offset-md-2 col-md-2 justify-content-center'>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onSubmit}>Save</button>
                    </div>
                </div>
            </div>
            </div>
        </Fragment>);
    }

    render() {
        return (<Fragment>
            { this.renderBasicDetails()}
        </Fragment>);
    }
}


export default connect(null, {getCountryList, updateCustomer})(AddressDetails)