

import axios from 'axios'
import {API_ENDPOINT} from 'Redux/apiConstant'
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from '../type' 
import {getConfig} from '../getConfig'


const login_user = (dispatch, payload) => {

  dispatch({
      type: LOGIN_SUCCESS,
      payload
  })
}

export const logout = () => dispatch => {
  return dispatch({
    type: LOGOUT_SUCCESS
  })
}

export const login = ({username, password}) => {   
  const config = getConfig()
  return (dispatch) => new Promise(async(resolve, reject) => {
      axios.post(`${API_ENDPOINT}/api/streams/dashboard/user/login`, {username,password}, config)
      .then((response) => {
          if(response.data.status === 200){
              login_user(dispatch, response.data)
              return resolve(response.data)
          }
          else {
             return reject(response)
          }
      })
      .catch((error) => {
          error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
          alert("Network Error") : alert("Network Error in api")
      })
  })
}

// forgot password apis

export const verifyUser = async ({email}) => {
  const message = "user_verification"
  let response = await axios.get(`${API_ENDPOINT}/api/verify/getotp/${email}/${message}`, getConfig())
  return response.data
}

export const verifyOTP = async ({email, otp}) => {
  let response = await axios.get(`${API_ENDPOINT}/api/verify/${email}/${otp}`, getConfig())
  return response.data
}

// update Profile
export const updateProfile = async ({user_email,password}) => {
  console.log({user_email})
   let response = await axios.put(`${API_ENDPOINT}/api/streams/dashboard/user/update-profile/${user_email}`,{password}, getConfig())
   return response.data
}

export const isUserExist = async ({email}) => {
  let response = await axios.get(`${API_ENDPOINT}/api/streams/dashboard/user/user-exists/${email}` , getConfig())
  return response.data
}