import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader';
import axios from 'axios';
import { API_ENDPOINT_V2 } from '../../Redux/apiConstant';
import { getConfig } from '../../Redux/getConfig';

class CreateCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
      err: false,
      loader: false,
      planlist: [],
      partnerlist: [],
      partnerCode: '',
      objectSent: false,
      partnerRecieved: false,
      planRecieved: true,
      discountType: '',
      amount: '',
      fields: {
        code: '',
        title: '',
        discountAmount: '',
        discountPer: '',
        flatAmount: '',
        validPaymentMode: '',
        validPlan: '',
        expiry: '',
        activationDate: '',
        expiryDate: '',
        maxUsers: '',
        usesCount: '',
        status: '',
      },
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler = (e) => {
    e.target.name === 'code'
      ? this.setState({
          fields: {
            ...this.state.fields,
            code: e.target.value.toUpperCase(),
          },
        })
      : e.target.name === 'discountType'
      ? this.setState({
          discountType: e.target.value,
        })
      : e.target.name === 'amount'
      ? this.state.discountType === 'discountPer'
        ? e.target.value === '100'
          ? this.setState({
              amount: e.target.value,
              fields: {
                ...this.state.fields,
                discountPer: e.target.value,
                validPaymentMode: 'offline',
              },
            })
          : this.setState({
              amount: e.target.value,
              fields: {
                ...this.state.fields,
                discountPer: e.target.value,
                validPaymentMode: 'online',
              },
            })
        : this.state.discountType === 'discountAmount'
        ? this.setState({
            amount: e.target.value,
            fields: {
              ...this.state.fields,
              discountAmount: e.target.value,
              validPaymentMode: 'online',
            },
          })
        : this.state.discountType === 'flatAmount' &&
          this.setState({
            amount: e.target.value,
            fields: {
              ...this.state.fields,
              flatAmount: e.target.value,
              validPaymentMode: 'online',
            },
          })
      : e.target.name === 'plan'
      ? this.setState({
          fields: {
            ...this.state.fields,
            validPlan: e.target.value,
          },
        })
      : e.target.name === 'users'
      ? this.setState({
          fields: {
            ...this.state.fields,
            maxUsers: e.target.value,
          },
        })
      : e.target.name === 'status'
      ? this.setState({
          fields: {
            ...this.state.fields,
            status: e.target.value,
          },
        })
      : e.target.name === 'partner'
      ? this.getPlan(e.target.value) &&
      this.setState({
        loader: true,
        planRecieved: false,
        partnerCode: e.target.value,
        fields: {
          ...this.state.fields,
          partnerCode: e.target.value,
        }
      })
      : this.setState({
          fields: {
            ...this.state.fields,
            [e.target.name]: e.target.value,
          },
        });
  };
  onSubmit = (e) => {
    const {
      code,
      title,
      validPlan,
      expiryDate,
      maxUsers,
      status,
    } = this.state.fields;
    const { partnerCode, discountType, amount } = this.state;
    code &&
    title &&
    validPlan &&
    expiryDate &&
    maxUsers &&
    status &&
    partnerCode &&
    discountType &&
    amount
      ? this.sendDashboardData() &&
        this.setState({
          validate: false,
          err: false,
          loader: false,
          planlist: [],
          partnerCode: '',
          //partnerlist: [],
          objectSent: false,
          planRecieved: true,
          discountType: '',
          amount: '',
          fields: {
            code: '',
            title: '',
            discountAmount: '',
            discountPer: '',
            flatAmount: '',
            validPaymentMode: '',
            validPlan: '',
            expiry: '',
            activationDate: '',
            expiryDate: '',
            maxUsers: '',
            usesCount: '',
            status: '',
          },
        })
      : this.setState({ validate: true });
  };

  getDate = (date = new Date()) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  async getPartner() {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_V2}/subscription/partners/info`,
        getConfig()
      );
      if (response.status === 200) {
        this.setState({
          partnerlist: response.data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          partnerRecieved: true,
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({ err: true });
    }
  }

  async getPlan(partnerCode) {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_V2}/subscription/partners/${partnerCode}/plans`,
        getConfig()
      );
      if (response.status === 200) {
        response.data.data.length
          ? this.setState({
              loader: false,
              planlist: response.data.data[0].plans,
              planRecieved: true,
            })
          : this.setState({
              loader: false,
              planlist: [],
              planRecieved: false,
            });
      }
    } catch (err) {
      console.error(err);
      this.setState({ err: true });
    }
  }

  async sendDashboardData() {
    const date = this.getDate();
    try {
      const res = await axios.post(
        `${API_ENDPOINT_V2}/coupons-dashboard/`,
        {
          ...this.state.fields,
          activationDate: date,
        },
        getConfig()
      );

      if (res.data.code === 200) {
        this.setState({
          objectSent: true,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidMount() {
    this.getPartner();
  }

  render() {
    console.log('offline',this.state.fields)
    return this.state.partnerRecieved ? (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-7'>
            <div className='card border-0 shadow-lg my-5'>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='p-5'>
                      <div className='text-center'>
                        <h1 className='h4 text-gray-900 mb-4'>Create Coupon</h1>

                        <form>
                          <div className='form-group '>
                            <input
                              type='text'
                              className='form-control form-control-user '
                              name='code'
                              id='id_code'
                              placeholder='Enter Code'
                              onChange={this.changeHandler}
                              value={this.state.fields.code}
                            />
                          </div>

                          <div className='form-group '>
                            <input
                              type='text'
                              className='form-control form-control-user '
                              name='title'
                              id='id_title'
                              placeholder='Enter Title'
                              onChange={this.changeHandler}
                              value={this.state.fields.title}
                            />
                          </div>
                        </form>

                        <div className='row'>
                          <div className='col-6'>
                            <div className='float-left'>
                              <select
                                className='form-control form-control-sm'
                                onChange={this.changeHandler}
                                name='discountType'
                                value={this.state.discountType}
                                style={{ width: '245px' }}
                              >
                                <option>Select Discount Type</option>
                                <option value='discountAmount'>
                                  Discount Amount
                                </option>
                                <option value='discountPer'>
                                  Discount Percent
                                </option>
                                <option value='flatAmount'>Flat Amount</option>
                              </select>
                            </div>
                          </div>

                          <div className='col-6'>
                            <div className='float-right'>
                              <input
                                type='number'
                                placeholder='Discount Value'
                                className='form-control form-control-sm '
                                name='amount'
                                onChange={this.changeHandler}
                                value={this.state.amount}
                                style={{ width: '245px' }}
                              />
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='float-left'>
                              <select
                                className='form-control form-control-sm '
                                onChange={this.changeHandler}
                                name='partner'
                                value={this.state.partnerCode}
                                style={{ width: '245px' }}
                              >
                                <option>Select Partner</option>
                                {this.state.partnerlist.map((itm, id) => {
                                  return (
                                    <option value={itm.code} key={id}>
                                      {itm.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className='col-6'>
                            <div className='float-right'>
                              {this.state.planRecieved ? (
                                <select
                                  className='form-control form-control-sm '
                                  onChange={this.changeHandler}
                                  name='plan'
                                  value={this.state.fields.validPlan}
                                  style={{ width: '245px' }}
                                >
                                  <option>Select Plan Id</option>
                                  {this.state.planlist.map((itm, id) => {
                                    return (
                                      <option value={itm} key={id}>
                                        {itm}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : this.state.loader ? (
                                <Loader />
                              ) : (
                                <div className='text-danger'>No Plan Found</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className='row'>
                          <div className='col-6'>
                            <input
                              type='number'
                              placeholder='Maximum Users'
                              className='form-control form-control-sm '
                              name='users'
                              value={this.state.fields.maxUsers}
                              onChange={this.changeHandler}
                              style={{ width: '245px' }}
                            />
                          </div>
                          <div className='col-6'>
                            <select
                              name='status'
                              onChange={this.changeHandler}
                              className='form-control form-control-sm '
                              value={this.state.fields.status}
                              style={{ width: '245px' }}
                            >
                              <option>Select Status</option>
                              <option value='ACTIVE'>ACTIVE</option>
                              <option value='INACTIVE'>INACTIVE</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className='float-left'
                          style={{
                            paddingTop: '20px',
                            paddingBottom: '20px',
                          }}
                        >
                          <div>
                            <label style={{ paddingRight: '10px' }}>
                              Expiry date :
                            </label>

                            <DatePicker
                              className='form-control form-control-sm '
                              dateFormat='yyyy-MM-dd'
                              value={this.state.fields.expiryDate}
                              onChange={(date) => {
                                date = this.getDate(date);
                                this.setState({
                                  fields: {
                                    ...this.state.fields,
                                    expiryDate: date.toString(),
                                    expiry: date.toString(),
                                  },
                                });
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                            />
                          </div>
                        </div>

                        <div>
                          <input
                            type='submit'
                            onClick={this.onSubmit}
                            className='btn btn-primary btn-stockal btn-user btn-block'
                            value='Create'
                          />
                        </div>
                        <br />
                        <div>
                          {this.state.validate ? (
                            <div className='alert alert-danger' role='alert'>
                              Please Enter all required fields
                            </div>
                          ) : (
                            this.state.objectSent && (
                              <div className='alert alert-success' role='alert'>
                                Coupon Created Successfully !
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default CreateCoupon;
