import React from 'react';
import { NavLink } from 'react-router-dom';
import { CAN_CREATE_COUPON } from '../../utils/roles';

export const Reports = () => (
  <li className='nav-item'>
    <a
      className='nav-link collapsed'
      href='#'
      data-toggle='collapse'
      data-target='#collapseTwo'
      aria-expanded='true'
      aria-controls='collapseTwo'
    >
      <span>Reports</span>
    </a>
    <div
      id='collapseTwo'
      className='collapse'
      aria-labelledby='headingTwo'
      data-parent='#accordionSidebar'
    >
      <div className='bg-white py-2 collapse-inner rounded'>
        <NavLink
          to={'/general_report'}
          className='collapse-item'
          activeClassName='active'
        >
          {' '}
          General{' '}
        </NavLink>
        <NavLink
          className='collapse-item'
          to='/new_accounts_opened'
          activeClassName='active'
        >
          New Accounts Opened
        </NavLink>
        <NavLink
          className='collapse-item'
          to='/remittances_approval'
          activeClassName='active'
        >
          Remittance Approval
        </NavLink>
        <NavLink
          className='collapse-item'
          to='/trade_summary'
          activeClassName='active'
        >
          Trade Summary
        </NavLink>
        <NavLink
          className='collapse-item'
          to='/revenue'
          activeClassName='active'
        >
          Revenue
        </NavLink>
      </div>
    </div>
  </li>
);

export const Reports1 = () => (
  <li className='nav-item'>
    <a
      className='nav-link collapsed'
      data-toggle='collapse'
      href='#collapseReport'
      role='button'
      aria-expanded='false'
      aria-controls='collapsePartner'
    >
      Reports
    </a>
    <div className='collapse' id='collapseReport'>
      <div className='bg-white py-2 collapse-inner rounded'>
        <NavLink
          className='nav-item collapse-item'
          to='/list_users'
          activeClassName='selectedCollapseLink'
        >
          Report
        </NavLink>
        <NavLink
          className='nav-item collapse-item'
          to='/export_users'
          activeClassName='selectedCollapseLink'
        >
          Download
        </NavLink>
      </div>
    </div>
  </li>
);

export const Register = () => (
  <li className='nav-item'>
    <span>
      <NavLink
        className='nav-link'
        to='/register'
        activeClassName='selectedLink'
      >
        Register
      </NavLink>
    </span>
  </li>
);

export const Orders = () => (
  <li className='nav-item'>
    <span>
      <NavLink className='nav-link' to='/orders' activeClassName='selectedLink'>
        Orders
      </NavLink>
    </span>
  </li>
);

export const Stacks = () => (
  <li className='nav-item'>
    <span>
      <NavLink className='nav-link' to='/stacks' activeClassName='selectedLink'>
        Stacks
      </NavLink>
    </span>
  </li>
);

export const Trade = () => (
  <li className='nav-item'>
    <span>
      <NavLink className='nav-link' to='/trade' activeClassName='selectedLink'>
        Trade
      </NavLink>
    </span>
  </li>
);

export const Export = () => (
  <li className='nav-item'>
    <span>
      <NavLink
        className='nav-link'
        to='/export_users'
        activeClassName='selectedLink'
      >
        Report
      </NavLink>
    </span>
  </li>
);

// export const Dashboard = () => (
//   <li className='nav-item'>
//     <span>
//       <NavLink
//         className='nav-link'
//         to='/dashboard'
//         activeClassName='selectedLink'
//       >
//         Dashboard
//       </NavLink>
//     </span>
//   </li>
// );

export const LRS = () => (
  <li className='nav-item'>
    <span>
      <NavLink className='nav-link' to='/lrs' activeClassName='selectedLink'>
        Offline LRS
      </NavLink>
    </span>
  </li>
);

export const Customers = () => (
  <li className='nav-item'>
    <span>
      {' '}
      <NavLink
        className='nav-link'
        to='/customers'
        activeClassName='selectedLink'
      >
        Customers
      </NavLink>{' '}
    </span>
  </li>
);

export const CreateNewCustomers = () => (
  <li className='nav-item'>
    <span>
      <NavLink
        className='nav-link'
        to='/create_new_customer'
        activeClassName='selectedLink'
      >
        Create New Customer
      </NavLink>
    </span>
  </li>
);

export const ExportReports = () => (
  <li className='nav-item'>
    <span>
      <NavLink
        className='nav-link'
        to='/export_reports'
        activeClassName='selectedLink'
      >
        Export Reports
      </NavLink>
    </span>
  </li>
);

export const Transactions = () => (
  <li className='nav-item'>
    <span>
      <NavLink
        className='nav-link'
        to='/transactions'
        activeClassName='selectedLink'
      >
        Transactions
      </NavLink>
    </span>
  </li>
);

export const Payments = () => (
  <li className="nav-item">
    <span><NavLink className="nav-link" to='/payments' activeClassName="selectedLink">Payments</NavLink></span>
  </li>
)

export const Portfolio = () => (
  <li className="nav-item">
    <span><NavLink className="nav-link" to='/portfolio' activeClassName="selectedLink">Portfolio</NavLink></span>
  </li>
)

export const Discrepency = () => (
  <li className="nav-item">
    <span><NavLink className="nav-link" to='/discrepency' activeClassName="selectedLink">Discrepancy</NavLink></span>
  </li>
)

export const Partners = () => (
  <li className='nav-item'>
    <a
      className='nav-link collapsed'
      data-toggle='collapse'
      href='#collapsePartner'
      role='button'
      aria-expanded='false'
      aria-controls='collapsePartner'
    >
      Partners
    </a>
    <div className='collapse' id='collapsePartner'>
      <div className='bg-white py-2 collapse-inner rounded'>
        <NavLink
          className='nav-item collapse-item'
          to='/createPartner'
          activeClassName='selectedCollapseLink'
        >
          Create
        </NavLink>
        <NavLink
          className='nav-item collapse-item'
          to='/showPartnerList'
          activeClassName='selectedCollapseLink'
        >
          Show List
        </NavLink>
      </div>
    </div>
  </li>
);

export const Coupons = (props) => (
  <li className='nav-item'>
    <a
      className='nav-link collapsed'
      data-toggle='collapse'
      href='#collapseCoupon'
      role='button'
      aria-expanded='false'
      aria-controls='collapseCoupon'
    >
      Coupons
    </a>
    <div className='collapse' id='collapseCoupon'>
      <div className='bg-white py-2 collapse-inner rounded'>
        {
          CAN_CREATE_COUPON.includes(props.auth.roleTitle) ? 
          <NavLink
          className='nav-item collapse-item'
          to='/createCoupon'
          activeClassName='selectedCollapseLink'
        >
          Generate Coupon
        </NavLink> : null
        }
       
        <NavLink
          className='nav-item collapse-item'
          to='/showActiveCouponList'
          activeClassName='selectedCollapseLink'
        >
          Coupon List
        </NavLink>
      </div>
    </div>
  </li>
);

export const VerifiedPortfolio = () => (
  <li className="nav-item">
    <span><NavLink className="nav-link" to='/verifiedPortfolio' activeClassName="selectedLink">Portfolio Verified</NavLink></span>
  </li>
)

export const Instapayment = () => (
  <li className='nav-item'>
    <span>
      <NavLink className='nav-link' to='/instaPayment' activeClassName='selectedLink'>
        Instapayment
      </NavLink>
    </span>
  </li>
);

