import axios from 'axios';
import { SET_TIMEZONE, SET_PARTNER_CODES, SET_PARTNER_CODES_FAIL,  ADD_PARTNER_CODE,
GET_PLANS, GET_PLANS_SUCCESS, GET_PLANS_FAIL
} from "../type"
import {getConfig} from '../getConfig'
import { API_ENDPOINT } from '../apiConstant';
import {logout} from './auth';

export const setTimeZone = (tz) => {
  return (dispatch) => {
    dispatch({
      type: SET_TIMEZONE,
      payload: tz
    })
  }
}

export const getPartnerCodes = () => {
  return (dispatch) => {
    const url = `${API_ENDPOINT}/api/partner-codes/list`;
    axios.get(url, getConfig()).then((response) => {
      if (response.status === 200){
        dispatch({type: SET_PARTNER_CODES, payload: response.data});
      }
      else {
        dispatch({ type: SET_PARTNER_CODES_FAIL, payload: 'Failed Fetching Errors!'});
      }
    })
    .catch((err) => {
      dispatch({ type: SET_PARTNER_CODES_FAIL, payload: err });
    })
  }
}

export const addPartnerCode = (data,userPartnerCode) => {
  return (dispatch) => new Promise(async(resolve, reject) => {
    axios.post(`${API_ENDPOINT}/api/partner-codes/update/`, data, getConfig())
    .then((response => {
      if(!response.data.status){
            // dispatch({type:ADD_PARTNER_CODE,payload:data})
            return resolve(response.data)
      }
      throw response.data.error
    }))
    .catch((err) => {
      return reject(err);
    })
  })
}

export const getPlans = () => {
  return (dispatch) => {
    dispatch({ type: GET_PLANS, payload: ''});
    const url = `${API_ENDPOINT}/api/streams/dashboard/plans/all`;
    axios.get(url, getConfig()).then((res) => {
      if (res.data.status === 200) {
        dispatch({ type: GET_PLANS_SUCCESS, payload: res.data.doc });
      }
    }).catch((err) => {
      dispatch({ type: GET_PLANS_FAIL, payload: [] });
  })
  }
}


export const getMyPlans = (userId) => {
  return (dispatch) => {
    const url = `${API_ENDPOINT}/api/plans/${userId}/all`;
    dispatch({type: GET_PLANS, payload: ''});
    axios.get(url, getConfig()).then((response) => {
      
      if (response.status === 200){
        if (response.data.status === 500) {
          dispatch(logout(dispatch));
        } else {
          dispatch({type: GET_PLANS_SUCCESS, payload: response.data.docs});
        }
      }
      else {
        dispatch({ type: GET_PLANS_FAIL, payload: 'Failed Fetching Plans!'});
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PLANS_FAIL, payload: 'Failed Fetching Plans!'});
    })
  }
};