import React from "react"
import Details from "./ProfileDetails"
import Trade from "./Trade"
import LogInfo from "./LogInfo";

function Profile(){
    return(
      <div className="container-fluid">
          <Details />
          <Trade />
          <LogInfo />
      </div>   
    );
}



export default Profile