import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect} from 'react-redux';
import { getClientId, returnPlansObj } from 'utils/common';

import { getStacks, selectTicker, addPartnerPlan, deletePartnerPlan } from 'Redux/actions/stacks'
import PartnerDataGrid from './DataGrid';
import AddStackInAll from './AddStacksInAll';
import { CAN_ADD_STACKS } from '../../utils/roles'

class AddStacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stacks: {},
            tickers: [],
            stacksMessage: '',
            currentTicker: '',
            currentStack: { stackPartner: []},
            selectedPlan: '',
            buyAum:'',
            sellcommission: '',
            activationFee: '',
            minimum:'',
            exitFee:'',
            entryFee:'',
            aumFrequency:'',
            activationFeeCurrency:'',
            comment:'',
            isAdd:true,
            myPlans: {},
            checkBox : '',
            isChecked: false,
            allChecked: false,
            checkedTicker: [],
            checkedAdd:true,
            singleCheck: false,
            updatedBy:'',

            planMessage: '',
            buyAumMessage: '',
            sellcommissionMessge: '',
            activationFeeMessage: '',
            activationCurrencyMessage: '',
            aumFrequencyMessage:'',
            minimumMessage: '',
            exitFeeMessage:'',
            entryFeeMessage:'',
            commentMessage:'',
            deleteModal:false,
            deletingPlanId: ''
            
        };

        this.addUpdatePlan = this.addUpdatePlan.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.editStackPlan = this.editStackPlan.bind(this);
        this.deleteStackPlan = this.deleteStackPlan.bind(this);
        this.reset = this.reset.bind(this);
        this.tickerCheckbox = this.tickerCheckbox.bind(this);
        this.afterYes = this.afterYes.bind(this);
        this.deleteModal = this.deleteModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.collapse = this.collapse.bind(this);
        this.dataTargetCollapse = this.dataTargetCollapse.bind(this);
    }
    
    reset() {
        this.setState({
            selectedPlan: '',
            buyAum: '',
            sellcommission: '',
            activationFee: '',
            minimum: '',
            activationFeeCurrency: '',
            exitFee:'',
            entryFee:'',
            comment:'',
            aumFrequency:'',
            isAdd: true,
        });
        this.resetText();
    }
    resetText() {
        this.setState({
            planMessage:'',
            buyAumMessage: '',
            sellcommissionMessage: '',
            activationFeeMessage: '',
            activationCurrencyMessage: '',
            minimumMessage: '',
            exitFeeMessage:'',
            entryFeeMessage:'',
            aumFrequencyMessage:'',
            commentMessage:''
        });
    }

    componentDidMount () {
        this.setState({updatedBy:this.props.auth.id})
        // Validate if signed in user is admin manager client
        const currentPartner = this.props.common.partnerCodes.filter((p) => p.code === this.props.match.params.code);
        // console.log(currentPartner);
        if (currentPartner.length > 0) {
            const myPlansArr = currentPartner[0].plans;
            const myPlans = returnPlansObj(myPlansArr);
            this.setState({myPlans: myPlans});
        }
        if (this.props.auth.roleId === "5" && this.props.match.params.code === getClientId(this.props.auth)) {
            // Not Required Now
            // Get Stacks
        // } else if (this.props.auth.roleId === "1" || this.props.auth.roleId === "2") {
        //     this.props.getStacks(this.props.match.params.code);
        // } else {
        //     this.props.history.push('/');
        // }
        }
        else if(CAN_ADD_STACKS.includes(this.props.auth.roleTitle)) {
            this.props.getStacks(this.props.match.params.code);
        }
        else{
            this.props.history.push('/');
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.stacks) {
            return { stacksMessage: props.stacksMessage }
        } else if (Object.keys(props.stacks).length === 0){
            return { stacksMessage: props.stacksMessage }
        } else if ( Object.keys(props.stacks).length > 0) {
            // console.log(props.stacks);
            const tickers = Object.keys(props.stacks);
            return { stacks: props.stacks, tickers, currentTicker:props.currentTicker, currentStack: props.stacks[props.currentTicker], stacksMessage: props.stacksMessage }
        } 

        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('snapshot', prevProps.message, this.props.message)
        if (prevProps.message !== this.props.message) {
            this.props.getStacks(this.props.match.params.code);
        }

        if (prevProps.deleteMessage === 'Plan Successfully Deleted') {
            this.props.getStacks(this.props.match.params.code);
        }
        return null;
    }
    
    editStackPlan(planID) {
        const { stackPartner } = this.state.stacks[this.state.currentTicker];
        const stackPartnersObj = _.keyBy(stackPartner, 'planID');

        this.setState({
            selectedPlan: planID,
            buyAum: stackPartnersObj[planID].aumForBuy,
            sellcommission: parseFloat(stackPartnersObj[planID].commissionForSell),
            activationFee: parseFloat(stackPartnersObj[planID].activationFee),
            minimum: parseFloat(stackPartnersObj[planID].minimum),
            activationFeeCurrency: stackPartnersObj[planID].activationFeeCurrency || stackPartnersObj[planID].activatonFeeCurrency,
            exitFee: parseFloat(stackPartnersObj[planID].exitFee),
            entryFee: parseFloat(stackPartnersObj[planID].entryFee),
            aumFrequency: (stackPartnersObj[planID].aumFrequency),
            isAdd: false,
         })
    }

    deleteStackPlan() {
        this.props.deletePartnerPlan(this.state.currentTicker, this.props.match.params.code,this.state.deletingPlanId);
        this.setState({deleteModal:false});
    }

    deleteModal(planID) {
        this.setState({deleteModal:true,deletingPlanId:planID})
    }

    closeModal() {
        this.setState({deleteModal:false})
    }
    
    handleClick() {
        this.resetText();
        let flag = true;
        if ( this.state.selectedPlan === '') { this.setState({ planMessage: 'Plan Cannot be empty'} ); flag = false; }
        if ( this.state.buyAum === '') { this.setState({ buyAumMessage: 'Buy AUM cannot be empty'} ); flag = false; }
        if ( this.state.sellcommission === '') { this.setState({ sellcommissionMessage: 'Commission be empty'} ); flag = false; }
        if ( this.state.activationFee === '') { this.setState({ activationFeeMessage: 'Activation Fee cannot be empty'} ); flag = false; }
        if ( this.state.minimum === '') { this.setState({ minimumMessage: 'Minimum cannot be empty'} ); flag = false; }
        if ( this.state.activationFeeCurrency === '') { this.setState({ activationCurrencyMessage: 'Currency cannot be empty'} ); flag = false; }
        if ( this.state.exitFee === '') { this.setState({ exitFeeMessage: 'Exit Fee cannot be empty'} ); flag = false; }
        if ( this.state.entryFee === '') { this.setState({ entryFeeMessage: 'Entry Fee cannot be empty'} ); flag = false; }
        if ( this.state.aumFrequency === '') { this.setState({ aumFrequencyMessage: 'Aum Frequency cannot be empty'} ); flag = false; }
        if ( this.state.comment === '') { this.setState({ commentMessage: 'Comment cannot be empty'} ); flag = false; }

        if (flag) {
            this.props.addPartnerPlan({
                ticker: this.state.currentTicker, 
                aumForBuy : this.state.buyAum, 
                partnerID : this.props.match.params.code, 
                planID : this.state.selectedPlan, 
                activationFee : this.state.activationFee, 
                activationFeeCurrency : this.state.activationFeeCurrency, 
                commissionForSell : this.state.sellcommission, 
                minimum : this.state.minimum, 
                exitFee : this.state.exitFee,
                entryFee : this.state.entryFee,
                aumFrequency : this.state.aumFrequency,
                comment : this.state.comment,
                updatedBy : this.state.updatedBy
            });
            this.resetText();
            this.reset();
        } else {
            return false;
        }
        
    }

    addUpdatePlan (e) {
        const selectedPlan = e.target.value;
        this.setState({selectedPlan, showForm: true});
    }

    handleChange (e) {
        console.log('e',e.target.value)
        this.setState({[e.target.name]: e.target.value})
        this.setState({[e.target.name+'message']: e.target.value});
    }

    // tickerCheckbox (e,ticker) {
    //     const target = e.target;
    //     let checked = e.target.checked;
    //     var value = target.value;
    //     if(target.checked){
    //         this.setState({
    //             checkedTicker: [...this.state.checkedTicker, value]
    //           })    
    //     }
    //     else{
    //         const {checkedTicker} = this.state
    //         for( var i = 0; i < checkedTicker.length; i++){ 
    //             if ( checkedTicker[i] === value) { 
    //                 checkedTicker.splice(i, 1); 
    //                 i--; 
    //             }
    //         }
    //         this.setState({checkedTicker: checkedTicker}) 
    //     }
    // }

    tickerCheckbox(e,ticker){
        const target = e.target;
        let checked = target.checked;
        var value = target.value;
        console.log('target',checked,value);
        if (value === "checkAll") {
            if(checked){
                this.setState({checkedTicker: this.state.tickers,isAllChecked:true,isChecked:true});
            }
            else{
                this.setState({checkedTicker:[],isAllChecked:false,isChecked:false})
            }
        }
        else if(value !== "checkAll" && checked){
            this.setState({
                checkedTicker: [...this.state.checkedTicker, value],singleCheck:checked
            })   
        }
        else{
            const {checkedTicker} = this.state
                for( var i = 0; i < checkedTicker.length; i++){ 
                    if ( checkedTicker[i] === value) { 
                        checkedTicker.splice(i, 1); 
                        i--; 
                    }
                }
                this.setState({checkedTicker: checkedTicker,singleCheck:checked,isAllChecked:false})
        }
    }

    afterYes(){
        this.setState({checkedTicker:[],isAllChecked:false});
    }

    renderForm(ticker) {
        const codes = Object.keys(this.state.myPlans);
        const { stackPartner } = this.state.stacks[ticker];
        const stackPartnersObj = _.keyBy(stackPartner, 'planID');
        return (<Fragment>
            <div className="form-group col-sm-6 col-md-4 col-xl-3 px-1">
                <label className="stackLabelFont">Select Plan</label>
                {
                    this.state.isAdd ? (
                        <select className="form-control-sm form-control" name="selectedPlan" checked={this.state.isChecked} value={this.state.selectedPlan} onChange={this.handleChange}>
                            <option value=""></option>
                            {
                                codes.map((key) => {
                                        if (!stackPartnersObj[key]) {
                                        return  (<option key={key} value={key}>{this.state.myPlans[key]}</option>)
                                        }
                                        return '';
                                })
                                
                            }
                        </select>
                    ) : (
                        <input className="form-control-sm form-control" type="text" disabled={true} value={this.state.myPlans[this.state.selectedPlan]} />
                    )
                }
                
                {
                    this.state.planMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.planMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Buy Aum</label>
                <input type ='number' min={0} className="form-control-sm form-control" name="buyAum" value={this.state.buyAum} onChange={this.handleChange} />
                {
                    this.state.buyAumMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.buyAumMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Sell Commission</label>
                <input type ='number' min={0} className="form-control-sm form-control" name="sellcommission" value={this.state.sellcommission} onChange={this.handleChange} />
                {
                    this.state.sellcommissionMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.sellcommissionMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Activation Fee</label>
                <input type ='number' min={0}  className="form-control-sm form-control" name="activationFee" value={this.state.activationFee} onChange={this.handleChange} />
                {
                    this.state.activationFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.activationFeeMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-3 px-1">
                <label className="stackLabelFont">Currency</label>
                <select className="form-control-sm form-control" width="100%" name="activationFeeCurrency" value={this.state.activationFeeCurrency} onChange={this.handleChange} >
                    <option value=""></option>
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                </select>
                {
                    this.state.activationCurrencyMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.activationCurrencyMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Minimum</label>
                <input type ='number' min={0}  className="form-control-sm form-control" name="minimum" value={this.state.minimum} onChange={this.handleChange} />
                {
                    this.state.minimumMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.minimumMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Exit Fee</label>
                <input type ='number' min={0}  className="form-control-sm form-control" name="exitFee" value={this.state.exitFee} onChange={this.handleChange} />
                {
                    this.state.exitFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.exitFeeMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                <label className="stackLabelFont">Entry Fee</label>
                <input type ='number' min={0}  className="form-control-sm form-control" name="entryFee" value={this.state.entryFee} onChange={this.handleChange} />
                {
                    this.state.entryFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.entryFeeMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-4 col-xl-3 px-1">
            <label className="stackLabelFont">AUM Frequency</label>
                <select className="form-control-sm form-control" width="100%" name="aumFrequency" value={this.state.aumFrequency} onChange={this.handleChange} >
                    <option value=""></option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="SemiAnnually">Semi-Annually</option>
                    <option value="Annually">Annually</option>
                </select>
                {
                    this.state.aumFrequencyMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.aumFrequencyMessage}</span>): ''
                }
            </div>
            {/* <div className="form-group col-sm-6 col-md-4 col-xl-3 px-1">
                <label className="stackLabelFont">Comment</label>
                <input type ='number' min={0}  className="form-control-sm form-control" name="entryFee" value={this.state.entryFee} onChange={this.handleChange} />
                {
                    this.state.entryFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.entryFeeMessage}</span>): ''
                }
            </div> */}
            <div className="form-group col-sm-6 col-md-9 col-xl-6 px-1">
                <label className="stackLabelFont" for="exampleFormControlTextarea1">Comment</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" name="comment" value={this.state.comment} onChange={this.handleChange}></textarea>
                {
                    this.state.commentMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.commentMessage}</span>): ''
                }
            </div>
            <div className="form-group col-sm-6 col-md-12 col-xl-3 pt-4 text-right">
                <button className="btn btn-primary mt-1 btn-sm mr-1" type="button" onClick={this.handleClick}>
                    {this.state.isAdd? 'Add' : 'Save'}
                </button>
                <button className="btn btn-primary mt-1 btn-sm" type="button" onClick={this.reset}>
                    Cancel
                </button>
            </div>
        </Fragment>);
    }

    renderPlans(ticker) {
        
        const codes = Object.keys(this.state.myPlans);
        const { stackPartner } = this.state.stacks[ticker];
        const stackPartnersObj = _.keyBy(stackPartner, 'planID');
        return codes.map((value) => {
            
            return (<div className="form-group mr-4" key={`plan_${value}`}>
                    <label className=" col-form-label" onClick={this.addUpdatePlan}>
                        <input className="form-control mr-2" 
                            type={stackPartnersObj[value]? 'checkbox': 'radio'} value={value} 
                            onChange={this.addUpdatePlan}
                            defaultChecked={stackPartnersObj[value]? true: false}
                            disabled={stackPartnersObj[value]? true: false}
                            // name={stackPartnersObj[value]? `${this.state.currentTicker}${value}`: `${this.state.currentTicker}`}
                            name={`${this.state.currentTicker}${value}`}
                            checked={this.state.selectedPlan === value}

                        />
                    {this.state.myPlans[value] }</label>
                </div>
                 ); 
        })
              
    }
    
    // collapse=(e)=>{return{ __html: `collapse_${e}`}}
    
    collapse(e) {
        console.log('e',e)
        if (e.includes('&')){
           let y = e.replace('&','');
           return `collapse_${y}`
        } else {
            return `collapse_${e}`
        }
    }

    dataTargetCollapse(e){
        if (e.includes('&')){
            let y = e.replace('&','');
            return `#collapse_${y}`
         } else {
             return `#collapse_${e}`
         }
    } 
    

    renderStacks() {
        // console.log('tickers',this.state.tickers)
        return this.state.tickers.map((ticker, key) => {
            return (
                <div className="card" key={`card_${ticker}`}>
                    {/* <div dangerouslySetInnerHTML={this.collapse(ticker)}>
                    </div> */}
                    <div className="card-header" id={`heading_${ticker}`} >
                        <h2 className="mb-0">
                            <div className="form-check form-check-inline addStacksCheckBox">
                                <input className="form-check-input checkAll" 
                                type="checkbox"
                                id={`inlineCheckbox${ticker}`}
                                checked = {this.state.checkedTicker.find(t => t === ticker)}
                                onChange={(e) => this.tickerCheckbox(e,ticker)}
                                value={ticker} />   
                            </div>
                            <button className="btn btn-link" type="button" data-toggle="collapse" data-target={this.dataTargetCollapse(ticker)} 
                                aria-expanded="false" aria-controls={`collapse_${ticker}`} onClick={() => this.props.selectTicker(ticker)}>
                                { this.state.stacks[ticker].name }&nbsp;( {this.state.stacks[ticker].ticker})
                            </button>
                        </h2>
                        <p className="ml-3 stackLabelFont">
                            {this.state.stacks[ticker].description}
                        </p>
                    </div>
                    <div id={this.collapse(ticker)} className={key === 0? 'collapse show': 'collapse'} aria-labelledby={`heading_${ticker}`} data-parent="#accordionExample">
                        <div className="card-body">
                            {/* <div className="row mb-3">
                                <div className="ml-5 form-inline">
                                    { this.renderPlans(ticker)}
                                   
                                </div>
                            </div> */}
                            <div className="form-row mb-3">
                                { this.renderForm(ticker)} 
                            </div>
                            {/* modal */}
                            {this.state.deleteModal ? (
                                <div id="popup1 mx-auto" className="overlay">
                                <div className="popup">
                                    <h3 className="pl-3 pt-3">are you sure you wanted to delete the plan ?</h3>
                                        {/* <div className="modal-body">
                                        <p>are you sure you wanted to delete the plan ?</p>
                                        </div> */}
                                        <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>NO</button>
                                                    <button type="button" className="btn btn-primary" onClick={this.deleteStackPlan}>YES</button>
                                        </div>
                                </div>
                            </div>): ""}

                            <div className="row">
                                {
                                    this.state.currentTicker === ticker ? 
                                        <PartnerDataGrid {...this.state} editPlan={this.editStackPlan} deletePlan={this.deleteModal} />
                                        : 
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
    }


    
    render() {
        return(<Fragment>
            <div className="container-fluid">
                <AddStackInAll 
                buttonDisable={this.state.buttonDisable}
                checkedTicker = {[...this.state.checkedTicker]}
                {...this.props}
                code={this.state.myPlans}
                stackPartner={this.state.stacks}
                updatedBy={this.state.updatedBy}
                afterYes={this.afterYes}/>
                <div className="pt-2 pl-2">
                <input type="checkbox" value="checkAll" checked={this.state.isAllChecked} onChange={(e) => this.tickerCheckbox(e)}/> Check all
                </div>
                <div className="accordion pt-3" id="accordionExample">
                    { this.renderStacks()}
                </div>
            </div>
        </Fragment>)
    }
}

const mapStateToProps = ({auth, common, stacks}) => ({ auth, common, ...stacks })

export default connect(mapStateToProps, { getStacks, selectTicker, addPartnerPlan, deletePartnerPlan })(AddStacks);