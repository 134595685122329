import React from 'react'



export function InputFilter ({props}) {
    return (<div className="form-group">
    <input
      className="form-control"
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      type="text"
    />
  </div>);
}

export function NumberFilter ({props}) {
    return (<div className="form-group">
    <input
      className="form-control"
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      type="number"
    />
  </div>);
}

export function  DFilter({ props }) {
  return (<div className="form-group">
    <input
      className="form-control"
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      type="date"
      placeholder="Date"
    />
  </div>);
}