import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPartnerCodes } from 'Redux/actions/common';
import { viewIntoSpecificTimeZone } from 'utils/common';

class ShowPartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerList: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.partnerList === '') {
      return { partnerList: props.common.partnerCodes };
    }

    return null;
  }
  render() {
    let { partnerList } = this.state;
    // let sorted = partnerList.sort(function(a,b){
    //   if ( a.code < b.code ){
    //     return -1;
    //   }
    //   if ( a.last_nom > b.last_nom ){
    //     return 1;
    //   }
    //   return 0;
    // });
    // console.log('list',sorted)
    return (
      <div className='col-lg-12'>
        <div className='card o-hidden border-0 shadow-lg my-5'>
          <div className='card-body p-0'>
            <div className='p-5'>
              <div className='text-center'>
                <h1 className='h4 text-gray-900 mb-4'>Partner List</h1>
              </div>
              <div style={{ float: 'right' }}></div>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>PARTNER NAME</th>
                    <th scope='col'>PARTNER CODE</th>
                    <th scope='col'>CREATED BY EMAIL</th>
                    <th scope='col'>CREATED AT</th>
                    <th scope='col'>ACTION</th>
                    {/* <th scope="col"></th> */}
                  </tr>
                </thead>
                <tbody>
                  {partnerList &&
                    partnerList.length > 0 &&
                    partnerList.map((partner, index) => (
                      <tr key={index}>
                        <td>{partner.name}</td>
                        <td>{partner.code}</td>
                        <td>
                          {partner.createdByUser ? partner.createdByUser : ''}
                        </td>
                        <td>
                          {partner.createdAt
                            ? viewIntoSpecificTimeZone(partner.createdAt)
                            : ''}
                        </td>
                        <td>
                          <button
                            className='btn btn-primary'
                            onClick={() => {
                              this.props.history.push(
                                `/partner/stacks/${partner.code}`
                              );
                            }}
                          >
                            Stacks
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, common }) => {
  return {
    auth,
    common,
  };
};

export default connect(mapStateToProps, { getPartnerCodes })(ShowPartnerList);
