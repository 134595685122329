

import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import Loader from 'react-loader'
import { connect} from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'

import { CLIENTS, commentCategory, getTIMEZONE, getClientId, viewIntoSpecificTimeZone } from 'utils/common';
import { DateFilter} from 'utils/DateFilter'
import { exportCSVFile } from 'utils/exportFile'    
import { CAN_VIEW_LRS_DETAILS, CAN_EDIT_LRS } from 'utils/roles';
import Toast from 'utils/Toast'

import { getLRSListV2, updateLRSStatus } from 'Redux/actions/lrs'
import { postComments } from "Redux/actions/comments"

const search_keys = {
  "requestId": "LRS ID",
  "applicantName": "Name",
  "applicantPhone": "Phone Number",
  "applicantEmail": "Email",
  "status": "Status",
  "createdAt": "Initiated Date",
  "pickUpDate": "Pickup Date",
  "lrsBankName": "Bank",
  "dwUsername": 'Username',
}


const export_headers = ["Initiated Date ,LRS ID,Name ,Phone Number,Email,Username ,Amount ,Bank ,Status ,Pickup Date, Pickup Time, Partner, Address \r\n"]

class LRS extends Component {
  constructor(){
    super()
      this.state = {
        is_loaded: false,
        list: [],
        end_date: new Date(),
        partnerId: '',
        start_date: new Date(),
        error_message: '',
        status:'',
        search_term: '',
        term: '',
        filteredList: [],
        alertMessage: "",
        showAlert: false,
        autoClose: 1500,
      }
      this.getLRSList = this.getLRSList.bind(this);
  }

  // confirmStatusSave = (index) => {
  //   confirmAlert({
  //     title: `Add Comment and Confirm to update LRS Status ?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => this.updateLrsDetail(index)
  //       },
  //       {
  //         label: 'No',
  //       }
  //     ]
  //   })
  // };

  confirmStatusSave = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
              <div className="custom-ui-body">
                <h3>Add Comment Before Saving</h3>
                <textarea className="form-control" rows="4" name="comment" onChange={(e) => {this.setState({comment: e.target.value})}}></textarea>
                <div className="custom-ui-body-button-group">
                  <button type="button" className="btn btn-primary btn-stockal" style={{marginRight: '.5rem'}}
                    onClick={() => {
                      this.addCommentAndUpdateLRS({index, onClose});
                      // onClose();
                    }}
                  >Update
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </div>
          </div>
           </div>
         );
       },
       closeOnClickOutside: false
     });
   }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
        return;
    };
  }
  componentDidMount() {
    this.setState({
      partnerId: getClientId(this.props.auth)
    }, () => {
      this.getLRSList()
    })
  }

  onChangeDate = (name,date) => {
    this.setState({[name]:date})
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onStatusChange = (index, e) => { 
    this.setState({status:e.target.value})
    this.confirmStatusSave(index, e)
  }

  

  onSearch = () => {
   let {search_term, term, list} = this.state
   if (search_term !== "" && term !== "") {
    let filterd_data_ = []
    list && Array.isArray(list) && list.length > 0 &&
    list.map((data) => {
      return _.mapKeys(data, (v,k) => {
        if (k === search_term && v && v.toLowerCase().includes(term.toLowerCase())) {
          filterd_data_.push(data)
        }
        this.setState({filteredList: filterd_data_})
      })
    })
   }
  }

  getLRSList = () => {
    console.log(this.state);
    this.setState({is_loaded: false, search_term: '', term: ''})
    // let {tz} = this.props.common;
    let { start_date, end_date, partnerId} = this.state
    start_date = getTIMEZONE(moment.utc(this.state.start_date).startOf('day'))
    end_date =  getTIMEZONE(moment.utc(this.state.end_date).endOf('day'));
    this.props.getLRSListV2({start_date, end_date, partnerId, userId:this.props.auth.id})
      .then(list => {
        this.setState({ 
          list, 
          filteredList:list,
          is_loaded: true
        });
      })
      .catch((err) => {
         this.setState({
          error_message: 'No Data Found', 
          list: [], 
          filteredList: [],
          is_loaded:  true,
          })
      })
  }

  setAlertOff = () => {
    setTimeout(() => this.setState({showAlert: false}), this.state.autoClose)
  }

  goToLRSDetails = (lrsid) => {
    // this.props.history.push(`/lrs/lrs_details/${lrsid}`)
    window.open(`/lrs/lrs_details/${lrsid}`, '_blank');
  }

  updateLrsDetail = (index) => {
    let {list, status} = this.state
    list[index]["status"] = status
    this.setState({list, status: ""})
    list[index]["updatedBy"]=this.props.auth.id
    updateLRSStatus(this.state.list[index]._id, status, this.props.auth.id)
    .then((data) => {
      this.setState({
        showAlert: true,
        alertMessage: "Updated!",
      })
    })
    .catch((err) => {
      this.setState({
        showAlert: true,
        alertMessage: "Error while updating Data!",
      })
    })
  }

  reset = () => {
    this.setState({
      filteredList: this.state.list,
      search_term: '', term: ""
    })
  }

  addCommentAndUpdateLRS = ({index, onClose}) => {
    let {list, comment} = this.state
    if (comment === undefined || comment === "" || comment.replace(/\s/g,"") === "") {
      this.setState({
        showAlert: true,
        alertMessage: "Add comment before updating",
      })
    }
    else {
      onClose()
      postComments({
        category: commentCategory.lrs,
        comment,
        categoryID: list[index]._id,
        customerId: list[index].userId,
        createdBy:this.props.auth.id
      })
      this.updateLrsDetail(index)
    }
  }


  render() {
    let {
      end_date,
      partnerId,
      start_date,
      is_loaded,
      filteredList,
      list,
      showAlert,
      alertMessage,
      autoClose
    } = this.state

    // let tz = this.props.common && this.props.common.tz

    let disable_view = CAN_VIEW_LRS_DETAILS.includes(this.props.auth.roleTitle) ? false : true
    let disable_edit = CAN_EDIT_LRS.includes(this.props.auth.roleTitle) ? false : true
    return (
      <div>
        <DateFilter 
        start_date={start_date}
        end_date={end_date}
        partnerId={partnerId}
        onChangeDate={this.onChangeDate}
        onChange={this.onChange}
        onSubmit={this.getLRSList}
        {...this.props}
        />
        <br />
        <Loader loaded={is_loaded}>
         <div className="card">
           {
             filteredList && filteredList.length > 0 ? 
             <React.Fragment>
          <div className="card-header">
            <div className="form-row">
              <div className="form-group col-md-10">
                <SearchFilter
                  state={this.state}
                  onChange={this.onChange}
                  onSearch={this.onSearch}
                  reset={this.reset}
                />
              </div>
              <div className="form-contol col-md-2">
                <button 
                  className="btn btn-secondary float-right" 
                  onClick={() => exportCSVFile(list,`${partnerId}-${moment(start_date).format("YYYY-MM-DD")}`, export_headers, "lrs")}>
                    Export CSV
                </button>
              </div>
            </div>
          </div>
        <div className="card-body" style={{overflow: 'auto'}}>
         <span>Total Amount: <b>{this.state.filteredList && this.state.filteredList.length && this.state.filteredList.reduce((sum, data) => {
           return sum + data.fixedFCAmt
         }, 0)}  </b></span>
          <span className="float float-right">Total: {this.state.filteredList && this.state.filteredList.length}  </span>
          <div id="tableContainer" className="tableContainer">
                <table className="table table-hover fixed_header">
                <LRSHeaders {...this.props}/>
                <tbody>
                {
                  filteredList && filteredList.length>0 &&
                  filteredList.map((data,index) => ( 
                    <tr key={index} data-toggle="tooltip" data-placement="top" title={data.applicantAddress ? data.applicantAddress : "No Address Found"} > 
                      <td>{data.createdAt && viewIntoSpecificTimeZone(data.createdAt)}</td>
                      <td>{data.requestId}</td>
                      <td>{data.applicantName}</td>
                      <td>{data.applicantPhone}</td>
                      <td>{data.applicantEmail}</td>
                      <td>{data.dwUsername}</td>
                      <td>{data.fixedFCAmt}</td>
                      <td>{data.lrsBankName}</td>
                      <td>{data.pickUpDate}</td>
                      <td>{data.pickUpTime}</td>
                      <td>
                        <select 
                        className="form-control"  
                        value={data.status ? data.status:'open'}
                        onChange={(e) => this.onStatusChange(index, e)} 
                        name="status"
                        disabled={disable_edit}
                        >
                        <option value="rejected">Rejected</option>
                        <option value="open">Open</option>
                        <option value="complete">Complete</option>
                        <option value="pickUpScheduled">Pick Up Scheduled</option>
                        <option value="self">Self</option>
                        <option value="underProcess">Under Process</option>
                        <option value="underTransit">Under Transit</option>
                        <option value="cancel">Cancel</option>
                        </select> 
                      </td>
                      {/* <td></td> */}
                      {
                         !disable_view &&
                         <React.Fragment>
                          <td>{CLIENTS.includes(data.partnerId) ? data.partnerId : "STOCKAL"}</td>
                          <td>
                          <button className="btn btn-dark" onClick={() => this.goToLRSDetails(data._id)}>Details</button>
                        </td>
                        </React.Fragment>
                      }
                    </tr>
                  ))
                } 
                </tbody>
              </table> 
            </div>
        </div> 
        </React.Fragment>
        : <h2 style={{textAlign: 'center'}}>No Data Found</h2> 
      } 
           </div> 
      
      </Loader>
      <Toast 
        showAlert={showAlert}
        alertMessage={alertMessage}
        autoClose={autoClose}
        setAlertOff={this.setAlertOff}
      />
      </div>
    )
  }
}

const LRSHeaders = (props) => (
  <thead style={{color:"Black", backgroundColor:"golden"}} className="fixedHeader">
    <tr>
      <th className="sticky_head">Initiated Date</th> 
      <th className="sticky_head">ID</th>
      <th className="sticky_head">Name</th>
      <th className="sticky_head">Phone Number</th>
      <th className="sticky_head">Email</th>
      <th className="sticky_head">Username</th>
      <th className="sticky_head">Amount ($)</th>
      <th className="sticky_head">Bank</th>
      <th className="sticky_head">Pick Up Date</th>
      <th className="sticky_head">Pick Up Time</th> 
      <th className="sticky_head">Status</th>
      {/* <th>Post LRS</th> */}
      {
        CAN_VIEW_LRS_DETAILS.includes(props.auth.roleTitle) &&
        <React.Fragment>
          <th className="sticky_head">Partner</th>
          <th className="sticky_head"></th>
        </React.Fragment>
      }
     
    </tr>     
  </thead>
)

const SearchFilter = ({state, onChange, onSearch, reset}) => {
  let {  search_term, term } = state
  return (
    <React.Fragment>
      <div className="form-row">
        <div className="form-group col-md-2">
        <select 
          className="form-control"
          value={search_term} 
          onChange={onChange}
          name="search_term"  
        > 
        <option value="" >Select Filter </option>
        { 
          Object.keys(search_keys).map((data) => (<option key={data} value={data}>{search_keys[data]}</option>)
        )}
        </select>
        </div>
        {
          search_term && search_term !== "" &&
          <React.Fragment>
            <div className="form-group col-md-2">
              <input 
                className="form-control"
                type="text" 
                name="term"
                value={term}
                onChange={onChange}
              />
            </div>
            <div className="form-group col-md-2">
             <button className="btn btn-primary btn-stockal" onClick={onSearch}>Search</button>
          </div>
          </React.Fragment>
        }
        {
         term !== "" || search_term !== "" ?
          <div className="form-group col-md-2">
            <button className="btn btn-secondary" onClick={reset}>Reset</button>
          </div> : null
        }
        </div>
        <div className="form-row">
        </div>
     </React.Fragment>
  )
}



export default connect(null, {getLRSListV2})(LRS)
// const LRSList = ({is_loaded}) => {
//   return (
//     <Loader loaded={is_loaded}> 
//       <div className="card">
//         <div className="card-header">
//          LRS List
//         </div>
//         <div className="card-body" style={{overflow: 'auto'}}>
//           <span className="float float-right">Total:  </span>
//         <div id="tableContaine<r" className="tableContainer">
//           <table className="table">
//             <tbody>
             
//             </tbody>
//           </table>
//           </div>
//         </div>
//       </div> : <h2 style={{textAlign: 'center'}}></h2>  
//      </Loader> 
// )
// }
