import React from "react";



function Table(props) {

    return (
        <div>
            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">USER ID</th>
                        <th scope="col">NAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">USER CREATED</th>
                    </tr>
                </thead>
                <tbody>
                 {  props.users.map(function(user){
                            return(<tr>
                                <th scope="row">{user.userId}</th>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.date}</td>
                            </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Table;