

import React , { Component } from 'react'
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
  Link
} from 'react-router-dom';
import {login} from 'Redux/actions/auth'

// import ForgotPassword from './ForgotPassword1'


class Login extends Component {

  constructor(props){
    super(props)
    this.state = {error : ''}
  }

  componentDidMount = () => {
    if(this.props.auth.isLoggedIn)
      this.props.history.push('/')
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  isValid = () => {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(this.state.email)) {
      this.setState({
          error: "Invalid Email"
      })
      return false
    }
    return true
  }

  onSubmit = (e) => {
    e.preventDefault()
    let {username, password} = this.state
    if ( username !== '' && password !== '')  {
      if (this.isValid)
      this.props.login({
        username,
        password
      })
      .then((response => {
        this.props.history.push('/')
      }))
      .catch(()=>{
        alert("Invalid user")
      })
    }       
    else {
      this.setState({
        error: "Please enter valid Email and Password"
      })
    }
}
  
render(){
    return(
    <div className="back">
      <div className="container">
        <div className="row justify-content-center" style={{padding:'10%'}}>
          <div className="col-xl-6 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Stockal Dashboard</h1>
                      </div>
                      <form className="user" onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <input 
                          type="email" 
                          className="form-control form-control-user" 
                          name="username" 
                          id="InputEmail" 
                          aria-describedby="emailHelp" 
                          placeholder="Enter Email Address..."
                          onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group">
                            <input 
                            type="password" 
                            className="form-control form-control-user" 
                            name="password" 
                            id="InputPassword" 
                            placeholder="Password"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                          <input type="checkbox" className="custom-control-input" id="customCheck"/>
                          <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                           <div className= "text-right" style={{float:"right"}}>
                           <Link to='ForgotPassword'>Forgot Password?</Link>
                           </div>
                        </div>   
                        </div>
                        <input type="submit" value="Sign In" className="btn btn-primary btn-stockal btn-user btn-block"/>
                      </form>
                        <hr />
                        <div className="text-center">
                        </div>
                        <div>
                        <span>{this.state.error}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div> 
    )    
    }
}

const mapStateToProps = (props) => {
  return {
    auth: props.auth
  }
}

const mapDispatchToProps = (diapatch) => {
  
}

export default connect(mapStateToProps, {login})(Login)
