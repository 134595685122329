import _ from 'lodash';
import { GET_STACKS, GET_STACKS_SUCCESS, GET_STACKS_FAIL, CHANGETICKER,
    ADD_PARTNERPLAN_STACKS, ADD_PARTNERPLAN_STACKS_FAIL, ADD_PARTNERPLAN_STACKS_SUCCESS,
    DELETE_PARTNERPLAN_STACKS, DELETE_PARTNERPLAN_STACKS_SUCCESS, DELETE_PARTNERPLAN_STACKS_FAIL, DELETE_PARTNERPLAN_STACKS_RESET
} from '../type';

const INITIAL_STATE = {
    stacks: {},
    stacksMessage: '',
    currentTicker: '',
    message: '',
    deleteMessage: '',
}

const StacksReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_STACKS: 
            return { ...state, stacks: {}, stacksMessage: 'Fetching Stacks', message: ''}
        case GET_STACKS_SUCCESS:
            const tickers = Object.keys(action.payload);
            return { ...state, stacks: action.payload, stacksMessage: '', currentTicker: state.currentTicker === ''? tickers[0]: state.currentTicker}
        case GET_STACKS_FAIL:
            return { ...state, stacks: null, stacksMessage: 'Failed to Retrive Stack, Please contact you administrator' };
        
        case CHANGETICKER:
            return { ...state, currentTicker:action.payload };

        case ADD_PARTNERPLAN_STACKS:
            return { ...state, message: '' };

        case ADD_PARTNERPLAN_STACKS_SUCCESS:
            console.log(state);
            return { ...state, message: action.payload };
        
        case ADD_PARTNERPLAN_STACKS_FAIL:
            return { ...state, message: 'Error Adding Stacks' };

        case DELETE_PARTNERPLAN_STACKS:
            return { ...state, deleteMessage: 'Initiating Delete Request' };
        case DELETE_PARTNERPLAN_STACKS_SUCCESS:
            return { ...state, deleteMessage: 'Plan Successfully Deleted'};
        case DELETE_PARTNERPLAN_STACKS_FAIL:
            return { ...state, deleteMessage: 'Failed Deleting Plan' };
        case DELETE_PARTNERPLAN_STACKS_RESET:
            return { ...state, deleteMessage: '' };
        
        
        default:
            return state;
    }
}
export default StacksReducer;