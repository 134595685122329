import {
    GET_ORDER_REPORT, GET_ORDER_REPORT_SUCCESS, GET_ORDER_REPORT_FAIL, GET_ORDER_REPORT_RESET,
    GET_ORDER_REPORT_SEARCH,
    GET_STACK_REPORT,
    GET_STACK_REPORT_SUCCESS,
    GET_STACK_REPORT_FAIL,
    GET_STACK_REPORT_RESET,
    GET_ALL_ORDER_DETAIL, GET_ALL_ORDER_SUCCESS, GET_ALL_ORDER_REPORT_FAIL, GET_ALL_ORDER_REPORT_RESET,
    GET_ALL_STACK_DETAIL, GET_ALL_STACK_SUCCESS, GET_ALL_STACK_REPORT_FAIL, GET_ALL_STACK_REPORT_RESET
} from "../type"

const INTIAL_STATE = {
  orders: [],
  orderReportMessage: '',

  stacks: [],
  stacksReportMessage: '',
}

const OrderReport = (state=INTIAL_STATE, action) => {
    switch(action.type){
      case GET_ORDER_REPORT:
        return { ...state, orders: [], orderReportMessage: 'Fetching Orders...' };
      case GET_ORDER_REPORT_SUCCESS:
        // console.log('Orders', state.orders.length, action.payload.length);
        const orders = [...state.orders, ...action.payload];
        // console.log('Orders Post', orders);
        return { ...state, orders: orders, orderReportMessage: '' };
      case GET_ORDER_REPORT_FAIL:
        return { ...state, orderReportMessage: 'Failed Fetching Orders.' };
      case GET_ORDER_REPORT_RESET:
          return { ...state, orderReportMessage: '' };


      case GET_STACK_REPORT:
        // console.log({...state, stacks: [], stacksReportMessage: 'Fetching Stacks...'})
        return { ...state, stacks: [], stacksReportMessage: 'Fetching Stacks...' };
      case GET_STACK_REPORT_SUCCESS:
        // console.log('Stale:', action.payload.length, state.orders.length);
        // console.log('State Orders',state.orders);
        // console.log('Stack Orders', stackOrders)
        // orders.push(orders);
        return { ...state, stacks: action.payload, stacksReportMessage: '' };
      case GET_STACK_REPORT_FAIL:
        return { ...state, stacksReportMessage: 'Failed Fetching Stacks.' };
      case GET_STACK_REPORT_RESET:
          return { ...state, stacksReportMessage: '' };

      // start
      case GET_ALL_ORDER_DETAIL:
        return { ...state, orders: [], orderReportMessage: 'Fetching Orders...' };
      case GET_ALL_ORDER_SUCCESS:
        console.log('all order success')
        // console.log('newOrders', state.orders.length, action.payload.length);
        const newOrders = [...state.orders, ...action.payload];
        console.log('Orders', newOrders);
        return ({ ...state, orders: newOrders, orderReportMessage: '' });
      case GET_ALL_ORDER_REPORT_FAIL:
        console.log('order failed')
        return { ...state, orderReportMessage: 'Failed Fetching Orders.' };
      case GET_ALL_ORDER_REPORT_RESET:
          return { ...state, orderReportMessage: '' };
      // end

      // start
      case GET_ALL_STACK_DETAIL:
        return { ...state, stacks: [], stacksReportMessage: 'Fetching Stacks...' };
      case GET_ALL_STACK_SUCCESS:
        console.log('all order success')
        const newStacks = [...state.stacks, ...action.payload];
        return ({ ...state, stacks: newStacks, stacksReportMessage: '' });
      case GET_ALL_STACK_REPORT_FAIL:
        return { ...state, stacksReportMessage: 'Failed Fetching Stacks.' };
      case GET_ALL_STACK_REPORT_RESET:
          return { ...state, stacksReportMessage: '' };
      // end
      
      case GET_ORDER_REPORT_SEARCH:
         const order = state.order
         const {term,search_term} = action.payload
         const filter_array = order.filter(item => {
            if(item[search_term].indexOf(term.trim()) > -1){
              return true;
            } else {
              return false;
            }
         });
         return { ...state, order:filter_array }

      default:
        return state
    }
    }
    
    export default OrderReport;