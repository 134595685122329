
import axios from 'axios'
import { API_ENDPOINT_V2, SECRET_KEY } from 'Redux/apiConstant'
import {getConfig} from '../getConfig'

export const DiscrepancyReport = async ({search_term, searchValue}) => {
   const headers = {
    'stockal-secret-key': SECRET_KEY,
   }; 
   const url = `${API_ENDPOINT_V2}/users-dashboard/portfolio/discrepancy-report/?${search_term}=${searchValue}`
   try{
      let response = await axios.get(url, {headers})
      console.log('responseReport',response)
      if (response.data.code === 200){
         return response
      }
      else
         throw response
   }
   catch (e) {
        alert(e)
   }
}

export const UpdateDiscrepancy = async ({id, newStatus,newcomment1,newcomment2}) => {
   console.log('update',id, newStatus,newcomment1,newcomment2)
   const body = {
      status : newStatus,
      comment1 : newcomment1,
      comment2 : newcomment2
    }
   const headers = {
      'stockal-secret-key': SECRET_KEY,
   }; 
   const url = `${API_ENDPOINT_V2}/users-dashboard/portfolio/discrepancy-report/${id}`
   // axios.put(`${v2Host}/users-dashboard/portfolio/discrepancy-report/${id}`,body ,{headers})
   try{
      let response = await axios.put(url, body, {headers})
      console.log('responseUpdate',response)
      if (response.data.code === 200){
          console.log('update')
         return response
      }
      else
         throw response
   }
   catch (e) {
        alert(e)
   }
}


export const DeleteDiscrepancyReport = async ({deleteID}) => {
  const headers = {
   'stockal-secret-key': SECRET_KEY,
  }; 
  const url = `${API_ENDPOINT_V2}/users-dashboard/portfolio/discrepancy-report/${deleteID}`
  try{
     let response = await axios.delete(url, {headers})
     if (response.data.code === 200){
         console.log('yhn bi aa gya')
        return response
     }
     else
        throw response
  }
  catch (e) {
   debugger
      console.log('hey')
      alert(e)
  }
}