import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { getPaymentsDetail } from '../../Redux/actions/payments';
import { getClientId, dateToString, PLANS1, getMyPlans } from 'utils/common';
import { DateFilter } from 'utils/DateFilter';
import { default as DataGrid } from 'utils/DataGrid/index';
// import {viewIntoSpecificTimeZone } from 'utils/common';
// import { getCustomerReport1 } from 'Redux/actions/export';
import { getPartnerOptions } from 'utils/common';




class Payments extends Component {
    constructor(props) {
        super(props);
        const start = new Date();
        const end = new Date();
        start.setDate(start.getUTCDate()-3);
        this.state = {
            minStart: new Date('2019-08-15'),
            start,
            end,
            partnerId: '',
            searchList:[],
            users: [],
            plans: {},
            filterBy: 'Payment',
            // skip: 0,
            limit: 500,
            apiCall: false,
            key: '',
            value: '',
            is_loaded:true
        }; 
        this.partnerOptions = getPartnerOptions({...props.auth, ...props.common});

        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchPaymentsDetail = this.fetchPaymentsDetail.bind(this);
        const statusTypes = [{ key: "NEW", value: "NEW" },
            { key: "PARTIAL_FILL", value: "PARTIAL_FILL" },
            { key: "CANCELED", value: "CANCELLED" },
            { key: "REJECTED", value: "REJECTED" },
            { key: "FILLED", value: "FILLED" }]
        this.columns = [
            { key: "date", 
              name: "Registration", 
            },
            { 
              key: "loginUsername", 
              name: "Login Name", },
            { 
              key: "userEmail", 
              name: "Email", 
            },
            { key: "paymentDate", name: "Pay Date"},
            { key: "paymentId", name: "Pay Id"},
            { key: "mobile", name: "Mobile"},
            { key: "paymentStatus", name: "Payment Status"},
            { key: "planId", name: "Plan"},
            { key: "paymentAmount", name: "Payment Amount"},
            { key: "dwUsername", name: "User Name"},
            { key: "couponCode", name: "Coupon"},
        ];
    }

    DateFormatter ({column, row}){
        const key = column.key;
        if (row[key]) {
            return dateToString(row[key]);
        } else 
        {
            return '';
        }
    };

    componentDidMount () {
        const { auth } = this.props
        const partnerId = getClientId(auth) === 'STOCKAL' ? 'ALL' : getClientId(auth);
        const plans = getMyPlans(this.props.common.plans || []);
        this.setState({ partnerId, plans });
        // this.props.getCustomerReport1(viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, 'Payment', skip, limit);
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (state.users.length === 0 ) {
    //         return { users: props.users };
    //     }
    //     if (props.skip !== state.skip) {
    //         return { skip: props.skip };
    //     }
    //     return null;
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.users.length !== this.props.users.length) {
    //         this.setState({ users: this.props.users, apiCall: false });
    //     }
    // }

    onSubmit() {
        const { start, end, partnerId } = this.state;
        console.log('state',start,end,partnerId)
        start.setHours(0, 0, 0);
        end.setHours(23,59, 59);
        this.fetchPaymentsDetail(start, end, partnerId);
        // this.props.getCustomerReport1(viewIntoSpecificTimeZone(start), viewIntoSpecificTimeZone(end), partnerId, filterBy);
    }

    formatDate (date) {
        return date.toISOString(); 
        // dateToString(date);
    }

    fetchPaymentsDetail(start, end, partnerId) {
        console.log('start',start)
        const nstart = this.formatDate(start);
        console.log('newstart',nstart)
        const nend = this.formatDate(end);
        this.getPaymentsData(nstart, nend, partnerId);
        // this.props.getPaymentsDetail(nstart, nend, partnerId);
    }

    getPaymentsData(nstart, nend, partnerId) {
        console.log('data',nstart, nend, partnerId)
        this.setState({is_loaded:false})
        getPaymentsDetail({nstart, nend, partnerId})
        .then((response) => {
            if(response.data.code === 200){
                this.setState({
                    searchList:response.data.data,
                    is_loaded:true,
                })
            }
            else{
                this.setState({searchList:[],is_loaded:true,search_error_message:"No data found" })
            }
        })
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    // onDateChange(name,date) {
    // const max = new Date();
    //     if (name === 'start_date') { 
    //         const start = date;
    //         const end = new Date(date);
    //         end.setDate(end.getDate() + 60);
            
    //         this.setState({ start, end }); 
    //         if (max > end) {
    //             this.setState({ end});
    //         } else {
    //             this.setState({ end: max});
    //         }
    //     }
    //     if (name === 'end_date') { 
    //         this.setState({ 'end':date }); 
    //     }
    // }

    onDateChange(name, date) {
        if (name === 'start_date') {
          this.setState({ start: date });
        }
        if (name === 'end_date') {
          this.setState({ end: date });
        }
      }

    renderSearchFilter() {
        return (
            <Fragment>
                 <Loader loaded={this.state.is_loaded}> 
                {
                    this.state.searchList && this.state.searchList.length > 0 ? (
                    <DataGrid columns={this.columns}
                        rows={this.state.searchList}
                    >
                    <span className="form-control"><strong>Count: {this.state.users.length}</strong></span>   
                    </DataGrid>
                    ) : 
                    <h3>{this.state.search_error_message}</h3>
                }
                </Loader>
            </Fragment>
        );
    }

    render() {
        // console.log('data',this.state.searchList)
        return (
        <Fragment>
            <DateFilter
                start_date={this.state.start}
                end_date={this.state.end}
                partnerId={this.state.partnerId}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onChangeDate={this.onDateChange}
                {...this.props}
            />
            {/* {this.renderStatusRibbon()}  */}
            {this.renderSearchFilter()}
        </Fragment>);
    }
}

const mapStateToProps = (props) => {
    return {
        common: props.common,
        auth: props.auth,
        // users: props.report.users,
        // skip: props.report.skip,
        
    };
}
export default connect(mapStateToProps, { getPaymentsDetail }) (Payments)


