import axios from 'axios';
import { API_ENDPOINT, DW_API_ENDPOINT} from 'Redux/apiConstant';
import { getConfig, getConfigDashboard } from '../getConfig';
import { SAVE_CUSTOMER_DETAILS } from '../type';
import { API_ENDPOINT_V2, SECRET_KEY} from '../apiConstant';

const v2_config = {
  headers: {
    'stockal-secret-key': SECRET_KEY,
  },
};
export const getSearchCustomerList = ({ search_term, userId }) => {
  const v2Host = API_ENDPOINT_V2;
  search_term = encodeURIComponent(search_term);
  const config = {
    headers: {
      'stockal-secret-key': SECRET_KEY,
      adminId: userId,
    },
  };
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      //   axios.post(`${API_ENDPOINT}/api/streams/dashboard/user/customer-list/?page=${page}`, data ,getConfigDashboard({partnerId}))
      axios
        .get(`${v2Host}/users-dashboard/search?q=${search_term}`, config)
        .then((response) => {
          if (response.status === 200) {
            return resolve(response.data.data);
          } else {
            return reject(response.data);
          }
        })
        .catch((error) => {
          error.response
            ? error.response.data
              ? alert(error.response.data.non_field_errors)
              : alert('Network Error')
            : alert('Network Error in api');
        });
    });
};

// start date , end date api for customer details
export const getCustomerList = ({data, page, partnerId}) => {
  debugger
  return (dispatch) => new Promise(async(resolve, reject) => {
      axios.post(`${API_ENDPOINT}/api/streams/dashboard/user/customer-list/?page=${page}`, data ,getConfigDashboard({partnerId}))
      .then((response) => {
        debugger
          if(response.data.status === 200){
              return resolve(response.data.docs)

          }
          else {
              return reject(response.data)
          }
      })
      .catch((error) => {
          error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
          alert("Network Error") : alert("Network Error in api")
      })
  })
}

export const getCustomerDetails = ({ userId, partnerId }) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      axios
        .get(
          `${API_ENDPOINT}/api/streams/dashboard/user/customer-details/${userId}`,
          getConfigDashboard({ partnerId })
        )
        .then((response) => {
          if (response.data.status === 200) {
            dispatch({
              type: SAVE_CUSTOMER_DETAILS,
              payload: response.data.docs,
            });
            return resolve(response.data.docs);
          } else {
            return reject(response.data);
          }
        })
        .catch((error) => {
          error.response
            ? error.response.data
              ? alert(error.response.data.non_field_errors)
              : alert('Network Error')
            : alert('Network Error in api');
        });
    });
};

// completeOrderList

export const getCompletedOrders = ({ userID, partnerID, planID }) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      // axios.get(`${API_ENDPOINT}/api/streams/dashboard/user/customer-details/${userId}`, getConfigDashboard({partnerId}))
      axios
        .get(`${API_ENDPOINT}/api/user/portfolio/user-portfolio-list`, {
          headers: {
            'content-type': 'application/json',
            'stockal-secret-key': SECRET_KEY,
            userid: userID,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            console.log('res', response);
            dispatch({ payload: response.data.docs });
            return resolve(response.data.docs);
          } else {
            return reject(response.data);
          }
        });
    });
};

// exports.getCompletedOrders = function ({userID, partnerID, planID}) {
//     let options = {
//       method: 'GET',
//       url: `${API_ENDPOINT}/api/user/portfolio/user-portfolio-list`,
//       headers: {
//         'content-type': 'application/json',
//         'stockal-secret-key': SECRET_KEY,
//         'userid': userID,
//         'partnerid': partnerID,
//         'planid': planID
//       },
//     };
//     request(options, function (error, response, body) {
//       try {
//         if (response.statusCode === 200) {
//           res.send(JSON.parse(body)).end();
//         } else {
//           res.send({status: response.statusCode, message: response.message}).end();
//         }
//       } catch (err) {
//         res.send({status: 500, message: 'Oops! Something went wrong.'}).end();
//       }
//     });
//   };

// create Account API"S

// Validate Username via DW
export const validateUsername = async ({ username }) => {
  try {
    let data = await axios.get(
      `${DW_API_ENDPOINT}/v1/users/?username=${username}`
    );
    if (data.data && data.data.code === 200) {
      return true;
    }
  } catch (err) {
    if (err && err.response === 404) {
      return false;
    }
  }
};

// Account Creation on DW

export const createDWAccount = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      axios
        .post(`${DW_API_ENDPOINT}/v1/signups/live`, data)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(
            error.response && error.response.data && error.response.data.message
          );
        });
    });
};

//  Create UserAccount Stockal
export const createUserAccount = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}/api/user/7/profile`, data, getConfig())
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(
            error.response && error.response.data && error.response.data.message
          );
        });
    });
};

/* Get Country List */
export const getCountryList = () => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}/api/dw-back-office/countries/active`, getConfig())
        .then((response) => {
          if (response.data.status === 200) {
            return resolve(response.data.doc);
          } else {
            return reject(response.data);
          }
        })
        .catch((error) => {
          error.response
            ? error.response.data
              ? alert(error.response.data.non_field_errors)
              : alert('Network Error')
            : alert('Network Error in api');
        });
    });
};

export const updateCustomer = (obj) => {
  let body = {};
  switch(obj.type) {
    case 'BASIC_INFO': {
      body = obj.body
      break;
    }
    case 'PERSONAL_INFO': {
      body = obj.body
      break;
    }
    case 'ADDRESS_INFO': {
      body = obj.body
      break;
    }
    case 'IDENTIFICATION_INFO': {
      body = obj.body
      break;
    }
    case 'EMPLOYMENT_INFO': {
      body = obj.body
      break;
    }
    default: {

    }
  }
  v2_config.headers.userID = obj.userID;
  return (dispatch) => {
    // dispatch({ type: PATCH_USER_UPDATE, payload: ''});
    const url = `${API_ENDPOINT_V2}/users/update`;
    axios.post(url, body, v2_config).then(res => {
      if(res.data.status === 200) {
        // dispatch(getCustomerDetails({ userId: obj.userId }));
        return true;
      }
    });
  }

};

export const uploadKycDocuments = (body) => {
  v2_config.headers.userID = body.userID;
  return (dispatch) => {
    // dispatch({ type: PATCH_USER_UPDATE, payload: ''});
    const url = `${API_ENDPOINT_V2}/users/profile/documents`;
    axios.post(url, body, v2_config).then(res => {
      if(res.data.status === 200) {
        // dispatch(getCustomerDetails({ userId: obj.userId }));
        return true;
      }
    });
  }

};
