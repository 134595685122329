import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect} from 'react-redux';
import { getStacks, selectTicker, addPartnerPlan, deletePartnerPlan } from 'Redux/actions/stacks'


class AddStackInAll extends Component {
    constructor(props){
        super(props)
        this.state={
            ticker : [],
            stacksMessage: '',
            currentTicker: '',
            currentStack: { stackPartner: []},
            selectedPlan: '',
            buyAum:'',
            sellcommission: '',
            activationFee: '',
            minimum:'',
            activationFeeCurrency:'',
            isAddAll:true,
            myPlans: {},
            checkedTicker:[],
            noTicks:"",
            modalToggle:false,
            exitFee:'',
            exitFeeMessage:'',
            entryFee:'',
            entryFeeMessage:'',
            aumFrequency:'',
            aumFrequencyMessage:'',
            comment:'',
            commentMessage:''
        }
        this.handleChange  = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.applyChange = this.applyChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handleChange (e) {
        this.setState({[e.target.name]: e.target.value})
        this.setState({[e.target.name+'message']: e.target.value});
    }

    handleClick() {
        let flag = true;
        if ( this.state.selectedPlan === '') { this.setState({ planMessage: 'Plan Cannot be empty'} ); flag = false; }
        if ( this.state.buyAum === '') { this.setState({ buyAumMessage: 'Buy AUM cannot be empty'} ); flag = false; }
        if ( this.state.sellcommission === '') { this.setState({ sellcommissionMessage: 'Commission be empty'} ); flag = false; }
        if ( this.state.activationFee === '') { this.setState({ activationFeeMessage: 'Activation Fee cannot be empty'} ); flag = false; }
        if ( this.state.minimum === '') { this.setState({ minimumMessage: 'Minimum cannot be empty'} ); flag = false; }
        if ( this.state.activationFeeCurrency === '') { this.setState({ activationCurrencyMessage: 'Currency cannot be empty'} ); flag = false; }
        if ( this.state.exitFee === '') { this.setState({ exitFeeMessage: 'Exit Fee cannot be empty'} ); flag = false; }
        if ( this.state.entryFee === '') { this.setState({ entryFeeMessage: 'Entry Fee cannot be empty'} ); flag = false; }
        if ( this.state.aumFrequency === '') { this.setState({ aumFrequencyMessage: 'Aum Frequency cannot be empty'} ); flag = false; }
        if ( this.state.comment === '') { this.setState({ commentMessage: 'Comment cannot be empty'} ); flag = false; }
        if (flag && this.props.checkedTicker.length > 0 ) {
            console.log('ticko',this.state.checkedTicker)
            this.setState({modalToggle:true,checkedTicker:this.props.checkedTicker});
        } else {
            return false;
        }   
    }

    applyChange(){
        for( var i = 0; i < this.props.checkedTicker.length; i++){
            this.props.addPartnerPlan({
                ticker: this.props.checkedTicker[i], 
                aumForBuy : this.state.buyAum, 
                partnerID : this.props.match.params.code, 
                planID : this.state.selectedPlan, 
                activationFee : this.state.activationFee, 
                activationFeeCurrency : this.state.activationFeeCurrency, 
                commissionForSell : this.state.sellcommission, 
                minimum : this.state.minimum, 
                exitFee : this.state.exitFee,
                entryFee : this.state.entryFee,
                aumFrequency : this.state.aumFrequency,
                comment : this.state.comment,
                updatedBy : this.props.updatedBy
            });
            this.setState({modalToggle:false});
            this.props.afterYes();
        }
    }

    closeModal(){
        this.setState({modalToggle:false});
    }

    render(){
        const codes = Object.keys(this.props.code);
        const { stackPartner } = this.props.stackPartner ;
        const stackPartnersObj = _.keyBy(stackPartner, 'planID');
        console.log('modal',this.props.updatedBy)
        return(
        <div className="card pr-4 pl-4 pt-3 ">
            <h3>Fill for all the selected stacks</h3>
            
             {/*new modal  */}
             {this.state.modalToggle ? (
              <div id="popup1 mx-auto" className="overlay">
              <div className="popup">
                <h2 className="pl-2">Stacks Selected</h2>
                    <div className="modal-body">
                    <p>the plan will be inserted in the selected stacks :-</p>
                    {this.props.checkedTicker.map((item,index) => (
                        <span className="font-weight-bold" key={item}>{(index ? ", " : '')+ item}</span>
                    ))}
                    </div>
                    <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>NO</button>
                                <button type="button" className="btn btn-primary" onClick={this.applyChange}>YES</button>
                    </div>
              </div>
          </div>): ""}



            <div className="row pl-2 pr-2">
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                    <label className="stackLabelFont">Select Plan</label>
                        {
                            this.state.isAddAll ? (
                                <select className="form-control-sm form-control" name="selectedPlan" value={this.state.selectedPlan} onChange={this.handleChange}>
                                    <option value=""></option>
                                    {
                                        codes.map((key) => {
                                                if (!stackPartnersObj[key]) {
                                                return  (<option key={key} value={key}>{this.props.code[key]}</option>)
                                                }
                                                return '';
                                        })
                                    }
                                </select>
                            ) : (
                                <input className="form-control-sm form-control" type="text" disabled={true} value={this.state.myPlans[this.state.selectedPlan]} />
                            )
                        }
                        
                        {
                            this.state.planMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.planMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                        <label className="stackLabelFont">Buy Aum</label>
                        <input type ='number' min={0} className="form-control-sm form-control" name="buyAum" value={this.state.buyAum} onChange={this.handleChange} />
                        {
                            this.state.buyAumMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.buyAumMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                        <label className="stackLabelFont">Sell Commission</label>
                        <input type ='number' min={0} className="form-control-sm form-control" name="sellcommission" value={this.state.sellcommission} onChange={this.handleChange} />
                        {
                            this.state.sellcommissionMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.sellcommissionMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                        <label className="stackLabelFont">Activation Fee</label>
                        <input type ='number' min={0}  className="form-control-sm form-control" name="activationFee" value={this.state.activationFee} onChange={this.handleChange} />
                        {
                            this.state.activationFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.activationFeeMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-1 px-1">
                        <label className="stackLabelFont">Currency</label>
                        <select className="form-control-sm form-control" width="100%" name="activationFeeCurrency" value={this.state.activationFeeCurrency} onChange={this.handleChange} >
                            <option value=""></option>
                            <option value="USD">USD</option>
                            <option value="INR">INR</option>
                        </select>
                        {
                            this.state.activationCurrencyMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.activationCurrencyMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-1 px-1">
                        <label className="stackLabelFont">Minimum</label>
                        <input type ='number' min={0}  className="form-control-sm form-control" name="minimum" value={this.state.minimum} onChange={this.handleChange} />
                        {
                            this.state.minimumMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.minimumMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                        <label className="stackLabelFont">Exit Fee</label>
                        <input type ='number' min={0}  className="form-control-sm form-control" name="exitFee" value={this.state.exitFee} onChange={this.handleChange} />
                        {
                            this.state.exitFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.exitFeeMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-2 px-1">
                        <label className="stackLabelFont">Entry Fee</label>
                        <input type ='number' min={0}  className="form-control-sm form-control" name="entryFee" value={this.state.entryFee} onChange={this.handleChange} />
                        {
                            this.state.entryFeeMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.entryFeeMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-4 col-xl-3 px-1">
                        <label className="stackLabelFont">AUM Frequency</label>
                        <select className="form-control-sm form-control" width="100%" name="aumFrequency" value={this.state.aumFrequency} onChange={this.handleChange} >
                            <option value=""></option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="SemiAnnually">Semi-Annually</option>
                            <option value="Annually">Annually</option>
                        </select>
                        {
                            this.state.aumFrequencyMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.aumFrequencyMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-9 col-xl-6 px-1">
                        <label className="stackLabelFont" for="exampleFormControlTextarea1">Comment</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" name="comment" value={this.state.comment} onChange={this.handleChange}></textarea>
                        {
                            this.state.commentMessage !== '' ? (<span className="text-danger stackLabelFont">{ this.state.commentMessage}</span>): ''
                        }
                    </div>
                    <div className="form-group col-sm-6 col-md-12  col-xl-1 pt-4">
                        {this.props.checkedTicker.length > 0 ? ( <button className="btn btn-primary mt-1 btn-sm mr-1" type="button" data-toggle="modal" data-target="#exampleModal" onClick={this.handleClick} >Apply
                        </button>) 
                        :  
                        <button className="btn btn-primary mt-1 btn-sm mr-1" type="button"  disabled >Apply
                        </button>}
                       
                        {/* <button className="btn btn-primary mt-1 btn-sm" type="button" onClick={this.reset}>
                            Cancel
                        </button> */}
                    </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = ({auth, common, stacks}) => ({ auth, common, ...stacks })

export default connect(mapStateToProps, { getStacks, selectTicker, addPartnerPlan, deletePartnerPlan })(AddStackInAll);
