import {
    INITIATE_KPI,
    CUSTOMERKPI_SUCCESS,
    CUSTOMERKPI_FAIL,
    BUSINESSKPI_SUCCESS,
    BUSINESSKPI_FAIL,
 } from '../type';

// INITIAL STATE
const IS = {
    mtd: true,
    ytd: false,
    custom: false,
    from:null,
    to:null,
    partnerId: '',
    customerKPI: {},
    businessKPI: {},
    customerKPIMessage: '',
    businessKPIMessage: '',

}

const LandingReportReducer = (state = IS, action) => {
    switch(action.type) { 
        case INITIATE_KPI:
            return { ...state, ...action.payload, customerKPI: {}, businessKPI: {}, 
            customerKPIMessage:'Fetching Customer KPI', businessKPIMessage: 'Fetching Business KPI' };

        case BUSINESSKPI_SUCCESS: 
            return {...state, businessKPI: action.payload.doc, businessKPIMessage:''}
        
        case BUSINESSKPI_FAIL: 
            return {...state, businessKPI: {}, businessKPIMessage: 'Failed Fetching Business Report' };

        case CUSTOMERKPI_SUCCESS: 
            return {...state, customerKPI: action.payload.result, customerKPIMessage: ''};
        
        case CUSTOMERKPI_FAIL: 
            return {...state, customerKPI: {}, customerKPIMessage: 'Failed Fetching Customer Report'};
        default:
            return state;
    }
};

export default LandingReportReducer;