

import axios from 'axios'
import {API_ENDPOINT, API_ENDPOINT_V2} from 'Redux/apiConstant'
import {getConfig} from '../getConfig'
import { GET_CUSTOMER_REPORT} from 'Redux/type'


export const updateLRS = async (data) => {
    const url = `${API_ENDPOINT}/api/streams/dashboard/customer/report/lrs/update`
   try{
      let response = await axios.post(url, data, getConfig())
      if (response.data.status === 200){
         return response.data
      }
      else
         throw response.data.status
   }
   catch (e) {
        alert(e)
   }
}

export const updateLRSV2 = async (data, userId) => {
    const config = {  headers : {
        'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
        'adminid' : userId
    }};
    const url = `${API_ENDPOINT_V2}/dashboard/remittance/${data._id}/lrs`
   try{
      let response = await axios.put(url, data, config)
      if (response.data.code === 200){
         return response.data.data
      }
      else
         throw response.data.message
   }
   catch (e) {
        alert(e)
   }
}
export const updateLRSStatus = async (id, status, userId) => {
    const config = {  headers : {
        'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
        'adminid' : userId
    }};
    const url = `${API_ENDPOINT_V2}/dashboard/remittance/${id}/status`
   try{
      let response = await axios.put(url, {status}, config)
      if (response.data.code === 200){
         return response.data.data
      }
      else
         throw response.data.message
   }
   catch (e) {
        alert(e)
   }
}

export const getLRSList = ({partnerId, start_date, end_date}) => {
    return (dispatch) => new Promise(async(resolve, reject) => {
        axios.get(`${API_ENDPOINT}/api/streams/dashboard/lrs/bank-lrs/${partnerId}/${start_date}/${end_date}`, getConfig())
        .then((response) => {
            if(response.data.status === 200){
                return resolve(response.data)
            }
            else
                return reject(response.data.error)
        })
        .catch((error) => {
            let err = error.response && error.response.data ? error.response.data.non_field_errors :"Network Error"
            return reject(err)
        })
    })
  }
  
export const getLRSListV2 = ({partnerId, start_date, end_date, userId}) => {
  return (dispatch) => new Promise(async(resolve, reject) => {
        const config = {  headers : {
            'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
            'adminid' : userId
        }};
        axios.get(`${API_ENDPOINT_V2}/dashboard/remittance?from=${start_date}&to=${end_date}&partner=${partnerId}`, config)
        .then((response) => {
            if(response.data.code === 200){
                return resolve(response.data.data)
            }
            else
                return reject({error_message: 'No Data Found'})
        })
        .catch((error) => {
            let err = error.response && error.response.data ? error.response.data.non_field_errors :"Network Error"
            return reject(err)
        })
    })
}

export const getLRSDetails = ({lrsId}) => {
    return (dispatch) => new Promise(async(resolve, reject) => {
        axios.get(`${API_ENDPOINT}/api/streams/dashboard/lrs/bank-lrs/${lrsId}`, getConfig())
        .then((response) => {
            if(response.data.status === 200){
                return resolve(response.data)
            }
        })
        .catch((error) => {
            error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
            alert("Network Error") : alert("Network Error in api")
        })
    })
}

export const getLRSDetailsV2 = ({lrsId, userId}) => {
  return (dispatch) => new Promise(async(resolve, reject) => {
    const config = {  headers : {
        'stockal-secret-key': 'CSeeVMAcZwo60ZHRlvhSeD8gwviaF1FfECbRAd3mKb1wNccCWF',
        'adminid' : userId
    }};
      axios.get(`${API_ENDPOINT_V2}/dashboard/remittance/${lrsId}`, config)
      .then((response) => {
          if(response.data.code === 200){
              return resolve(response.data.data)
          }
      })
      .catch((error) => {
          error.response ? error.response.data ? alert(error.response.data.non_field_errors) :
          alert("Network Error") : alert("Network Error in api")
      })
  })
}
