import { combineReducers } from 'redux';
import {
  AuthReducer,
  CustomerReportReducer,
  CustomerDetailsReducer,
  CommonReducer,
  OrdersReducer,
  TransactionReducer,
  LandingReportReducer,
  StacksReducer,
  CouponReducer,
  InstapaymentReducer,
} from './reducers';
import { LOGOUT_SUCCESS } from './type';

const appReducer = combineReducers({
  auth: AuthReducer,
  report: CustomerReportReducer,
  customer_details: CustomerDetailsReducer,
  common: CommonReducer,
  orders: OrdersReducer,
  transactions: TransactionReducer,
  landing: LandingReportReducer,
  stacks: StacksReducer,
  coupon: CouponReducer,
  instapayment: InstapaymentReducer, 
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
