
import React from 'react'
import moment from 'moment-timezone'
import  { roles } from './roles'
import _ from 'lodash'
import {store} from 'store'

//  clients 
// export const PARTNERS = {
//   "ALL": "ALL", 
//   "STOCKAL" : "Stockal", 
//   'HDFC' : "HDFC",
//   'STOCUBE' : "Cube",
//   "CREDENCE": "Credence",
//   "WINRICH": "Winrich",
//   "DOLLAR_INVESTING": "Dollar Investing",
//   "RANDOMDIMES": "Randomdimes",
//   "ORACLE": "Oracle"
// }

export const PARTNERS = [ ...store.getState().common && store.getState().common.partnerCodes ];

export const getPlans = () => {
  const plansObj = store.getState().common.plans;
  const plans = {};
  _.forEach(plansObj, (plan, i) => {
    plans[plan.planId] = plan.planName;
  });
  return plans;
};


export const returnPlansObj = (plansArr) => {
  const allPlans = getPlans();
  const myPlans = {};
  plansArr.forEach((plan) => {
    myPlans[plan] = allPlans[plan];
  });
  return myPlans;
}

export const CLIENTS = []
PARTNERS && PARTNERS.length > 0 && PARTNERS.map((partner) => {
  if(partner.code !== "STOCKAL"){
    CLIENTS.push(partner.code)
  }
}) 

export const getClientId = ({roleId, clientCode}) => {
    return roles[String(roleId)] === "client" ? clientCode : "STOCKAL"
}

//  get partner options to select for STOCKAL
export const getPartnerOptions = ({roleId, clientCode, partnerCodes}) => {
  let partners =[{code: "ALL", name: "All"}];
  let PARTNERS;
  if (partnerCodes) {
     PARTNERS = partnerCodes;
  } else {
    PARTNERS = [ ...store.getState().common && store.getState().common.partnerCodes ];
  }
  PARTNERS && PARTNERS.length > 0 && PARTNERS.map(({code, name}) => partners.push({code, name}));
  return roles[String(roleId)] && roles[String(roleId)].toLowerCase() === "client" ? 
    PARTNERS && PARTNERS.filter((partner) => partner.code === clientCode) 
    : 
    partners;
}

export const getMyPlans = (plans) => {
  const PLANSOBJ = {};
  if (plans.length > 0) {
    plans.forEach((plan) => {
      PLANSOBJ[plan.planId] = plan.planName;
    });
  } else {
    // console.log('else', );
    const p = store.getState().common.plans;
    // console.log(p);
    p.forEach((plan) => {
      PLANSOBJ[plan.planId] = plan.planName;
    });
  }
  
  return PLANSOBJ;
}
// plans
export const PLANS = {
  "sub13999": "GOLD",
  "sub3333": "SILVER",
  "pay-per-trade": 'BASIC',
  // "sub500": 'BASIC',
  "PREMIER": "PREMIER",
  "BASIC_USD": "BASIC",
  "SILVER_USD": "SILVER",
  "GOLD_USD": "GOLD",
}
export const PLANS1 = {
    "sub13999": "GOLD",
    "sub3333": "SILVER",
    "pay-per-trade": 'BASIC',
    "PREMIER": "PREMIER",
    "BASIC_USD": "BASIC USD",
    "SILVER_USD": "SILVER USD",
    "GOLD_USD": "GOLD USD",
}

export const TRANSACTIONTYPES = [
  { key: 'CSR', value: 'A deposit' },
  { key: "CSD",	value: "A withdrawal", },
  { key: "FEE",	value: "A fee was deducted from the account. Fee types can include AUM and withdrawal fees", },
  { key: "JNLC",	value: "A cash journal was made from one account to another", },
  { key: "CADJ",	value: "A credit adjustment was made, generally used for returned ACH transactions", },
  { key: "INT",	value: "An interest adjustment", },
  { key: "SPUR",	value: "A security (stock/ETF/ADR) was bought", },
  { key: "SSAL",	value: "A security (stock/ETF/ADR) was sold", },
  { key: "MERGER_ACQUISITION",	value: "A merger or acquisition has taken place", },
  { key: "DIV",	value: "A dividend payment", },
  { key: "DIVTAX",	value: "A tax withholding on a dividend", },
  { key: "DIVNRA",	value: "A tax withholding on a dividend for a non-resident alien", },
  { key: "STCK",	value: "A stock price adjustment", },
  { key: "COMM",	value: "A commission", },
  { key: "SPINOFF",	value: "Stock spinoff", },
  { key: "STOCK_SPLIT",	value: "Stock split/reverse stock split", },
  { key: "ACATS_CASH",	value: "ACATS cash delivery or receive", },
  { key: "ACATS_STOCK",	value: "ACATS stock delivery or receive", }
]

//  get plans according to client
export const getPlanOptions = ({roleId, clientCode}) => {
  let plans = _.keys(PLANS)
  // return roles[String(roleId)] && roles[String(roleId)].toLowerCase() === "client" && clientCode === "HDFC" ? ["sub500", "sub13999", "pay-per-trade"] : plans;
  return plans;
}

//  document status

export const getDocumentStatus = ({statusCode}) => {
  switch(statusCode) {
    case undefined: return '';
    case null: return '';
    case 1: return "PENDING";
    case 2: return "APPROVED"; 
    case 3: return "REJECTED";
    default: return "OTHER"
  }
}

export const getTIMEZONE = (date) => {
  let tz = store.getState().common && store.getState().common.tz;
  switch(tz) {
    case 'Asia/Kolkata': return date.subtract(19800, 'seconds').format(); break;
    case 'Asia/Qatar':  
    case 'Asia/Riyadh' :
    case 'Asia/Bahrain' :
    case 'Asia/Kuwait' :
    case 'Asia/Aden' : return moment.utc(date).subtract(10800, 'seconds').format(); break;
    case 'Asia/Muscat' :
    case 'Asia/Dubai' : return date.subtract(14400,'seconds').format(); break;
    default: return date.format(); 
  }
}

export const TIMEZONES = [
  {value: 'Asia/Kolkata', name: 'Indian Standard Time', timeDifference: 19800},
  {value: 'Asia/Bahrain' ,name:"Bahrain"},
  {value: 'Asia/Qatar', name: 'Qatar', timeDifference: 10800},
  {value: 'Asia/Riyadh' ,name:"Saudi Arabia"},
  {value: 'Asia/Kuwait' ,name:"Kuwait"},
  {value: 'Asia/Muscat' ,name:"Oman"},
  {value: 'Asia/Dubai' ,name:"United Arab Emirates"},
  {value: 'Asia/Aden' ,name:"Yemen"},
  {value: 'utc', name: 'UTC', timeDifference: 0},
]

export const viewIntoSpecificTimeZone = (date, tz = store.getState().common && store.getState().common.tz) => {
 if(tz === 'utc') return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')
 else return moment(date).tz(tz).format('YYYY-MM-DDTHH:mm:ss')
}

export const dateToString = (date) => {
  if (typeof date === 'object') {
    const d = viewIntoSpecificTimeZone(date);
    // console.log(d)
    return d;
  } else {
    return moment(viewIntoSpecificTimeZone(new Date(date))).format('YYYY-MM-DD');
  }
  
}

export const dateTimeToString = (date) => {
  return moment(viewIntoSpecificTimeZone(new Date(date))).format('YYYY-MM-DDTHH:mm:ss');
}

export const getSubscriptionName = (planID) => {
  return PLANS1[planID] || '';
}

export const partnerStatus = [
  'active',
  'inactive'
]

export const commentCategory = {
  lrs: 'LRS',
  user: 'User',
  payment: 'Payment',
  kyc: 'KYC',
  trade: 'Trade'
}