
import React from 'react'
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts';



// export const Graph = ({categories, data}) => {
//   const config = {
//     chart: {
//       polar: true
//     },
//     xAxis: {
//       categories
//     },
//     series: [{
//       data
//     }]
//   };
  
//   return (
//     <ReactHighcharts config = {config}></ReactHighcharts>
//   )
// }

export const ColoumnChart = ({data}) => {
    const config = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Daily Report'
        },
        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Users(Number)'
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<b>{point.y:1f}</b>'
        },
        series: [{
            data,
            dataLabels: {
                enabled: true,
                // rotation: -0,
                color: '#FFFFFF',
                align: 'right',
                format: '{point.y:1f}', // one decimal
                // y: 5, // 10 pixels down from the top
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        }]
    }
    return (
        <ReactHighcharts config = {config}></ReactHighcharts>
    )
} 



// export const PieChart = ({data}) => {
//   console.log("config_data ", data)
//   const config = {
//     chart: {
//         plotBackgroundColor: null,
//         plotBorderWidth: null,
//         plotShadow: false,
//         type: 'pie'
//     },
//     title: {
//         text: 'Daily Report'
//     },
//     tooltip: {
//         pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
//     },
//     plotOptions: {
//         pie: {
//             allowPointSelect: true,
//             cursor: 'pointer',
//             dataLabels: {
//                 enabled: true,
//                 format: '<b>{point.name}</b>: {point.percentage:.1f} %'
//             }
//         }
//     },
//     series: [{
//         name:"Data",
//         colorByPoint: true,
//         data: data
//     }]
//   }
//   return (
//     <ReactHighcharts config = {config}></ReactHighcharts>
//   )
// }

export const BasicBarChart = ({categories, data}) => {
    const config = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: '30 Days Data'
        },
        subtitle: {
            // text: 'Source: WorldClimate.com'
        },
        xAxis: {
            categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'No. of Users'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y} </b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: data
    }
    return (
        <ReactHighcharts config = {config}></ReactHighcharts>
      )
}