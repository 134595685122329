import axios from "axios"
import {API_ENDPOINT , API_ENDPOINT_V2, SECRET_KEY} from 'Redux/apiConstant'
import {GET_ORDER_REPORT,
    GET_ORDER_REPORT_SUCCESS, GET_ORDER_REPORT_FAIL, GET_ORDER_REPORT_RESET,
    GET_ORDER_REPORT_SEARCH,
    GET_STACK_REPORT,
    GET_STACK_REPORT_SUCCESS,
    GET_STACK_REPORT_FAIL,
    GET_STACK_REPORT_RESET,
    GET_ALL_ORDER_DETAIL, GET_ALL_ORDER_SUCCESS, GET_ALL_ORDER_REPORT_FAIL, GET_ALL_ORDER_REPORT_RESET,
    GET_ALL_STACK_DETAIL, GET_ALL_STACK_SUCCESS, GET_ALL_STACK_REPORT_FAIL, GET_ALL_STACK_REPORT_RESET

} from "../type"
import { isArray } from "highcharts"
import moment from "moment"
// new api v2
// export const getOrderDetail = (start, end, userId, adminId) => {
//     console.log('user',userId)
//     return (dispatch) => {
//         dispatch({ type: GET_ORDER_REPORT, payload: ''});
//         const config = {  headers : {
//             'stockal-secret-key': SECRET_KEY,
//             adminid : adminId
//         }};
//         const url = `${API_ENDPOINT_V2}/dashboard/order?from=${start}&to=${end}&direction=desc&page=1&pageSize=10&partnerCode=${userId}`;
//         axios.get(url, config ).then((res) => {
//             console.log('res',res)
//             if (res.data.code === 200) {
//                 dispatch({ type: GET_ORDER_REPORT_SUCCESS, payload: res.data.data });
//             } else {
//                 dispatch({ type: GET_ORDER_REPORT_FAIL, payload: [] });
//                 setTimeout(() => {
//                     dispatch({ type: GET_ORDER_REPORT_RESET, payload: [] });
//                 }, 15000)
//             }
//         }).catch((err) => {
//             dispatch({ type: GET_ORDER_REPORT_FAIL, payload: [] });
//             setTimeout(() => {
//                 dispatch({ type: GET_ORDER_REPORT_RESET, payload: [] });
//             }, 5000)
//         })
//     };
// } 


// old v1 order API

export const getOrderDetail = (start, end, partnerId, filterType, userId) => {
    return (dispatch) => {
        dispatch({ type: GET_ORDER_REPORT, payload: ''});
        const config = {  headers : {
            'stockal-secret-key': SECRET_KEY,
            'adminid' : userId
        }};
        let url;
        if(partnerId !== "ALL")
            url = `${API_ENDPOINT_V2}/dashboard/order?from=${moment(start).format('YYYY-MM-DD')}&to=${moment(end).format('YYYY-MM-DD')}&sortDirection=-1&partnerCode=${partnerId}&filterType=${filterType}`;
        else
            url = `${API_ENDPOINT_V2}/dashboard/order?from=${moment(start).format('YYYY-MM-DD')}&to=${moment(end).format('YYYY-MM-DD')}&sortDirection=-1&filterType=${filterType}&partnerCode=All`;
        axios.get(url, config).then((res) => {
            if (res.data.code === 200) {
                const orders = res.data.data 
                if (isArray(orders) && orders.length > 0) {
                    orders.map(order => {
                        if(order.category === 'stack')
                            Object.assign(order, {isExpanded: false});
                        return order;
                    });
                }
                dispatch({ type: GET_ORDER_REPORT_SUCCESS, payload: orders });
            } else {
                dispatch({ type: GET_ORDER_REPORT_FAIL, payload: [] });
                setTimeout(() => {
                    dispatch({ type: GET_ORDER_REPORT_RESET, payload: [] });
                }, 15000)
            }
        }).catch((err) => {
            dispatch({ type: GET_ORDER_REPORT_FAIL, payload: [] });
            setTimeout(() => {
                dispatch({ type: GET_ORDER_REPORT_RESET, payload: [] });
            }, 15000)
        })
    };
}


export const getStacks = (start, end, partnerId, userId) => {
    return (dispatch) => {
        dispatch({ type: GET_STACK_REPORT, payload: ''});
        const config = {  headers : {
            'stockal-secret-key': SECRET_KEY,
            'adminid' : userId
        }};
        let url;
        if(partnerId !=='ALL')
            url = `${API_ENDPOINT_V2}/dashboard/order?from=${moment(start).format('YYYY-MM-DD')}&to=${moment(end).format('YYYY-MM-DD')}&sortDirection=-1&partnerCode=${partnerId}&filterType=created&onlyStacks=true`;
        else
            url = `${API_ENDPOINT_V2}/dashboard/order?from=${moment(start).format('YYYY-MM-DD')}&to=${moment(end).format('YYYY-MM-DD')}&sortDirection=-1&filterType=created&onlyStacks=true&partnerCode=All`;
        axios.get(url, config).then((res) => {
            if (res.data.code === 200) {
                const stacks = res.data.data;
                if (isArray(stacks) && stacks.length > 0) {
                    stacks.map(stack => {
                        Object.assign(stack, {isExpanded: false});
                        return stack;
                    });
                }
                dispatch({ type: GET_STACK_REPORT_SUCCESS, payload: stacks });
            } else {
                dispatch({ type: GET_STACK_REPORT_FAIL, payload: [] });
                setTimeout(() => {
                    dispatch({ type: GET_STACK_REPORT_RESET, payload: [] });
                }, 15000)
            }
        }).catch((err) => {
            dispatch({ type: GET_STACK_REPORT_FAIL, payload: [] });
            setTimeout(() => {
                dispatch({ type: GET_STACK_REPORT_RESET, payload: [] });
            }, 5000)
        })
    };
}
export const getOrderDetailBySearch = ({term,search_term}) => {
    return (dispatch) => dispatch({type:GET_ORDER_REPORT_SEARCH,payload:{term:term,search_term:search_term}})
}

// export const getAllOrderDetail = async ({clickUserAccount}) => {
//     const config = {  headers : {
//         'stockal-secret-key': SECRET_KEY,
//         'q' : clickUserAccount
//     }};
//     const url = `${API_ENDPOINT_V2}/trades/orders/`
//     try{
//         let response = await axios.get(url, config)
//         if (response.data.code === 200){
//            return response
//         }
//         else
//            throw response
//     }
//     catch (e) {
//         // console.log('e',e)
//         alert('no account found')
//     }
// }

export const getAllOrderDetail = (userId, clickUserId) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_ORDER_DETAIL, payload: ''});
        const config = {  headers : {
            'stockal-secret-key': SECRET_KEY,
            'adminid': userId
        }};
        const url = `${API_ENDPOINT_V2}/dashboard/order?q=${clickUserId}`;
        axios.get(url, config ).then((res) => {
            if (res.data.code === 200) {
                dispatch({ type: GET_ALL_ORDER_SUCCESS, payload: res.data.data });
            } else {
                dispatch({ type: GET_ALL_ORDER_REPORT_FAIL, payload: [] });
                setTimeout(() => {
                    dispatch({ type: GET_ALL_ORDER_REPORT_RESET, payload: [] });
                }, 3000)
            }
        }).catch((err) => {
            dispatch({ type: GET_ALL_ORDER_REPORT_FAIL, payload: [] });
            setTimeout(() => {
                dispatch({ type: GET_ALL_ORDER_REPORT_RESET, payload: [] });
            }, 3000)
        })
    };
}

export const getAllStackDetail = (userId, clickUserId) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_STACK_DETAIL, payload: ''});
        const config = {  headers : {
            'stockal-secret-key': SECRET_KEY,
            'adminid': userId
        }};
        const url = `${API_ENDPOINT_V2}/dashboard/order?q=${clickUserId}&onlyStacks=true`;
        axios.get(url, config ).then((res) => {
            if (res.data.code === 200) {
                console.log('response',res.data.data)
                dispatch({ type: GET_ALL_STACK_SUCCESS, payload: res.data.data });
            } else {
                dispatch({ type: GET_ALL_STACK_REPORT_FAIL, payload: [] });
                setTimeout(() => {
                    dispatch({ type: GET_ALL_STACK_REPORT_RESET, payload: [] });
                }, 3000)
            }
        }).catch((err) => {
            dispatch({ type: GET_ALL_STACK_REPORT_FAIL, payload: [] });
            setTimeout(() => {
                dispatch({ type: GET_ALL_STACK_REPORT_RESET, payload: [] });
            }, 3000)
        })
    };
}