

import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import './App.css';
import {Routes} from './routes';


export const App = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={Routes} />
    </BrowserRouter>
  )
}