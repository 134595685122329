

import moment from 'moment'
import React, { Component } from 'react'

import Loader from 'react-loader'
import _ from 'lodash'
import { connect } from 'react-redux'

import { DateFilter } from 'utils/DateFilter'
import { getCustomerReport } from 'Redux/actions/export'
import { BasicBarChart, ColoumnChart } from './Graph'
import { getClientId, getMyPlans, getPlanOptions, getDocumentStatus, getTIMEZONE, PLANS } from 'utils/common'

// const plan = ["sub13999","sub500"]

class UserAnalytics extends Component {

  _isMounted = false

  constructor(props){
    super(props);
    this.state = {
      is_loaded: true,
      is_loaded_monthly: false,
      is_submitted: false,
      end_date: new Date(),
      partnerId: '',
      start_date: new Date(),
      error_message: "",
      error_message_monthly: "",
      is_partner_id_change: false,
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.setState({
      partnerId: getClientId(this.props.auth)
    }, () => {
      this.customerReportAnanlytics()
      this.customerReportAnanlyticsLastMonth()
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  onChangeDate = (name,date) => {
      this.setState({[name]:date})
  }

  onChange = (e) => {
    let name = e.target.name
    if (name === "partnerId") {
      this.setState({
        is_partner_id_change: true
      })
    }
    this.setState({[name]: e.target.value})

  }

  onSubmit = () => {
    let { is_partner_id_change } = this.state
    this.customerReportAnanlytics()
    if (is_partner_id_change) {
      this.customerReportAnanlyticsLastMonth()
    }
  }

  customerReportAnanlytics = () => {
    let { start_date , end_date, partnerId } = this.state
    this.setState({
      is_loaded: false, 
      is_submitted: true,
      data:[],
    })
    let {tz} = this.props.common;
    this.props.getCustomerReport({
      start_date: start_date && getTIMEZONE(moment.utc(start_date).startOf('day')),
      end_date: end_date && getTIMEZONE(moment.utc(end_date).endOf('day')),
      partnerId
    })
    .then((data) => {
      this.setState({
        is_loaded: true,
        data,
      })
    })
    .catch((err) => {
      this.setState({
        is_loaded:  true,
        error_message: err
      })
    })
  }

  customerReportAnanlyticsLastMonth = () => {
    this.setState({
      is_loaded_monthly: false, 
      is_submitted: true, 
      is_partner_id_change: false,
      dataMonthWise: []
    })
    this.props.getCustomerReport({
      start_date: moment(moment().subtract(30, 'days')).format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      partnerId: this.state.partnerId
    })
    .then((dataMonthWise) => {
      dataMonthWise.map((data) => {
        if (data["Date"]) 
          data["Date"]= moment(data["Date"]).format("DD-MM-YYYY")
        })
      this.setState({
        // is_loaded_monthly: true,
        dataMonthWise,
      })
    })
    .catch((err) => {
      this.setState({
        // is_loaded_monthly:  true,
        error_message_monthly: err
      })
    })
  }
  
  render() {
   let {
     data,
     dataMonthWise,
     is_loaded,
     is_loaded_monthly,
     is_submitted,
     end_date,
     partnerId,
     start_date,
   } = this.state
  
   let plan = getPlanOptions(this.props.auth)
    if (data && data.length > 0) {
      var config_data = []
      var total = data.length
      var basic =  (_.filter(data, (key) => key.plan === "sub500" || key.plan === "pay-per-trade")).length
      var silver =  (_.filter(data, (key) => key.plan === "sub3333")).length
      var gold =  (_.filter(data, (key) => key.plan === "sub13999")).length
      
      var lrs_initiated = (_.filter(data, (key) => key.lrsSubmitted && key.lrsSubmitted === "Done")).length
      var lrs_approved = (_.filter(data, (key) => getDocumentStatus({statusCode:key.idStatus}) === "APPROVED" && getDocumentStatus({statusCode:key.addressProofStatus}) === "APPROVED")).length
      var lrs_rejected = (_.filter(data, (key) => getDocumentStatus({statusCode:key.idStatus}) === "REJECTED" || getDocumentStatus({statusCode:key.addressProofStatus}) === "REJECTED")).length

      config_data.push(
        ['Total', data.length],
        ['Basic', basic],
        ['Gold', gold],
        ['Silver', silver],
        // ['Lrs Initiated', lrs_initiated],
        ['KYC Approved', lrs_approved],
        ['KYC Rejected', lrs_rejected],
      )
      // if (getClientId(this.props.auth) !== "HDFC") {
      //   console.log("in if ", config_data)
      //   config_data.splice(2,0,['Silver', silver])
      // }
  
    }
    
    if (dataMonthWise && dataMonthWise.length > 0) {
      var config_month = []       
      config_month = _(dataMonthWise).groupBy("Date")
      .map((data, date) => {
        return {
          Date: date,
          no_of_users: data.length,
          lrs_initiated:(_.filter(data, (key) => key.lrsSubmitted && key.lrsSubmitted === "Done")).length,
          approved: (_.filter(data, (key) => getDocumentStatus({statusCode:key.idStatus}) === "APPROVED" && getDocumentStatus({statusCode:key.addressProofStatus}) === "APPROVED")).length,
          rejected: (_.filter(data, (key) => getDocumentStatus({statusCode:key.idStatus}) === "REJECTED" || getDocumentStatus({statusCode:key.addressProofStatus}) === "REJECTED")).length
        }
      }).value()
    }

    // console.log("config_data ", config_data)
    const p = getMyPlans(this.props.common.plans);
    return (
      <div>
        <DateFilter 
          start_date={start_date}
          end_date={end_date}
          partnerId={partnerId}
          onChangeDate={this.onChangeDate}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          {...this.props}
        />
        <br/>  
        {
          is_submitted ?
          <React.Fragment>
              <div className="row">
                  <div className="col-md-4">
                  <div className="card border-left-primary shadow py-2">
                    <div className="card-body">
                    <Loader loaded={is_loaded}>
                      {
                        data && data.length > 0 ?
                    <div className="row no-gutters align-items-center">
                      <div className="col md-6">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">TOTAL USERS </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{total}</div>
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">LRS Initiated </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{lrs_initiated}</div>
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Accounts Approved </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{lrs_approved}</div>
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Accounts Rejected </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{lrs_rejected}</div>
                      </div>
                      <div className="col md-6">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Plans </div>
                        {
                            plan && plan.map((p, index) => {
                              const plns = this.props.common.plans.length > 0 ? this.props.common.plans.find((pls) => pls.planId === p) : ''
                              return (
                                <React.Fragment key={index}>
                                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{plns.planName}({plns.currency})</div>
                                  <div className="h5 mb-0 font-weight-bold text-gray-800">{(_.filter(data, (key) => key.plan === p)).length}</div>
                                </React.Fragment> 
                              )
                            })
                        }
                      </div>
                    </div> : <h2 style={{textAlign: 'center'}}>{this.state.error_message}</h2>  
                      }
                    </Loader>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card">
                  <div className="card-body">
                    {/* <Graph 
                      categories={["Total Users", "Basic", "Gold", "LRS Initiated", "LRS Approved", "LRS Rejected"]}
                      data={[total, basic, gold, lrs_initiated, lrs_approved, lrs_rejected]}
                    /> */}
                    <Loader loaded={is_loaded}>
                      {
                        data && data.length > 0 ?  
                        <ColoumnChart data={config_data}/> 
                        : 
                        
                        <h2 style={{textAlign: 'center'}}>{this.state.error_message}</h2>  
                      }
                     
                    </Loader>
                  </div>
                  </div>
              </div>
            </div>
              {/* Moved the Monthly Bar chart to end of page */}
          
      </React.Fragment> : null
    }
  </div>
    )
  }
}

const mapStateToProps = ({auth, common}) => {
  return {  
    auth, common
  }
}

export default connect(mapStateToProps, { getCustomerReport })(UserAnalytics)


// {/* <Loader loaded={is_loaded_monthly}> 
//       <br />
//         <div className="row">
//           <div className="col-md-12">
//            <div className="card">
//             <div className="card-body">
//               <Loader loaded={is_loaded_monthly}>
              // {/* <Graph 
              //   categories={["Total Users", "Basic", "Gold", "LRS Initiated", "LRS Approved", "LRS Rejected"]}
              //   data={[total, basic, gold, lrs_initiated, lrs_approved, lrs_rejected]}
              // /> */}
      //         {
      //          this.state.dataMonthWise && this.state.dataMonthWise.length > 0 ? 
      //           <BasicBarChart 
      //             categories={_.map(config_month, (data) => data.Date)}
      //             data={
      //               [
      //                 {name: "Total Users", data: _.map(config_month, (data) => data.no_of_users)},
      //                 {name: "Lrs", data: _.map(config_month, (data) => data.lrs_initiated)},
      //                 {name: "Approved", data: _.map(config_month, (data) => data.approved)},
      //                 {name: "Rejected", data: _.map(config_month, (data) => data.rejected)}
      //               ]
      //           }
      //           /> : <h2 style={{textAlign: 'center'}}>{this.state.error_message}</h2>  
      //         }
      //           </Loader>
      //       </div>
      //       </div>
      //   </div>
      //   </div>
      // </Loader> */}