import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'

const tableHeaders = {
    rows: 2,
    nodes: {
        'user-kpi': { text: 'User KPI&quote;s' },
        'total-users': { text: 'Total Users' },
        'paid-users': { text: 'Paid Users', children: ['Gold', 'Silver', 'Basic']},
        'kyc': { text: 'KYC', children: ['Approvals', 'Rejections']}
    },
};
class LandingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            splitView: false
        };
    }

    renderCustomerKPI() {
        if (Object.keys(this.props.customerKPI).length > 0) {
            return _.map(this.props.customerKPI, (kpi) => {
                return (<tr>
                        <td className="text-left color-black align-middle ">{ kpi.name}</td>
                        <td className="color-black align-middle text-center">{kpi.total}</td>
                        <td className="color-black align-middle text-center">{kpi.gold}</td>
                        <td className="color-black align-middle text-center">{kpi.silver}</td>
                        <td className="color-black align-middle text-center">{kpi.basic}</td>
                        <td className="color-black align-middle text-center">{parseInt(kpi.gold) + parseInt(kpi.silver) + parseInt(kpi.basic)}</td>
                        <td className="color-black align-middle text-center">{kpi.kycApproved}</td>
                        <td className="color-black align-middle text-center">{kpi.kycRejected || 0}</td>
                </tr>);
            });
        } else {
            return (<tr>
                <td colSpan="8" className="color-black align-middle text-center">
                    <strong>{ this.props.customerKPIMessage }</strong>
                </td>
            </tr>)
        }
    }


    renderBusinessKPI() {
        if (Object.keys(this.props.businessKPI).length > 0) {
            return _.map(this.props.businessKPI, (kpi) => {
                return (<tr>
                        <td className="text-left color-black align-middle">{kpi.name}</td>
                        <td className="color-black align-middle text-center">{kpi.fundTransafterCount}</td>
                        <td className="color-black align-middle text-center">{kpi.totalAum}</td>
                        <td className="color-black align-middle text-center">{kpi.lrsInitiate}</td>
                        <td className="color-black align-middle text-center">{kpi.lrsApproved}</td>
                        <td className="color-black align-middle text-center">{kpi.lrsRejected}</td>
                        <td className="color-black align-middle text-center">{kpi.lrsPending}</td>
                </tr>);
            });
        } else {
            return (<tr>
                <td colSpan="8" className="color-black align-middle text-center">
                    <strong>{ this.props.businessKPIMessage }</strong>
                </td>
            </tr>)
        }
    }

    render() {
        const {splitView} = this.state;
        return (<Fragment>
            <div className="row mt-4">
                <div className="col-12">
                    <button className="btn landing-header-btn float-right" onClick={() => { this.setState({splitView: !splitView })}}>Split | View</button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                <table className={`table table-bordered ${splitView? 'splitView mr-1': ''} `}>
                    <thead>
                        <tr>
                        <th className="text-center text-wrap align-middle selectedActive" scope="col" rowSpan="2"><strong>User KPIs</strong></th>
                        <th className="text-center text-wrap align-middle color-black" scope="col" rowSpan="2"><strong>Total Users</strong></th>
                        <th className="text-center align-middle color-black" scope="col" colSpan="4">Paid Users</th>
                        <th className="text-center align-middle color-black" scope="col" colSpan="2">KYC</th>
                        </tr>
                        <tr>
                        <th scope="col" className="color-black align-middle text-center">Gold</th>
                        <th scope="col" className="color-black align-middle text-center">Silver</th>
                        <th scope="col" className="color-black align-middle text-center">Basic</th>
                        <th scope="col" className="color-black align-middle text-center">Total</th>
                        <th scope="col" className="color-black align-middle text-center">Approvals</th>
                        <th scope="col" className="color-black align-middle text-center">Rejections</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderCustomerKPI() }
                    </tbody>
                </table>

                <table className={`table table-bordered ${splitView? 'splitView': ''} `}>
                    <thead>
                        <tr>
                        <th className="color-black text-center text-wrap align-middle selectedActive" scope="col" rowSpan="2"><strong>Business KPIs</strong></th>
                        <th className="color-black text-center text-wrap align-middle" scope="col" rowSpan="2"><strong># of Fund Transfers</strong></th>
                        <th className="color-black text-center text-wrap align-middle" scope="col" rowSpan="2"><strong>Total Funds</strong></th>
                        <th className="color-black text-center align-middle" scope="col" colSpan="4">LRS</th>
                        
                        </tr>
                        <tr>
                        <th scope="col" className="color-black align-middle text-center">Initiated</th>
                        <th scope="col" className="color-black align-middle text-center">Approved</th>
                        <th scope="col" className="color-black align-middle text-center">Rejected</th>
                        <th scope="col" className="color-black align-middle text-center">Pending</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderBusinessKPI() }
                    </tbody>
                </table>
                </div>

            </div>
        </Fragment>);
    }
}

const mapStateToProps = (state) => {
    const { landing } = state;
    return { ...landing }
}

export default connect(mapStateToProps, null)(LandingTable)