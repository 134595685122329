import React, { Component, Fragment } from 'react';
import BasicDetails from './Dw/BasicDetails';
import PersonalDetails from './Dw/PersonalDetails';
import AddressDetails from './Dw/AddressDetails';
import IdentificationDetails from './Dw/IdentificationDetails';
import EmploymentDetails from './Dw/EmploymentDetails';
import InvestingDetails from './Dw/InvestingDetails';
import AccountDetails from './Dw/AccountDetails';
import UploadDocuments from './Dw/UploadDocuments';
import { getDocumentStatus } from 'utils/common';
import _ from 'lodash';


  export default class DWDetailsNew extends Component {
      render() {
          return (<Fragment>
              <BasicDetails {...this.props} getDetails = {this.props.getDetails}/>
              <PersonalDetails {...this.props} getDetails = {this.props.getDetails}/>
              <AddressDetails {...this.props} getDetails = {this.props.getDetails}/>
              <IdentificationDetails {...this.props} getDetails = {this.props.getDetails}/>
              <EmploymentDetails {...this.props} getDetails = {this.props.getDetails}/>
              <InvestingDetails {...this.props} getDetails = {this.props.getDetails}/>
              <AccountDetails {...this.props} getDetails = {this.props.getDetails}/>
              <UploadDocuments {...this.props} getDetails = {this.props.getDetails}/>
          </Fragment>);
      }
  }