import React from "react"

function LogInfo(){
    return(
        <div>
            <div id="accordion">
                <div className="card">
                        <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Log Info
                                </button>
                            </h5>
                        </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div >
                        <table className="table">
                            <tr>
                                <th scope="row">Name</th>
                                <td>Apple Gupta</td>  
                                <th>ID</th>
                                <td>#123</td>  
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td>Ishan6380@gmail.com</td>
                                <th>Status</th>
                                <td>Online</td>
                            </tr>
                            <tr>
                                <th>Plan Type</th>
                                <td>Premium</td>
                                <th>Created Date</th>
                                <td>19/03/2019</td>
                            </tr>
                            <tr>
                                <th>Contact Number</th>
                                <td>8755140457</td>
                                <th>Location</th>
                                <td>Banglore</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    );
}


export default LogInfo