import React from 'react'
import DatePicker from 'react-datepicker';


export default class CustomDate extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            showDatePickers: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.custom && !state.showDatePickers) {
            return { showDatePickers: true };
        }
        else {
            return {showDatePickers: false};
        }
    }


    handleClick() {
        this.setState({ showDatePickers: false });
    }
      
    
    
    render() {
        return (<React.Fragment>
            <div className={`btn-group mx-auto`} role="group">
                        <div id="btnGroupDrop1" type="button" className={`btn landing-header-btn btn-light active ${this.props.custom? 'selectedActive': ''}`} 
                             aria-haspopup="true" aria-expanded="false" onClick= { this.props.onClicked}>
                            {this.props.dateText}
                        </div>
                            <div className={this.state.showDatePickers? 'dropdown-menu show': 'dropdown-menu hide'} 
                                aria-labelledby="btnGroupDrop1">
                                <div className="landing-dropdown-menu">
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd'
                                        onChange={(date) => this.props.changeDates('from', date)}
                                        selected={this.props.from}
                                        speekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        placeholderText="Select Start Date"
                                        className="datePickerExtension"
                                        maxDate={new Date()}
                                        minDate={new Date('2019-08-01')}
                                    />
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd'
                                        onChange={(date) => { 
                                                this.props.changeDates('to', date); 
                                                this.handleClick(); 
                                            }
                                        }
                                        selected={this.props.to}
                                        speekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        placeholderText="Select End Date"
                                        maxDate={new Date()}
                                        minDate={this.props.from}
                                    />
                                </div>
                            </div>
                        
                    </div>
        </React.Fragment>);
    }
}