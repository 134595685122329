

import React, { Component } from 'react'
import moment from 'moment'
import { goToLRSDetails } from 'utils/navigations'

export default class LrsDetails extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-header">LRS </div>
        <div className="card-body" style={{overflow: 'auto'}}>
          {
            this.props.lrs && this.props.lrs.length > 0 ?
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Initiated Date</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Amount ($)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Pick Up Date</th>
                    <th scope="col">PickUpTime</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.lrs.map((data,index) => {
                      if (data && data.requestId) {
                        var {
                          createdAt,
                          lrsBankName,
                          fixedFCAmt,
                          status,
                          pickUpDate,
                          pickUpTime,
                          requestId
                        } = data
                      }
                      return (
                        <tr key={index}>
                          <th scope="row">1</th>
                          <td>{createdAt && moment(createdAt).format("YYYY-MM-DD")}</td>
                          <td>{lrsBankName}</td>
                          <td>{fixedFCAmt}</td>
                          <td>{status}</td>
                          <td>{pickUpDate}</td>
                          <td>{pickUpTime}</td>
                          <td><button className="btn btn-secondary" onClick={() => goToLRSDetails(requestId)}> View More Details</button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            : null
          }
        </div>
      </div>
    )
  }
}
