

import React, { Component } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Loader from 'react-loader'
import { getCommentsByCategoryV2 } from 'Redux/actions/comments'
import moment from 'moment';


export default class Comments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commentList: [],
      is_loaded: false,
    }
  }

  componentDidMount = () => {
    this.getCommentsApi()
  }

  getCommentsApi = () => {
    let { category, categoryID, userId} = this.props.match.params
    console.log("this.props.match.params ", this.props.match.params)
    getCommentsByCategoryV2({
      adminId: this.props.auth.id,
      category,
      categoryID
    })
    .then((response) => {
      this.setState({
        // commentList: response.docs.sort((a, b) => Date.parse(new Date(b.createdAt.split("/").reverse().join("-")) - Date.parse(new Date(a.createdAt.split("/").reverse().join("-"))))),
        commentList: response.sort((a,b) =>  moment(b.createdAt).diff(a.createdAt)),
        is_loaded: true,
      })
    })
  }

  render() {
    let { commentList, is_loaded } = this.state
    let date = "2009-2010"
    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <label><h5><b>{this.props.match.params.name}</b></h5>({this.props.match.params.email})</label>
        </div>
        <Loader loaded={is_loaded}>
        {
          commentList && commentList.length > 0 ?
          <VerticalTimeline>
          {
            commentList.map((comments, index) => {
              return (
                <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                date={comments.createdAt && moment(comments.createdAt).format("DD-MM-YYYY hh:mm")}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
              >
                <span style={{color:'rgb(33, 150, 243)'}}>{comments.name}</span>
                <p className="verticalComment">
                  {comments.comment}
                </p>
              </VerticalTimelineElement>
              )
            })
          }
          
         </VerticalTimeline> : 
         <h2 style={{textAlign: 'center'}}>No Comments Have Been Added Yet.</h2>
        }
        </Loader>
      </div>
    )
  }
}
