


import axios from 'axios'
import {API_ENDPOINT,API_ENDPOINT_V2} from 'Redux/apiConstant'
import {getConfig,getConfigV2} from '../getConfig'
import { GET_CUSTOMER_REPORT,
   GET_USER_REPORT,
   GET_USER_REPORT_SUCCESS,
   GET_USER_REPORT_FAIL,
   GET_USER_REPORT_RESET,

   ADD_NEW_EXPORT,
   ADD_NEW_EXPORT_SUCCESS,
   ADD_NEW_EXPORT_FAIL,
} from 'Redux/type'


export const getCustomerReport = ({start_date, end_date, partnerId}) => {
  const url = `${API_ENDPOINT}/api/streams/dashboard/reports/${start_date}/${end_date}/${partnerId}`
   return (dispatch) => new Promise (async (resolve, reject) => {
         axios.get(url, getConfig())
         .then((response) => {
            if(response.data.status === 200) {
               dispatch({type:GET_CUSTOMER_REPORT, payload: response.data.docs})
               return resolve(response.data.docs)
            }
            else {
               return reject(response.data.error)
            }
         })
         .catch((err) => {
            return reject("Server Timeout")
         })
   })
}


export const getCustomerReportV1 = ({start_date, end_date, partnerId}) => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/reports/v1/${start_date}/${end_date}/${partnerId}`
    return (dispatch) => new Promise (async (resolve, reject) => {
          axios.get(url, getConfig())
          .then((response) => {
            //  console.log(response, response.status === 200);
            const string = response.data.replaceAll('}{', '}|{');
            const arr = string.split('|');
             if(response.status === 200) {
               // let arr = response.data.split('}');
               dispatch({type:GET_CUSTOMER_REPORT, payload: arr})
               return resolve(arr)
             }
             else {
                return reject(response.data.error)
             }
          })
          .catch((err) => {
             return reject("Server Timeout")
          })
   });
}


export const getCustomerReport1 = (start, end, partner, filter = 'Registration', skip = 0, limit = 0, key = '', value = '') => {
   return (dispatch) => {
      dispatch({ type: GET_USER_REPORT, payload: { skip, limit, filter }});
      let query = '';
      if (key !== '') {
         query = `key=${key}&value=${value}`;
      }
      const url = `${API_ENDPOINT}/api/streams/dashboard/reports/v2/${start}/${end}/${partner}?filter=${filter}&skip=${skip*limit}&limit=${limit}&${query}`;
      console.log(url);
      axios.get(url, getConfig()).then((res) => {
         if (res.data.status === 200) {
            dispatch({ type: GET_USER_REPORT_SUCCESS, payload: res.data.docs });
         } else {
            dispatch({ type: GET_USER_REPORT_FAIL, payload: [] });
            setTimeout(() => {
               dispatch({ type: GET_USER_REPORT_RESET, payload: [] });
           }, 15000)
         }

      }).catch((err) => {
         dispatch({ type: GET_USER_REPORT_FAIL, payload: []})
         setTimeout(() => {
            dispatch({ type: GET_USER_REPORT_RESET, payload: [] });
        }, 15000)
      });

   }

}


export const getCustomerReportV2 = (adminID,start, end, partner, filter = 'Registration', skip = 0, limit = 0, key = '', value = '',page='') => {
   return (dispatch) => {
      dispatch({ type: GET_USER_REPORT, payload: { skip, limit, filter }});
      let query = '';
      if (key !== '') {
         query = `key=${key}&value=${value}`;
      }
      const url = `${API_ENDPOINT_V2}/dashboard/reports?filterKey=${filter}&from=${start}&to=${end}&skip=${skip*limit}&page=${page}&limit=${limit}&${query}`;
      console.log(url);
      axios.get(url, getConfigV2(adminID)).then((res) => {
         console.log('export res action',res)
         if (res.data.code === 200) {
            dispatch({ type: GET_USER_REPORT_SUCCESS, payload: res.data.data });
         } else {
            dispatch({ type: GET_USER_REPORT_FAIL, payload: [] });
            setTimeout(() => {
               dispatch({ type: GET_USER_REPORT_RESET, payload: [] });
           }, 15000)
         }

      }).catch((err) => {
         dispatch({ type: GET_USER_REPORT_FAIL, payload: []})
         setTimeout(() => {
            dispatch({ type: GET_USER_REPORT_RESET, payload: [] });
        }, 15000)
      });

   }

}

export const getPayedCustomerReport = ({start_date, end_date, partnerId}) => {
  const url = `${API_ENDPOINT}/api/streams/dashboard/customer/report/payedusers/${start_date}/${end_date}/${partnerId}`
   return (dispatch) => new Promise (async (resolve, reject) => {
         axios.get(url, getConfig())
         .then((response) => {
            if(response.data.status === 200) {
               dispatch({type:GET_CUSTOMER_REPORT, payload: response.data.docs})
               return resolve(response.data.docs)
            }
            else {
               return reject(response.data.error)
            }
         })
         .catch((err) => {
            return reject("Server Timeout")
         })
   })
}

export const AddNewExportReport = (start, end, partner, userId, tz='utc', filter = 'registration') => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/export/${userId}`;
   const payload = {
      fromDate: start,
      toDate: end,
      partnerId: partner,
      filter: filter,
      tz: tz,
   }
   return axios.post(url, payload, getConfig());
}

export const getExportReport = (userId) => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/export/${userId}`;
   return axios.get(url, getConfig());
}

export const updateFollowUp = async (data) => {
  const url = `${API_ENDPOINT}/api/streams/dashboard/customer/report/follow/update`
  try{
    let response = await axios.post(url, data, getConfig())
    if (response.data.status === 200){
      return response.data
    }
    else
      throw response.data.status
  }
  catch (e) {
    alert(e)
  }
}

export const exportKYCReportStatus = async ({start_date, end_date, partnerId}) => {
   const url = `${API_ENDPOINT}/api/streams/dashboard/user/cutomer-list-with-kyc-status/?start-date=${start_date}&end-date=${end_date}`;
   try{
     const headers = getConfig();
     headers['headers']['partner-id'] = partnerId? partnerId : 'ALL';
    let response = await axios.get(url, headers)
    if (response.data.status === 200){
      return response.data
    }
    else
      throw response.data.status
  }
  catch (e) {
    return e;
  }
}
