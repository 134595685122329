import { API_ENV, SECRET_KEY } from './apiConstant';

export const getConfig = () => {
  return {
    headers: {
      'content-type': 'application/json',
      'stockal-secret-key': SECRET_KEY,
      'api-env': API_ENV,
    },
  };
};

export const getConfigV2 = (adminId) => {
  return {
    headers: {
      'content-type': 'application/json',
      'stockal-secret-key': SECRET_KEY,
      'adminid' : adminId
    },
  };
};


export const getConfigDashboard = ({ partnerId }) => {
  return {
    headers: {
      'content-type': 'application/json',
      'stockal-secret-key': SECRET_KEY,
      'partner-id': partnerId,
    },
  };
};
