
import { CLIENTS, getDocumentStatus, getPlanOptions} from './common';
import {viewIntoSpecificTimeZone} from 'utils/common';


const lrs_object = (data) => {
  return {
      "initiatedDate": data.createdAt ? viewIntoSpecificTimeZone(data.createdAt) : "",
      "requestId": data.requestId ? data.requestId : "",
      "applicantName": data.applicantName ? data.applicantName : "",
      "applicantPhone": data.applicantPhone ? data.applicantPhone : "",
      "applicantEmail": data.applicantEmail ? data.applicantEmail : "",
      "dwUsername": data.dwUsername ? data.dwUsername : "",
      "fixedFCAmt":data.fixedFCAmt ? data.fixedFCAmt : "",
      "lrsBankName":data.lrsBankName ? data.lrsBankName : "",
      "status": data.status ? data.status : "",
      "pickUpDate": data.pickUpDate ? viewIntoSpecificTimeZone(data.pickUpDate) : "",
      "pickUpTime": data.pickUpTime ? data.pickUpTime : "",
      "partner": CLIENTS.includes(data.partnerId) ? data.partnerId : "STOCKAL",
      "pickUpLocation": data.pickUpLocation ? data.pickUpLocation.replace(/,/g,'-') : "",
    // "postLRS": data.postLRS ? data.postLRS : "",
  }
}

const removeNull = (data)=>{
  if(data.substring(0,4)==='null')
    return data.substring(4);
  return data;
}

const kyc_status = (data) => {
  return {
    createdAt: data.createdAt ? data.createdAt:'',     
    fullname: data.fullname? data.fullname:'', 
    username: data.dwUsername ? data.dwUsername:'', 
    email: data.username ? data.username:'',
    phone: (data.phone && data.phone !== "") ? data.phone : (data.dw.length > 0 && data.dw[0].phoneHome) ? data.dw[0].phoneHome : '',
    idStatus: data.dw && data.dw.length > 0 && data.dw[0].idStatus ? getDocumentStatus({statusCode: data.dw[0]["idStatus"]}) :'',    
    addressProofStatus: data.dw && data.dw.length > 0 && data.dw[0].addressProofStatus ?getDocumentStatus({statusCode: data.dw[0]["addressProofStatus"]}) :'',
    accountStatus: data.dw && data.dw.length > 0 && data.dw[0].accountStatus ? getDocumentStatus({statusCode: data.dw[0]["accountStatus"]}) :'',
    idReason: data.dw && data.dw.length > 0 && data.dw[0].idReasonDetail ? removeNull(data.dw[0].idReasonDetail):'',
    addressProofReason: data.dw && data.dw.length > 0 && data.dw[0].addressReasonDetail ? removeNull(data.dw[0].addressReasonDetail) : '',
    riaId: data.riaId ? data.riaId : '',
  }

}

const transaction_object = (data) => {
// const plan = data.subscriptionType === "sub13999" ? 'Gold' : data.subscriptionType === "sub3333" ? 'Silver' : 'Basic'
    return{
    "accountNo" : data.accountNo ? data.accountNo :"",
    "accountAmount": data.accountAmount ? data.accountAmount : "",
    "accountBalance": data.accountBalance ? data.accountBalance : "",
    "accountType": data.accountType ? data.accountType : "",
    "comment": data.comment ? data.comment : "",
    "dnb": data.dnb ? data.dnb : "",
    "finTranID": data.finTranID ? data.finTranID : "",
    "finTranTypeID": data.finTranTypeID ? data.finTranTypeID : "",
    "feeSec": data.feeSec ? data.feeSec : "",
    "feeTaf": data.feeTaf ? data.feeTaf : "",
    "feeBase": data.feeBase ? data.feeBase : "",
    "feeXtraShares": data.feeXtraShares ? data.feeXtraShares : "",
    "feeExchange": data.feeExchange ? data.feeExchange :"",
    "fillQty": data.fillQty ? data.fillQty : "",
    "fillPx": data.fillPx ? data.fillPx : "",
    "orderID": data.orderID ? data.orderID : "",
    "orderNo":data.orderNo ? data.orderNo : "" ,
    "sendCommissionToInteliclear": data.sendCommissionToInteliclear ? data.sendCommissionToInteliclear :"",
    "systemAmount": data.systemAmount ? data.systemAmount : "",
    "tranAmount": data.tranAmount ? data.tranAmount : "",
    "tranSource": data.tranSource ? data.tranSource : "",
    "tranWhen": data.tranWhen ? data.tranWhen : "" ,
    "wlpAmount": data.wlpAmount ? data.wlpAmount : "",
    "partnerCode" : data.partnerCode ? data.partnerCode : "",
    "subscriptionType": data.subscriptionType ? data.subscriptionType: "" ,
   }
}

const order_object = (data) => {
  const plan = data.subscriptionType === "sub13999" ? 'Gold' : data.subscriptionType === "sub3333" ? 'Silver' : 'Basic'
    return{
    "id" : data.id ? data.id : "" ,
    "accountID" : data.accountID ? data.accountID : "" ,
    "accountNo" : data.accountNo ? data.accountNo : "" ,
    "averagePrice" : data.averagePrice ? data.averagePrice : "",
    "created" : data.created ? viewIntoSpecificTimeZone(data.created) : "",
    "createdBy" : data.createdBy ? data.createdBy : "" ,
    "cumulativeQuantity" : data.cumulativeQuantity ? data.cumulativeQuantity : "" ,
    "fees" : data.fees ? data.fees : "",
    "orderExpires" : data.orderExpires ? viewIntoSpecificTimeZone(data.orderExpires) : "" ,
    "orderNo" : data.orderNo ? data.orderNo : "" ,
    "partnerCode" : data.partnerCode ? data.partnerCode : "",
    "quantity" : data.quantity ? data.quantity : "",
     "side" : data.side ? data.side : "" ,
     "status" : data.status ? data.status : "" ,
     "symbol" : data.symbol ? data.symbol : "", 
     "totalOrderAmount" : data.totalOrderAmount ? data.totalOrderAmount : "" ,
     "type" : data.type ? data.type : "",
     "userID" : data.userID ? data.userID : "",
     "subscriptionType" : plan,
     "username" : data.username ? data.username : ''
  }
}


function convertToCSV(objArray, type) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';


  for (var i = 0; i < array.length; i++) {
      var line = '';

    switch(type) {
      case "lrs" : var object_ = lrs_object(array[i]); break;
      case "kyc_status": var object_ = kyc_status(array[i]); break;
      case "order": var object_ = order_object(array[i]);break;
      case "transaction": var object_ = transaction_object(array[i]);break;
    }

    Object.keys(object_).forEach(key=>{
        if (line != '') line += ','
        line += `"${object_[key]}"`;
    })

      str += line + '\r\n';
  }

  return str;
}


export const exportCSVFile = ( items, fileTitle, headers, type) => {
  // if (headers) {
  //     items.unshift(headers);
  // }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = headers + convertToCSV(jsonObject, type);
  
  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}