export const LOGIN_SUCCESS = 'login_success';
export const REGISTER_SUCCESS = 'register_success';
export const LOGOUT_SUCCESS = 'logout_success';
export const GET_CUSTOMER_REPORT = 'get_customer_report';
export const SET_ONBOARDED_USERS = 'set_onboarded_users';
export const SAVE_CUSTOMER_DETAILS = 'save_customer_details';
export const SET_TIMEZONE = 'set_timezone';
export const SET_PARTNER_CODES = 'set_partner_codes';
export const SET_PARTNER_CODES_FAIL = 'set_partner_codes_FAIL';

export const GET_ORDER_REPORT = 'get_order_report';
export const GET_ORDER_REPORT_SUCCESS = 'get_order_report_sucess';
export const GET_ORDER_REPORT_FAIL = 'get_order_report_fail';
export const GET_ORDER_REPORT_RESET = 'get_order_report_reset';

export const GET_STACK_REPORT = 'get_stack_report';
export const GET_STACK_REPORT_SUCCESS = 'get_stack_report_sucess';
export const GET_STACK_REPORT_FAIL = 'get_stack_report_fail';
export const GET_STACK_REPORT_RESET = 'get_stack_report_reset';

export const GET_ALL_ORDER_DETAIL = 'get_all_order_report';
export const GET_ALL_ORDER_SUCCESS = 'get_all_order_report_sucess';
export const GET_ALL_ORDER_REPORT_FAIL = 'get_all_order_report_fail';
export const GET_ALL_ORDER_REPORT_RESET = 'get_all_order_report_reset';

export const GET_ALL_STACK_DETAIL = 'get_all_stack_report';
export const GET_ALL_STACK_SUCCESS = 'get_all_stack_report_sucess';
export const GET_ALL_STACK_REPORT_FAIL = 'get_all_stack_report_fail';
export const GET_ALL_STACK_REPORT_RESET = 'get_all_stack_report_reset';

export const GET_ORDER_REPORT_SEARCH = 'get_order_report_search';
export const ADD_PARTNER_CODE = 'add_partner_code';

export const GET_TRANSACTION_REPORT_START = 'get_transaction_report_start';
export const GET_TRANSACTION_REPORT_SUCCESS = 'get_transaction_report_success';
export const GET_TRANSACTION_REPORT_FAIL = 'get_transaction_report_fail';

// transaction v2 API 
export const GET_ALL_TRANSACTION_DETAIL = 'get_all_transaction_detail';
export const GET_ALL_TRANSACTION_SUCCESS = 'get_all_transaction_sucess';
export const GET_ALL_TRANSACTION_FAIL = 'get_all_transaction_fail';
export const GET_ALL_TRANSACTION_RESET = 'get_all_transaction_reset';
// end

export const GET_TRANSACTION_SEARCH_REPORT_START =
  'get_transaction_search_report';
export const GET_TRANSACTION_SEARCH_REPORT_SUCCESS =
  'get_transaction_search_report_success';
export const GET_TRANSACTION_SEARCH_REPORT_FAIL =
  'get_transaction_search_report_fail';

export const INITIATE_KPI = 'initiate_kpi';
export const CUSTOMERKPI_SUCCESS = 'customerkpi_success';
export const CUSTOMERKPI_FAIL = 'customerkpi_fail';

export const BUSINESSKPI_SUCCESS = 'businesskpi_success';
export const BUSINESSKPI_FAIL = 'businesskpi_fail';

export const GET_STACKS = 'get_stacks';
export const GET_STACKS_SUCCESS = 'get_stacks_success';
export const GET_STACKS_FAIL = 'get_stacks_fail';

export const GET_PLANS = 'get_plans';
export const GET_PLANS_SUCCESS = 'get_plans_success';
export const GET_PLANS_FAIL = 'get_plans_fail';

export const ADD_PARTNERPLAN_STACKS = 'add_partnerplan_stacks';
export const ADD_PARTNERPLAN_STACKS_SUCCESS = 'add_partnerplan_stack_success';
export const ADD_PARTNERPLAN_STACKS_FAIL = 'add_partnerplan_stacks_fail';

export const EDIT_PARTNERPLAN_STACKS = 'edit_partnerplan_stacks';
export const EDIT_PARTNERPLAN_STACKS_SUCCESS =
  'edit_partnerplan_stacks_success';
export const EDIT_PARTNERPLAN_STACKS_FAIL = 'edit_partnerplan_stacks_fail';

export const DELETE_PARTNERPLAN_STACKS = 'delete_partnerplan_stacks';
export const DELETE_PARTNERPLAN_STACKS_SUCCESS =
  'delete_partnerplan_stacks_success';
export const DELETE_PARTNERPLAN_STACKS_FAIL = 'delete_partnerplan_stacks_fail';
export const DELETE_PARTNERPLAN_STACKS_RESET =
  'delete_partnerplan_stacks_RESET';

// export const GET_USER_REPORT = 'user_report';
// export const GET_USER_REPORT_SUCCESS = 'user_report_success';
// export const GET_USER_REPORT_FAIL = 'user_report_fail';
// export const GET_USER_REPORT_RESET = 'user_report_reset';

export const ADD_NEW_EXPORT = 'new_export';
export const ADD_NEW_EXPORT_SUCCESS = 'new_export_success';
export const ADD_NEW_EXPORT_FAIL = 'new_export_fail';
export const ADD_NEW_COUPON = 'new_coupon';

// export const GET_USER_EXPORT = 'user_export';
// export const GET_USER_EXPORT_SUCCESS = 'user_export_success';
// export const GET_USER_EXPORT_FAIL = 'user_export_fail';
// export const GET_USER_EXPORT_RESET = 'user_export_reset';

export const GET_USER_REPORT = 'get_user_report';
export const GET_USER_REPORT_SUCCESS = 'get_user_report_success';
export const GET_USER_REPORT_FAIL = 'get_user_report_fail';
export const GET_USER_REPORT_RESET = 'get_user_report_reset';

export const GET_INSTAPAYMENT_REPORT = 'get_instapayment';
export const GET_INSTAPAYMENT_REPORT_SUCCESS = 'get_instapayment_success';
export const GET_INSTAPAYMENT_REPORT_FAIL = 'get_instapayment_fail';
export const GET_INSTAPAYMENT_REPORT_RESET = 'get_instapayment_reset';

export const CHANGETICKER = 'changeTicker';
