
import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import {} from './style.css';
import { getPartnerOptions } from 'utils/common';


export const DateFilter = (props) => {
  const {start_date,end_date,partnerId,onChangeDate, onChange ,onSubmit, minStart, maxEnd } = props;
  const partnerOptions = getPartnerOptions({...props.auth, ...props.common});
  return (  
    <div className="card ">
      <div className="card-body mx-auto date-filter-body" >
        <div className="row">
          <div className="col-md-6 col-lg-3 col-xl-2 dateFilter">
            <p className="stackLabelFont">Start Date</p>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              selected={start_date}
              onChange={(date) => onChangeDate('start_date', date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={minStart || new Date("2016-01-01")}
              maxDate={new Date()}
            />
          </div>
          <div className={props.children? "col-md-6 col-lg-3 col-xl-2 dateFilter ": "col-md-6 col-lg-3 col-xl-2 dateFilter offset-xl-1 "}>
            <p className="stackLabelFont">End Date</p>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              selected={end_date}
              onChange={(date) => onChangeDate('end_date', date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={start_date && start_date}
              maxDate={ end_date }
            />
          </div>
          { props.children }
          { partnerOptions.length > 0 ?
            (<div className={props.children? "col-md-6  col-lg-4 col-xl-4" : "col-md-6  col-lg-4 col-xl-4 offset-xl-1"}>
              <p className="stackLabelFont">Partner</p>
                {
                  Array.isArray(partnerOptions) && partnerOptions.length > 0?
                <select 
                  name='partnerId'
                  className="form-control custom-select" 
                  onChange={onChange}
                  value={partnerId}
                > 
                  {
                    partnerOptions.map((partner, id) => (
                      <option key={id} value={partner.code}>{partner.name}</option>
                    )) 
                  }
                </select> :
                <label> ALL </label>
                }
            </div>) 
            : ''
          }
          <div className="col-md-6 mt-md-4 mt-lg-0 pt-md-2 col-lg-2 col-xl-1 pt-lg-1 mt-xl-1 pt-xl-4">
            <button onClick={onSubmit} className="mt-xl-2 btn btn-sm btn-primary btn-stockal ">Submit</button>
          </div>
        </div> 
      </div>
  </div>
  )
}
