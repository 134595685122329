import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
// import { viewIntoSpecificTimeZone  } from 'utils/common';
import LandingHeader from './LandingHeader';
import LandingTable from './LandingTable';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {};   
    }

    render() {
        return (
            <Fragment>
                <LandingHeader { ...this.state } />
                <LandingTable { ...this.state } />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth }
}

export default connect(mapStateToProps, null)(Landing)
