
import {viewIntoSpecificTimeZone, PLANS1, dateToString, getSubscriptionName} from 'utils/common';



function convertToCSV(columns, rows) {
    // Loop Through Every Row in Data
    // Run Each key as per each column in columns
    // Apply formatters if any
    let data = '';
    let header = [];
    columns.forEach(col => {
        header.push(col.name);
    });
    header = header.join(',') + '\r\n';
    rows.forEach(row => {
        const rowArr = [];
        columns.forEach((column, i) => {
            if (column.exportFormatter) {
                rowArr.push(column.exportFormatter({ column, row }) || '');
            } else if (column.formatter) {
                rowArr.push(column.formatter({ column, row }) || '');
            } else {
                rowArr.push(row[column.key] || '');
            }
        });
        data += `" ${rowArr.join(`","`)}"\r\n`;
        
        if (row.children) {
            
            const children = row.children;
            children.forEach(childRow => {
                const childArr = [];
                console.log(childRow);
                columns.forEach((column, i) => {
                    if (column.exportFormatter) {
                        childArr.push(column.exportFormatter({ column, row: childRow }) || '');
                    } else if (column.formatter) {
                        childArr.push(column.formatter({ column, row: childRow }) || '');
                    } else {
                        childArr.push(childRow[column.key] || '');
                    }
                });
                // data += childArr.join(',') + '\r\n';
                data += `" ${childArr.join(`","`)}"\r\n`;
            });
        }
    });
    return header+data;
}

export function exportFile (columns, data, title, type = 'csv') {
    const csvStringFile = convertToCSV(columns, data);
    const fileName = title !== '' ? title + '.csv' : 'export.csv';
    const blob = new Blob([csvStringFile], { type: 'text/csv;charset=utf-8;' } );
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

}