

import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    } from '../type'

const INTIAL_STATE = {
    isLoggedIn: false,
}

const AuthReducer = (state=INTIAL_STATE, action) => {
  switch(action.type){
    case LOGIN_SUCCESS:
      return {
          ...state,
          email: action.payload.user.email,
          id:action.payload.user.id,
          name:action.payload.user.name,
          roleId: action.payload.user.roleId,
          roleTitle:action.payload.user.roleTitle,
          token: action.payload.token,
          isLoggedIn: true,
          clientCode: action.payload.user.clientCode
        }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false
      }
    default:
      return state
  }
}

export default AuthReducer;
