import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from 'common/Header';
import SideNav from 'common/Sidebar/SideNav';

import requireAuth from 'utils/HOC/requireAuth';
import { CAN_ACCESS_PARTNER } from 'utils/roles';
import User from 'components/userPage/index';
import Profile from 'components/userProfile/index';
import Create from 'components/createAccount';
import { CustomerList, CustomerDetails } from 'components/Customers';
import { UserExport, UserAnalytics, ExportReports } from 'components/Export';
import { LRS, LRSDetails } from 'components/LRS';
import pagination from './utils/pagination';
import Comments from './components/Comments';
import CreateNew from './components/Customers/Create';
import ExportList from './components/ExportList';
import { getPartnerCodes, getMyPlans } from 'Redux/actions/common';
import UpdatePassword from './components/auth/login/UpdatePassword';
import Landing from './components/Landing';
import Transactions from './components/Transactions';
import Orders from './components/Orders';
import Stacks from './components/Orders/Stacks';
import CreatePartner from './components/partners/CreatePartner';
import ShowPartnerList from './components/partners/ShowPartnerList';
import CreateCoupon from './components/coupons/CreateCoupon';
import ActiveCoupon from './components/coupons/ActiveCoupon';
import AddStacks from './components/partners/AddStacks';
import Payments from './components/Payment/Payment';
import Portfolio from './components/Portfolio/portfolio';
import { roles } from './utils/roles';
import Discrepency from './components/Discrepency/index'
import VerifiedPortfolio from './components/Portfolio Verified/verified';
import  Instapayment  from './components/Instapayment';
// import Detail from 'components/LRS/Detail';

class Home extends Component {
  componentDidMount = () => {
    if (this.props.auth.id) {
      this.props.getMyPlans(this.props.auth.id);
    }
    if (CAN_ACCESS_PARTNER.includes(this.props.auth.roleTitle)) {
      this.getPartnerCodes();
    }
  };

  getPartnerCodes() {
    this.props.getPartnerCodes();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.common.partnerCodes.length !==
      nextProps.common.partnerCodes.length
    ) {
      return true;
    }
    return false;
  }

  render() {
    const path =
      this.props.auth &&
      roles[this.props.auth.roleId] === this.props.auth.roleTitle
        ? '/list_users'
        : '/login';

    const main = {
      // marginLeft: '220px', /* Same as the width of the sidenav */
      // fontSize: '28px', /* Increased text to enable scrolling */
      padding: '0px 10px',
    };
    return (
      <div id='page-top'>
        <div id='wrapper'>
          <SideNav />
          <div id='content-wrapper' className='d-flex flex-column'>
            <div id='content' style={main}>
              <Header />
              <Switch>
                <Route
                  path='/user'
                  component={(props) => <User {...props} />}
                />
                <Route
                  exact
                  path='/lrs'
                  component={(props) => <LRS {...this.props} />}
                />
                <Route
                  exact
                  path='/lrs/lrs_details/:lrsid'
                  component={(props) => (
                    <LRSDetails {...this.props} {...props} />
                  )}
                />
                <Route
                  path='/profile'
                  component={(props) => <Profile {...props} />}
                />
                <Route
                  path='/list_users'
                  component={(props) => (
                    <UserExport {...this.props} {...props} />
                  )}
                />
                <Route
                  path='/export_users'
                  component={(props) => (
                    <ExportReports {...this.props} {...props} />
                  )}
                />

                {/* <Route
                  path='/dashboard'
                  component={(props) => (
                    <UserAnalytics {...this.props} {...props} />
                  )}
                /> */}
                <Route
                  path='/pagination'
                  component={(props) => <pagination {...props} />}
                />
                <Route
                  exact
                  path='/register'
                  component={(props) => <Create {...this.props} />}
                />
                <Route
                  path='/customers'
                  component={(props) => (
                    <CustomerList {...this.props} {...props} />
                  )}
                />
                <Route
                  path='/customer_details/:userId'
                  component={(props) => (
                    <CustomerDetails {...this.props} {...props} />
                  )}
                />
                <Route
                  path='/create_new_customer'
                  component={(props) => <CreateNew {...props} />}
                />
                <Route
                  path='/comments/:category/:userId/:email?/:name?/:categoryID?'
                  component={(props) => <Comments {...this.props} {...props} />}
                />
                <Route
                  path='/export_reports'
                  component={(props) => (
                    <ExportList {...this.props} {...props} />
                  )}
                />
                <Route
                  path='/update_password'
                  component={(props) => <UpdatePassword />}
                />
                <Route
                  path='/orders'
                  component={(props) => <Orders {...this.props} />}
                />
                <Route
                  path='/stacks'
                  component={(props) => <Stacks {...this.props} />}
                />
                {/* <Route path="/landing" component={(props) => <Landing}/> */}
                <Route
                  path='/transactions'
                  component={(props) => <Transactions {...this.props} />}
                />
                <Route
                  path='/createPartner'
                  component={() => <CreatePartner {...this.props} />}
                />
                <Route
                  path='/showPartnerList'
                  component={(props) => <ShowPartnerList {...this.props} />}
                />
                <Route
                  path='/partner/stacks/:code?'
                  component={(props) => (
                    <AddStacks {...this.props} {...props} />
                  )}
                />
                <Route 
                  path="/portfolio" 
                  component={(props) => 
                  <Portfolio  {...this.props} {...props} /> } 
                />
                <Route
                  exact
                  path='/createCoupon'
                  component={() => <CreateCoupon />}
                />
                <Route
                  path='/showActiveCouponList'
                  component={() => <ActiveCoupon />}
                />
                <Route 
                  path="/discrepency"
                  component={() => <Discrepency/>}
                />
                 <Route 
                  path="/payments"
                  component={() => <Payments/>}
                />
                <Route 
                  path="/verifiedPortfolio"
                  component={() => <VerifiedPortfolio/>}
                />
                <Route
                  path='/instaPayment'
                  component={(props) => <Instapayment {...this.props} />}
                />
                {/* <Route path="/" component={(props) => <Landing  {...props} /> } /> */}
                {/* <Route exact path="/Lrsform" component={(props) => <Lrsform  {...props} /> } />  */}
                {/* <Route exact path="/general_report" component={(props) => <Report  {...props} /> } /> */}
                {/* <Route path="/(general_report|new_accounts_opened|remittances_approval|trade_summary|revenue)" component={(props) => <Report  {...props} /> } /> */}
                {/* <Route exact path="/" component={(props) => <Create  {...props} /> } /> */}
                <Redirect from='/' to={path} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, common }) => {
  return {
    auth,
    common,
  };
};

export default connect(mapStateToProps, { getPartnerCodes, getMyPlans })(
  requireAuth(Home)
);
