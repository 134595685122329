import axios from 'axios';
import {API_ENDPOINT} from 'Redux/apiConstant';
import {getConfig} from '../getConfig';
import {
    INITIATE_KPI,
    CUSTOMERKPI_FAIL,
    CUSTOMERKPI_SUCCESS,
    BUSINESSKPI_FAIL,
    BUSINESSKPI_SUCCESS,
} from '../type';


export const fetchKPI = (state) => {
    return (dispatch) => {
        dispatch({ type: INITIATE_KPI, payload: state });
        getCustomerConsolidated(state, dispatch);
        getLRSConsolidated(state, dispatch);
    };
}

const getCustomerConsolidated = (state, dispatch) => {
    const url = `${API_ENDPOINT}/api/streams/dashboard/customer/consolidate/${state.from}/${state.to}/${state.partnerId}`;
    console.log(url);
        axios.get(url, getConfig()).then((response) => {
            if (response.data.status === 200) {
                dispatch({ type: CUSTOMERKPI_SUCCESS, payload: response.data})
            } else {
                dispatch({ type: CUSTOMERKPI_FAIL, payload: 'Unable to fetch Data'});
            }   
        });
  }


const getLRSConsolidated = (state, dispatch) => {
    const url = `${API_ENDPOINT}/api/streams/dashboard/lrs/bank-lrs/consolidate/${state.partnerId}/${state.from}/${state.to}`;
    console.log(url);
    axios.get(url, getConfig()).then((response) => {
        if (response.data.status === 200) {
            dispatch({ type: BUSINESSKPI_SUCCESS, payload: response.data})
        } else {
            dispatch({ type: BUSINESSKPI_FAIL, payload: 'Unable to fetch Data'});
        }
    });

}