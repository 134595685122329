
import React, { Component } from 'react'
import moment from 'moment';
import DatePicker from 'react-datepicker'
import {connect} from "react-redux";
import Loader from 'react-loader'

import { goToComments } from 'utils/navigations'
import { commentCategory } from 'utils/common';
import ConfirmAlert from 'utils/ConfirmAlert'; 
import { CAN_EDIT_LRS } from 'utils/roles';
import Toast from 'utils/Toast'

import { postComments } from "Redux/actions/comments"
import { updateLRSV2, getLRSDetailsV2 } from "Redux/actions/lrs"

import {} from './style.css'

const changeDateFormat = (date) => {
  return  new Date(date)
}

class Detail extends Component {
  constructor() {
    super()
    this.state = {
      Resp: {},
      is_loaded: false,
      comment: "",
      alertMessage: "",
      showAlert: false,
      autoClose: 1500,
      addComment:false
    }
    this.onFieldChange = this.onFieldChange.bind(this);
  }


  componentDidMount() {
    let lrsId = this.props.match.params.lrsid;
    this.setState({lrsId})
    this.props.getLRSDetailsV2({lrsId, userId: this.props.auth.id})
      .then(res => {
        // Resp["pickUpDate"] = Resp.pickUpDate && changeDateFormat(Resp.pickUpDate)
        this.setState({Resp:res, is_loaded: true})
      })

  }

  onFieldChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      Resp:{
        ...this.state.Resp, [name]: value
      }
    })
  }

  onDateChange = (date) => {
    this.setState({
      Resp:{
        ...this.state.Resp, ['pickUpDate']: date
      }
    })
  }

  setAlertOff = () => {
    setTimeout(() => this.setState({showAlert: false,addComment:false}), this.state.autoClose)
  }
  
  
  updateLrsDetail = () => {
    this.setState({addComment:true})
    let { comment, Resp, lrsId } = this.state
    let data = Resp
    let { userId } = data
    if (comment === undefined || comment === "" || comment.replace(/\s/g, "") === "") {
      this.setState({
        showAlert: true,
        alertMessage: "Please add comment before Updating",
      })
    }
    else {
      data["updatedBy"]=this.props.auth.id
      data["pickUpDate"] = data["pickUpDate"] && moment(data["pickUpDate"]).format("YYYY-MM-DD") 
      updateLRSV2(this.state.Resp, this.props.auth.id)
      .then((data) => {
        postComments({
          category: commentCategory.lrs,
          comment,
          categoryID: lrsId,
          customerId: userId,
          createdBy:this.props.auth.id
        })
        .then((data) => {
          this.setState({
            showAlert: true,
            alertMessage: "Updated",
          })
        })
        .catch((err) => {
          this.setState({
            showAlert: true,
            alertMessage: "Error While Updating Comment",
          })
        })
      })
      .catch((err) => {
        this.setState({
          showAlert: true,
          alertMessage: "Error While Updating Data",
        })
      })
    }
  }

  render() {
    let { comment, showAlert, alertMessage, autoClose, lrsId } = this.state
    let {
      createdAt,
      pickUpLocation,
      pickUpTime,
      pickUpDate,
      dropBranchName,
      dropBankAddress,
      dropIfscCode,
      status,
      statusReason,
      applicantAccountNumber,
      applicantPan,
      applicantPhone,
      applicantEmail,
      applicantAddress,
      customerId,
      applicantName,
      lrsBankName,
      userId,                  
      updatedAt,
      fullname,
      username,
      sourceOfFunds,
      purposeDescription,
      purposeCode,
      fixedFCAmt,
      beneficiaryName,
      dwUsername,
    } = this.state.Resp
    let { is_loaded} = this.state
    pickUpDate = pickUpDate && changeDateFormat(pickUpDate)
    let can_update_lrs = CAN_EDIT_LRS.includes(this.props.auth.roleTitle) ? false : true
    return (
      <div className="card" style={{color:"black"}}>
        <div className="card-header">
         Remittance Detail
          {createdAt ? <span className="float float-right"><h6>Created At: {createdAt && moment(createdAt).format("DD-MM-YYYY")}</h6></span> : null}
          </div>
        <div className="card-body">
          <Loader loaded={is_loaded}>
          <form>
            <fieldset disabled={can_update_lrs}>
            <h4>LRS Pick up </h4>
            <hr />
            <div className="form-row">
              <div className="col-12">
              <b>Pick Up Location</b>
                <input type="text" 
                  className="form-control" 
                  placeholder="Pick Up Location"
                  id= "pickUpLocation"
                  name= "pickUpLocation"
                  onChange = {this.onFieldChange} 
                  value={pickUpLocation || ''} />
    
              </div>
              </div>
              <br/>
              <div className="form-row">
              <div className="col-6">
                <b>Pick Up Time</b>
                <select 
                className="form-control" 
                name="pickUpTime"
                value={pickUpTime} 
                onChange = {this.onFieldChange} >
                <option value="8AM - 12PM">8AM - 12PM</option>
                <option value="12PM - 4PM">12PM - 4PM</option>
                <option value="4PM - 8PM">4PM - 8PM</option>
                </select>
              </div>
              <div className="col-6">
                <b style={{marginLeft:"10px"}}>Pick Up Date</b>
                <div className="col-12">
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  selected={pickUpDate}
                  onChange={this.onDateChange}
                  // maxDate={end_date}
                />
                </div>
              </div>
              </div>
            <br />

            <h4>Lrs Drop</h4>
            <hr />
            <div className="form-row">
              <div className="col-12">
                <b>Drop Bank Address</b>
                <input type="text" 
                className="form-control" 
                placeholder="Drop Bank Address" 
                id="txtdropBankAdress" 
                name="dropBankAddress"
                onChange = {this.onFieldChange}
                value={dropBankAddress} />
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="col-6">
                <b>Drop Branch Name</b>
                <input type="text"
                className="form-control" 
                placeholder="Drop Branch Name" 
                id= "txtDropBranchName"
                name="dropBranchName"
                onChange = {this.onFieldChange} 
                value={dropBranchName}  />
              </div>
              <div className="col-6">
                <b>IFSC code</b>
                <input type="text" 
                className="form-control"
                placeholder="IFSC code" 
                id="txtIFSC"
                name = "dropIfscCode"
                onChange = {this.onFieldChange}
                value={dropIfscCode} />
              </div>
              </div>
            <br />
            <h4>Status</h4>
            <hr />
            <div className="form-row">
              <div className="col">
                
                <b>Status</b>
                <select 
                className="form-control"  
                value={status ? status:'open'} 
                onChange={this.onFieldChange} 
                name="status"
                >
                <option value="rejected">Rejected</option>
                <option value="open">Open</option>
                <option value="complete">Complete</option>
                <option value="pickUpScheduled">Pick Up Scheduled</option>
                <option value="self">Self</option>
                <option value="underProcess">Under Process</option>
                <option value="underTransit">Under Transit</option>
                <option value="cancel">Cancel</option>
                </select>
              </div>
              <div className="col">
                <b>Status Reason</b>
                <input 
                type="text" 
                className="form-control" 
                placeholder="Status Reason" 
                id="txtStatusReason"
                name="statusReason"
                onChange={this.onFieldChange}
                value={(statusReason) ? statusReason : ""} />
              </div>
            </div>
            <div className="rom-row">
              <div className="col-12">
                <b>Add Comments</b>
                <textarea 
                  className="form-control" 
                  rows="4" 
                  name="comment" 
                  value={comment} 
                  disabled={this.state.addComment}
                  onChange={(e) => {this.setState({comment:e.target.value})}}>  
                </textarea>
              </div>
            </div>
            <br />
            
            <h4>Applicant Information</h4>
            <hr />
            <fieldset disabled={true}>
            <div className="form-row">
              <div className="col-4">
                <b>Applicant Account No.</b>
                <input type="text" 
                className="form-control" 
                placeholder="Enter Applicant Account Number" 
                id = "txtApplicantAccountNumber"
                name = "applicantAccountNumber"
                onChange= {this.onFieldChange}
                value={applicantAccountNumber } />
              </div>
              <div className="col-4">
                <b>Applicant pan</b>
                <input 
                style={{align:"left"}}
                type="text" 
                className="form-control" 
                placeholder="applicantPan" 
                id = "txtApplicantpan"
                name = "applicantPan"
                onChange= {this.onFieldChange}
                value={applicantPan} />
              </div>
              <div className="col-4">
                <b>Applicant Phone</b>
                <input type="number" 
                className="form-control" 
                placeholder=" applicantPhone" 
                id = "txtApplicantPhone"
                name = "applicantPhone"
                onChange= {this.onFieldChange}
                value={applicantPhone} />
              </div>
            </div>
            <br />
            <div className="form-row">
             
              <div className="col-4">
                <b>Applicant Email</b>
                <input type="email" 
                className="form-control" 
                placeholder="applicantEmail" 
                id = "txtApplicantEmail"
                name = "applicantEmail"
                onChange= {this.onFieldChange}
                value={applicantEmail} />
              </div>
              <div className="col-8">
                <b>Applicant Address</b><input type="text" 
                className="form-control" 
                placeholder="applicantAddress" 
                id = "txtAapplicantAddress"
                name = "applicantAddress"
                onChange= {this.onFieldChange}
                value={applicantAddress} />
              </div>
            </div>
            <br />
       
              <div className="form-row">
                <div className="col-4">
                  <b>Customer Id</b><input type="text"
                  className="form-control" 
                  placeholder=" customerId" 
                  id = "txtCustomerId"
                  name = "customerId"
                  onChange= {this.onFieldChange}
                  value={customerId} />
                </div>
              <div className="col-4">
                <b>Applicant Name</b>
                <input type="text" 
                className="form-control" 
                placeholder="applicantName" 
                id = "txtApplicantName"
                name = "applicantName"
                onChange= {this.onFieldChange}
                value={applicantName} />
              </div>
              <div className="col-4">
                <b>Remittance Bank Name</b>
                <input type="text" 
                className="form-control" 
                placeholder="lrsBankName"
                id = "txtLrsBankName"
                name = "lrsBankName"
                onChange= {this.onFieldChange} 
                value={lrsBankName} />
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="col-4">
                <b>User ID</b>
                <input type="text" 
                className="form-control" 
                placeholder=" userId"
                id = "txtUserId"
                name = "userId"
                onChange= {this.onFieldChange} 
                value={userId} />
              </div>
              <div className="col-4">
                <b>Updated At</b>
                <input type="text" 
                className="form-control" 
                placeholder="updatedAt"
                id = "txtUpdatedAt"
                name = "updatedAt"
                onChange= {this.onFieldChange}  
                value={updatedAt} />
              </div>
              <div className="col-4">
                <b>FullName</b>
                <input type="text" 
                className="form-control" 
                placeholder="fullname" 
                id = "txtFullname"
                name = "fullname"
                onChange= {this.onFieldChange}  
                value={fullname} />
              </div>
              </div>
              <br />
              <div className="form-row">
              <div className="col-4">
                <b>Email</b>
                <input type="text" 
                className="form-control" 
                placeholder="email"
                id = "txtEmail"
                name = "email"
                onChange= {this.onFieldChange}  
                value={username} />
              </div>
              <div className="col-4">
                <b>Username</b>
                <input type="text" 
                className="form-control" 
                placeholder="username"
                id = "txtUsername"
                name = "username"
                onChange= {this.onFieldChange}  
                value={dwUsername} />
              </div>
            </div>
            </fieldset>
            <br />
            <h4>Other Details</h4>
            <hr />
            <div className="form-row">
              <div className="col-4">
                <b>Source Of Funds</b>
                <input type="text" 
                className="form-control" 
                placeholder="SourceOfFunds" 
                readOnly 
                defaultValue={(sourceOfFunds) ? sourceOfFunds : ""} />
              </div>
              <div className="col-4">
                <b>Purpose Description</b>
                <input 
                type="text" 
                className="form-control" 
                placeholder="purposeDescription" 
                readOnly 
                defaultValue={(purposeDescription) ? purposeDescription : ""} />
              </div>
              <div className="col-4">
                <b>Purpose Code</b>
                <input 
                type="text" 
                className="form-control" 
                placeholder="purposeCode" 
                readOnly 
                defaultValue={(purposeCode) ? purposeCode : "" } />
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="col-4">
                <b>Fixed FC Amount</b>
                <input 
                type="text" 
                className="form-control" 
                placeholder="fixedFCAmt" 
                readOnly 
                defaultValue={(fixedFCAmt) ? fixedFCAmt : "" } />
              </div>
              <div className="col-4">
                  <b>Beneficiary Name</b>
                  <input 
                  type="text" 
                  className="form-control" 
                  readOnly 
                  defaultValue={(beneficiaryName) ? beneficiaryName : "" } />
                </div>
            </div>
            <br /> 
            </fieldset>
          </form>
          <div style={{float:"right"}}>
              <ConfirmAlert
                className='creditDelete'
                name="update lrs"
                confirmAlert = {this.updateLrsDetail}
                button_name = "Update LRS"
                disabled={can_update_lrs}
              />
            </div>
            <div>
              <button className="btn btn-dark" style={{color:"white",float:"right"}} onClick={() => goToComments({userId, applicantName, applicantEmail, category: commentCategory.lrs, categoryID: this.state.lrsId})} >
                View All Comments              
              </button>
            </div>
          </Loader>
          <br/>
        </div>
        <Toast 
          showAlert={showAlert}
          alertMessage={alertMessage}
          autoClose={autoClose}
          setAlertOff={this.setAlertOff}
        />
      </div>
    )
  }
}


const mapStateToProps = ({auth}) => {
  return {
    auth
  }
}

export default connect(mapStateToProps, { getLRSDetailsV2 })(Detail)
