import React, {Component} from "react"
import { registerUser } from 'Redux/actions/dashboard'
import _ from 'lodash'

// import {RegisterForm} from './Forms'
import {roles} from 'utils/roles'
import { getPartnerOptions } from "utils/common";

class CreateUser extends Component {
  state ={error:'', clientCode:''}

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  isValid = () => {
    let { password,userConfPasswd} = this.state
    if (password !== userConfPasswd) {
      this.setState({
          error: "Password does not match"
      })
      return false
    }
    return true
  }

  registerUserApi = (e) => {
    e.preventDefault()
    this.setState({error: ''})
    let {name, password,email, roleId, clientCode} = this.state
    if (this.isValid()) {
      let data = {
        name,
        email,
        password,
        roleTitle: roles[roleId],
        roleId,
        clientCode
      }
      registerUser(data)
        .then((response => {
          if (response.status === 200) {
            alert(response.msg)
            this.setState({
              email: '', name: '', password: '', userConfPasswd: '', roleId: '', clientCode: ""
            })
        }
        else {
          switch(response.error){
            case 'user-exists' : var error = "User Already Exists in the System."
          }
          this.setState({error})
        }
      }))
    }
  }



  render() {
    let {name, email, roleId, clientCode} = this.state
    let keys = _.keys(roles);
    const partnerOptions = getPartnerOptions({...this.props.auth, ...this.props.common});
    return (
      <div>
        <button type="button" className="btn btn-primary btn-stockal" data-toggle="modal" data-target="#myModal">CreateUser</button>
      
        <div className="modal fade" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="col-lg-12">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="p-5">
                      <form 
                      className="user"
                      onSubmit={this.registerUserApi} 
                      >
                        <div className="form-group ">
                          <input 
                          type="text" 
                          className="form-control custom-select" 
                          name="name"
                          id="fullName"
                          placeholder="Full Name"
                          onChange={this.onChange}
                          value={name}
                          required
                          />
                        </div>
                        <div className="form-group">
                          <select 
                          className="form-control custom-select" 
                          name="roleId"
                          id="dwnUserRole"
                          onChange={this.onChange}
                          value={roleId}
                          required
                          >
                            <option value={0}>Select User Role</option>
                            {
                              keys.map((key) => (
                                <option key={key} value={key}>{roles[key].toUpperCase()}</option>
                              ))
                              
                            }
                          </select>
                        </div>
                        {
                          roleId === "5" ?
                          <div className="form-group">
                          <select 
                          className="form-control custom-select" 
                          name="clientCode"
                          onChange={this.onChange}
                          required
                          >
                            <option value={clientCode} disabled>Select Client</option>
                          {
                            partnerOptions && partnerOptions.length > 0 && partnerOptions.map((partner, index) => (
                              <option key={index} value={partner.code}>{partner.name}</option>
                            ))
                          }
                          </select>
                        </div> : null
                        } 
                        <div className="form-group">
                          <input 
                          type="email" 
                          className="form-control form-control-user"
                          id="userEmail" 
                          name="email"    
                          placeholder="Email Address"
                          onChange={this.onChange}
                          value={email}
                          required
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <input 
                            type="password" 
                            className="form-control form-control-user"
                            id="userPasswd" 
                            name="password" 
                            placeholder="Password"
                            onChange={this.onChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <input 
                            type="password" 
                            className="form-control form-control-user"
                            id="userConfPasswd" 
                            name="userConfPasswd" 
                            placeholder="Repeat Password"
                            onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <input 
                        type="submit" 
                        className="btn btn-primary btn-stockal btn-user btn-block"
                        value="Register"
                        />
                      </form>
                      <div>
                          <span style={{color: 'red'}}>{this.state.error}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default CreateUser;