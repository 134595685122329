

import moment from 'moment';
import React, { Component, Fragment } from 'react';
import Loader from 'react-loader'
import DatePicker from 'react-datepicker';
// import Loader from 'react-loader';
import {connect} from 'react-redux';
import { exportKYCReportStatus } from 'Redux/actions/export'; 
import { getTIMEZONE } from 'utils'
import { DateFilter } from 'utils/DateFilter';
import { exportCSVFile } from 'utils/exportFile';
import { getClientId } from 'utils/common';


const export_headers = ["Date, Name, Username, Email, Mobile, ID Status, Address Proof Status, Account Status, ID Status Reason, Address Proof Reason, Partner                                                                                                                                                                                                                                                                                                                                                                                                                               \r\n"]


const initialState = {};

class ExportList extends Component {

constructor(props) {
  super(props);
  const start = new Date();
  const end = new Date();
  start.setDate(start.getUTCDate()-3);
  this.state = {
    start,
    end,
    partnerId: '',
    isLoaded: true,
    errorMessage: ''
  }
  this.onDateChange = this.onDateChange.bind(this);
  this.onChange = this.onChange.bind(this);

}
  

  initialState = this.state;

  componentDidMount () {
    const { auth } = this.props
    const partnerId = getClientId(auth)
    this.setState({ partnerId, userId: auth.id });
}
    onDateChange(name,date) {
    if (name === 'start_date') { this.setState({ 'start': date }); }
    if (name === 'end_date') { this.setState({ 'end':date }); }
  }
  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  clearState = () => {
    this.setState({initialState})
  }

  exportKYCReport = () => {
    let {start, end, partnerId} = this.state
    let {tz} = this.props.common;
    this.setState({isLoaded: false, errorMessage: ''})
    exportKYCReportStatus({
      start_date: getTIMEZONE(moment.utc(this.state.start).startOf('day')), 
      end_date: getTIMEZONE(moment.utc(end).endOf('day')),
      partnerId: partnerId
    })
    .then((data) => {
      new Promise((resolve, reject) => {
        try {
          if(data && data.docs && data.docs.length > 0)
            exportCSVFile(data.docs,`KYC_STATUS-${moment(start).format("YYYY-MM-DD")}`, export_headers, "kyc_status")
          else this.setState({errorMessage: "NO DATA FOUND"});
          return resolve()
        }
        catch(err) {
          return reject();
        }
      })
      .then((data) => {this.setState({isLoaded: true}); this.clearState()})
      .catch((data) => this.setState({isLoaded: true, errorMessage: 'Error While Exporting Data!.'}) )
    })
    .catch((error) => {
      this.setState({isLoaded: true, errorMessage: 'Error While Exporting'})
    })
  }

  render1() {
    let {start_date, end_date, isLoaded, errorMessage } = this.state
    return(
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <span style={{marginRight: '.5rem'}}>Start Date</span>
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  selected={start_date}
                  onChange={(date) => this.onChangeDate('start_date', date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date("2016-01-01")}
                  // maxDate={end_date && end_date}
                />
              </div>
              <div className="col-md-4">
                <span style={{marginRight: '.5rem'}}>End Date</span>
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  selected={end_date}
                  onChange={(date) => this.onChangeDate('end_date', date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date("2016-01-01")}
                  // maxDate={end_date && end_date}
                />
              </div>
                <div className="col-md-4">
                  <Loader loaded={isLoaded}> 
                    <button type="button" className="btn btn-primary btn-stockal " onClick={this.exportKYCReport}>Export KYC Status Report</button>
                  </Loader>
                </div>
              
            </div>
          </div>      
        </div>
          <div>
          { errorMessage.length > 0 && 
            <h1 style={{color: 'red', textAlign:'center', paddingTop: '10px'}}>{errorMessage}</h1>
            }
        </div>

      </React.Fragment>
    ) 
  }

  render() {
    return (<Fragment>
            
      <DateFilter
          start_date={this.state.start}
          end_date={this.state.end}
          partnerId={this.state.partnerId}
          onSubmit={this.exportKYCReport}
          onChange={this.onChange}
          onChangeDate={this.onDateChange}
          {...this.props}
      />
      {
        !this.state.isLoaded ? (<Loader/>): ''
      }
      
  </Fragment>);
  }
}

const mapStateToProps = ({common}) => {
  return {common}
}

export default connect(mapStateToProps)(ExportList)