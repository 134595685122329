import React, { Component } from "react"
import DatePicker from 'react-datepicker';
import axios from 'axios';
import {API_ENDPOINT_V2, SECRET_KEY} from 'Redux/apiConstant'
import { default as DataGrids } from '../../utils/DataGrid/index';
import Loader from 'react-loader';
import { DiscrepancyReport, DeleteDiscrepancyReport, UpdateDiscrepancy } from '../../Redux/actions/discrepancy'
import { InputFilter } from 'utils/DataGrid/Components/InputFilter.js';



const v2Host = API_ENDPOINT_V2;

class Discrepency extends Component{
    constructor(props){
        super(props)
        this.state={
            search_term:'',
            searchValue:'',
            is_loaded:true,
            searchList:[],
            editItem:[],
            newStatus:"",
            newcomment1:"",
            newcomment2:"",
            id:"",
            showDate:false,
            search_error_message:"",
            deleteID:"",
            updated:false,
            updatedMessage:""
            // createdAtDate:""
        }
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.modalData = this.modalData.bind(this);
        this.statusEdit = this.statusEdit.bind(this);
        this.comment1Edit = this.comment1Edit.bind(this);
        this.comment2Edit = this.comment2Edit.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.discrepancyDelete = this.discrepancyDelete.bind(this);
        this.discrepancyUpdate = this.discrepancyUpdate.bind(this);
        this.getDate = this.getDate.bind(this);
    }

    getDate = (date = new Date()) => {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    };

    onChangeSearch = (e) => {
        this.setState({searchValue:e.target.value})
    }

    onChangeInput = (e) => {
      if(e.target.value === 'createdAt'){
        this.setState({search_term:e.target.value,showDate:true,searchValue:""})
      }else{
        this.setState({search_term:e.target.value,showDate:false})
      }
    }

    onSearch = () => {
        this.setState({is_search: true}, () => {this.getDiscrepancyReport()})
    }

    getDiscrepancyReport() {
      this.setState({is_loaded:false})
        let { searchValue, search_term } = this.state
      // const headers = {
      //   'stockal-secret-key': SECRET_KEY,
      // };
      // axios.get(`${v2Host}/users-dashboard/portfolio/discrepancy-report/?${search_term}=${searchValue}`,  {headers} )
      DiscrepancyReport({
           searchValue,
           search_term
          })
      .then((response) => {
        if(response.data.code === 200){
          //start 
          const edited = response.data.data.map((item) => {
            return  {
              ...item,
              button1: (
                <button
                  onClick={() => this.modalData(item)}
                  type='button'
                  data-toggle='modal'
                  data-target='#exampleModalCenter'
                  className='btn-sm btn-primary btn-stockal btn-user btn-block'
                >
                  EDIT
                </button>
              ),
              button2: (
                <button
                  onClick={() => this.deleteData(item)}
                  type='button'
                  data-toggle="modal" 
                  data-target="#exampleModal"
                  className='btn-sm btn-danger btn-user btn-block'
                >
                  delete
                </button>
              )
            }
          });
          if(response.data.data && response.data.data.length > 0 ){
            this.setState({
              searchList:edited,
              is_loaded:true,
             })
            }
            else{
              this.setState({searchList:[],is_loaded:true,search_error_message:"No data found" })
            }
          // end 
        }else{
      this.setState({searchList:[],is_loaded:true,search_error_message:"No data found" })
        }
      }) .catch((err) => {
        console.log('something error', err)
        this.setState({search_error_message:"No Data Found",is_loaded:true,searchList:[]})
      })
    }
    
    modalData = (item) => {
      this.setState({editItem:item,id:item._id,
      newStatus:item.status,newcomment1:item.comment1,
      newcomment2:item.comment2,updated:false})
    }

    deleteData = (item) => {
      this.setState({deleteID:item._id,updated:false})
    }

    statusEdit = (event) => {
      this.setState({newStatus: event.target.value});
    }

    comment1Edit = (e) => {
      this.setState({newcomment1:e.target.value})
    } 

    comment2Edit = (e) => {
      this.setState({newcomment2:e.target.value})
    } 

    discrepancyUpdate() {
      let { id, newStatus,newcomment1,newcomment2 } = this.state
      // const body = {
      //   status : newStatus,
      //   comment1 : newcomment1,
      //   comment2 : newcomment2
      // }
      // const headers = {
      //   'stockal-secret-key': SECRET_KEY,
      // };
      // axios.put(`${v2Host}/users-dashboard/portfolio/discrepancy-report/${id}`,body ,{headers})
      UpdateDiscrepancy({id,newStatus,newcomment1,newcomment2})
      .then(response => {
        console.log('coming here',response)
        if(response.data.code === 200){
          this.getDiscrepancyReport()
          this.setState({ updated:true,updatedMessage:"Updated Successfuly"})
        }
        else{
          console.log('something went wrong')
          this.setState({ updated:true,updatedMessage:"Try Again"})
        }
        }).catch((err) => {
          console.log('something error', err)
          this.setState({ updated:true,updatedMessage:"Something went wrong!"})
        });
    }

    discrepancyDelete() {
      let { deleteID } = this.state
      // const headers = {
      //   'stockal-secret-key': SECRET_KEY,
      // };
      // axios.delete(`${v2Host}/users-dashboard/portfolio/discrepancy-report/${deleteID}`, {headers})
      DeleteDiscrepancyReport({deleteID})
      .then(response => {
        if(response.data.code === 200){
          this.getDiscrepancyReport()
          this.setState({updated:true,updatedMessage:"Deleted successfuly"})
        }
        else{
          console.log('something went wrong')
          this.setState({updated:true,updatedMessage:"Soemthing went wrong! Try Again"})
        }
      }).catch((err) => {
        console.log('something error', err)
        this.setState({updated:true,updatedMessage:"Soemthing went wrong! Try Again"})
      })
    }

    

    render(){
      console.log('search',this.state.search_term)
      let { editItem, is_loaded } = this.state
        return(
            <div>
              <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <h5 class="modal-title" id="exampleModalLabel">{editItem.dwUsername}</h5>
                      <h5 class="modal-title" id="exampleModalLabel">{editItem.email}</h5> */}
                      <h4>Update Report</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span> 
                      </button>
                    </div>
                    <div className="modal-body">
                      <h5 className="modal-title" id="exampleModalLabel">USERNAME:    {editItem.dwUsername}</h5>
                      <h5 className="modal-title" id="exampleModalLabel">EMAIL:   {editItem.email}</h5>
                      <h5 className="modal-title" id="exampleModalLabel">USERID:   {editItem.userID}</h5>
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"><h5>STATUS</h5></label>
                        <input className="form-control" id="exampleFormControlTextarea1" value={this.state.newStatus} onChange={this.statusEdit}></input>
                      </div>
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"><h5>Comment 1</h5></label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" value={this.state.newcomment1} onChange={this.comment1Edit}></textarea>
                      </div>
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"><h5>Comment 2</h5></label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" value={this.state.newcomment2} onChange={this.comment2Edit}></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={this.discrepancyUpdate}>Save changes</button>
                    </div>
                    {this.state.updated ? (
                      <div class='alert alert-success text-center' role='alert'>
                        {this.state.updatedMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              
              {/* delete modal */}
              <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Do you really want to delete ?</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">NO</button>
                      <button type="button" className="btn btn-primary" onClick={this.discrepancyDelete}>YES</button>
                    </div>
                    {this.state.updated ? (
                      <div class='alert alert-success text-center' role='alert'>
                        {this.state.updatedMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* end */}


              <SearchFilter 
              state={this.state}
              onChangeSearch={this.onChangeSearch}
              onChangeInput={this.onChangeInput}
              onSearch={this.onSearch}
              reset={this.reset}
              showDate={this.state.showDate}
              />

            { this.state.showDate ?
            <div className="d-inline-flex">
            <DatePicker
              className='form-control form-control-sm '
              dateFormat='yyyy-MM-dd'
              value={this.state.searchValue.slice(
                0,
                10
              )}
              onChange={(date) => {
                date = this.getDate(date);
                this.setState({
                  searchValue: date.toString(),
                    // expiry: date.toString(),
                });
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
            /> 
            <div className="form-group col-md-2">
            <button className="btn btn-primary btn-stockal" style={{width:"125px",height:"35px"}} onClick={this.onSearch}>Search</button>
           </div>
           </div>
             : "" }

            
              {/* // <DateFilter
              // start_date={this.state.start_date}
              // // end_date={this.state.end_date}
              // partnerId={this.state.partnerId}
              // // onSubmit={() => this.getCustomersList(1)}
              // onChange={this.onChange}
              // onChangeDate={this.onDateChange}
              // {...this.props}
               */}

            <Loader loaded={is_loaded}> 
            {
              this.state.searchList && this.state.searchList.length > 0 ? (
                <div>
                  <DataGrids
                    columns={[
                      { key: 'userID', name: 'USERID', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'dwCostBasis', name: 'DW COST BASIS', filterRenderer: p => <InputFilter props={p} /> },
                      // {
                      //   key: 'discountPer',
                      //   name: 'DISCOUNT PERCENTAGE',
                      //   resizable: false,
                      // },
                      { key: 'comment1', name: 'COMMENT 1', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'comment2', name: 'COMMENT 2', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'dwQuantity', name: 'DW QUANTITY' , filterRenderer: p => <InputFilter props={p} />},
                      { key: 'stoCostBasis', name: 'STOCKAL COST BASIS', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'stoQuantity', name: 'STOCKAL QUANTITY', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'dwUsername', name: 'DW USERNAME' , filterRenderer: p => <InputFilter props={p} />},
                      { key: 'email', name: 'EMAIL', filterRenderer: p => <InputFilter props={p} /> },
                      { key: 'name', name: 'NAME' , filterRenderer: p => <InputFilter props={p} />},
                      { key: 'partner', name: 'PARTNER' , filterRenderer: p => <InputFilter props={p} />},
                      { key: 'status', name: 'STATUS' , filterRenderer: p => <InputFilter props={p} />},
                     
                      { key: 'symbol', name: 'SYMBOL' , filterRenderer: p => <InputFilter props={p} />},
                      { key: 'button1', name: '' , exportFormatter: () => ''},
                      { key: 'button2', name: '' , exportFormatter: () => ''}
                    ]}
                    rows={this.state.searchList}
                    onScroll={(e) => {
                      if (
                        e.target.offsetHeight + e.target.scrollTop >
                        e.target.scrollHeight
                      ) {
                        if (this.props.scrollEvent) {
                          this.props.scrollEvent();
                        }
                      }
                    }}
                  />
                </div>
              ) : 
                <h3>{this.state.search_error_message}</h3>
            }
            </Loader>

                {/* <table className="table mt-5">
                    <thead>
                    <TableHeaders 
                    />
                    </thead>    
                    {this.state.searchList && this.state.searchList.length > 0 ?
                    this.state.searchList.map((item, index) => (
                    <tbody>
                    <tr>
                      <th>{item.userID}</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    </tbody>
                    )) : "" }
                </table>     */}
            </div>
        )
    }
}

const TableHeaders = () => {
    return (
      <tr className="portfolioHead">
        <th>ID</th>
        <th>Status</th>
        <th>Category</th>
        <th>Name</th>
        <th>Email</th>
        <th>Partner</th>
        <th>UserID</th>
        <th>accountID</th>
        <th>symbol</th>
    </tr>
    )
  }



const search_keys = {
    "dwUsername": "DwUsername",  
    "userID" : 'UserID',
    "createdAt": "Date"
  }
  
  const SearchFilter = ({state, onChangeSearch, onSearch, onChangeInput,showDate}) => {
    let {  search_term, term } = state
    let searchInputClassName= (search_term==="phone"?"number-control":"")
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-group col-md-2">
          <select 
            className="form-control"
            value={search_term} 
            onChange={onChangeInput}
            name="search_term"  
          > 
          <option value="" >Search By </option>
          { 
            Object.keys(search_keys).map((data) => (<option key={data} value={data}>{search_keys[data]}</option>)
          )}
          </select>
          </div>
          {
            showDate ? '' :
            search_term && search_term !== "" &&
            <React.Fragment>
              <div className="form-group col-md-3">
                <input 
                  className={`${searchInputClassName} form-control`}
                  // type= {search_term==="phone"?"number":"text"} 
                  name="term"
                  value={term}
                  onChange={onChangeSearch}
                />
              </div>
              <div className="form-group col-md-2">
               <button className="btn btn-primary btn-stockal" style={{width:"125px"}} onClick={onSearch}>Search</button>
              </div>
            </React.Fragment>
          }
          </div>
       </React.Fragment>
    )
  }

export default Discrepency