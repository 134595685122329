
import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class ConfirmAlert extends Component {

  submit = () => {
    confirmAlert({
      title: `Confirm to ${this.props.name} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.confirmAlert()
        },
        {
          label: 'No',
        }
      ]
    })
  };

  render() {
    return (
      <div className="container" style={{cursor:'pointer'}}>   
        <button 
          onClick={this.submit}
          className="btn btn-dark" 
          style={{width:"130px"}} 
          disabled={this.props.disabled}
        >{this.props.button_name}
        </button>
      </div>
    );
  }
}

export default ConfirmAlert