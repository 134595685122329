import React, { Component } from 'react';

import { commentCategory } from 'utils/common';
import { CommentBox } from 'utils/CommentComponent';

export default class BasicDetails extends Component {
  render() {
    let { user, refCode } = this.props;
    if (user) {
      var partnerId = user.riaId;
      if (user.dw && user.dw.length > 0)
        var { phoneHome, addressLine2, addressLine1, city } = user.dw[0];
    }
    if (refCode && refCode.length > 0) var refcode = refCode[0].bankToken;
    return (
      <div className="card">
        <div className="card-header">
          Basic Information
        </div>
        <div className="card-body customer-details-body">
        <div className="col-md-8">
          <div className="customer-details-body-card1">
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>Name</b></p>
              <p className="col-md-8 mt-0">{user && user.fullname}</p>
            </div>
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>Login Username</b></p>
              <p className="col-md-8 mt-0">{user && user.dwUsername}</p>
            </div>
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>Email</b></p>
              <p className="col-md-8 mt-0">{user && user.username}</p>
            </div>
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>RefCode</b></p>
              <p className="col-md-8 mt-0">{refcode && refCode !== "" ? refcode : 'NA'}</p>
            </div> 
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>Phone</b></p>
              <p className="col-md-8 mt-0">{phoneHome ? phoneHome : "--"}</p>
            </div>
            <div className="customer-details-body-card1-details">
              <p className="col-md-2 text-justify"><b>Address</b></p>
              <p className="col-md-8 mt-0">{addressLine1 ? (addressLine1 , addressLine2 , city) : "--" }</p>
            </div>
          </div>
          </div>
          <div className='col-md-4'>
            <CommentBox
              category={commentCategory.user}
              categoryID={this.props.userId}
              userId={this.props.userId}
              createdBy={this.props.auth}
              applicantEmail={user && user.username}
              applicantName={user && user.fullname}
            />
          </div>
        </div>
      </div>
    );
  }
}
